import { createSelector } from '@reduxjs/toolkit';
import { generateLadder } from '../utils/generate-ladder';

// Selectors for state slices
export const selectPredictions = (state) => state.ladderPredictor.predictions;
export const selectTeams = (state) => state.ladderPredictor.teams;
export const selectPreviousLadder = (state) =>
    state.ladderPredictor.previousLadder;

// Memoized selector for generating the ladder
export const selectLadder = createSelector(
    [selectPredictions, selectTeams, selectPreviousLadder],
    (predictions, teams, previousLadder) => {
        // Generate the current ladder using utility function
        return generateLadder(predictions, teams, previousLadder);
    }
);
