import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useLeaderboardNavSync } from './hooks/use-leaderboard-nav-sync';
import { leaderboardNavSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { ClubNavItem, SeasonNavItem, Search } from './components/nav-items';
import { SettingsButton } from 'common/react/components/SettingsButton';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { ShowPredicted } from '../nav-items/ShowPredicted';
import { useGetCurrentCompFilters } from 'widgets/brownlow-predictor-v2/js/hooks/use-get-current-comp-filters';
import { TeamFilterItem } from 'common/react/components/competition-nav/filter-items';
import { useResetTeamFilter } from 'widgets/brownlow-predictor-v2/js/hooks/use-reset-team-filter';
import { LeaderboardTableFetcher } from './components/table/LeaderboardTableFetcher';
import { useFiltersTracking } from 'common/react/hooks/use-filters-tracking';

export const Leaderboard = ({
    seasonsToShow,
    seasonPid,
    competitionId,
    shouldHidePredicted,
    title
}) => {
    const nav = useSelector(leaderboardNavSelector);
    const leaderboardNavRef = useRef();

    const options = useCompetitionNavigationOptions(nav);
    const windowSize = useWindowSize();

    options.defaultSeason = seasonPid ?? seasonPid;
    options.seasonsToShow = seasonsToShow ?? seasonsToShow;

    const newOpts = {
        ...options,
        competitionId: competitionId
    };
    useLeaderboardNavSync(newOpts);

    const classPrefix = 'brownlow-leaderboard';

    const shouldHidePredictedBool =
        shouldHidePredicted === 'true' ? true : false;

    const isResponsive = () =>
        windowSize.width <= PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    const currentFilters = useGetCurrentCompFilters();

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    /**
     * custom hook providing tracking of filters
     */
    useFiltersTracking({
        title,
        filtersState: nav,
        options,
        filtersFields: ['season', 'club', 'showPredicted', 'sortByFavourites'],
        filtersRef: leaderboardNavRef
    });

    return (
        <div className={classPrefix} ref={leaderboardNavRef}>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <SeasonNavItem seasonsToShow={seasonsToShow} />
                        {!showResponsiveItems ? (
                            <ClubNavItem
                                classes={'u-margin-right-auto'}
                                title={title}
                            />
                        ) : null}
                        {!showResponsiveItems && !shouldHidePredictedBool ? (
                            <ShowPredicted />
                        ) : null}
                        {!showResponsiveItems ? (
                            <div className={`${classPrefix}-nav__extras`}>
                                <Search />
                            </div>
                        ) : null}
                    </StatsSection.NavItems>
                    {showResponsiveItems &&
                    currentFilters.competitionId &&
                    currentFilters.compSeason?.id ? (
                        <StatsSection.NavFilterItems>
                            <TeamFilterItem
                                currentFilters={currentFilters}
                                addOnResetCallback={useResetTeamFilter}
                                isBrownlow={true}
                                title={title}
                            />
                        </StatsSection.NavFilterItems>
                    ) : null}

                    <StatsSection.SettingsButton>
                        <StatsSection.InfoButton
                            shouldHidePredicted={shouldHidePredictedBool}
                            seasonPid={seasonPid}
                            tooltipContent="PlayerAwardsLeaderboardTooltip"
                        />
                        <SettingsButton />
                    </StatsSection.SettingsButton>

                    {showResponsiveItems ? (
                        <StatsSection.SearchBarMobile>
                            <div className={`${classPrefix}-nav__extras`}>
                                <Search />
                            </div>
                        </StatsSection.SearchBarMobile>
                    ) : null}
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentBody>
                        {showResponsiveItems && !shouldHidePredictedBool ? (
                            <ShowPredicted />
                        ) : null}
                        <LeaderboardTableFetcher
                            cssClass={classPrefix}
                            shouldHidePredicted={shouldHidePredictedBool}
                            title={title}
                        />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
        </div>
    );
};

Leaderboard.propTypes = {
    seasonsToShow: PropTypes.string,
    seasonPid: PropTypes.string,
    competitionId: PropTypes.string,
    shouldHidePredicted: PropTypes.string,
    title: PropTypes.string
};
