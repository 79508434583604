import React, { useState, useMemo, useEffect } from 'react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { Select } from 'common/react/components/select';
import PropTypes from 'prop-types';

import { selectRemainingPredictions } from '../../selector/remaining-predictions';

import { getTranslation } from 'common/react/utils/translations';

import { ByesGroup } from './components/byes-group';

import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

import { groupMatchesByDate } from 'widgets/fixtures/js/utils/group-matches-by-date';
import { groupMatchesByRoundName } from 'widgets/fixtures/js/utils/group-matches-by-roundName';

import {
    findLastInstanceAndInsert,
    filteredTeams
} from 'widgets/fixtures/js/utils/helpers';
import { TypeRenderer } from './components/type-renderer';

import { useDispatch, useSelector } from 'react-redux';
import {
    setTotalMatches,
    setRound,
    setTeam,
    setCurrentFilter
} from 'common/store/modules/ladder-predictor/init';

import { CONFIG } from '../constants';

/*
 * Sidebar
 */
export const Sidebar = ({
    seasonId,
    teamsData,
    matchesData,
    roundsData,
    currentRoundNumber
}) => {
    const selectedRegion = useSingleSiteSetting('region');
    const [filter, setFilter] = useState(CONFIG.ROUND);
    const [selectedRound, setSelectedRound] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [currentRoundIndex, setCurrentRoundIndex] = useState(0);
    const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
    const getRemainingPredictions = useSelector(selectRemainingPredictions);
    const dispatch = useDispatch();

    // Set and process rounds
    const rounds = useMemo(
        () =>
            roundsData
                ? [...roundsData]
                      .filter(PULSE.app.common.match.isNonFinalRound)
                      .sort((a, b) => a.roundNumber - b.roundNumber)
                      .map((round) => ({
                          ...round
                      }))
                : null,
        [roundsData]
    );

    // Set and process teams
    const teams = useMemo(
        () =>
            teamsData
                ? [...teamsData]
                      .filter((team) => team.name)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((team) => ({
                          ...team,
                          teamAbbr: team.abbreviation
                      }))
                : null,
        [teamsData]
    );

    // Group matches by team and round
    const groupMatchesByTeamAndRound = useMemo(() => {
        if (matchesData) {
            const filteredMatches = matchesData.filter(
                (match) =>
                    (match.round.name.includes(CONFIG.ROUND_INDENTIFIER) &&
                        !match.round.name.includes(CONFIG.FINAL_IDENTIFIER)) ||
                    (match.round.name.includes(CONFIG.WEEK_INDENTIFIER) &&
                        !match.round.name.includes(CONFIG.FINAL_IDENTIFIER))
            );
            const organised = {
                byTeam: {},
                byRound: {}
            };

            filteredMatches.forEach((match) => {
                const homeTeam = match.home.team.id;
                const awayTeam = match.away.team.id;
                const roundName = match.round.id;

                // Add match to the home team's bucket
                if (!organised.byTeam[homeTeam]) {
                    organised.byTeam[homeTeam] = [];
                }
                organised.byTeam[homeTeam].push(match);

                // Add match to the away team's bucket
                if (!organised.byTeam[awayTeam]) {
                    organised.byTeam[awayTeam] = [];
                }
                organised.byTeam[awayTeam].push(match);

                // Add match to the round's bucket
                if (!organised.byRound[roundName]) {
                    organised.byRound[roundName] = [];
                }
                organised.byRound[roundName].push(match);
            });

            return organised;
        }

        return [];
    }, [matchesData]);

    // Set total matches
    useEffect(() => {
        if (matchesData && matchesData.length) {
            const totalMatches = matchesData.filter(
                (match) => match.status !== CONFIG.CONCLUDED
            ).length;
            dispatch(setTotalMatches(totalMatches));
        }
    }, [matchesData]);

    // Process grouped matches ready for render
    const processedGroupedMatches = useMemo(() => {
        if (
            groupMatchesByTeamAndRound.byRound &&
            groupMatchesByTeamAndRound.byTeam &&
            selectedTeam &&
            selectedRound &&
            filter
        ) {
            let matchesList = [];
            if (filter === CONFIG.ROUND) {
                matchesList =
                    groupMatchesByTeamAndRound.byRound[selectedRound.id];
            } else if (filter === CONFIG.TEAM) {
                matchesList =
                    groupMatchesByTeamAndRound.byTeam[selectedTeam.id];
            }

            if (matchesList) {
                // (1) Group matches by date
                const groupedByDate = groupMatchesByDate(
                    matchesList,
                    selectedRegion.timezone
                );

                // (2) Group matches by round name within each date
                for (const date in groupedByDate) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            groupedByDate,
                            date
                        )
                    ) {
                        const groupedByRoundName = groupMatchesByRoundName(
                            groupedByDate[date]
                        );
                        groupedByDate[date] =
                            Object.entries(groupedByRoundName);
                    }
                }

                // (3) Flatten the grouped matches structure
                let flattenResult = Object.entries(groupedByDate).flat(
                    CONFIG.FLATTEN_DEPTH
                );

                // (4) Process bye rounds for the specific team
                if (filter === CONFIG.TEAM && selectedTeam && roundsData) {
                    let arrayWithByes = [];

                    for (const round of roundsData) {
                        if (
                            round.byes.length &&
                            round.byes.some((bye) => bye.id === selectedTeam.id)
                        ) {
                            const newRound = { ...round, isBye: true };
                            const teamsWithBye = filteredTeams(newRound, {
                                teams: [selectedTeam.id]
                            });

                            if (teamsWithBye.length) {
                                arrayWithByes = findLastInstanceAndInsert(
                                    flattenResult,
                                    newRound
                                );
                            }
                        }
                    }

                    if (arrayWithByes.length) {
                        return arrayWithByes;
                    }
                }

                return flattenResult;
            }
        }
    }, [groupMatchesByTeamAndRound, selectedTeam, selectedRound, filter]);

    // Paginate displayed team or group
    const paginate = (delta) => {
        const isRound = filter === CONFIG.ROUND;
        const isTeam = filter === CONFIG.TEAM;

        if (isRound) {
            const nextIndex = currentRoundIndex + delta;
            if (nextIndex >= 0 && nextIndex < rounds.length) {
                setSelectedRound(rounds[nextIndex]);
                dispatch(setRound(rounds[nextIndex]));
                setCurrentRoundIndex(nextIndex);
            }
        } else if (isTeam) {
            const nextIndex = currentTeamIndex + delta;
            if (nextIndex >= 0 && nextIndex < teams.length) {
                setSelectedTeam(teams[nextIndex]);
                dispatch(setTeam(teams[nextIndex]));
                setCurrentTeamIndex(nextIndex);
            }
        }
    };

    // Disable next and previous buttons
    const isNextDisabled = useMemo(() => {
        if (filter === CONFIG.ROUND) {
            return currentRoundIndex >= rounds.length - 1;
        }
        if (filter === CONFIG.TEAM) {
            return currentTeamIndex >= teams.length - 1;
        }
        return true;
    }, [
        filter,
        currentRoundIndex,
        currentTeamIndex,
        rounds.length,
        teams.length
    ]);

    const isPreviousDisabled = useMemo(() => {
        if (filter === CONFIG.ROUND) {
            return currentRoundIndex <= 0;
        }
        if (filter === CONFIG.TEAM) {
            return currentTeamIndex <= 0;
        }
        return true;
    }, [filter, currentRoundIndex, currentTeamIndex]);

    // Set current round to the default round or
    // the current round if it is in the dropdown.
    useEffect(() => {
        if (rounds?.length && !selectedRound) {
            const defaultRound = rounds[0];
            const currentRound = rounds[currentRoundNumber];
            if (currentRound) {
                setSelectedRound(currentRound);
                dispatch(setRound(currentRound));
                setCurrentRoundIndex(currentRound.roundNumber);
            } else {
                setSelectedRound(defaultRound);
                dispatch(setRound(defaultRound));
                setCurrentRoundIndex(0);
            }
        }
    }, [rounds, selectedRound]);

    // Set current team
    useEffect(() => {
        if (teams?.length && !selectedTeam) {
            const defaultTeam = teams[0];
            setSelectedTeam(defaultTeam);
            dispatch(setTeam(defaultTeam));
            setCurrentTeamIndex(0);
        }
    }, [teams, selectedTeam]);

    // Get remaining predictions
    const remainingPredictions = useMemo(
        () => getRemainingPredictions(matchesData),
        [matchesData, getRemainingPredictions]
    );

    // Set dropDownRounds
    const dropDownRounds = useMemo(
        () =>
            Object.keys(remainingPredictions.ByRound).length
                ? [...roundsData]
                      .filter(PULSE.app.common.match.isNonFinalRound)
                      .sort((a, b) => a.roundNumber - b.roundNumber)
                      .map((round) => ({
                          ...round,
                          total: remainingPredictions.ByRound[round.id]
                              .remainingPredictions
                      }))
                : null,
        [remainingPredictions?.ByRound]
    );

    // Set dropDownTeams
    const dropDownTeams = useMemo(
        () =>
            Object.keys(remainingPredictions.ByTeam).length
                ? [...teamsData]
                      .filter((team) => team.name)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((team) => ({
                          ...team,
                          teamAbbr: team.abbreviation,
                          total: remainingPredictions.ByTeam[team.id]
                              .remainingPredictions
                      }))
                : null,
        [remainingPredictions?.ByTeam]
    );

    return (
        <aside className="ladder-predictor-sidebar">
            {!matchesData && (
                <div className="ladder-predictor-sidebar__loading"></div>
            )}

            <div className="ladder-predictor-sidebar__header">
                <div className="ladder-predictor-sidebar__toggles">
                    <h3 className="ladder-predictor-sidebar__title">
                        {getTranslation('label.ladderPredictor.matches')}
                    </h3>
                    <div className="ladder-predictor-sidebar__view-toggle-buttons">
                        <button
                            className={`ladder-predictor-sidebar__view-toggle-button ${
                                filter === CONFIG.ROUND
                                    ? 'ladder-predictor-sidebar__view-toggle-button--active'
                                    : ''
                            }`}
                            onClick={() => {
                                setFilter(CONFIG.ROUND);
                                dispatch(setCurrentFilter(CONFIG.ROUND));
                            }}
                        >
                            {getTranslation('label.ladderPredictor.byRound')}
                        </button>
                        <button
                            className={`ladder-predictor-sidebar__view-toggle-button ${
                                filter === CONFIG.TEAM
                                    ? 'ladder-predictor-sidebar__view-toggle-button--active'
                                    : ''
                            }`}
                            onClick={() => {
                                setFilter(CONFIG.TEAM);
                                dispatch(setCurrentFilter(CONFIG.TEAM));
                            }}
                        >
                            {getTranslation('label.ladderPredictor.byTeam')}
                        </button>
                    </div>
                </div>

                <div className="ladder-predictor-sidebar__filters">
                    <div className="ladder-predictor-sidebar__select">
                        {filter === CONFIG.ROUND
                            ? dropDownRounds?.length && (
                                  <Select
                                      items={dropDownRounds}
                                      itemToString={(round) => round.name}
                                      initialSelectedItem={dropDownRounds[0]}
                                      activeItem={
                                          dropDownRounds.length &&
                                          selectedRound?.id
                                              ? dropDownRounds.find(
                                                    (round) =>
                                                        round.id ===
                                                        selectedRound.id
                                                )
                                              : dropDownRounds[0]
                                      }
                                      isReadyToRender={() =>
                                          dropDownRounds?.length
                                      }
                                      label={getTranslation(
                                          'label.ladderPredictor.filter'
                                      )}
                                      hideLabel={true}
                                      onSelectedItemChange={({
                                          selectedItem
                                      }) => {
                                          const selectedIndex =
                                              dropDownRounds.findIndex(
                                                  (round) =>
                                                      round.id ===
                                                      selectedItem.id
                                              );
                                          setSelectedRound(selectedItem);
                                          dispatch(setRound(selectedItem));
                                          setCurrentRoundIndex(selectedIndex);
                                      }}
                                      displaySlim={true}
                                      showTotals={true}
                                  />
                              )
                            : dropDownTeams?.length && (
                                  <Select
                                      items={dropDownTeams}
                                      itemToString={(team) => team.name}
                                      initialSelectedItem={dropDownTeams[0]}
                                      showTeamLogo={true}
                                      activeItem={
                                          dropDownTeams.length &&
                                          selectedTeam?.id
                                              ? dropDownTeams.find(
                                                    (team) =>
                                                        team.id ===
                                                        selectedTeam.id
                                                )
                                              : dropDownTeams[0]
                                      }
                                      isReadyToRender={() =>
                                          dropDownTeams?.length
                                      }
                                      label={getTranslation(
                                          'label.ladderPredictor.filter'
                                      )}
                                      hideLabel={true}
                                      onSelectedItemChange={({
                                          selectedItem
                                      }) => {
                                          const selectedIndex =
                                              dropDownTeams.findIndex(
                                                  (team) =>
                                                      team.id ===
                                                      selectedItem.id
                                              );
                                          setSelectedTeam(selectedItem);
                                          dispatch(setTeam(selectedItem));
                                          setCurrentTeamIndex(selectedIndex);
                                      }}
                                      displaySlim={true}
                                      showTotals={true}
                                  />
                              )}
                    </div>

                    <div className="ladder-predictor-sidebar__navigation-buttons">
                        <button
                            className="ladder-predictor-sidebar__navigation-button ladder-predictor-sidebar__navigation-button--previous"
                            onClick={() => {
                                paginate(-1);
                            }}
                            disabled={isPreviousDisabled}
                        >
                            <SvgIcon
                                icon="arrow-left"
                                className="filter-button__icon"
                            />
                            <span className="u-screen-reader">
                                {getTranslation(
                                    'label.ladderPredictor.previous'
                                )}
                            </span>
                        </button>
                        <button
                            className="ladder-predictor-sidebar__navigation-button ladder-predictor-sidebar__navigation-button--next"
                            onClick={() => {
                                paginate(1);
                            }}
                            disabled={isNextDisabled}
                        >
                            <span className="u-screen-reader">
                                {getTranslation('label.ladderPredictor.next')}
                            </span>
                            <SvgIcon
                                icon="arrow-right"
                                className="filter-button__icon"
                            />
                        </button>
                    </div>
                </div>
            </div>

            <div className="ladder-predictor-sidebar__list">
                {processedGroupedMatches?.length
                    ? processedGroupedMatches.map((item, index) => (
                          <TypeRenderer
                              item={item}
                              index={index}
                              selectedTeam={selectedTeam}
                              isTeam={filter === CONFIG.TEAM}
                              key={
                                  typeof item === 'string'
                                      ? `${item}-${index}`
                                      : item.id
                              }
                          />
                      ))
                    : null}

                {selectedRound &&
                !isNaN(selectedRound.roundNumber) &&
                selectedRound.roundNumber < CONFIG.LAST_BYE_ROUND &&
                filter === CONFIG.ROUND &&
                seasonId ? (
                    <ByesGroup
                        compseasonId={Number(seasonId)}
                        roundNumber={selectedRound.roundNumber}
                    />
                ) : null}
            </div>

            <div className="ladder-predictor-sidebar__footer">
                <button
                    className="ladder-predictor-sidebar__footer-navigation-button ladder-predictor-sidebar__footer-navigation-button--previous"
                    onClick={() => {
                        paginate(-1);
                    }}
                    disabled={isPreviousDisabled}
                >
                    <SvgIcon
                        icon="arrow-left"
                        className="filter-button__icon"
                    />
                    {getTranslation('label.ladderPredictor.previous')}
                </button>
                <button
                    className="ladder-predictor-sidebar__footer-navigation-button ladder-predictor-sidebar__footer-navigation-button--next"
                    onClick={() => {
                        paginate(1);
                    }}
                    disabled={isNextDisabled}
                >
                    {getTranslation('label.ladderPredictor.next')}
                    <SvgIcon
                        icon="arrow-right"
                        className="filter-button__icon"
                    />
                </button>
            </div>
        </aside>
    );
};

Sidebar.propTypes = {
    seasonId: PropTypes.string,
    teamsData: PropTypes.arrayOf(Object),
    matchesData: PropTypes.arrayOf(Object),
    roundsData: PropTypes.arrayOf(Object),
    currentRoundNumber: PropTypes.string
};
