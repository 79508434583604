import React from 'react';

import { ModalTitle } from './ModalTitle';
import { ModalOver18 } from './ModalOver18';

import { getTranslation } from 'common/react/utils/translations';
import { useSiteSettings } from 'common/react/hooks/use-site-settings';

export const Over18ModalWrapper = () => {
    const { siteSettings, setSiteSettings, settingsConfig } = useSiteSettings();

    return (
        <>
            <ModalTitle
                title={getTranslation('settingsModal.aflidSettings')}
                closeBtn={false}
            />
            <ModalOver18
                title={getTranslation('settingsModal.over18Question')}
                subtitle={getTranslation('settingsModal.over18Subtitle')}
                buttons={[
                    {
                        label: getTranslation('settingsModal.over18Yes'),
                        onClick: async () => {
                            setSiteSettings({
                                [settingsConfig.over18.key]: true
                            });

                            // Force update on MIS immediately
                            const settingsToSync =
                                await PULSE.app.common.MisPrefSync.filterOnlySyncedSettings(
                                    {
                                        ...siteSettings,
                                        [settingsConfig.over18.key]: true
                                    }
                                );

                            try {
                                await PULSE.app.common.MisPrefSync.updateSettings(
                                    settingsToSync
                                );
                                // Close the modal once a choice is made
                                PULSE.app.common.siteSettings.closeSettingsModal();
                            } catch (error) {
                                PULSE.app.common.siteSettings.openSettingsModal(
                                    'over18Error'
                                );
                            }
                        }
                    },
                    {
                        label: getTranslation('settingsModal.over18No'),
                        onClick: async () => {
                            setSiteSettings({
                                [settingsConfig.over18.key]: false
                            });
                            setSiteSettings({
                                [settingsConfig.displayWagering.key]: false
                            });

                            // Force update on MIS immediately
                            const settingsToSync =
                                PULSE.app.common.MisPrefSync.filterOnlySyncedSettings(
                                    {
                                        ...siteSettings,
                                        [settingsConfig.over18.key]: false,
                                        [settingsConfig.displayWagering
                                            .key]: false
                                    }
                                );

                            try {
                                await PULSE.app.common.MisPrefSync.updateSettings(
                                    settingsToSync
                                );
                                // Close the modal once the choice is made
                                PULSE.app.common.siteSettings.closeSettingsModal();
                            } catch (error) {
                                PULSE.app.common.siteSettings.openSettingsModal(
                                    'over18Error'
                                );
                            }
                        }
                    }
                ]}
                note={getTranslation('settingsModal.over18Note')}
            />
        </>
    );
};
