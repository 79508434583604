import React from 'react';
import PropTypes from 'prop-types';

import { LazyImage } from 'common/react/components/LazyImage';
import { useGetContentItemQuery } from 'common/store/modules/api/content-api';

export const MapLocationSelectorImage = ({ selectedVenue }) => {
    const mapContainer = document.querySelector(
        '.js-interactive-event-map-container'
    );
    const startingVenueLogo = useGetContentItemQuery({
        type: 'PHOTO',
        id: mapContainer.dataset.startingImage
    });
    const startingLogoURL = `${startingVenueLogo.data?.onDemandUrl}?width=300`;

    return (
        <LazyImage
            url={
                selectedVenue.imgRef !== 'undefined'
                    ? selectedVenue.imgRef
                    : startingLogoURL
            }
            useImg={true}
            objectFit={false}
            cssClass="map-location-selector__selected-logo"
        />
    );
};

MapLocationSelectorImage.propTypes = {
    selectedVenue: PropTypes.shape({
        imgRef: PropTypes.string
    })
};
