import React, { useEffect, useState, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { WinnerCard } from '../winner-card/winner-card';
import { getTranslation } from 'common/react/utils/translations';
import { MatchCardFinalUpcoming } from '../match-cards/final/upcoming';
import { MatchCardFinalAwaiting } from '../match-cards/final/awaiting';
import {
    setActiveView,
    setFinalsStep,
    setSeasonStep,
    setToolTip,
    setTotalFinalsMatches,
    setLocalStorageFinalsName,
    setPredictionsFinals
} from 'common/store/modules/ladder-predictor/init';

import { useDispatch, useSelector } from 'react-redux';
import { selectLadder } from '../../selector/ladder';
import {
    createBracket,
    createSemiFinals,
    createPreliminaryFinals,
    createGrandFinal,
    createWinner,
    getTeams
} from '../../utils/generate-bracket';
import { predictionsFinalsSelector } from '../../selector/predictor';
import { useGetMatchesQuery } from 'common/store/modules/api/afl-api';
import { CONFIG } from '../constants';

export const Bracket = ({ seasonId, competitionId }) => {
    const [semiFinalsMatches, setSemiFinalMatches] = useState([]);
    const [preliminaryFinalsMatches, setPreliminaryFinalMatches] = useState([]);
    const [grandFinal, setGrandFinal] = useState({});
    const [winner, setWinner] = useState({});

    const max = useSelector(
        (state) => state.ladderPredictor.progressBar.totalMatches
    );

    const current = useSelector(
        (state) => state.ladderPredictor.predictions.length
    );

    const userClosed = useSelector(
        (state) => state.ladderPredictor.progressSteps.toolTip.userClosed
    );

    const dispatch = useDispatch();
    const ladder = useSelector(selectLadder);
    const bracket = useMemo(() => {
        return getTeams(ladder);
    }, [ladder]);

    const predictions = useSelector((state) =>
        predictionsFinalsSelector(state)
    );

    const { data: allMatches = [] } = useGetMatchesQuery({
        pageSize: CONFIG.MAX_MATCHES,
        competitionId: competitionId,
        compSeasonId: seasonId
    });

    const filteredFinalsMatches = useMemo(() => {
        const finalsMatches = {
            firstWeekMatches: [],
            semiFinals: [],
            preliminaryFinals: [],
            grandFinal: []
        };

        let totalMatches = [];

        for (let i = allMatches.length - 1; i >= 0; i--) {
            const match = allMatches[i];

            if (match.round.name === 'Finals Week 1') {
                finalsMatches.firstWeekMatches.push(match);
            } else if (match.round.name === 'Semi Finals') {
                finalsMatches.semiFinals.push(match);
            } else if (match.round.name === 'Preliminary Finals') {
                finalsMatches.preliminaryFinals.push(match);
            } else if (match.round.name === 'Grand Final') {
                finalsMatches.grandFinal.push(match);
            } else {
                break;
            }
            totalMatches.push(match);
        }

        // Count total matches that are concluded
        const totalMatchesLength = totalMatches.filter(
            (match) => match.status === CONFIG.CONCLUDED
        ).length;

        dispatch(
            setTotalFinalsMatches(CONFIG.FINALS_MATCHES - totalMatchesLength)
        );

        // Set local storage once here.
        let storageName =
            'comp' +
            competitionId +
            '_season' +
            seasonId +
            '_' +
            CONFIG.LOCAL_STORAGE_NAME_BRACKET;

        dispatch(setLocalStorageFinalsName(storageName));

        const storedPredictions = JSON.parse(localStorage.getItem(storageName));

        if (storedPredictions?.length) {
            dispatch(setPredictionsFinals(storedPredictions));
        }

        return finalsMatches;
    }, [bracket]);

    const weekOneMatches = createBracket(ladder);

    useEffect(() => {
        setSemiFinalMatches(createSemiFinals(predictions, bracket));

        setPreliminaryFinalMatches(
            createPreliminaryFinals(predictions, bracket)
        );

        setGrandFinal(createGrandFinal(predictions, bracket));

        setWinner(createWinner(predictions, bracket));
    }, [predictions, filteredFinalsMatches]);

    // Get team type from teams
    const teamType = useMemo(() => {
        let type = 'MEN';
        if (bracket) {
            type = bracket[0].team.teamType;
        }
        return type;
    }, [bracket]);

    return (
        <div className="ladder-predictor-bracket">
            <div className="ladder-predictor-bracket__header">
                <h3 className="ladder-predictor-bracket__header-title">
                    {getTranslation('label.ladderPredictor.finalsPredictions')}
                </h3>
            </div>

            <div className="ladder-predictor-bracket__bracket">
                <div className="ladder-predictor-bracket__bracket-col">
                    <p className="ladder-predictor-bracket__bracket-col-title">
                        {getTranslation('label.ladderPredictor.columns.finals')}
                    </p>
                    <div className="ladder-predictor-bracket__bracket-col-cards">
                        {weekOneMatches?.map((match, i) => (
                            <MatchCardFinalUpcoming
                                match={match}
                                key={i}
                                matchesData={
                                    filteredFinalsMatches.firstWeekMatches
                                }
                                seasonId={seasonId}
                                competitionId={competitionId}
                            />
                        ))}
                    </div>
                </div>

                <div className="ladder-predictor-bracket__bracket-col ladder-predictor-bracket__bracket-col--semi-finals">
                    <p className="ladder-predictor-bracket__bracket-col-title">
                        {getTranslation(
                            'label.ladderPredictor.columns.semiFinals'
                        )}
                    </p>
                    <div className="ladder-predictor-bracket__bracket-col-descriptor ladder-predictor-bracket__bracket-col-descriptor--top">
                        {getTranslation('label.ladderPredictor.columns.l')}
                    </div>
                    <div className="ladder-predictor-bracket__bracket-col-descriptor ladder-predictor-bracket__bracket-col-descriptor--bottom">
                        {getTranslation('label.ladderPredictor.columns.l')}
                    </div>
                    <div className="ladder-predictor-bracket__bracket-col-cards">
                        {semiFinalsMatches?.map((match, i) =>
                            match.home || match.away ? (
                                <MatchCardFinalUpcoming
                                    match={match}
                                    key={i}
                                    finalsStage="semiFinal"
                                    index={i}
                                    matchesData={
                                        filteredFinalsMatches.semiFinals
                                    }
                                    seasonId={seasonId}
                                    competitionId={competitionId}
                                />
                            ) : (
                                <MatchCardFinalAwaiting
                                    key={i}
                                    finalsStage="semiFinal"
                                    index={i}
                                />
                            )
                        )}
                    </div>
                </div>

                <div className="ladder-predictor-bracket__bracket-col ladder-predictor-bracket__bracket-col--preliminary-finals">
                    <p className="ladder-predictor-bracket__bracket-col-title">
                        {getTranslation(
                            'label.ladderPredictor.columns.preliminaryFinals'
                        )}
                    </p>
                    <div className="ladder-predictor-bracket__bracket-col-descriptor ladder-predictor-bracket__bracket-col-descriptor--top">
                        {getTranslation('label.ladderPredictor.columns.w')}
                    </div>
                    <div className="ladder-predictor-bracket__bracket-col-descriptor ladder-predictor-bracket__bracket-col-descriptor--bottom">
                        {getTranslation('label.ladderPredictor.columns.w')}
                    </div>
                    <div className="ladder-predictor-bracket__bracket-col-cards">
                        {preliminaryFinalsMatches?.map((match, i) =>
                            match.home || match.away ? (
                                <MatchCardFinalUpcoming
                                    match={match}
                                    key={i}
                                    finalsStage="preliminary"
                                    index={i}
                                    matchesData={
                                        filteredFinalsMatches.preliminaryFinals
                                    }
                                    seasonId={seasonId}
                                    competitionId={competitionId}
                                />
                            ) : (
                                <MatchCardFinalAwaiting
                                    key={i}
                                    finalsStage="preliminary"
                                    index={i}
                                />
                            )
                        )}
                    </div>
                </div>

                <div className="ladder-predictor-bracket__bracket-col ladder-predictor-bracket__bracket-col--grand-final">
                    <p className="ladder-predictor-bracket__bracket-col-title">
                        {getTranslation(
                            'label.ladderPredictor.columns.grandFinal'
                        )}
                    </p>
                    {winner ? (
                        <WinnerCard
                            teamName={winner?.team?.name}
                            teamAbbreviation={winner?.team?.abbreviation.toLowerCase()}
                            teamType={teamType}
                        />
                    ) : (
                        <WinnerCard
                            teamName={''}
                            teamAbbreviation={''}
                            teamType={teamType}
                        />
                    )}

                    <div className="ladder-predictor-bracket__bracket-col-cards">
                        {grandFinal?.home || grandFinal?.away ? (
                            <MatchCardFinalUpcoming
                                match={grandFinal}
                                finalsStage="grandFinal"
                                matchesData={filteredFinalsMatches.grandFinal}
                                seasonId={seasonId}
                                competitionId={competitionId}
                            />
                        ) : (
                            <MatchCardFinalAwaiting finalsStage="grandFinal" />
                        )}
                    </div>
                </div>
            </div>

            <div className="ladder-predictor-bracket__footer">
                <button
                    className="ladder-predictor-bracket__footer-button"
                    onClick={() => {
                        dispatch(setActiveView(CONFIG.SEASON));
                        dispatch(
                            setToolTip({
                                open: current === max && userClosed === false,
                                userClosed: userClosed
                            })
                        );
                        dispatch(
                            setFinalsStep({
                                active: true,
                                current: false,
                                complete: false
                            })
                        );
                        dispatch(
                            setSeasonStep({
                                active: true,
                                current: true,
                                complete: false
                            })
                        );
                    }}
                >
                    <SvgIcon
                        icon="arrow-left"
                        className="ladder-predictor-bracket__footer-button-icon icon"
                    />
                    {getTranslation('label.ladderPredictor.editSeason')}
                </button>
            </div>
        </div>
    );
};

Bracket.propTypes = {
    seasonId: PropTypes.string,
    competitionId: PropTypes.string
};
