import React from 'react';
import PropTypes from 'prop-types';

import { useGetMatchEventsQuery } from 'store/modules/api/content-api';
import { useGetLiveStreamsQuery } from 'store/modules/api/content-api';

import { FixtureMatchReportLink } from './FixtureMatchReportLink';
import { UpcomingFixtureLinks } from './UpcomingFixtureLinks';
import { LiveFixtureLinks } from './LiveFixtureLinks';

const MATCH_STATUS = PULSE.app.common.CONSTANTS.MATCH_STATUS;

export const FixtureLinks = ({
    match,
    matchStatus,
    metadata = {},
    broadcastGuideUrl,
    reportLabel
}) => {
    // Parameters to get broadcaster match events for this round
    const params = {
        compseason: match?.compSeason?.id,
        round: match?.round?.roundNumber
    };

    const { shouldShowBroadcaster } = useGetMatchEventsQuery(params, {
        selectFromResult: ({ data }) => ({
            shouldShowBroadcaster: data?.content?.some(
                (matchEvent) =>
                    matchEvent?.channels?.length &&
                    matchEvent.contentReference.providerId === match.providerId
            )
        })
    });

    /**
     * Fetch live video livestreams for the round, then check to see if there
     * is one for this match with the right attributes
     */
    const { liveStream } = useGetLiveStreamsQuery(
        { type: 'video' },
        {
            selectFromResult: ({ data }) => ({
                liveStream: data?.content?.find(
                    (stream) =>
                        stream?.streams?.length &&
                        stream?.matchId === match.providerId &&
                        stream?.streams?.some((item) =>
                            item.customAttributes?.some(
                                (attr) =>
                                    attr.attrName === 'AFL Live Video' &&
                                    attr.attrValue === 'AFL Live Video'
                            )
                        )
                )
            })
        }
    );

    // Pull out the right stream item
    const liveStreamStream = liveStream?.streams?.find((item) =>
        item.customAttributes?.some(
            (attr) =>
                attr.attrName === 'AFL Live Video' &&
                attr.attrValue === 'AFL Live Video'
        )
    );

    // create a live stream url for video modal if a live stream exists for the fixture
    const streamUrl = liveStream
        ? PULSE.app.common.url.getLiveStreamUrl(liveStreamStream)
        : null;

    /**
     * Fetch audio streams for the round and check if assigned
     * to this match
     */
    const { radioStream } = useGetLiveStreamsQuery(
        { type: 'audio', params },
        {
            selectFromResult: ({ data }) => ({
                radioStream: data?.content?.find(
                    (stream) =>
                        stream?.streams?.length &&
                        stream.matchId === match.providerId
                )
            })
        }
    );

    const hasFreeEntry = metadata.free_entry === 'true' ? true : false,
        isSoldOut = metadata.sold_out === 'true' ? true : false;

    const renderSwitch = () => {
        switch (matchStatus) {
            case MATCH_STATUS.UPCOMING: // UPCOMING
                return (
                    <UpcomingFixtureLinks
                        metadata={metadata}
                        hasFreeEntry={hasFreeEntry}
                        isSoldOut={isSoldOut}
                        broadcastGuideUrl={broadcastGuideUrl}
                        shouldShowBroadcaster={shouldShowBroadcaster}
                        streamUrl={streamUrl}
                        match={match}
                    />
                );
            case MATCH_STATUS.LIVE: // LIVE
                return (
                    <LiveFixtureLinks
                        broadcastGuideUrl={broadcastGuideUrl}
                        shouldShowBroadcaster={shouldShowBroadcaster}
                        streamUrl={streamUrl}
                        radioStream={radioStream}
                        match={match}
                    />
                );
            case MATCH_STATUS.COMPLETED: // COMPLETED
                return (
                    <FixtureMatchReportLink
                        match={match}
                        reportLabel={reportLabel}
                    />
                );
            default:
                return <></>;
        }
    };

    return renderSwitch();
};

FixtureLinks.propTypes = {
    match: PropTypes.object,
    specialRound: PropTypes.string,
    matchStatus: PropTypes.string,
    metadata: PropTypes.object,
    reportLabel: PropTypes.string,
    broadcastGuideUrl: PropTypes.string
};
