import { CONFIG } from '../components/constants';

/**
 * Filter predictions to remove concluded matches and unmatched fixtures
 *
 * @param {Array} predictions - An array of prediction objects
 * @param {Array} matches - An array of match objects
 * @returns {Array} - An updated array of prediction objects
 */
export const filterConcludedPredictions = (predictions, matches) => {
    // Create a set of match IDs where the status is "CONCLUDED"
    const concludedMatchIds = new Set(
        matches
            .filter((match) => match.status === CONFIG.CONCLUDED)
            .map((match) => match.id)
    );

    // Create a set of all match IDs from the matches list
    const validMatchIds = new Set(matches.map((match) => match.id));

    // Filter out predictions where the match ID exists in the concludedMatchIds set
    // or the match ID is not found in the validMatchIds set
    return predictions.filter(
        (prediction) =>
            !concludedMatchIds.has(prediction.i) && // Exclude concluded matches
            validMatchIds.has(prediction.i) // Exclude unmatched fixtures
    );
};
