import React, { useEffect, useState } from 'react';
import { CGNav } from './CGNav';

export const CGFiltersAuthWrapper = (data) => {
    const [userAuthenticated, setUserAuthenticated] = useState(null);

    useEffect(() => {
        PULSE.app.authClient.isAuthenticated().then((isAuthenticated) => {
            setUserAuthenticated(isAuthenticated);
        });
    }, [userAuthenticated]);

    return userAuthenticated !== null ? (
        <CGNav {...data} userAuthenticated={userAuthenticated} />
    ) : null;
};
