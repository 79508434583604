import React from 'react';
import PropTypes from 'prop-types';

import { InsAndOutsRow } from './InsAndOutsRow';
import { getTranslation } from 'common/react/utils/translations';

const CATEGORY_MAP = {
    ins: { title: 'IN', cssClass: 'in' },
    outs: { title: 'OUT', cssClass: 'out' },
    clubDebuts: { title: 'NEW', cssClass: 'new' }
};

export const InsAndOuts = ({ match, isActiveMatch }) => {
    const { homeTeam, awayTeam } = match.matchRoster;

    let subData = {};

    if (isActiveMatch) {
        const homeSub = PULSE.app.common.team.checkForSub(homeTeam.positions);
        const awaySub = PULSE.app.common.team.checkForSub(awayTeam.positions);

        subData = {
            home: homeSub ? [homeSub] : '',
            away: awaySub ? [awaySub] : '',
            match: match?.match
        };
    }

    return (
        <div className="team-lineups__section">
            {Object.keys(CATEGORY_MAP).map((category) => {
                const data = {
                    home: homeTeam[category],
                    away: awayTeam[category],
                    match: match?.match
                };

                if (!data.home.length && !data.away.length) {
                    return null;
                }

                return (
                    <InsAndOutsRow
                        key={category}
                        title={CATEGORY_MAP[category].title}
                        data={data}
                        cssClass={CATEGORY_MAP[category].cssClass}
                    />
                );
            })}
            {isActiveMatch && (subData.home.length || subData.away.length) ? (
                <InsAndOutsRow
                    title="SUB"
                    data={subData}
                    cssClass="sub"
                    tooltipContent={getTranslation(
                        'label.teamLineups.sub.tooltip'
                    )}
                />
            ) : null}
        </div>
    );
};

InsAndOuts.propTypes = {
    match: PropTypes.object.isRequired,
    isActiveMatch: PropTypes.bool
};
