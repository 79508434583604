/* eslint-disable id-length */

import { createSlice } from '@reduxjs/toolkit';
import { generatePredictions } from 'widgets/ladder-predictor-v2/js/utils/generate-prediction';
import { CONFIG } from 'widgets/ladder-predictor-v2/js/components/constants';

const initialState = {
    localStorageName: '',
    localStorageFinalsName: '',
    activeView: CONFIG.SEASON,
    progressSteps: {
        season: {
            active: true,
            current: true,
            complete: false
        },
        finals: {
            active: true,
            current: false,
            complete: false
        },
        toolTip: {
            open: false,
            userClosed: false
        }
    },
    predictions: [],
    predictionsFinals: [],
    teams: [],
    previousLadder: [],
    progressBar: {
        autoFill: {
            disabled: false
        },
        totalMatches: null,
        totalFinalsMatches: null
    },
    currentRound: 0,
    currentTeam: 0,
    currentFilter: CONFIG.ROUND
};

// RTK Query update: RTK slices can be used to update state in the Redux store (https://redux-toolkit.js.org/api/createSlice)
const ladderPredictor = createSlice({
    name: 'ladder-predictor',
    initialState: () => {
        return initialState;
    },
    reducers: {
        setLocalStorageName: (state, action) => {
            state.localStorageName = action.payload;
        },
        setLocalStorageFinalsName: (state, action) => {
            state.localStorageFinalsName = action.payload;
        },
        setActiveView: (state, action) => {
            state.activeView = action.payload;
        },
        setToolTip: (state, action) => {
            state.progressSteps.toolTip.open = action.payload.open;
            state.progressSteps.toolTip.userClosed = action.payload.userClosed;
        },
        setSeasonStep: (state, action) => {
            state.progressSteps.season = action.payload;
        },
        setFinalsStep: (state, action) => {
            state.progressSteps.finals = action.payload;
        },
        setPrediction: (state, action) => {
            /**
             * @param {number} i - Match id.
             * @param {number} r - Round number.
             * @param {number} w - Winning team id.
             * @param {number} l - Losing team id.
             * @param {boolean} d - Draw. True if the match has drawn.
             * @param {number} m - Score margin.
             * @param {boolean} c - Is match concluded
             */
            const { i, r, w, l, d, m, c = false } = action.payload;
            const existingIndex = state.predictions.findIndex(
                (prediction) => prediction.i === i
            );

            let updatedPredictions;

            if (existingIndex !== -1) {
                // Remove the existing prediction
                const updatedPrediction = { i, r, w, l, d, m, c };

                updatedPredictions = [
                    ...state.predictions.slice(0, existingIndex), // Elements before the existing index
                    updatedPrediction,
                    ...state.predictions.slice(existingIndex + 1) // Elements after the existing index
                ];
            } else {
                // Add a new prediction
                updatedPredictions = [
                    ...state.predictions,
                    { i, r, w, l, d, m, c }
                ];
            }

            // Update localStorage
            localStorage.setItem(
                state.localStorageName,
                JSON.stringify(updatedPredictions)
            );

            if (state.localStorageFinalsName.length) {
                // Only strip out predictions that are not for CONCLUDED matches.
                const updatedFinals = state.predictionsFinals.filter(
                    (prediction) => prediction.c
                );
                // Update localStorage
                localStorage.setItem(
                    state.localStorageFinalsName,
                    JSON.stringify(updatedFinals)
                );

                return {
                    ...state,
                    predictions: updatedPredictions,
                    predictionsFinals: updatedFinals
                };
            }

            return {
                ...state,
                predictions: updatedPredictions
            };
        },
        setPredictionFinals: (state, action) => {
            /**
             * @param {number} i - Match id.
             * @param {number} r - Round number.
             * @param {number} w - Winning team id.
             * @param {number} l - Losing team id.
             * @param {string} n - Round name.
             * @param {boolean} c - Is match concluded
             */
            const { i, r, w, l, n = '', c = false } = action.payload;
            const existingIndex = state.predictionsFinals.findIndex(
                (prediction) => prediction.n === n
            );

            // Create a new copy to handle of the existing state
            let updatedPredictionFinals;

            if (existingIndex !== -1) {
                // Remove the existing prediction
                updatedPredictionFinals = [
                    ...state.predictionsFinals.slice(0, existingIndex), // Elements before the existing index
                    { i, r, w, l, n, c }, // The updated prediction
                    ...state.predictionsFinals.slice(existingIndex + 1) // Elements after the existing index
                ];
            } else {
                // Add a new prediction
                updatedPredictionFinals = [
                    ...state.predictionsFinals,
                    { i, r, w, l, n, c }
                ];
            }

            updatedPredictionFinals.sort((a, b) => a.r - b.r);

            // Update localStorage
            localStorage.setItem(
                state.localStorageFinalsName,
                JSON.stringify(updatedPredictionFinals)
            );

            return {
                ...state,
                predictionsFinals: updatedPredictionFinals
            };
        },
        setPredictions: (state, action) => {
            state.predictions = action.payload;
        },
        setPredictionsFinals: (state, action) => {
            state.predictionsFinals = action.payload;
        },
        setTeams: (state, action) => {
            state.teams = action.payload;
        },
        setLadder: (state, action) => {
            state.previousLadder = action.payload;
        },
        setTotalFinalsMatches: (state, action) => {
            state.progressBar.totalFinalsMatches = action.payload;
        },
        setTotalMatches: (state, action) => {
            state.progressBar.totalMatches = action.payload;
        },
        setRound: (state, action) => {
            state.currentRound = action.payload;
        },
        setTeam: (state, action) => {
            state.currentTeam = action.payload;
        },
        setCurrentFilter: (state, action) => {
            state.currentFilter = action.payload;
        },
        removeAllPredictions: (state) => {
            state.predictions = initialState.predictions;
            state.predictionsFinals = initialState.predictionsFinals;
            // Update localStorage
            localStorage.removeItem(state.localStorageName);
            localStorage.removeItem(state.localStorageFinalsName);
        },
        removePredictionsByFilter: (state) => {
            if (state.currentFilter === CONFIG.ROUND) {
                // Get round number
                const roundNumber = state.currentRound.roundNumber;
                // Filter to remove predictions with matching round number
                state.predictions = state.predictions.filter(
                    (prediction) => prediction.r !== roundNumber
                );
            } else if (state.currentFilter === CONFIG.TEAM) {
                // Get team ID
                const teamId = state.currentTeam.id;
                // Filter to remove predictions where w or l matches the team ID
                state.predictions = state.predictions.filter(
                    (prediction) =>
                        prediction.w !== teamId && prediction.l !== teamId
                );
            }
            // Update localStorage
            localStorage.setItem(
                state.localStorageName,
                JSON.stringify(state.predictions)
            );
        },
        autoFillPredictions: (state, action) => {
            const { matchList } = action.payload;

            // Filter matchList to exclude matches that are complete or already predicted
            const filteredMatches = matchList.filter((match) => {
                // Check if match is complete
                const isComplete = match.status === CONFIG.CONCLUDED;

                // Check if match is already predicted
                const isAlreadyPredicted = state.predictions.some(
                    (prediction) => prediction.i === match.id
                );

                // Only include matches that are not complete and not already predicted
                return !isComplete && !isAlreadyPredicted;
            });

            // Generate predictions using the utility for the filtered matches
            const newPredictions = generatePredictions(
                filteredMatches,
                state.teams,
                state.previousLadder,
                state.predictions
            );

            // Update the predictions state
            state.predictions = [...state.predictions, ...newPredictions];
            localStorage.setItem(
                state.localStorageName,
                JSON.stringify(state.predictions)
            );
        },
        removeAllFinalsPredictions: (state) => {
            // Only strip out predictions that are not for CONCLUDED matches.
            state.predictionsFinals = state.predictionsFinals.filter(
                (prediction) => prediction.c
            );
            // Update localStorage
            localStorage.setItem(
                state.localStorageFinalsName,
                JSON.stringify(state.predictionsFinals)
            );
        },
        validatePrediction: (state, action) => {
            // Find the index of the prediction with the given name
            const targetIndex = state.predictionsFinals.findIndex(
                (prediction) => prediction.n === action.payload.name
            );

            // Create a new copy to handle of the existing state
            let updatedPredictionFinals = state.predictionsFinals;

            if (targetIndex !== -1) {
                // Check if the winner's id matches the given id
                const targetPrediction = state.predictionsFinals[targetIndex];
                if (targetPrediction.w === action.payload.id) {
                    // Remove the prediction from the state
                    updatedPredictionFinals = [
                        ...state.predictionsFinals.slice(0, targetIndex), // Elements before the target index
                        ...state.predictionsFinals.slice(targetIndex + 1) // Elements after the target index
                    ];
                }
            }

            // Update localStorage
            localStorage.setItem(
                state.localStorageFinalsName,
                JSON.stringify(updatedPredictionFinals)
            );

            return {
                ...state,
                predictionsFinals: updatedPredictionFinals
            };
        },
        removeFinalsPredictionsById: (state, action) => {
            // Only strip out predictions that are not for CONCLUDED matches.
            state.predictionsFinals = state.predictionsFinals.filter(
                (prediction) => prediction.i !== action.payload.id
            );
            // Update localStorage
            localStorage.setItem(
                state.localStorageFinalsName,
                JSON.stringify(state.predictionsFinals)
            );
        }
    }
});

// RTK Query update: Export the slice reducer and actions
if (PULSE.app.redux) {
    PULSE.app.redux.reducers.ladderPredictor = {
        root: ladderPredictor.reducer
    };
    PULSE.app.redux.actions = {
        ...PULSE.app.redux.actions,
        ladderPredictor: ladderPredictor.actions
    };
} else {
    PULSE.app.redux = {
        actions: {
            ladderPredictor: ladderPredictor.actions
        },
        reducers: {
            root: {
                ladderPredictor: ladderPredictor.reducer
            }
        }
    };
}

// Export actions so we can call them elsewhere in modules
export const {
    setLocalStorageName,
    setLocalStorageFinalsName,
    setActiveView,
    setToolTip,
    setSeasonStep,
    setFinalsStep,
    setPrediction,
    setPredictionFinals,
    setTeams,
    setLadder,
    setTotalMatches,
    setRound,
    setTeam,
    setCurrentFilter,
    removePredictionsByFilter,
    removeAllPredictions,
    removeAllFinalsPredictions,
    autoFillPredictions,
    setPredictions,
    setPredictionsFinals,
    setTotalFinalsMatches,
    validatePrediction,
    removeFinalsPredictionsById
} = ladderPredictor.actions;
