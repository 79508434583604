import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';

import {
    RoundListNavItem,
    SeasonNavItem,
    CompNavItem,
    ResponsiveSeasonNavItem
} from 'common/react/components/competition-nav/nav-items';

import {
    TeamFilterItem,
    VenueFilterItem,
    CityFilterItem
} from 'common/react/components/competition-nav/filter-items';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useNavSync } from 'common/react/components/competition-nav/hooks/use-nav-sync';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';
import { SettingsButton } from 'common/react/components/SettingsButton';
import { useFiltersTracking } from 'common/react/hooks/use-filters-tracking';

export const FixturesNav = ({
    competitionId,
    seasonId,
    competitionProviderId,
    seasonPid,
    onRoundClick,
    title
}) => {
    const windowSize = useWindowSize();

    const isResponsive = () =>
        windowSize.width < PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    const initialValues = {
        competitionId,
        seasonId,
        competitionProviderId,
        seasonPid
    };

    const nav = useSelector(navSelector);
    const options = useCompetitionNavigationOptions(nav);

    useNavSync({ ...options, initialValues });

    const competitions = options.competitions;

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    const currentFilters = useGetCurrentCompFilters();
    const competitionNavRef = useRef();

    /**
     * custom hook providing tracking of filters
     */
    useFiltersTracking({
        title,
        filtersState: nav,
        options,
        filtersFields: [
            'competition',
            'season',
            'round',
            'teams',
            'venues',
            'cities'
        ],
        filtersRef: competitionNavRef
    });

    return (
        <div className="competition-nav" ref={competitionNavRef}>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <CompNavItem
                            competitions={competitions}
                            title={title}
                        />
                        {!showResponsiveItems ? (
                            <SeasonNavItem
                                seasons={seasons}
                                classes={'u-margin-right-auto'}
                                title={title}
                            />
                        ) : null}
                    </StatsSection.NavItems>
                    {currentFilters.competitionId &&
                    currentFilters.compSeason?.id ? (
                        <StatsSection.NavFilterItems title={title}>
                            {showResponsiveItems ? (
                                <ResponsiveSeasonNavItem
                                    seasons={seasons}
                                    title={title}
                                />
                            ) : null}

                            <TeamFilterItem
                                currentFilters={currentFilters}
                                title={title}
                            />
                            <VenueFilterItem
                                currentFilters={currentFilters}
                                title={title}
                            />
                            <CityFilterItem
                                currentFilters={currentFilters}
                                title={title}
                            />
                        </StatsSection.NavFilterItems>
                    ) : null}
                    <StatsSection.SettingsButton>
                        <SettingsButton />
                    </StatsSection.SettingsButton>
                    <StatsSection.RoundButtons>
                        <RoundListNavItem
                            classes={'competition-nav__round-filter-wrapper'}
                            onRoundClick={onRoundClick}
                            title={title}
                        />
                    </StatsSection.RoundButtons>
                </StatsSection.Nav>
            </StatsSection.Main>
        </div>
    );
};

FixturesNav.propTypes = {
    competitionId: PropTypes.string,
    competitionProviderId: PropTypes.string,
    seasonPid: PropTypes.string,
    seasonId: PropTypes.string,
    onRoundClick: PropTypes.func,
    title: PropTypes.string
};
