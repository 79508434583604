import React from 'react';
import PropTypes from 'prop-types';

import { SelectDesktop } from './SelectDesktop';
import { SelectMobile } from './SelectMobile';

// Custom selects
import { SelectDesktopWithDefault } from './SelectDesktopWithDefault';
import { SelectMobileWithDefault } from './SelectMobileWithDefault';

/**
 * @param {object} props - Props.
 * @param {string} props.label - Label.
 * @param {any[]} props.items - Items.
 * @param {Function} [props.itemToString] - Item to string.
 * @param {any} [props.initialSelectedItem] - Initial selected item.
 * @param {any} [props.activeItem] - Active item from state.
 * @param {Function} [props.onSelectedItemChange] - On change callback.
 * @param {bool} [props.isDisabled] - Is select disabled.
 * @returns {JSX.Element} Component.
 */
export const Select = (props) => {
    const selectDesktopComponent = () => {
        switch (props.type) {
            case 'with-default':
                return <SelectDesktopWithDefault {...props} />;
            default:
                return <SelectDesktop {...props} />;
        }
    };

    const selectMobileComponent = () => {
        switch (props.type) {
            case 'with-default':
                return <SelectMobileWithDefault {...props} />;
            default:
                return <SelectMobile {...props} />;
        }
    };

    return (
        <>
            <div className="u-hide-until-desktop">
                {selectDesktopComponent()}
            </div>
            <div className="u-hide-from-desktop w-100">
                {selectMobileComponent()}
            </div>
        </>
    );
};

Select.propTypes = {
    label: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any),
    itemToString: PropTypes.func,
    initialSelectedItem: PropTypes.any,
    activeItem: PropTypes.any,
    onSelectedItemChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    hideLabel: PropTypes.bool,
    showTeamLogo: PropTypes.bool,
    displaySlim: PropTypes.bool,
    showTotals: PropTypes.bool,
    showCompLogo: PropTypes.bool,
    type: PropTypes.string
};
