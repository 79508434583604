import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

export const ModalButton = ({
    icon,
    label,
    description,
    onClick,
    btnLabel,
    cssModifier
}) => {
    return (
        <div className="modal-item__container">
            {onClick ? (
                <button
                    onClick={onClick}
                    className={`modal-link modal-button  ${
                        cssModifier ? `modal-button--${cssModifier}` : null
                    }`}
                >
                    {icon ? (
                        <SvgIcon
                            icon={icon}
                            className={`icon modal-link__icon ${
                                cssModifier
                                    ? `modal-link__icon--${cssModifier}`
                                    : null
                            }`}
                        />
                    ) : null}
                    <div
                        className={`modal-link__label ${
                            cssModifier
                                ? `modal-link__label--${cssModifier}`
                                : null
                        }`}
                    >
                        <span className="modal-link__text-container">
                            {label}
                            {description ? (
                                <span className="modal-link__description">
                                    {description}
                                </span>
                            ) : null}
                        </span>
                        {btnLabel ? (
                            <span
                                className={`filter-button ${
                                    cssModifier
                                        ? `filter-button--${cssModifier}`
                                        : null
                                }`}
                            >
                                {btnLabel}
                                <SvgIcon
                                    icon="arrow-right"
                                    className="icon modal-link__icon"
                                />
                            </span>
                        ) : null}
                    </div>
                </button>
            ) : null}
        </div>
    );
};

ModalButton.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    btnLabel: PropTypes.string,
    cssModifier: PropTypes.string
};
