import React from 'react';
import PropTypes from 'prop-types';

import { Header } from './components';
import { getTranslation } from 'common/react/utils/translations';

export const TeamLineupsEmpty = ({ match }) => {
    return (
        <div className="team-lineups__item">
            <Header match={match} />

            <div className="team-lineups__sub-section team-lineups__sub-section--empty-state">
                {getTranslation('label.teamLineups.notAvailable')}
            </div>
        </div>
    );
};

TeamLineupsEmpty.propTypes = {
    match: PropTypes.object.isRequired
};
