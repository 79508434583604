import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { CGFiltersAuthWrapper } from '../CGFiltersAuthWrapper';

export const CGNavProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <CGFiltersAuthWrapper {...data} />
        </Provider>
    );
};

CGNavProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
