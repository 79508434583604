import React from 'react';
import PropTypes from 'prop-types';

/*
 * Render match card betting odds
 */
export const MatchCardBettingOdds = ({ href, value }) => {
    return (
        <div className="ladder-predictor-match-card__betting-odds">
            <a
                href={href}
                target="_blank"
                className="ladder-predictor-match-card__betting-odds-pill"
                rel="noreferrer"
            >
                {new Intl.NumberFormat('en-AU', {
                    style: 'currency',
                    currency: 'AUD'
                }).format(value)}
            </a>
        </div>
    );
};

MatchCardBettingOdds.propTypes = {
    href: PropTypes.string,
    value: PropTypes.string
};
