import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import {
    StatsLeadersPositionsFilters,
    StatsLeadersTeamsFilters
} from './components/filters';
import {
    Heading,
    BenchmarkingSwitch,
    DataTypeToggle,
    dataTypeOptions
} from './components/header';
import {
    CategoryNavItem,
    SeasonNavItem,
    RoundNavItem
} from './components/nav-items';
import { StatsLeadersTable } from './components/table';
import {
    BenchmarkingExplainer,
    BenchmarkingLegend
} from 'common/react/components/benchmarking';
import {
    statsLeadersBenchmarkingSelector,
    statsLeadersNavSelector,
    statsLeadersFiltersSelector,
    statsLeadersDataTypeSelector
} from 'store/modules/statspro/stats-leaders/selectors';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useStatsLeadersNavSync } from './hooks/use-stats-leaders-nav-sync';
import { useFiltersTracking } from 'common/react/hooks/use-filters-tracking';
import { POSITIONS } from './config/positions';

/**
 * @param root0
 * @param root0.compseasonId
 * @param root0.competitionId
 * @param root0.competitionPid
 * @param root0.showBenchmarkingAflw
 * @param root0.title
 * @returns {JSX.Element} Component.
 */
export const StatsLeaders = ({
    compseasonId,
    competitionId,
    competitionPid,
    showBenchmarkingAflw,
    title
}) => {
    const benchmarking = useSelector(statsLeadersBenchmarkingSelector);
    const dataType = useSelector(statsLeadersDataTypeSelector);

    const nav = useSelector(statsLeadersNavSelector);
    const filters = useSelector(statsLeadersFiltersSelector);
    const options = useCompetitionNavigationOptions(nav);
    useStatsLeadersNavSync(options, competitionId, compseasonId);

    const showBenchmarkingAFLWBool = showBenchmarkingAflw === 'true';
    const { WOMENS } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
    const isAFLW = competitionPid === WOMENS;

    const isAFLM =
        competitionPid === PULSE.app.common.CONSTANTS.PROVIDER_IDS.PREMIERSHIP;

    const showBenchmarking =
        (!isAFLW || (isAFLW && showBenchmarkingAFLWBool)) &&
        (dataType?.type === 'averages' ||
            dataType?.type === 'benchmarkedAverages');

    const statsLeadersRef = useRef();

    /**
     * custom hook providing tracking of filters
     */
    useFiltersTracking({
        title,
        filtersState: {
            category: nav.category,
            season: nav.season,
            position: filters.positions.map((pos) => ({ id: pos })),
            filters: {
                teams: filters.teams
            },
            round: nav.round,
            dataType,
            benchmarking
        },
        options: {
            ...options,
            dataTypes: dataTypeOptions,
            positions: POSITIONS.map((pos) => ({
                value: pos.keys[0],
                label: pos.name
            }))
        },
        filtersFields: [
            'category',
            'season',
            'round',
            'position',
            'teams',
            'benchmarking',
            'dataType'
        ],
        filtersRef: statsLeadersRef
    });

    return (
        <div ref={statsLeadersRef}>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <CategoryNavItem title={title} />
                        <SeasonNavItem title={title} />
                        <RoundNavItem title={title} />
                    </StatsSection.NavItems>
                    <StatsSection.NavFilterItems title={title}>
                        <StatsLeadersPositionsFilters title={title} />
                        <StatsLeadersTeamsFilters title={title} />
                    </StatsSection.NavFilterItems>
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentHeader>
                        <StatsSection.ContentHeaderHeading>
                            <Heading />
                        </StatsSection.ContentHeaderHeading>
                        <StatsSection.ContentHeaderOptions>
                            {showBenchmarking && <BenchmarkingSwitch />}
                            <DataTypeToggle />
                        </StatsSection.ContentHeaderOptions>
                        <StatsSection.ContentHeaderBelow>
                            {benchmarking?.isActive && showBenchmarking && (
                                <BenchmarkingLegend />
                            )}
                            {benchmarking?.isActive && showBenchmarking && (
                                <BenchmarkingExplainer />
                            )}
                        </StatsSection.ContentHeaderBelow>
                    </StatsSection.ContentHeader>
                    <StatsSection.ContentBody>
                        <StatsLeadersTable isAFLW={isAFLW} isAFLM={isAFLM} />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
        </div>
    );
};

StatsLeaders.propTypes = {
    compseasonId: PropTypes.string.isRequired,
    competitionId: PropTypes.string.isRequired,
    competitionPid: PropTypes.string.isRequired,
    showBenchmarkingAflw: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    title: PropTypes.string
};
