import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { useGetDraftByYearQuery } from '../api/draft-tracker-api';
import { draftTrackerNavSelector } from 'store/modules/draft-tracker/leaderboard/selectors';
import { useTeamsList } from 'widgets/player-awards-tracker/js/components/Leaderboard/components/table/utils';

export const ClubNavItem = ({ year, seasonId }) => {
    const dispatch = useDispatch();
    const nav = useSelector(draftTrackerNavSelector);
    const teamsList = useTeamsList(seasonId);

    const teams = [
        { providerId: -1, name: getTranslation('label.stats.allClubs') },
        ...teamsList.sort((a, b) => a.name.localeCompare(b.name))
    ];

    const { data: draftData } = useGetDraftByYearQuery(year);

    return (
        <Select
            items={teams}
            itemToString={(team) => team?.name}
            initialSelectedItem={teams[0]}
            activeItem={teams.find((item) => item.providerId === nav.club)}
            label={getTranslation('label.filters.club')}
            isDisabled={
                draftData && draftData.selections?.length ? false : true
            }
            onSelectedItemChange={({ selectedItem }) => {
                // RTK Query update: Update the Redux store with a slice action
                dispatch(
                    PULSE.app.redux.actions.draftTracker.updateNav({
                        name: 'club',
                        value: selectedItem.providerId
                    })
                );
            }}
        />
    );
};

ClubNavItem.propTypes = {
    seasonId: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    title: PropTypes.string
};
