/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { PlayerProfileLink } from 'widgets/statspro/js/components/common/PlayerProfileLink';
import { PlayerPhoto } from 'common/react/components/PlayerPhoto';
import { useGetImageScale } from 'widgets/team-lineups-v2/js/utils/use-get-image-scale';

export const InsAndOutsPlayer = ({ player, reason, match }) => {
    const imageScale = useGetImageScale();

    return (
        <div className="team-lineups-ins-and-outs__grid-item-wrapper">
            <PlayerProfileLink
                key={player.playerId}
                classes="team-lineups-ins-and-outs__grid-item"
                player={{
                    playerId: player.playerId,
                    firstName: player.playerName.givenName,
                    surname: player.playerName.surname
                }}
                trackingInfo={{
                    event: PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT
                        .LINK,
                    component_name: match?.name,
                    component_content_type:
                        PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES
                            .LINEUPS
                }}
            >
                <div className="team-lineups-ins-and-outs__player-name-wrapper">
                    <p className="team-lineups-ins-and-outs__player-name">
                        {player.playerName.givenName}{' '}
                        {player.playerName.surname}
                    </p>
                    {reason ? (
                        <p className="team-lineups-ins-and-outs__player-reason">
                            {reason}
                        </p>
                    ) : null}
                </div>
                <div className="team-lineups-ins-and-outs__headshot">
                    <PlayerPhoto
                        photoUrl={player.photoURL}
                        alt={`${player?.playerName?.givenName} ${player?.playerName?.surname}`}
                        scale={imageScale}
                    />
                </div>
            </PlayerProfileLink>
        </div>
    );
};

InsAndOutsPlayer.propTypes = {
    player: PropTypes.object.isRequired,
    reason: PropTypes.string,
    match: PropTypes.object
};
