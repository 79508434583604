import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { MapLocationSelectorOption } from './MapLocationSelectorOption';
import { MapLocationSelectorImage } from './MapLocationSelectorImage';
import { useGetContentItemQuery } from 'common/store/modules/api/content-api';

export const MapLocationSelector = ({ setVenue }) => {
    const mapContainer = document.querySelector(
        '.js-interactive-event-map-container'
    );
    const [startingVenue, setStartingVenue] = useState(null);

    const [selectedVenue, setSelectedVenue] = useState({
        name: PULSE.core.localStorage.getStorage('mapSelectedVenue'),
        imgRef: PULSE.core.localStorage.getStorage('mapSelectedVenueLogo'),
        id: PULSE.core.localStorage.getStorage('mapSelectedVenueId')
    });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const urlSearchParams = new URLSearchParams(window.location.search);

    const [logosArray, setLogosArray] = useState([]);

    const startingVenueData = useGetContentItemQuery({
        type: 'PLAYLIST',
        id: mapContainer?.dataset?.startingVenueId
    }).data;

    const additionalOptions = startingVenueData?.references?.filter(
        (reference) => reference.type === 'PLAYLIST'
    );

    const locationParams = {
        venue: Number(urlSearchParams.get('location')),
        marker: Number(urlSearchParams.get('id'))
    };

    const handleImageUrl = (locationObject) => {
        setLogosArray((prevLogo) => [...prevLogo, locationObject]);
    };

    const openDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const updateSelectedVenue = (item) => {
        setSelectedVenue(item);
        typeof setVenue === 'function' ? setVenue(item) : null;
        PULSE.app.common.MapLocationManager.updateMapSelectedVenue(item);
        setDropdownOpen(false);
    };

    useEffect(() => {
        const selectionElement = document.querySelector(
            '.js-map-location-selector__button'
        );

        selectionElement?.addEventListener('click', openDropdown);

        return () => {
            selectionElement?.removeEventListener('click', openDropdown);
        };
    }, [dropdownOpen, additionalOptions]);

    useEffect(() => {
        const optionItems = document.querySelectorAll('.js-option-btn');

        if (dropdownOpen) {
            for (let i = 0; i < optionItems.length; i++) {
                optionItems[i].addEventListener('click', () => {
                    updateSelectedVenue({
                        name: optionItems[i].querySelector(
                            '.js-option-btn__name'
                        ).innerHTML,
                        imgRef: optionItems[i].querySelector('img')?.src,
                        id: optionItems[i].dataset.venueId
                    });
                });
            }
        }

        return () => {
            for (let i = 0; i < optionItems.length; i++) {
                optionItems[i].removeEventListener(
                    'click',
                    updateSelectedVenue
                );
            }
        };
    }, [additionalOptions, dropdownOpen]);

    useEffect(() => {
        setStartingVenue({
            name: mapContainer.dataset.startingVenue,
            imgRef: {
                contentType: 'PHOTO',
                reference: mapContainer.dataset.startingImage
            },
            id: mapContainer.dataset.startingVenueId
        });
    }, []);

    useEffect(() => {
        window.addEventListener('mapLocationChange', () => {
            setSelectedVenue({
                name: PULSE.core.localStorage.getStorage('mapSelectedVenue'),
                imgRef: PULSE.core.localStorage.getStorage(
                    'mapSelectedVenueLogo'
                ),
                id: PULSE.core.localStorage.getStorage('mapSelectedVenueId')
            });

            typeof setVenue === 'function'
                ? setVenue({
                      name: PULSE.core.localStorage.getStorage(
                          'mapSelectedVenue'
                      ),
                      imgRef: PULSE.core.localStorage.getStorage(
                          'mapSelectedVenueLogo'
                      ),
                      id: PULSE.core.localStorage.getStorage(
                          'mapSelectedVenueId'
                      )
                  })
                : null;
        });

        if (locationParams.venue > 0) {
            let imgURL;

            logosArray.forEach((item) => {
                if (locationParams.venue === Number(item.id)) {
                    imgURL = item.imgRef;

                    if (
                        item.name ===
                        PULSE.core.localStorage.getStorage('mapSelectedVenue')
                    ) {
                        setSelectedVenue({
                            name: PULSE.core.localStorage.getStorage(
                                'mapSelectedVenue'
                            ),
                            imgRef: imgURL,
                            id: PULSE.core.localStorage.getStorage(
                                'mapSelectedVenueId'
                            )
                        });

                        setVenue({
                            name: PULSE.core.localStorage.getStorage(
                                'mapSelectedVenue'
                            ),
                            imgRef: imgURL,
                            id: PULSE.core.localStorage.getStorage(
                                'mapSelectedVenueId'
                            )
                        });
                    }
                }
            });
        }
    }, [logosArray]);

    return (
        <div
            className={`map-location-selector ${
                dropdownOpen ? 'map-location-selector--active' : ''
            } js-map-location-selector`}
        >
            <button
                className={`map-location-selector__button ${
                    additionalOptions?.length
                        ? 'js-map-location-selector__button'
                        : ''
                }`}
            >
                {selectedVenue.imgRef !== 'undefined' ||
                startingVenue?.imgRef?.reference.length ? (
                    <MapLocationSelectorImage selectedVenue={selectedVenue} />
                ) : null}
                <span className="map-location-selector__selected-location">
                    {selectedVenue.name}
                </span>
                {additionalOptions?.length ? (
                    <span className="map-location-selector__arrow-container">
                        <SvgIcon icon="chevron-blue" className="icon" />
                    </span>
                ) : null}
            </button>
            <ul
                className={`map-location-selector__options-list ${
                    dropdownOpen ? '' : 'u-hide'
                }`}
            >
                {startingVenue && (
                    <MapLocationSelectorOption
                        selectedVenue={selectedVenue}
                        location={startingVenue}
                        handleImageUrl={handleImageUrl}
                        isAdditionalLocation={false}
                    />
                )}
                {additionalOptions?.length
                    ? additionalOptions?.map((location) => (
                          <MapLocationSelectorOption
                              key={location.id}
                              selectedVenue={selectedVenue}
                              location={location}
                              handleImageUrl={handleImageUrl}
                              isAdditionalLocation={true}
                          />
                      ))
                    : null}
            </ul>
        </div>
    );
};

MapLocationSelector.propTypes = {
    setVenue: PropTypes.func,
    additionalOptions: PropTypes.array
};
