import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { PlayerSearchInput } from 'common/react/components/PlayerSearchInput';

import { UPDATE_LEADERBOARD_FILTER } from 'store/modules/brownlow-tracker/leaderboard/actions';

export const Search = () => {
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState(query);
    const [startSearchFired, setStartSearchFired] = useState(false);

    const elementRef = useRef('');

    const dispatch = useDispatch();

    const onSearchSubmit = async (newQuery) => {
        dispatch(
            UPDATE_LEADERBOARD_FILTER.request({
                query: newQuery
            })
        );
    };

    const onSearchClear = () => {
        onSearchSubmit('');
    };

    const handleElementChange = (element) => {
        elementRef.current = element;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setQuery(debouncedQuery.trim());

            if (debouncedQuery.trim().length > 0 && !startSearchFired) {
                PULSE.app.analytics.addSearchTracking(elementRef.current);
                setStartSearchFired(true);
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    useEffect(() => {
        if (query !== '' && query.length > 2) {
            onSearchSubmit(query);
        } else {
            onSearchSubmit('');
        }
    }, [query]);

    return (
        <>
            <PlayerSearchInput
                setDebouncedQuery={setDebouncedQuery}
                onClear={onSearchClear}
                onElementChange={handleElementChange}
            />
        </>
    );
};
