import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

/*
    We can use this as a default way to render the <td> and still allow a custom component inside it.
    Useful for reducing duplication.
*/

const DefaultCellRendererContainer = ({ className, children }) => {
    return <td className={cx(className, 'custom-table__cell')}>{children}</td>;
};

export default DefaultCellRendererContainer;

DefaultCellRendererContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};
