/* eslint-disable react/jsx-key */
import React, {
    useRef,
    useState,
    useCallback,
    useEffect,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectLadder } from '../../../selector/ladder';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { getTranslation } from 'common/react/utils/translations';
import { Form } from './components/Form';
import { TableScrollButtons } from 'widgets/statspro/js/components/common/stats-table/TableScrollButtons';
import { BettingOdds } from './components/BettingOdds';
import { getPremiershipWinProp } from 'widgets/ladder-predictor-v2/js/utils/get-premiership-win-prop';
import { useBettingVis } from 'common/react/hooks/use-betting-vis';
import { useGetBettingOddsQuery } from 'common/store/modules/api/cfs-api';

const SCROLL_DEBOUNCE_MS = 10;

/*
 * Render the prediced ladder table
 */
export const PredictedLadderTable = ({ compSeason }) => {
    const ladder = useSelector(selectLadder);
    const scrollWrapperRef = useRef();
    const wrapperRef = useRef();
    const [isScrollable, setIsScrollable] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const leftButtonRef = useRef();
    const rightButtonRef = useRef();

    // Debounce needs to be set as a function to work correctly and then be called
    const debounceScrollPosition = _.debounce((value) => {
        setScrollPosition(value);
    }, SCROLL_DEBOUNCE_MS);

    const updateIsScrollable = useCallback(() => {
        const scrollableWrapper = scrollWrapperRef.current;
        const wrapper = wrapperRef.current;

        if (!scrollableWrapper || !wrapper) {
            setIsScrollable(false);
            return;
        }

        setIsScrollable(scrollableWrapper.clientWidth > wrapper.clientWidth);
    }, []);

    // Run the scrollability check only once, after the ladder has rendered
    useEffect(() => {
        if (ladder.length > 0) {
            const handleInitialCheck = () => {
                setTimeout(() => {
                    updateIsScrollable();
                }, 0);
            };

            handleInitialCheck();
        }
    }, [ladder, updateIsScrollable]);

    // Update scrollability on window resize
    useEffect(() => {
        const handleResize = () => updateIsScrollable();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [updateIsScrollable]);

    const handleScroll = (e) => {
        debounceScrollPosition(e.target.scrollLeft);
    };

    const isBettingVis = useBettingVis();
    const { data: bettingData } = useGetBettingOddsQuery({
        competitionType: PULSE.app.common.match.getCompAbbr(
            compSeason.providerId
        )
    });

    // Check for a book for Premiership Winner and that the comp id matches
    const hasPremiershipWinBook = useMemo(
        () =>
            bettingData?.competition?.books.find((book) => {
                return book.name.includes('Premiership Winner');
            }) && bettingData?.competition?.seasonId === compSeason.providerId,
        [
            bettingData?.competition?.books,
            bettingData?.competition?.seasonId,
            compSeason.providerId
        ]
    );

    return (
        <>
            <div
                className={`stats-table ${
                    isScrollable ? 'stats-table--is-scrollable' : ''
                } stats-table--ladder`}
                ref={wrapperRef}
            >
                <div
                    className="stats-table__wrapper js-scrollable-container"
                    style={{ overflow: 'auto' }}
                    onScroll={handleScroll}
                >
                    {isScrollable && (
                        <TableScrollButtons
                            scrollWrapperRef={scrollWrapperRef}
                            wrapperRef={wrapperRef}
                            scrollPosition={scrollPosition}
                            leftButtonRef={leftButtonRef}
                            rightButtonRef={rightButtonRef}
                        />
                    )}

                    <table
                        className="stats-table__table"
                        ref={scrollWrapperRef}
                    >
                        <thead>
                            <tr className="stats-table__header-row">
                                <th
                                    className="stats-table__header-cell stats-table__header-cell--position"
                                    scope="col"
                                >
                                    <button
                                        className="stats-table__header-cell-button"
                                        disabled={true}
                                    >
                                        <span className="stats-table__header-cell-position-label">
                                            {getTranslation(
                                                `label.ladder.pos.short`
                                            )}
                                        </span>
                                        <span className="stats-table__header-cell-club-label">
                                            {getTranslation(
                                                `label.ladder.club`
                                            )}
                                        </span>
                                    </button>
                                </th>
                                <th
                                    className="stats-table__header-cell stats-table__header-cell--club-name"
                                    scope="col"
                                ></th>
                                <th
                                    colSpan="1"
                                    role="columnheader"
                                    className="stats-table__header-cell stats-table__header-cell--played"
                                    scope="col"
                                >
                                    <button
                                        className="stats-table__header-cell-button"
                                        disabled={true}
                                    >
                                        {getTranslation(
                                            `label.ladder.played.short`
                                        )}
                                    </button>
                                </th>
                                <th
                                    className="stats-table__header-cell stats-table__header-cell--points"
                                    scope="col"
                                >
                                    <button
                                        className="stats-table__header-cell-button"
                                        disabled={true}
                                    >
                                        <span>
                                            {getTranslation(
                                                `label.ladder.points.short`
                                            )}
                                        </span>
                                    </button>
                                </th>
                                <th
                                    colSpan="1"
                                    role="columnheader"
                                    className="stats-table__header-cell stats-table__header-cell--percentage"
                                    scope="col"
                                >
                                    <button
                                        className="stats-table__header-cell-button"
                                        disabled={true}
                                    >
                                        {getTranslation(
                                            `label.percentage.short`
                                        )}
                                    </button>
                                </th>
                                <th
                                    colSpan="1"
                                    role="columnheader"
                                    className="stats-table__header-cell stats-table__header-cell--won"
                                    scope="col"
                                >
                                    <button
                                        className="stats-table__header-cell-button"
                                        disabled={true}
                                    >
                                        {getTranslation(
                                            `label.ladderPredictor.allOutcomes`
                                        )}
                                    </button>
                                </th>
                                <th
                                    className="stats-table__header-cell stats-table__header-cell--form"
                                    scope="col"
                                >
                                    <button
                                        className="stats-table__header-cell-button"
                                        disabled={true}
                                    >
                                        <div className="stats-table__header-cell-content stats-table__header-cell-content--form">
                                            <span>
                                                {getTranslation(`label.form`)}
                                            </span>
                                            <SvgIcon
                                                icon="arrow-right"
                                                className="icon"
                                            />

                                            <span className="stats-table__latest-label">
                                                {getTranslation(
                                                    `label.ladder.latest`
                                                )}
                                            </span>
                                        </div>
                                    </button>
                                </th>

                                {isBettingVis && hasPremiershipWinBook && (
                                    <th
                                        className="stats-table__header-cell stats-table__header-cell--betting-odds"
                                        scope="col"
                                    >
                                        <button
                                            className="stats-table__header-cell-button"
                                            disabled={true}
                                        >
                                            <img
                                                className="icon"
                                                src={`/resources/${window.RESOURCES_VERSION}/i/elements/betting-cell-banner.svg`}
                                            />
                                        </button>
                                    </th>
                                )}
                            </tr>
                        </thead>

                        <tbody>
                            {ladder.map((row, index) => (
                                <tr
                                    key={row.team.id}
                                    className={`stats-table__body-row ${
                                        index + 1 === 8
                                            ? 'is-qualifier-row'
                                            : ''
                                    }`}
                                >
                                    <th className="stats-table__row-header-cell stats-table__row-header-cell stats-table__cell--position">
                                        <div className="stats-table__position-club-wrapper">
                                            <div className="stats-table__position">
                                                <span className="stats-table__position-cell-label">
                                                    {index + 1}
                                                </span>
                                                {row.positionChange ===
                                                    'UP' && (
                                                    <SvgIcon
                                                        icon="pos-change-up"
                                                        className="icon"
                                                    />
                                                )}
                                                {row.positionChange ===
                                                    'DOWN' && (
                                                    <SvgIcon
                                                        icon="pos-change-down"
                                                        className="icon"
                                                    />
                                                )}
                                                {row.positionChange ===
                                                    'NONE' && (
                                                    <SvgIcon
                                                        icon="pos-change-none"
                                                        className="icon"
                                                    />
                                                )}
                                            </div>
                                            <div className="stats-table__club">
                                                <TeamIcon
                                                    className="ladder-predictor-match-card__team-logo"
                                                    providerId={
                                                        row.team.providerId
                                                    }
                                                />
                                            </div>

                                            {index + 1 === 8 && (
                                                <div className="stats-table__qualifiers-wrapper">
                                                    <div className="stats-table__qualifiers-banner">
                                                        <span className="stats-table__qualifiers-banner-tab">
                                                            {getTranslation(
                                                                `label.ladder.topEight`
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                    <th className="stats-table__row-header-cell stats-table__row-header-cell stats-table__cell--club-name">
                                        <div className="stats-table__club-name-wrapper">
                                            <span className="stats-table__club-name">
                                                {row.team.name}
                                            </span>
                                        </div>
                                    </th>
                                    <td className="stats-table__cell">
                                        {row.played}
                                    </td>
                                    <td className="stats-table__cell stats-table__cell--points">
                                        {row.thisSeasonRecord.aggregatePoints}
                                    </td>
                                    <td className="stats-table__cell">
                                        {row.thisSeasonRecord.percentage}
                                    </td>

                                    <td className="stats-table__cell">
                                        {
                                            row.thisSeasonRecord.winLossRecord
                                                .wins
                                        }
                                        -
                                        {
                                            row.thisSeasonRecord.winLossRecord
                                                .losses
                                        }
                                        -
                                        {
                                            row.thisSeasonRecord.winLossRecord
                                                .draws
                                        }
                                    </td>

                                    <Form form={row.form} />

                                    {isBettingVis && hasPremiershipWinBook && (
                                        <BettingOdds
                                            proposition={getPremiershipWinProp(
                                                bettingData,
                                                row.team.providerId
                                            )}
                                        />
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

PredictedLadderTable.propTypes = {
    compSeason: PropTypes.object
};
