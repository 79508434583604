import React from 'react';
import PropTypes from 'prop-types';

import { SwitchInput } from 'common/react/components/SwitchInput';
import { getTranslation } from 'common/react/utils/translations';

export const ExpandLineupsToggle = ({
    isActive,
    onChange,
    reversed = false
}) => {
    const onClickHandler = (active) => {
        onChange(active);
    };

    return (
        <div className="competition-nav__lineups-toggle">
            <SwitchInput
                id="expand-lineups-toggle"
                label={getTranslation('label.teamLineups.expandAllLineups')}
                isChecked={isActive}
                onClick={onClickHandler}
                reversed={reversed}
            />
        </div>
    );
};

ExpandLineupsToggle.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    reversed: PropTypes.bool
};
