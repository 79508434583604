import { useSelector } from 'react-redux';
import { selectLadder } from '../selector/ladder';

/**
 * Get the position of a team from the ladder.
 *
 * @param {string} teamId - The ID of the team.
 * @returns {object | null} - The position and movement of the team, or null if not found.
 */
export const useTeamPosition = (teamId) => {
    // Use the `selectLadder` selector to get the ladder from the state
    const ladder = useSelector(selectLadder);

    // Find the team in the ladder and return its position
    if (ladder && Array.isArray(ladder)) {
        const team = ladder.find((entry) => entry.team.id === teamId);
        return team
            ? {
                  position: team.position,
                  positionChange: team.positionChange
              }
            : null;
    }

    // If ladder is unavailable, return null
    return null;
};
