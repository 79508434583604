import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { useGetDraftByYearQuery } from '../api/draft-tracker-api';
import { draftTrackerNavSelector } from 'store/modules/draft-tracker/leaderboard/selectors';

export const RoundNavItem = ({ year }) => {
    const dispatch = useDispatch();
    const nav = useSelector(draftTrackerNavSelector);

    const { data: draftData } = useGetDraftByYearQuery(year);

    const roundList = [
        ...new Set(draftData?.selections?.map((item) => item.roundNumber))
    ];
    const rounds = [
        { id: -1, name: getTranslation('label.draftTracker.allRounds') },
        ...(roundList?.map((item) => ({ id: item, name: item })) ?? [])
    ];

    return (
        <Select
            items={rounds}
            itemToString={(item) => item?.name}
            initialSelectedItem={rounds[0]}
            activeItem={rounds.find((item) => item.id === nav.round)}
            label={getTranslation('label.stats.round')}
            isDisabled={
                draftData && draftData.selections?.length ? false : true
            }
            onSelectedItemChange={({ selectedItem }) => {
                // RTK Query update: Update the Redux store with a slice action
                dispatch(
                    PULSE.app.redux.actions.draftTracker.updateNav({
                        name: 'round',
                        value: selectedItem.id
                    })
                );
            }}
        />
    );
};

RoundNavItem.propTypes = {
    year: PropTypes.string.isRequired,
    title: PropTypes.string
};
