import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { TeamLineupsFieldView } from './fieldview';
import { TeamLineupsListView } from './listview';

export const FieldListView = ({ match, isAFLW = false }) => {
    const [tabView, setTabView] = useState('field');

    const onTabClick = (view) => {
        /* eslint-disable camelcase */
        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.TAB,
            {
                eventData: {
                    tab_name: `${view} view`,
                    component_name: match.match.name,
                    component_content_type:
                        PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES
                            .LINEUPS
                }
            }
        );
        /* eslint-enable camelcase */

        setTabView(view);
    };

    const hasTeamLineups = useMemo(() => {
        return (
            match?.matchRoster?.homeTeam?.positions.length > 0 &&
            match?.matchRoster?.awayTeam?.positions.length > 0
        );
    }, [match]);

    return (
        <>
            {hasTeamLineups ? (
                <div
                    className="team-lineups__sub-section team-lineups__sub-section--views"
                    tabIndex={0}
                >
                    <div className="team-lineups__tabs">
                        <button
                            className={`team-lineups__tab-toggle-button ${
                                tabView === 'field' ? 'is-active' : ''
                            }`}
                            onClick={() => onTabClick('field')}
                        >
                            <SvgIcon icon="login" className="icon" />
                            {getTranslation(
                                'label.teamannouncements.fieldView'
                            )}
                        </button>
                        <button
                            className={`team-lineups__tab-toggle-button ${
                                tabView === 'list' ? 'is-active' : ''
                            }`}
                            onClick={() => onTabClick('list')}
                        >
                            <SvgIcon icon="list-bullet" className="icon" />
                            {getTranslation('label.teamannouncements.listView')}
                        </button>
                    </div>
                    <div className="team-lineups__tabs-content">
                        {tabView === 'field' ? (
                            <TeamLineupsFieldView {...match} isAFLW={isAFLW} />
                        ) : (
                            <TeamLineupsListView {...match} isAFLW={isAFLW} />
                        )}
                    </div>
                </div>
            ) : (
                <div className="team-lineups__sub-section team-lineups__sub-section--empty-state">
                    {getTranslation('label.teamLineups.notAvailable')}
                </div>
            )}
        </>
    );
};

FieldListView.propTypes = {
    match: PropTypes.object.isRequired,
    isAFLW: PropTypes.bool
};
