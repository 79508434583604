import React from 'react';
import PropTypes from 'prop-types';

import { STATS_COLUMNS } from 'widgets/statspro/js/components/common/config/columns';
import { BENCHMARKING } from 'common/react/components/benchmarking/config/benchmarking';
import { BenchmarkLabel } from 'common/react/components/benchmarking';
import { TeamIcon } from 'common/react/components/TeamIcon';

const CLASS_BLOCK = 'player-stats-tooltip-main-stat';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @param {string} [props.benchmark] - Benchmark.
 * @returns {JSX.Element} Component.
 */
export const PlayerStatsTooltipMainStat = ({ row, cell, benchmark }) => {
    const stat = cell.column.id;

    return (
        <div className={CLASS_BLOCK}>
            <div className={`${CLASS_BLOCK}__header-row`}>
                <div>
                    <div className={`${CLASS_BLOCK}__name`}>
                        {STATS_COLUMNS[stat].name}
                    </div>
                    <div className={`${CLASS_BLOCK}__row`}>
                        <div className={`${CLASS_BLOCK}__value`}>
                            {row[stat]}
                        </div>
                        {benchmark && (
                            <BenchmarkLabel
                                benchmarkKey={BENCHMARKING[benchmark].KEY}
                            />
                        )}
                    </div>
                </div>
                {row?.opponent?.teamAbbr && (
                    <div
                        key={row?.player?.playerId}
                        className={`${CLASS_BLOCK}__opponent`}
                    >
                        v {row?.opponent?.teamAbbr}
                        <TeamIcon
                            teamAbbreviation={row?.opponent?.teamAbbr}
                            className={`${CLASS_BLOCK}__opponent-logo`}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

PlayerStatsTooltipMainStat.propTypes = {
    row: PropTypes.object.isRequired,
    cell: PropTypes.object.isRequired,
    benchmark: PropTypes.string
};
