import React from 'react';
import PropTypes from 'prop-types';

import { InformationCardHeader } from './InformationCardHeader';
import { InformationCardSponsor } from './InformationCardSponsor';
import { InformationCardOpening } from './InformationCardOpening';
import { InformationCardDescription } from './InformationCardDescription';
import { InformationCardFooter } from './InformationCardFooter';

export const InformationCard = ({
    selectedLocation,
    googlePlacesData,
    timezone
}) => {
    return (
        <article
            className={`interactive-event-map__location-container js-interactive-event-map__location-container ${
                selectedLocation !== null ? '' : 'u-hide'
            }`}
        >
            <InformationCardHeader selectedLocation={selectedLocation} />

            {selectedLocation?.references?.length > 0 && (
                <InformationCardSponsor
                    sponsorItem={selectedLocation?.references[0]}
                />
            )}

            {selectedLocation?.metadata?.map_event_address ||
            googlePlacesData?.formatted_address ? (
                <InformationCardOpening
                    selectedLocation={selectedLocation}
                    googlePlacesData={googlePlacesData}
                    timezone={timezone}
                />
            ) : null}

            {selectedLocation?.description ? (
                <InformationCardDescription
                    selectedLocation={selectedLocation}
                    googlePlacesData={googlePlacesData}
                />
            ) : null}

            {selectedLocation?.promoUrl || googlePlacesData?.website ? (
                <InformationCardFooter
                    selectedLocation={selectedLocation}
                    googlePlacesData={googlePlacesData}
                />
            ) : null}
        </article>
    );
};

InformationCard.propTypes = {
    selectedLocation: PropTypes.object,
    googlePlacesData: PropTypes.object,
    timezone: PropTypes.string
};
