/* eslint-disable no-undefined */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { TeamIcon } from 'common/react/components/TeamIcon';
import { SvgIcon } from 'common/react/components/SvgIcon';

import { useDispatch, useSelector } from 'react-redux';
import { predictionFinalsSelector } from 'widgets/ladder-predictor-v2/js/selector/predictor';

import { MatchCardTeamRowCompleted } from './teamRowCompleted';
import { CONFIG } from '../../constants';

import {
    setPredictionFinals,
    removeFinalsPredictionsById,
    validatePrediction
} from 'common/store/modules/ladder-predictor/init';
import { getTranslation } from 'common/react/utils/translations';

const GRAND_FINAL = getTranslation(
    'label.ladderPredictor.finalsGame.grandFinal'
);
const FIRST_PRELIMINARY_FINAL = getTranslation(
    'label.ladderPredictor.finalsGame.1stPreliminaryFinal'
);
const SECOND_PRELIMINARY_FINAL = getTranslation(
    'label.ladderPredictor.finalsGame.2ndPreliminaryFinal'
);
const FIRST_SEMI_FINAL = getTranslation(
    'label.ladderPredictor.finalsGame.1stSemiFinal'
);
const SECOND_SEMI_FINAL = getTranslation(
    'label.ladderPredictor.finalsGame.2ndSemiFinal'
);

// eslint-disable-next-line complexity
export const MatchCardTeamRow = ({
    match,
    isHomeTeam,
    matchStatus,
    cardText,
    score,
    seasonId,
    competitionId,
    isSelectable
}) => {
    const homeTeam = match?.home?.team;
    const awayTeam = match?.away?.team;

    // Redux hooks
    const dispatch = useDispatch();
    const prediction = useSelector((state) =>
        predictionFinalsSelector(state, match.id)
    );

    // Handle winner select
    const handleWinnerSelect = (winnerId, loserId) => {
        dispatch(
            setPredictionFinals({
                i: match.id, // Match id
                r: match.round.roundNumber, // Round number
                w: winnerId, // Winning team
                l: loserId, // Losing team
                n: match.round.name, // Round name
                c: matchStatus === CONFIG.CONCLUDED // Is match concluded
            })
        );
    };

    useEffect(() => {
        if (prediction && prediction?.w) {
            if (
                prediction.w !== homeTeam?.id &&
                prediction.w !== awayTeam?.id
            ) {
                // console.warn(`${match.id} was predicted with a winning team that is no longer valid.`);
                dispatch(removeFinalsPredictionsById({ id: match.id }));

                const teamIdNoLongerExisting = prediction.w;

                // Check the current prediction and validate the full path to the final
                if (prediction.n === SECOND_SEMI_FINAL) {
                    dispatch(
                        validatePrediction({
                            name: FIRST_PRELIMINARY_FINAL,
                            id: teamIdNoLongerExisting
                        })
                    );
                    dispatch(
                        validatePrediction({
                            name: GRAND_FINAL,
                            id: teamIdNoLongerExisting
                        })
                    );
                } else if (prediction.n === FIRST_SEMI_FINAL) {
                    dispatch(
                        validatePrediction({
                            name: SECOND_PRELIMINARY_FINAL,
                            id: teamIdNoLongerExisting
                        })
                    );
                    dispatch(
                        validatePrediction({
                            name: GRAND_FINAL,
                            id: teamIdNoLongerExisting
                        })
                    );
                } else if (prediction.n === FIRST_PRELIMINARY_FINAL) {
                    dispatch(
                        validatePrediction({
                            name: GRAND_FINAL,
                            id: teamIdNoLongerExisting
                        })
                    );
                } else if (prediction.n === SECOND_PRELIMINARY_FINAL) {
                    dispatch(
                        validatePrediction({
                            name: GRAND_FINAL,
                            id: teamIdNoLongerExisting
                        })
                    );
                }
            }
        }
    }, [matchStatus, isHomeTeam, seasonId, competitionId, homeTeam, awayTeam]);

    // Returns:
    if (matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.CONCLUDED) {
        return (
            <MatchCardTeamRowCompleted
                homeTeam={homeTeam}
                awayTeam={awayTeam}
                isHomeTeam={isHomeTeam}
                match={match}
                score={score}
            />
        );
    }

    return (
        isHomeTeam
            ? homeTeam?.id && homeTeam?.abbreviation
            : awayTeam?.id && awayTeam?.abbreviation
    ) ? (
        <button
            className={`ladder-predictor-match-card__team ladder-predictor-match-card__team--button ${
                prediction &&
                prediction?.w === (isHomeTeam ? homeTeam?.id : awayTeam?.id)
                    ? 'ladder-predictor-match-card__team--selected'
                    : ''
            }`}
            onClick={() => {
                handleWinnerSelect(
                    isHomeTeam ? homeTeam?.id : awayTeam?.id,
                    isHomeTeam ? awayTeam?.id : homeTeam?.id
                );
            }}
            disabled={!isSelectable}
        >
            {(
                isHomeTeam
                    ? homeTeam?.id && homeTeam?.abbreviation
                    : awayTeam?.id && awayTeam?.abbreviation
            ) ? (
                <>
                    <div className="ladder-predictor-match-card__team-logo-light">
                        <TeamIcon
                            className="ladder-predictor-match-card__team-logo"
                            providerId={
                                isHomeTeam
                                    ? homeTeam?.providerId
                                    : awayTeam?.providerId
                            }
                            theme={PULSE.app.common.team.getTeamLogoTheme(
                                isHomeTeam
                                    ? homeTeam?.abbreviation
                                    : awayTeam?.abbreviation,
                                'b'
                            )}
                        />
                    </div>
                    <div className="ladder-predictor-match-card__team-logo-dark">
                        <TeamIcon
                            className="ladder-predictor-match-card__team-logo"
                            providerId={
                                isHomeTeam
                                    ? homeTeam?.providerId
                                    : awayTeam?.providerId
                            }
                        />
                    </div>
                </>
            ) : (
                <TeamIcon
                    className="ladder-predictor-match-card__team-logo ladder-predictor-match-card__team-logo--placeholder"
                    providerId={''}
                />
            )}
            <p
                className="ladder-predictor-match-card__position-static"
                aria-label="Team Position"
            >
                {isHomeTeam ? match?.home?.position : match?.away?.position}
            </p>
            <p
                className="ladder-predictor-match-card__name"
                aria-label="Team Name"
            >
                {isHomeTeam
                    ? homeTeam
                        ? homeTeam?.name
                        : cardText.topText
                    : awayTeam
                    ? awayTeam?.name
                    : cardText.bottomText}
            </p>
            <div className="ladder-predictor-match-card__right">
                {prediction &&
                    prediction?.w ===
                        (isHomeTeam ? homeTeam?.id : awayTeam?.id) && (
                        <div className="ladder-predictor-match-card__selected-icon">
                            <SvgIcon icon="checkmark" className="icon" />
                        </div>
                    )}
            </div>
        </button>
    ) : (
        <div className="ladder-predictor-match-card__team">
            <TeamIcon
                className="ladder-predictor-match-card__team-logo ladder-predictor-match-card__team-logo--placeholder"
                providerId={''}
            />
            <p
                className="ladder-predictor-match-card__name"
                aria-label="Team Name"
            >
                {cardText}
            </p>
        </div>
    );
};

MatchCardTeamRow.propTypes = {
    match: PropTypes.object.isRequired,
    isHomeTeam: PropTypes.bool,
    matchStatus: PropTypes.string,
    cardText: PropTypes.string,
    score: PropTypes.number,
    seasonId: PropTypes.string,
    competitionId: PropTypes.string,
    isSelectable: PropTypes.bool
};
