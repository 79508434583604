import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
import { LazyImage } from 'common/react/components/LazyImage';

import { ModalTitle } from '../site-settings-modal/js/components/modal-elements/ModalTitle';
import { SkeletonLoader } from 'common/react/components/SkeletonLoader';

export const ModalGeneric = ({
    tag,
    bgColour,
    darkMode,
    isAflId,
    type,
    modalName = 'modal generic',
    ctaCallbacks = [],
    preventModals
}) => {
    const { promo, isLoading } = useGetContentItemQuery(
        {
            type: 'PROMO',
            params: {
                pageSize: 1,
                tagNames: tag
            }
        },
        {
            selectFromResult: ({ data: promoData }) => ({
                promo: promoData?.content[0]
            })
        }
    );

    useEffect(() => {
        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.VIEW,
            {
                /* eslint-disable camelcase */
                eventData: {
                    modal_name: modalName
                }
                /* eslint-enable camelcase */
            }
        );

        return () => {
            if (PULSE.app.common.siteSettings.get().trackEvent) {
                PULSE.app.tracking.recordEvent(
                    PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.CLOSE,
                    {
                        /* eslint-disable camelcase */
                        eventData: {
                            modal_name: modalName
                        }
                        /* eslint-enable camelcase */
                    }
                );
            }
        };
    }, []);

    /**
     * callback hook
     * if preventModals is tue, modifies promoUrl (if exists) adding aflmodals=0 search parameter
     *
     * @returns {string | boolean} url string or false
     */
    const promoLink = useMemo(() => {
        if (promo?.promoUrl) {
            const url = new URL(promo?.promoUrl, location.origin);

            // is preventModals true
            // and is the link's origin the same as this site (don't add parameter to the external site)
            if (preventModals && url.origin === location.origin) {
                url.searchParams.set('aflmodals', '0');
            }

            return url.toString();
        }

        return false;
    }, [promo]);

    return isLoading || !promo ? (
        <div className="modal-skeleton" tabIndex={1}>
            <div className="modal-skeleton__modal-title-close-btn">
                <SkeletonLoader />
            </div>
            <div className="modal-skeleton__image-container">
                <SkeletonLoader />
            </div>
            <div className="modal-skeleton__body">
                <div className="modal-skeleton__afl-id-logo">
                    <SkeletonLoader />
                </div>
                <div className="modal-skeleton__heading">
                    <SkeletonLoader />
                </div>
                <div className="modal-skeleton__description">
                    <SkeletonLoader />
                </div>
                <div className="modal-skeleton__buttons">
                    <SkeletonLoader />
                    <SkeletonLoader />
                </div>
                <div className="modal-skeleton__footer">
                    <SkeletonLoader />
                </div>
            </div>
        </div>
    ) : (
        <div
            className={`modal-container ${
                darkMode ? 'modal-container--dark-mode' : null
            }`}
            style={{ background: bgColour }}
        >
            <ModalTitle bgColour={bgColour} darkMode={darkMode} type={type} />
            {promo?.promoItem?.onDemandUrl ? (
                <div className="modal-image-container">
                    <LazyImage
                        url={`${promo.promoItem.onDemandUrl}?height=196`}
                        cssClass="modal-image"
                    />
                </div>
            ) : null}
            <div className="modal-body" style={{ background: bgColour }}>
                <div className="modal-body__content">
                    <div className="modal-body__title">
                        {isAflId ? (
                            <SvgIcon
                                icon={'afl-id-logo'}
                                className="modal-body__afl-id-logo"
                            />
                        ) : null}
                        <div className="modal-body__summary">
                            {promo?.title ? (
                                <h3 className="modal-body__heading">
                                    {promo?.title}
                                </h3>
                            ) : null}
                            {promo?.description ? (
                                <p className="modal-body__description">
                                    {promo?.description}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="modal-body__buttons">
                        {promo?.links?.map((link, index) => (
                            <a
                                href={link?.promoUrl}
                                onClick={
                                    ctaCallbacks[index]
                                        ? (evnt) =>
                                              ctaCallbacks[index](evnt, link)
                                        : null
                                }
                                className="modal-afl-id-promo__button button-new button-new--primary"
                                key={`modal-body__button_${index}`}
                            >
                                <span className="modal-afl-id-promo__button-text">
                                    {link?.linkText}
                                </span>
                                <SvgIcon
                                    icon={'arrow-right'}
                                    className="icon"
                                />
                            </a>
                        ))}
                    </div>
                    <div className="modal-body__footer">
                        {promo?.linkText ? (
                            promo?.promoUrl ? (
                                <a
                                    href={promoLink}
                                    className="modal-body__footer"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {promo?.linkText}
                                    <SvgIcon
                                        icon="arrow-right"
                                        className="icon"
                                    />
                                </a>
                            ) : (
                                <p>{promo?.linkText}</p>
                            )
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

ModalGeneric.propTypes = {
    tag: PropTypes.string,
    bgColour: PropTypes.string,
    darkMode: PropTypes.bool,
    isAflId: PropTypes.bool,
    type: PropTypes.string,
    modalName: PropTypes.string,
    ctaCallbacks: PropTypes.array,
    preventModals: PropTypes.bool
};
