import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from 'react-redux';
import { CGSlider } from '../components/CGSlider';

export const CGSliderProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <CGSlider {...data} />
        </Provider>
    );
};

CGSliderProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
