import { getTranslation } from 'common/react/utils/translations';
import PropTypes from 'prop-types';
import React from 'react';
import { PlayerProfileLink } from 'widgets/statspro/js/components/common/PlayerProfileLink';

export const Milestones = ({ match }) => {
    const { homeTeam, awayTeam } = match.matchRoster;

    const homeTeamMilestones = homeTeam?.milestones.filter(
        (item) =>
            item.milestoneEvent !== 'Debut game' ||
            !homeTeam.clubDebuts.find(
                (debut) => debut.player.playerId === item.player.playerId
            )
    );

    const awayTeamMilestones = awayTeam?.milestones.filter(
        (item) =>
            item.milestoneEvent !== 'Debut game' ||
            !awayTeam.clubDebuts.find(
                (debut) => debut.player.playerId === item.player.playerId
            )
    );

    if (!homeTeamMilestones.length && !awayTeamMilestones.length) {
        return null;
    }

    const renderMilestoneEvent = (milestoneEvent, isLast) => {
        return (
            <React.Fragment key={milestoneEvent.player.playerId}>
                <PlayerProfileLink
                    player={{
                        playerId: milestoneEvent.player.playerId,
                        firstName: milestoneEvent.player.playerName.givenName,
                        surname: milestoneEvent.player.playerName.surname
                    }}
                >
                    {`${milestoneEvent.player.playerName.givenName} ${milestoneEvent.player.playerName.surname} (${milestoneEvent.milestoneEvent})`}
                </PlayerProfileLink>
                {!isLast ? ', ' : ''}
            </React.Fragment>
        );
    };

    return (
        <div className="team-lineups-milestones">
            {/* Mobile Title */}
            <div className="team-lineups__title-section u-hide-from-tablet">
                <span className="team-lineups__title">
                    {getTranslation('label.teamlineups.milestones')}
                </span>
            </div>
            <div className="team-lineups__grid">
                <div className="team-lineups__event-text team-lineups__event-text--home">
                    {homeTeamMilestones.length ? (
                        homeTeamMilestones.map((item, i) =>
                            renderMilestoneEvent(
                                item,
                                i + 1 === homeTeamMilestones.length
                                    ? true
                                    : false
                            )
                        )
                    ) : (
                        <>&ndash;</>
                    )}
                </div>
                <div className="team-lineups__title-section u-hide-until-tablet">
                    <span className="team-lineups__title">
                        {getTranslation('label.teamlineups.milestones')}
                    </span>
                </div>
                <div className="team-lineups__event-text">
                    {awayTeamMilestones.length ? (
                        awayTeamMilestones.map((item, i) =>
                            renderMilestoneEvent(
                                item,
                                i + 1 === awayTeamMilestones.length
                                    ? true
                                    : false
                            )
                        )
                    ) : (
                        <>&ndash;</>
                    )}
                </div>
            </div>
        </div>
    );
};

Milestones.propTypes = {
    match: PropTypes.object.isRequired
};
