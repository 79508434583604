import { getTranslation } from 'common/react/utils/translations';

const TOP_TEAMS = 8;
const FIRST_PLACE = 0;
const SECOND_PLACE = 1;
const THIRD_PLACE = 2;
const FOURTH_PLACE = 3;
const FIFTH_PLACE = 4;
const SIXTH_PLACE = 5;
const SEVENTH_PLACE = 6;
const EIGTH_PLACE = 7;

// Fictional round numbers. Needed for finals predictions sorting.
const FINALS_WEEK_ONE_ROUND = 1001;
const SEMI_FINALS_ROUND = 1002;
const PRELIMINARY_FINALS_ROUND = 1003;
const GRAND_FINAL_ROUND = 1004;

// Fictional match ids.
const FIRST_WEEK_INITIAL_ID = 10000;
const SEMI_FINALS_INITIAL_ID = 10004;
const PREL_FINALS_INITIAL_ID = 10006;
const GRAND_FINAL_INITIAL_ID = 10008;

/**
 * Creates the finals bracket from the first eight teams of the ladder.
 *
 * @param {Array} bracket - The of the first eight teams.
 *
 * @returns {Array} - The array of finals rounds.
 */
export function createBracket(bracket) {
    let roundId = FIRST_WEEK_INITIAL_ID;
    const rounds = [];
    const customPairs = [
        [
            FIRST_PLACE,
            FOURTH_PLACE,
            getTranslation(
                'label.ladderPredictor.finalsGame.1stQualifyingFinal'
            )
        ], // 1st with 4th
        [
            FIFTH_PLACE,
            EIGTH_PLACE,
            getTranslation(
                'label.ladderPredictor.finalsGame.1stEliminationFinal'
            )
        ], // 5th with 8th
        [
            SIXTH_PLACE,
            SEVENTH_PLACE,
            getTranslation(
                'label.ladderPredictor.finalsGame.2ndEliminationFinal'
            )
        ], // 6th with 7th
        [
            SECOND_PLACE,
            THIRD_PLACE,
            getTranslation(
                'label.ladderPredictor.finalsGame.2ndQualifyingFinal'
            )
        ] // 2nd with 3rd
    ];

    for (const [homeIndex, awayIndex, roundName] of customPairs) {
        const homeTeam = bracket[homeIndex];
        const awayTeam = bracket[awayIndex];
        const round = _createFinalsRound(
            homeTeam,
            awayTeam,
            FINALS_WEEK_ONE_ROUND,
            roundName
        );
        round.id = roundId++;
        rounds.push(round);
    }

    return rounds;
}

/**
 *  Generates the Semi-Finals matches based on Finals week one predictions.
 *
 * @param {Array} predictions - The Finals week one predictions.
 * @param {Array} bracket - The bracket. Used to get the team information.
 *
 * @returns {Array} - The Semi-Finals rounds.
 */
export function createSemiFinals(predictions, bracket) {
    let roundId = SEMI_FINALS_INITIAL_ID;
    const rounds = [];
    const customPairs = [
        [
            getTranslation(
                'label.ladderPredictor.finalsGame.1stQualifyingFinal'
            ),
            getTranslation(
                'label.ladderPredictor.finalsGame.1stEliminationFinal'
            ),
            getTranslation('label.ladderPredictor.finalsGame.1stSemiFinal')
        ],
        [
            getTranslation(
                'label.ladderPredictor.finalsGame.2ndQualifyingFinal'
            ),
            getTranslation(
                'label.ladderPredictor.finalsGame.2ndEliminationFinal'
            ),
            getTranslation('label.ladderPredictor.finalsGame.2ndSemiFinal')
        ]
    ];

    for (const [homeType, awayType, roundName] of customPairs) {
        const homeTeamId = predictions?.find(
            (prediction) => prediction.n === homeType
        )?.l;
        const awayTeamId = predictions?.find(
            (prediction) => prediction.n === awayType
        )?.w;
        const homeTeam = bracket?.find((item) => item.team.id === homeTeamId);
        const awayTeam = bracket?.find((item) => item.team.id === awayTeamId);

        const round = _createFinalsRound(
            homeTeam,
            awayTeam,
            SEMI_FINALS_ROUND,
            roundName
        );
        round.id = roundId++;
        rounds.push(round);
    }

    return rounds;
}

/**
 *  Generates the Preliminary Finals matches based on Semi-Finals predictions.
 *
 * @param {Array} predictions - The predictions.
 * @param {Array} bracket - The bracket. Used to get the team information.
 *
 * @returns {Array} - The Preliminary Finals rounds.
 */
export function createPreliminaryFinals(predictions, bracket) {
    let roundId = PREL_FINALS_INITIAL_ID;
    const rounds = [];
    const customPairs = [
        [
            getTranslation(
                'label.ladderPredictor.finalsGame.1stQualifyingFinal'
            ),
            getTranslation('label.ladderPredictor.finalsGame.2ndSemiFinal'),
            getTranslation(
                'label.ladderPredictor.finalsGame.1stPreliminaryFinal'
            )
        ],
        [
            getTranslation(
                'label.ladderPredictor.finalsGame.2ndQualifyingFinal'
            ),
            getTranslation('label.ladderPredictor.finalsGame.1stSemiFinal'),

            getTranslation(
                'label.ladderPredictor.finalsGame.2ndPreliminaryFinal'
            )
        ]
    ];

    for (const [homeType, awayType, roundName] of customPairs) {
        const homeTeamId = predictions?.find(
            (prediction) => prediction.n === homeType
        )?.w;
        const awayTeamId = predictions?.find(
            (prediction) => prediction.n === awayType
        )?.w;
        const homeTeam = bracket?.find((item) => item.team.id === homeTeamId);
        const awayTeam = bracket?.find((item) => item.team.id === awayTeamId);
        const round = _createFinalsRound(
            homeTeam,
            awayTeam,
            PRELIMINARY_FINALS_ROUND,
            roundName
        );
        round.id = roundId++;
        rounds.push(round);
    }

    return rounds;
}

/**
 *  Generates the Grand Final match based on the Preliminary Finals predictions.
 *
 * @param {Array} predictions - The predictions.
 * @param {Array} bracket - The bracket. Used to get the team information.
 *
 * @returns {object} - The Grand Final match.
 */
export function createGrandFinal(predictions, bracket) {
    let roundId = GRAND_FINAL_INITIAL_ID;
    const matchDetails = {
        homeType: getTranslation(
            'label.ladderPredictor.finalsGame.1stPreliminaryFinal'
        ),
        awayType: getTranslation(
            'label.ladderPredictor.finalsGame.2ndPreliminaryFinal'
        ),
        roundName: getTranslation('label.ladderPredictor.columns.grandFinal')
    };

    const homeTeamId = predictions?.find(
        (prediction) => prediction.n === matchDetails.homeType
    )?.w;
    const awayTeamId = predictions?.find(
        (prediction) => prediction.n === matchDetails.awayType
    )?.w;

    const homeTeam = bracket?.find((item) => item.team.id === homeTeamId);
    const awayTeam = bracket?.find((item) => item.team.id === awayTeamId);

    const grandFinal = _createFinalsRound(
        homeTeam,
        awayTeam,
        GRAND_FINAL_ROUND,
        matchDetails.roundName
    );
    grandFinal.id = roundId;
    return grandFinal;
}

/**
 *  Creates the winner based on the Preliminary Finals predictions.
 *
 * @param {Array} predictions - The predictions.
 * @param {Array} bracket - The bracket. Used to get the team information.
 *
 * @returns {object} - Returns the winner object.
 */
export function createWinner(predictions, bracket) {
    const winnerId = predictions?.find(
        (prediction) =>
            prediction.n ===
            getTranslation('label.ladderPredictor.columns.grandFinal')
    )?.w;

    const winner = bracket?.find((item) => item.team.id === winnerId);

    return winner;
}

/**
 * Gets the first teams of the ladder.
 *
 * @param {Array} ladder - The ladder rankings we will use to get the first teams.
 * @returns {Array} - The teams that the bracket consists of.
 */
export function getTeams(ladder) {
    return ladder.slice(0, TOP_TEAMS);
}

/**
 * Creates the finals rounds.
 *
 * @param {object} homeTeam - The home team.
 * @param {object} awayTeam - The away team.
 * @param {number} roundNumber - The round number.
 * @param {string} roundName - The round name.
 * @returns {object} - The round object.
 */
function _createFinalsRound(homeTeam, awayTeam, roundNumber, roundName) {
    return {
        away: {
            ...awayTeam
        },
        home: {
            ...homeTeam
        },
        id: 0,
        round: {
            roundNumber: roundNumber,
            name: roundName
        }
    };
}

/**
 * Generates the round specific text for the TBC cards on the bracket.
 *
 * @param {string} finalsStage - One of semiFinal, preliminary and grandFinal.
 * @param {number} index - The index to determine where the card
 *  is placed in the bracket.
 *
 * @returns {object} An object with topText, bottomText and matchName to be used
 * across the card.
 */
export function generateTBCCardText(finalsStage, index) {
    switch (finalsStage) {
        case 'semiFinal':
            if (index === 0) {
                return {
                    topText: getTranslation(
                        'label.ladderPredictor.finalsGame.LoserOfQF1'
                    ),
                    bottomText: getTranslation(
                        'label.ladderPredictor.finalsGame.WinnerOfEF1'
                    ),
                    roundName: getTranslation(
                        'label.ladderPredictor.finalsGame.1stSemiFinal'
                    )
                };
            }
            return {
                topText: getTranslation(
                    'label.ladderPredictor.finalsGame.LoserOfQF2'
                ),
                bottomText: getTranslation(
                    'label.ladderPredictor.finalsGame.WinnerOfEF2'
                ),
                roundName: getTranslation(
                    'label.ladderPredictor.finalsGame.2ndSemiFinal'
                )
            };
        case 'preliminary':
            if (index === 0) {
                return {
                    topText: getTranslation(
                        'label.ladderPredictor.finalsGame.WinnerOfQF1'
                    ),
                    bottomText: getTranslation(
                        'label.ladderPredictor.finalsGame.WinnerOfSF2'
                    ),
                    roundName: getTranslation(
                        'label.ladderPredictor.finalsGame.1stPreliminaryFinal'
                    )
                };
            }
            return {
                topText: getTranslation(
                    'label.ladderPredictor.finalsGame.WinnerOfQF2'
                ),
                bottomText: getTranslation(
                    'label.ladderPredictor.finalsGame.WinnerOfSF1'
                ),
                roundName: getTranslation(
                    'label.ladderPredictor.finalsGame.2ndPreliminaryFinal'
                )
            };
        default:
            return {
                topText: getTranslation(
                    'label.ladderPredictor.finalsGame.WinnerOfPF1'
                ),
                bottomText: getTranslation(
                    'label.ladderPredictor.finalsGame.WinnerOfPF2'
                ),
                roundName: getTranslation(
                    'label.ladderPredictor.columns.grandFinal'
                )
            };
    }
}

/**
 *
 * @param {Array} matchesData - An array of matches returned from an API call.
 * @param {object} predictedMatch - The object of the created match.
 * @param {Function} setterFunction - The callback function that dispatches
 * the setPredictionFinals reducer.
 */
export function handleConcludedMatch(
    matchesData,
    predictedMatch,
    setterFunction
) {
    matchesData?.forEach((matchData) => {
        if (
            matchData?.status ===
            PULSE.app.common.CONSTANTS.MATCH_STATUS.CONCLUDED
        ) {
            if (
                matchData?.home?.team?.id === predictedMatch?.home?.team?.id &&
                matchData?.home?.score
            ) {
                if (
                    matchData?.home?.score?.totalScore >
                        matchData?.away?.score?.totalScore &&
                    matchData?.home?.team
                ) {
                    setterFunction(
                        matchData?.home?.team?.id,
                        matchData?.away?.team?.id,
                        matchData
                    );
                } else if (
                    matchData?.away?.score?.totalScore >
                        matchData?.home?.score?.totalScore &&
                    matchData?.away?.team
                ) {
                    setterFunction(
                        matchData?.away?.team?.id,
                        matchData?.home?.team?.id,
                        matchData
                    );
                }
            }
        }
    });
}
