import React from 'react';

import { ModalTitle } from '../ModalTitle';
import { getTranslation } from 'common/react/utils/translations';
import { MarketingPrefsErrorModal } from './MarketingPreferencesErrorModal';

export const MarketingPrefsErrorWrapper = () => {
    return (
        <>
            <ModalTitle
                title={getTranslation('label.accountSettings.error.title')}
                closeBtn={false}
            />
            <MarketingPrefsErrorModal />
        </>
    );
};
