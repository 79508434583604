import React, { useRef, useState, useEffect, useMemo } from 'react';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { MatchCardBettingOdds } from '../components/betting-odds';
import { MatchCardHeader } from '../components/header';
import { getTranslation } from 'common/react/utils/translations';
import { useGetBettingOddsQuery } from 'store/modules/api/cfs-api';
import { useBettingVis } from 'common/react/hooks/use-betting-vis';
import { useTeamPosition } from '../../../hooks/get-position';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { setPrediction } from 'common/store/modules/ladder-predictor/init';

import { predictionSelector } from 'widgets/ladder-predictor-v2/js/selector/predictor';

import { CONFIG } from '../../constants';

/*
 * Render season upcoming match card
 */

// eslint-disable-next-line complexity
export const MatchCardSeasonUpcoming = ({ match }) => {
    // Get prediction
    const prediction = useSelector((state) =>
        predictionSelector(state, match.id)
    );

    // State management
    const [showMarginSelector, setShowMarginSelector] = useState(false); // Toggle visibility of margin selector
    const [margin, setMargin] = useState(
        prediction?.m ?? CONFIG.DEFAULT_MARGIN
    ); // Initialize with prediction margin or default to 20
    const isBettingVisible = useBettingVis(); // Check visibility of betting odds

    // Redux hooks
    const dispatch = useDispatch();

    // Team position
    const homeTeamPosition = useTeamPosition(match?.home.team.id);
    const awayTeamPosition = useTeamPosition(match?.away.team.id);

    // Refs
    const ref = useRef(null); // Reference for closing margin selector
    const rangeRef = useRef(null); // Reference for range input

    // Toggle visibility of margin selector
    const toggleMarginSelector = (event) => {
        event.stopPropagation();
        setShowMarginSelector((prev) => !prev);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowMarginSelector(false);
            }
        };
        window.addEventListener('mousedown', handleOutsideClick);
        return () =>
            window.removeEventListener('mousedown', handleOutsideClick);
    }, []);

    // Handle range input value
    const handleChange = (event) => {
        setMargin(Number(event.target.value));
    };

    // Range background styling
    const rangeStyle = useMemo(() => {
        const progress = (margin / 100) * 100;
        return `linear-gradient(to right, var(--button-bg-primary) ${progress}%, var(--ui-material-mid-2) ${progress}%)`;
    }, [margin]);

    // Adjust margin increment/decrement
    const adjustMargin = (delta) => {
        setMargin((prevMargin) =>
            Math.max(0, Math.min(prevMargin + delta, 100))
        );
    };

    // Handle margin change
    useEffect(() => {
        if (margin === 0 && prediction) {
            if (margin !== prediction.m) {
                handleSetDraw();
            }
        } else if (margin > 0 && prediction) {
            if (margin !== prediction.m) {
                handleWinnerSelect(prediction.w, prediction.l);
            }
        } else if (margin === 0) {
            handleSetDraw();
        }
    }, [margin]);

    // Logic for betting odds
    const { data, isError } = useGetBettingOddsQuery({
        competitionType: PULSE.app.common.match.getCompAbbr(
            match?.compSeason?.providerId
        )
    });

    const isBettingMatch = PULSE.app.common.betting.isBettingMatch(
        match.providerId,
        data?.competition?.books
    );

    // Render odds item
    const renderOddsItem = (team) => {
        if (!team) {
            return null;
        }
        const proposition = isBettingMatch.propositions.find(
            (item) => item.clubId === team.providerId
        );
        return (
            <MatchCardBettingOdds
                href={proposition?.contextualUrls?.fixture || proposition?.url}
                value={proposition.h2h.toFixed(2)}
            />
        );
    };

    // Handle winner select
    const handleWinnerSelect = (winnerId, loserId) => {
        if (margin === 0) {
            setMargin(CONFIG.DEFAULT_MARGIN);
        }

        dispatch(
            setPrediction({
                i: match.id, // Match id
                r: match.round.roundNumber, // Round number
                w: winnerId, // Winning team
                l: loserId, // Loosing team
                d: false, // Draw boolean
                m: margin // Margin (score)
            })
        );
    };

    // Handle set draw
    const handleSetDraw = () => {
        setMargin(0);
        dispatch(
            setPrediction({
                i: match.id, // Match id
                r: match.round.roundNumber, // Round number
                w: match.home.team.id, // Winning team
                l: match.away.team.id, // Loosing team
                d: true, // Draw boolean
                m: 0 // Margin (score)
            })
        );
    };

    // Reset margin when no prediction
    useEffect(() => {
        if (prediction === null) {
            setMargin(CONFIG.DEFAULT_MARGIN);
        }
        if (prediction) {
            setMargin(prediction.m);
        }
    }, [prediction]);

    return (
        <div className="ladder-predictor-match-card">
            <MatchCardHeader
                showComplete={false}
                startDateTime={match?.utcStartTime}
                venueName={match?.venue.name}
                showDate={false}
            />
            <div className="ladder-predictor-match-card__teams">
                <button
                    className={`ladder-predictor-match-card__team ladder-predictor-match-card__team--button ladder-predictor-match-card__team--home-team ${
                        prediction?.w === match?.home.team.id ||
                        prediction?.d === true
                            ? 'ladder-predictor-match-card__team--selected'
                            : ''
                    }`}
                    onClick={() => {
                        handleWinnerSelect(
                            match?.home.team.id,
                            match?.away.team.id
                        );
                    }}
                >
                    <div className="ladder-predictor-match-card__team-logo-light">
                        <TeamIcon
                            className="ladder-predictor-match-card__team-logo"
                            providerId={match?.home.team.providerId}
                            theme={PULSE.app.common.team.getTeamLogoTheme(
                                match?.home.team.abbreviation,
                                'b'
                            )}
                        />
                    </div>

                    <div className="ladder-predictor-match-card__team-logo-dark">
                        <TeamIcon
                            className="ladder-predictor-match-card__team-logo"
                            providerId={match?.home.team.providerId}
                        />
                    </div>
                    <div className="ladder-predictor-match-card__position-dynamic">
                        <p
                            className="ladder-predictor-match-card__position-item"
                            aria-label="Team Position"
                        >
                            {homeTeamPosition.position}
                            {homeTeamPosition.positionChange === 'UP' && (
                                <SvgIcon
                                    icon="pos-change-up"
                                    className="icon"
                                />
                            )}
                            {homeTeamPosition.positionChange === 'DOWN' && (
                                <SvgIcon
                                    icon="pos-change-down"
                                    className="icon"
                                />
                            )}
                            {homeTeamPosition.positionChange === 'NONE' && (
                                <SvgIcon
                                    icon="pos-change-none"
                                    className="icon"
                                />
                            )}
                        </p>
                    </div>
                    <p
                        className="ladder-predictor-match-card__name"
                        aria-label="Team Name"
                    >
                        {match?.home.team.name}
                    </p>
                    <div className="ladder-predictor-match-card__right">
                        {(prediction?.w === match?.home.team.id ||
                            prediction?.d === true) && (
                            <div className="ladder-predictor-match-card__selected-icon">
                                <SvgIcon icon="checkmark" className="icon" />
                            </div>
                        )}

                        {!isBettingVisible ||
                        !data?.competition?.books ||
                        isError ||
                        !isBettingMatch ? (
                            <></>
                        ) : (
                            renderOddsItem(match.home?.team)
                        )}
                    </div>
                </button>
                <button
                    className={`ladder-predictor-match-card__team ladder-predictor-match-card__team--button ladder-predictor-match-card__team--away-team ${
                        prediction?.w === match?.away.team.id ||
                        prediction?.d === true
                            ? 'ladder-predictor-match-card__team--selected'
                            : ''
                    }`}
                    onClick={() => {
                        handleWinnerSelect(
                            match?.away.team.id,
                            match?.home.team.id
                        );
                    }}
                >
                    <div className="ladder-predictor-match-card__team-logo-light">
                        <TeamIcon
                            className="ladder-predictor-match-card__team-logo"
                            providerId={match?.away.team.providerId}
                            theme={PULSE.app.common.team.getTeamLogoTheme(
                                match?.away.team.abbreviation,
                                'b'
                            )}
                        />
                    </div>

                    <div className="ladder-predictor-match-card__team-logo-dark">
                        <TeamIcon
                            className="ladder-predictor-match-card__team-logo"
                            providerId={match?.away.team.providerId}
                        />
                    </div>
                    <div className="ladder-predictor-match-card__position-dynamic">
                        <p
                            className="ladder-predictor-match-card__position-item"
                            aria-label="Team Position"
                        >
                            {awayTeamPosition.position}
                            {awayTeamPosition.positionChange === 'UP' && (
                                <SvgIcon
                                    icon="pos-change-up"
                                    className="icon"
                                />
                            )}
                            {awayTeamPosition.positionChange === 'DOWN' && (
                                <SvgIcon
                                    icon="pos-change-down"
                                    className="icon"
                                />
                            )}
                            {awayTeamPosition.positionChange === 'NONE' && (
                                <SvgIcon
                                    icon="pos-change-none"
                                    className="icon"
                                />
                            )}
                        </p>
                    </div>
                    <p
                        className="ladder-predictor-match-card__name"
                        aria-label="Team Name"
                    >
                        {match?.away.team.name}
                    </p>
                    <div className="ladder-predictor-match-card__right">
                        {(prediction?.w === match?.away.team.id ||
                            prediction?.d === true) && (
                            <div className="ladder-predictor-match-card__selected-icon">
                                <SvgIcon icon="checkmark" className="icon" />
                            </div>
                        )}

                        {!isBettingVisible ||
                        !data?.competition?.books ||
                        isError ||
                        !isBettingMatch ? (
                            <></>
                        ) : (
                            renderOddsItem(match.away?.team)
                        )}
                    </div>
                </button>
            </div>
            <div className="ladder-predictor-match-card__footer">
                <div className="ladder-predictor-match-card__detail" ref={ref}>
                    {getTranslation('label.ladderPredictor.margin')}:
                    <button
                        className={`ladder-predictor-match-card__margin-toggle ${
                            showMarginSelector
                                ? 'ladder-predictor-match-card__margin-toggle--open'
                                : 'ladder-predictor-match-card__margin-toggle--closed'
                        }`}
                        onClick={toggleMarginSelector}
                    >
                        {margin > 0
                            ? margin +
                              ' ' +
                              getTranslation('label.ladderPredictor.ptsAbbr')
                            : getTranslation('label.ladderPredictor.draw')}

                        <SvgIcon icon="chevron-down" className="icon" />
                    </button>
                    {showMarginSelector && (
                        <div className="ladder-predictor-match-card__margin-selector">
                            <div className="ladder-predictor-match-card__arrow"></div>
                            <div className="ladder-predictor-match-card__margin-selector-range">
                                <label
                                    htmlFor="margin"
                                    className="u-screen-reader"
                                >
                                    {getTranslation(
                                        'label.ladderPredictor.selectMargin'
                                    )}
                                </label>
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="10"
                                    id="margin"
                                    onChange={handleChange}
                                    ref={rangeRef}
                                    style={{ background: `${rangeStyle}` }}
                                    value={margin}
                                />
                                <div className="ladder-predictor-match-card__margin-selector-range-ticks">
                                    <span className="ladder-predictor-match-card__margin-selector-range-tick">
                                        {getTranslation(
                                            'label.ladderPredictor.rangeTick.0'
                                        )}
                                    </span>
                                    <span className="ladder-predictor-match-card__margin-selector-range-tick">
                                        {getTranslation(
                                            'label.ladderPredictor.rangeTick.20'
                                        )}
                                    </span>
                                    <span className="ladder-predictor-match-card__margin-selector-range-tick">
                                        {getTranslation(
                                            'label.ladderPredictor.rangeTick.40'
                                        )}
                                    </span>
                                    <span className="ladder-predictor-match-card__margin-selector-range-tick">
                                        {getTranslation(
                                            'label.ladderPredictor.rangeTick.60'
                                        )}
                                    </span>
                                    <span className="ladder-predictor-match-card__margin-selector-range-tick">
                                        {getTranslation(
                                            'label.ladderPredictor.rangeTick.80'
                                        )}
                                    </span>
                                    <span className="ladder-predictor-match-card__margin-selector-range-tick">
                                        {getTranslation(
                                            'label.ladderPredictor.rangeTick.100'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="ladder-predictor-match-card__margin-selector-footer">
                                <button
                                    className="ladder-predictor-match-card__margin-selector-button"
                                    onClick={handleSetDraw}
                                >
                                    {getTranslation(
                                        'label.ladderPredictor.draw'
                                    )}
                                </button>
                                <div className="ladder-predictor-match-card__margin-selector-incremental-buttons">
                                    <button
                                        className="ladder-predictor-match-card__margin-selector-button ladder-predictor-match-card__margin-selector-button--decrease"
                                        onClick={() => adjustMargin(-10)}
                                    >
                                        <SvgIcon
                                            icon="accordion-close"
                                            className="icon"
                                        />
                                    </button>
                                    <button
                                        className="ladder-predictor-match-card__margin-selector-button ladder-predictor-match-card__margin-selector-button--increase"
                                        onClick={() => adjustMargin(10)}
                                    >
                                        <SvgIcon
                                            icon="accordion-open"
                                            className="icon"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

MatchCardSeasonUpcoming.propTypes = {
    match: PropTypes.object.isRequired
};
