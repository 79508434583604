import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelect, useMultipleSelection } from 'downshift';
import classNames from 'classnames';

import { SvgIcon } from '../SvgIcon';
import { DrawerBottom } from '../drawer';

/**
 * @param {object} props - Props.
 * @param {string} props.label - Label.
 * @param props.icon
 * @param {any[]} props.items - Items.
 * @param {any[]} props.selectedItems - Selected items.
 * @param {Function} props.getItemText - Given an item, get the text that
 * represents the item.
 * @param {Function} props.getItemLabel - Given an item, get the label for the
 * item. It can be text or some JSX.
 * @param {Function} props.getItemDisabled - Get disabled state for item if required.
 * @param {Function} props.onSelectedItemsChange - Fires when selectedItems changes
 * @returns {JSX.Element} Component.
 */

const isMobile = window.matchMedia(
    `(max-width: ${PULSE.app.measurements.desktop}px)`
);

const getSelectedItem = (itemsList, targetItem) => {
    for (let item of itemsList) {
        if (_.isEqual(item, targetItem)) {
            return item;
        }
    }
    return null;
};

export const SelectMultiple = ({
    label,
    icon,
    items,
    getItemText,
    getItemLabel,
    getItemDisabled = null,
    onSelectedItemsChange,
    getInitialSelectedItems,
    addOnResetCallback
}) => {
    const selectRef = useRef();

    const {
        getDropdownProps,
        addSelectedItem,
        removeSelectedItem,
        selectedItems,
        reset
    } = useMultipleSelection({
        initialSelectedItems: getInitialSelectedItems(),
        onSelectedItemsChange: ({ selectedItems: newSelectedItems }) => {
            selectRef?.current.dispatchEvent(
                new Event(
                    PULSE.app.common.CONSTANTS.EVENTS.INTERACTION.CHANGE,
                    { bubbles: true }
                )
            );

            if (typeof onSelectedItemsChange === 'function') {
                onSelectedItemsChange(newSelectedItems);
            }
        },
        stateReducer: (state, actionAndChanges) => {
            const { type, changes } = actionAndChanges;

            switch (type) {
                case useMultipleSelection.stateChangeTypes
                    .FunctionAddSelectedItem:
                    return {
                        ...changes,
                        // De duplicate any selectedItems as it fires twice in some scenarios
                        selectedItems: [...new Set(changes.selectedItems)]
                    };
                default:
                    return changes;
            }
        }
    });

    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        getLabelProps,
        closeMenu
    } = useSelect({
        items,
        selectedItem: null,
        stateReducer: (state, actionAndChanges) => {
            const { type, changes } = actionAndChanges;

            const selectedItemObj = getSelectedItem(
                selectedItems,
                changes.selectedItem
            );

            switch (type) {
                case useSelect.stateChangeTypes.MenuKeyDownEnter:
                case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
                case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
                case useSelect.stateChangeTypes.ItemClick:
                    if (!changes.selectedItem) {
                        return changes;
                    }

                    if (
                        selectedItems.some((selectedItem) =>
                            _.isEqual(selectedItem, changes.selectedItem)
                        )
                    ) {
                        removeSelectedItem(selectedItemObj);
                    } else {
                        addSelectedItem(changes.selectedItem);
                    }

                    return {
                        ...changes,
                        isOpen: true
                    };
                case useSelect.stateChangeTypes.ToggleButtonBlur:
                    return {
                        ...changes,
                        isOpen: isMobile.matches ? true : false
                    };
                default:
                    return changes;
            }
        }
    });

    /**
     * Hookup reset method to the list of reset functions to execute when the
     * reset button is clicked.
     */
    useEffect(() => {
        addOnResetCallback(reset);
    }, []);

    return (
        <div className="w-100" ref={selectRef}>
            <div
                className={classNames('select-multiple', {
                    'select-multiple--open': isOpen
                })}
            >
                <div
                    className={classNames('select-multiple__display-wrapper', {
                        'select-multiple__display-wrapper--has-selected-items':
                            !!selectedItems.length
                    })}
                >
                    <button
                        type="button"
                        aria-label={`${label?.toLowerCase()}-dropdown-button`}
                        {...getToggleButtonProps(getDropdownProps())}
                        className="select-multiple__display"
                    >
                        <div className="select-multiple__current">
                            {icon && (
                                <SvgIcon
                                    className="select-multiple__icon"
                                    icon={icon}
                                />
                            )}

                            {!selectedItems.length && label}

                            {selectedItems.reduce(
                                (acc, item) =>
                                    acc
                                        ? acc + ', ' + getItemText(item)
                                        : acc + getItemText(item),
                                ''
                            )}
                        </div>

                        {isOpen && !selectedItems.length && (
                            <SvgIcon
                                className="select-multiple__close"
                                icon="close"
                            />
                        )}

                        {!isOpen && !selectedItems.length && (
                            <SvgIcon
                                className="select-multiple__chevron"
                                icon="chevron-down"
                            />
                        )}
                    </button>
                    {!!selectedItems.length && (
                        <button
                            className="select-multiple__reset"
                            onClick={reset}
                            aria-label={`Reset ${label} dropdown button`}
                        >
                            <SvgIcon
                                className="select-multiple__reset-icon"
                                icon="close-circle-fill"
                            />
                        </button>
                    )}
                </div>
                <div
                    className={classNames('select-multiple__options-wrapper', {
                        'u-hide': isMobile.matches
                    })}
                >
                    <ul
                        {...getMenuProps({}, { suppressRefError: true })}
                        className={classNames('select-multiple__options-list', {
                            'u-hide': !isOpen
                        })}
                    >
                        {items.map((item, index) => {
                            const itemText = getItemLabel(item);

                            const isSelected = selectedItems.some(
                                (selectedItem) => _.isEqual(selectedItem, item)
                            );

                            const isDisabled =
                                typeof getItemDisabled === 'function'
                                    ? getItemDisabled(item)
                                    : false;

                            return (
                                <li
                                    key={`${itemText}${index}`}
                                    {...getItemProps({ item, index })}
                                    className={classNames(
                                        'select-multiple__option',
                                        {
                                            'select-multiple__option--selected':
                                                isSelected,
                                            'select-multiple__option--highlighted':
                                                highlightedIndex === index,
                                            'select-multiple__option--disabled':
                                                isDisabled
                                        }
                                    )}
                                >
                                    {isSelected ? (
                                        <SvgIcon
                                            className="select-multiple__checkmark"
                                            icon="checkmark-square"
                                        />
                                    ) : (
                                        <SvgIcon
                                            className="select-multiple__checkmark-empty"
                                            icon="checkmark-square-empty"
                                        />
                                    )}

                                    <span className="select-multiple__option-label-wrapper">
                                        {getItemLabel(item)}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            {isMobile.matches ? (
                <DrawerBottom
                    isOpen={isOpen}
                    onClose={() => closeMenu()}
                    heading={label}
                >
                    <div className="select-multiple select-multiple--mobile select-multiple--open">
                        {/*
                        An invisible label for accessibility purposes.
                    */}
                        <label className="u-hide" {...getLabelProps()}>
                            {label}
                        </label>

                        {/*
                        An invisible dummy toggle button just so we're calling
                        getToggleButtonProps to ensure rc-drawer doesn't crash.
                    */}
                        <button
                            className="u-hide"
                            {...getToggleButtonProps(getDropdownProps())}
                        ></button>

                        <div className="select-multiple__options-wrapper">
                            <ul
                                {...getMenuProps(
                                    {},
                                    { suppressRefError: true }
                                )}
                                className={classNames(
                                    'select-multiple__options-list',
                                    {
                                        'u-hide': !isOpen
                                    }
                                )}
                            >
                                {items.map((item, index) => {
                                    const itemText = getItemLabel(item);
                                    const isSelected = selectedItems.some(
                                        (selectedItem) =>
                                            _.isEqual(selectedItem, item)
                                    );
                                    const isDisabled =
                                        typeof getItemDisabled === 'function'
                                            ? getItemDisabled(item)
                                            : false;

                                    return (
                                        <li
                                            key={`${itemText}${index}`}
                                            {...getItemProps({ item, index })}
                                            className={classNames(
                                                'select-multiple__option',
                                                {
                                                    'select-multiple__option--selected':
                                                        isSelected,
                                                    'select-multiple__option--highlighted':
                                                        highlightedIndex ===
                                                        index,
                                                    'select-multiple__option--disabled':
                                                        isDisabled
                                                }
                                            )}
                                        >
                                            {isSelected ? (
                                                <SvgIcon
                                                    className="select-multiple__checkmark"
                                                    icon="checkmark-square"
                                                />
                                            ) : (
                                                <SvgIcon
                                                    className="select-multiple__checkmark-empty"
                                                    icon="checkmark-square-empty"
                                                />
                                            )}

                                            <span className="select-multiple__option-label-wrapper">
                                                {getItemLabel(item)}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </DrawerBottom>
            ) : null}
        </div>
    );
};

SelectMultiple.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any),
    itemToString: PropTypes.func,
    getInitialSelectedItems: PropTypes.func,
    onSelectedItemsChange: PropTypes.func.isRequired,
    addOnResetCallback: PropTypes.func.isRequired,
    getItemText: PropTypes.func.isRequired,
    getItemLabel: PropTypes.func.isRequired,
    getItemDisabled: PropTypes.func
};
