import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    filtersSelector,
    navSelector
} from 'common/store/modules/competition-nav/selectors';
import { useGetMatchRosterRoundQuery } from 'common/store/modules/api/cfs-api';
import { useGetMatchesQuery } from 'common/store/modules/api/afl-api';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';
import { useGroupMatches } from 'common/react/hooks/use-group-matches';
import { useFilterMatches } from 'common/react/hooks/use-filter-matches';
import { EmptyState } from 'common/react/components/EmptyState';
import { filterByCity } from 'widgets/fixtures/js/utils/filter-by-city';
import { TeamLineupsItemWrapper } from './TeamLineupsItemWrapper';
import { TeamLineupsLegend } from './TeamLineupsLegend';
import { TeamLineupsSkeleton } from './TeamLineupsSkeleton';
import { getTranslation } from 'common/react/utils/translations';

const SKELETON_ITEMS = 4;

export const TeamLineupsList = ({
    competitionId,
    compSeason,
    roundNumber,
    onMatchesToggleUpdate,
    matchesToggles,
    fixtureIdParam
}) => {
    const nav = useSelector(navSelector);

    const { data: rosterData, isFetching: isRosterFetching } =
        useGetMatchRosterRoundQuery(
            { roundId: nav?.round.providerId },
            {
                skip: !nav?.round.providerId
            }
        );

    const filters = useSelector(filtersSelector);
    const selectedRegion = useSingleSiteSetting('region');

    const { matches = [], isMatchesFetching } = useGetMatchesQuery(
        {
            pageSize: 300,
            competitionId: competitionId,
            compSeasonId: compSeason.id,
            ...{ roundNumber: roundNumber },
            ...(filters.teams.length && { teamId: filters.teams }),
            ...(filters.venues.length && { venueId: filters.venues })
        },
        {
            selectFromResult: ({ data, isFetching }) => ({
                matches:
                    data && filters.cities.length
                        ? filterByCity(data, filters.cities)
                        : data,
                isMatchesFetching: isFetching
            })
        }
    );

    const isAFLW =
        PULSE.app.common.match.getCompAbbr(compSeason.providerId) === 'AFLW';

    // Add index property for tracking
    const formattedMatches = matches.map((item, index) =>
        Object.assign({}, item, { index: index + 1 })
    );

    // Group matches by date
    const matchItems = useGroupMatches(
        useFilterMatches(formattedMatches),
        selectedRegion.timezone
    );

    const matchItemsWithRosters = useMemo(() => {
        if (!matchItems?.length || !rosterData?.length) {
            return null;
        }

        return matchItems.map((item) => {
            const found = rosterData.find((rosterItem) =>
                rosterItem.match
                    ? item.providerId === rosterItem.match?.matchId
                    : null
            );

            if (found) {
                return {
                    ...item,
                    rosterItem: found
                };
            }
            return item;
        });
    }, [matchItems, rosterData]);

    /**
     * Handler for "Show lineups" / "Hide lineups" toggle in the Team Lineups Item
     *
     * @param {object} param0
     * @param {number} param0.id Match id (Pulse id)
     * @param {boolean} param0.expanded New toggle state
     */
    const onLineupsItemToggle = ({ id, expanded }) => {
        // basic immutability
        const togglesCopy = [...matchesToggles];
        const foundToggle = togglesCopy.find((toggle) => toggle.id === id);
        foundToggle.expanded = expanded;

        onMatchesToggleUpdate(togglesCopy);
    };

    /**
     * effect hook
     * reacting to change in matchItemsWithRosters
     * creates new list of { id, expanded } object representing toggle state of each Team Lineups item,
     * then calls onMatchesToggleUpdate callback so the Team Lineups component can maintain the state
     */
    useEffect(() => {
        if (matchItemsWithRosters) {
            const matchesTogglesList = matchItemsWithRosters
                .filter((item) => item.providerId)
                .map((item) => ({ id: item.id, expanded: false }));

            // to prevent rerenders, checking for differences beetween matchItemsWithRosters ids and incoming matchesToggles ids
            if (
                matchesTogglesList.map((item) => item.id).join('') !==
                matchesToggles.map((item) => item.id).join('')
            ) {
                onMatchesToggleUpdate(matchesTogglesList);
            }
        }
    }, [matchItemsWithRosters]);

    if (isRosterFetching || isMatchesFetching) {
        return (
            <div className="stats-section-wrapper wrapper">
                <TeamLineupsSkeleton items={SKELETON_ITEMS} />
            </div>
        );
    }

    if (
        !matchItemsWithRosters ||
        !matchItemsWithRosters.find(
            (item) => typeof item === 'object' && 'rosterItem' in item // no roster items in match items data
        )
    ) {
        return (
            <EmptyState
                title={getTranslation('label.teamLineups.noDataTitle')}
                summary={getTranslation('label.teamLineups.noDataText')}
                cssClass="competition-nav__empty-state"
            />
        );
    }

    return (
        <div className="stats-section-wrapper wrapper">
            {matchItemsWithRosters.map((item, index) => (
                <TeamLineupsItemWrapper
                    key={
                        typeof item === 'string' ? `${item}-${index}` : item.id
                    }
                    item={item}
                    expandLineups={
                        item.providerId
                            ? matchesToggles.find(
                                  (toggle) => toggle.id === item.id
                              )?.expanded ?? false
                            : null
                    }
                    setExpandLineups={onLineupsItemToggle}
                    shouldScrollTo={
                        fixtureIdParam
                            ? item.id === parseInt(fixtureIdParam)
                            : false
                    }
                    isAFLW={isAFLW}
                />
            ))}

            <TeamLineupsLegend />
        </div>
    );
};

TeamLineupsList.propTypes = {
    competitionId: PropTypes.number,
    compSeason: PropTypes.object,
    roundNumber: PropTypes.number,
    matchesToggles: PropTypes.array,
    onMatchesToggleUpdate: PropTypes.func,
    fixtureIdParam: PropTypes.string
};
