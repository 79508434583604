import React, {
    createRef,
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { PlayerSelectionModal } from './components/PlayerSelectionModal';
import { PlayerSelectionFilterItems } from './components/PlayerSelectionFilterItems';
import { PlayerSelectionSearchResults } from './components/PlayerSelectionSearchResults';
import { PlayerSearchInput } from 'common/react/components/PlayerSearchInput';
import {
    PlayerSelectionPositionsFilters,
    PlayerSelectionTeamsFilters
} from './components/filters';
import { FilterButton } from 'common/react/components/FilterButton';
import { getTranslation } from 'common/react/utils/translations';

import {
    playerSelectionVisibleSelector,
    playerComparisonFiltersSelector
} from 'store/modules/statspro/player-comparison/selectors';
import { CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY } from 'store/modules/statspro/player-comparison/actions';

import { useGetSearchResultsQuery } from 'store/modules/api/statsPro-api';
import { useGetPlayersQuery } from 'store/modules/api/cfs-api';
import { POSITIONS } from './config/positions';

const DEBOUNCE_TIMER = 500;

/**
 * @param root0
 * @param root0.compseason
 * @param root0.compseasonPid
 * @returns {JSX.Element} Component.
 */
export const PlayerSelection = ({ compseason, compseasonPid }) => {
    const dispatch = useDispatch();

    const containerRef = createRef();
    const playerSelectionFiltersRef = useRef();

    // Player Search
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState(query);
    const [isActive, setIsActive] = useState(false);
    const [sortedSearchResults, setSortedSearchResults] = useState([]);
    const [filtersInteracted, setFiltersInteracted] = useState(false);

    const elementRef = useRef('');

    const filters = useSelector(playerComparisonFiltersSelector);
    const posQuery = filters.positions.flat().join(',');
    const teamQuery = filters.teams.join(',');

    const [skipNoQuery, setSkipNoQuery] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [startSearchFired, setStartSearchFired] = useState(false);

    const handleElementChange = (element) => {
        elementRef.current = element;
    };

    const { data: resultsWithNoQuery, isFetching: noQueryIsFetching } =
        useGetPlayersQuery(
            { seasonId: compseasonPid, teamIds: teamQuery, position: posQuery },
            { skip: skipNoQuery }
        );
    const { data: resultsWithQuery, isFetching: queryIsFetching } =
        useGetSearchResultsQuery(
            {
                query,
                compseason: compseasonPid,
                position: posQuery,
                teamId: teamQuery
            },
            { skip: !skipNoQuery }
        );

    useEffect(() => {
        if (queryIsFetching || noQueryIsFetching) {
            setIsFetching(true);
        } else {
            setIsFetching(false);
        }
    }, [noQueryIsFetching, queryIsFetching]);

    const searchResults = useMemo(() => {
        if (query || posQuery) {
            return resultsWithQuery?.players;
        }
        return resultsWithNoQuery?.players;
    }, [resultsWithNoQuery, resultsWithQuery, query, posQuery, teamQuery]);

    useEffect(() => {
        if (query.length || posQuery) {
            setSkipNoQuery(true);
        } else {
            setSkipNoQuery(false);
        }
    }, [query, posQuery]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setQuery(debouncedQuery.trim());

            if (debouncedQuery.trim().length > 0 && !startSearchFired) {
                PULSE.app.analytics.addSearchTracking(elementRef.current);
                setStartSearchFired(true);
            }
        }, DEBOUNCE_TIMER);
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    useEffect(() => {
        if (searchResults) {
            if (query || posQuery) {
                setSortedSearchResults(handleSortByName(searchResults));
            } else {
                setSortedSearchResults(searchResults);
            }
        }
    }, [searchResults]);

    // Modal control
    const [areFilterOptionsVisible, setAreFilterOptionsVisible] =
        useState(false);

    const [resetFilterBtnStyles, setResetFilterBtnStyles] = useState(false);

    const isFiltersVisibleHandler = () => {
        setAreFilterOptionsVisible((prev) => !prev);
    };

    const onFiltersInteracted = () => {
        setFiltersInteracted(true);
    };

    const { modalVisible } = useSelector((state) => ({
        // @ts-ignore
        modalVisible: playerSelectionVisibleSelector(state)
    }));

    const changeModalVisibility = (isVisible) => {
        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.CLOSE,
            {
                eventData: {
                    /* eslint-disable-next-line camelcase */
                    modal_name: getTranslation(
                        'label.statspro.playerComparison.addPlayers'
                    )
                }
            }
        );

        return dispatch(
            CHANGE_PLAYER_SELECTION_MODAL_VISIBILITY.request(isVisible)
        );
    };

    const trackFilters = useCallback(() => {
        if (filtersInteracted) {
            // reformatting filters to the shape accepted by convertFiltersValues
            const positions = filters.positions.map((pos) => ({ id: pos[0] }));
            const filtersValues = PULSE.app.analytics.convertFiltersValues(
                ['position', 'teams'],
                {
                    position: positions,
                    filters: {
                        teams: filters.teams
                    }
                },
                {
                    positions: POSITIONS.map((pos) => ({
                        value: pos.keys[0],
                        label: pos.name
                    }))
                },
                compseason
            );

            PULSE.app.tracking.applyFilters({
                name: title,
                ...filtersValues
            });
        }
    }, [filtersInteracted]);

    const title = getTranslation('label.statspro.playerComparison.addPlayers');

    useEffect(() => {
        modalVisible === true ? setIsActive(true) : setIsActive(false);
    }, [modalVisible]);

    useEffect(() => {
        if (playerSelectionFiltersRef?.current) {
            playerSelectionFiltersRef.current.addEventListener(
                PULSE.app.common.CONSTANTS.EVENTS.INTERACTION.CHANGE,
                onFiltersInteracted
            );
        }
    }, [playerSelectionFiltersRef?.current]);

    useEffect(() => {
        return () => {
            playerSelectionFiltersRef?.current.removeEventListener(
                PULSE.app.common.CONSTANTS.EVENTS.INTERACTION.CHANGE,
                onFiltersInteracted
            );
            window.removeEventListener('beforeunload', trackFilters);
        };
    }, []);

    useEffect(() => {
        if (isActive) {
            window.addEventListener('beforeunload', trackFilters);
        } else {
            window.removeEventListener('beforeunload', trackFilters);
        }

        return () => {
            window.removeEventListener('beforeunload', trackFilters);
        };
    }, [isActive, onFiltersInteracted]);

    const onHideModal = () => {
        changeModalVisibility(false);
        trackFilters();
        window.removeEventListener('beforeunload', trackFilters);
    };

    return modalVisible ? (
        <div className="player-selection">
            <PlayerSelectionModal
                icon="compare"
                title={title}
                isVisible={modalVisible}
                hideModal={onHideModal}
            >
                <div className="player-selection__items-container">
                    <div
                        ref={containerRef}
                        className="player-selection__nav"
                        tabIndex="0"
                    >
                        <PlayerSearchInput
                            setDebouncedQuery={setDebouncedQuery}
                            isActive={isActive}
                            query={query}
                            onElementChange={handleElementChange}
                        />

                        <div className="player-selection-nav__filter-button-wrapper">
                            <FilterButton
                                onChange={isFiltersVisibleHandler}
                                areFilterOptionsVisible={
                                    areFilterOptionsVisible
                                }
                                resetFilterBtnStyles={resetFilterBtnStyles}
                                setResetFilterBtnStyles={
                                    setResetFilterBtnStyles
                                }
                            />
                        </div>
                    </div>

                    <div
                        className={`${
                            !areFilterOptionsVisible ? 'u-hide' : ''
                        }`}
                        ref={playerSelectionFiltersRef}
                    >
                        <PlayerSelectionFilterItems
                            isFiltersVisibleHandler={isFiltersVisibleHandler}
                            setResetFilterBtnStyles={setResetFilterBtnStyles}
                            title={title}
                        >
                            <PlayerSelectionPositionsFilters />
                            <PlayerSelectionTeamsFilters
                                compseason={compseason}
                            />
                        </PlayerSelectionFilterItems>
                    </div>

                    {searchResults && (
                        <PlayerSelectionSearchResults
                            isLoading={isFetching}
                            query={query}
                            results={sortedSearchResults}
                            seasonId={compseasonPid}
                        />
                    )}
                </div>
            </PlayerSelectionModal>
        </div>
    ) : (
        ''
    );
};

PlayerSelection.propTypes = {
    compseason: PropTypes.string.isRequired,
    compseasonPid: PropTypes.string.isRequired
};

/**
 * Full player list will be used when filtering. The results are sorted alphabetically like the list of 20 used on load.
 *
 * @param {*} items
 * @returns
 */
const handleSortByName = (items) => {
    let sortedItems;
    let arrayToSort = [...items];

    sortedItems = arrayToSort?.sort((a, b) =>
        a.playerDetails.givenName.localeCompare(b.playerDetails.givenName)
    );

    return sortedItems;
};
