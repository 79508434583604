import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';
import { Select } from 'common/react/components/select';
import { getCompetitionList } from 'widgets/fixtures/js/utils/helpers';
import { getTranslation } from 'common/react/utils/translations';

import { DEFAULT_COMP_ORDER } from 'common/react/components/competition-nav/nav-items/CompNavItem';

export const CGCompNavItem = ({
    competitions,
    allowListOrder = DEFAULT_COMP_ORDER,
    className,
    defaultItem,
    onReset,
    handleCookieUpdate
}) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    if (!competitions || nav.competition.id === '') {
        return <></>;
    }

    const competitionList = getCompetitionList(competitions, allowListOrder);
    competitionList.unshift(defaultItem);

    return (
        <div className="stats-leaders-nav__select competition-nav__competition-select">
            <Select
                items={competitionList}
                itemToString={(competition) => competition.name}
                initialSelectedItem={competitionList.find((c) =>
                    nav.competition.id === '*'
                        ? c.id === '*'
                        : c.id === Number(nav.competition.id)
                )}
                activeItem={competitionList.find((c) => {
                    return nav.competition.id === '*'
                        ? c.id === '*'
                        : c.id === Number(nav.competition.id);
                })}
                showCompLogo={true}
                icon={'football'}
                className={className}
                showCompLogoInside={true}
                isReadyToRender={() =>
                    competitionList?.length &&
                    (Number(nav.competition.id) || nav.competition.id === '*')
                }
                label={getTranslation('label.filters.competition')}
                onSelectedItemChange={({ selectedItem }) => {
                    handleCookieUpdate('competition', selectedItem.id);
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'competition',
                            value: {
                                id: selectedItem.id,
                                providerId: selectedItem.providerId
                            }
                        })
                    );
                }}
                isOpen={true}
                title={getTranslation('label.filters.competition')}
                type="with-default"
                defaultItem={defaultItem}
                onReset={onReset}
            />
        </div>
    );
};

CGCompNavItem.propTypes = {
    competitions: PropTypes.array,
    allowListOrder: PropTypes.array,
    className: PropTypes.string,
    defaultItem: PropTypes.object,
    onReset: PropTypes.func,
    handleCookieUpdate: PropTypes.func
};
