import React, { useEffect } from 'react';
import { SettingsButton } from '../settings-button/button';
import { StepToolTip } from './step-tooltip';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { useSelector, useDispatch } from 'react-redux';
import {
    setToolTip,
    setFinalsStep,
    setSeasonStep,
    setActiveView
} from 'common/store/modules/ladder-predictor/init';
import { CONFIG } from '../constants';

const ONE = 1;
const TWO = 2;

/*
 * Render progress steps
 */
export const ProgressSteps = () => {
    const dispatch = useDispatch();

    const step1Active = useSelector(
        (state) => state.ladderPredictor.progressSteps.season.active
    );
    const step2Active = useSelector(
        (state) => state.ladderPredictor.progressSteps.finals.active
    );
    const step1Current = useSelector(
        (state) => state.ladderPredictor.progressSteps.season.current
    );
    const step2Current = useSelector(
        (state) => state.ladderPredictor.progressSteps.finals.current
    );
    const step1Completed = useSelector(
        (state) => state.ladderPredictor.progressSteps.season.complete
    );
    const step2Completed = useSelector(
        (state) => state.ladderPredictor.progressSteps.finals.complete
    );
    const stepToolTipOpen = useSelector(
        (state) => state.ladderPredictor.progressSteps.toolTip.open
    );

    const userClosed = useSelector(
        (state) => state.ladderPredictor.progressSteps.toolTip.userClosed
    );

    const max = useSelector(
        (state) => state.ladderPredictor.progressBar.totalMatches
    );
    const current = useSelector(
        (state) => state.ladderPredictor.predictions.length
    );

    useEffect(() => {
        if (max !== null) {
            dispatch(
                setToolTip({
                    open: current === max && max !== 0,
                    userClosed: false
                })
            );
            dispatch(
                setSeasonStep({
                    active: true,
                    current: true,
                    complete: current === max
                })
            );
            dispatch(
                setFinalsStep({
                    active: true,
                    current: false,
                    complete: false
                })
            );
        }
    }, [current, max]);

    const handleSeasonClick = () => {
        dispatch(setActiveView(CONFIG.SEASON));
        dispatch(
            setSeasonStep({
                active: true,
                current: true,
                complete: current === max
            })
        );
        dispatch(
            setFinalsStep({
                active: true,
                current: false,
                complete: false
            })
        );
        dispatch(
            setToolTip({
                open: current === max && userClosed === false,
                userClosed: userClosed
            })
        );
    };

    const handleFinalsClick = () => {
        dispatch(setActiveView(CONFIG.FINALS));
        dispatch(
            setFinalsStep({
                active: true,
                current: true,
                complete: false
            })
        );
        dispatch(
            setSeasonStep({
                active: true,
                current: false,
                complete: current === max
            })
        );
        dispatch(
            setToolTip({
                open: false,
                userClosed: userClosed
            })
        );
    };

    return (
        <div className="ladder-predictor-progress-steps">
            <div className="ladder-predictor-progress-steps__steps">
                <div className="ladder-predictor-progress-steps__step">
                    <button
                        className={`step-button ${
                            step1Current ? 'step-button--current' : ''
                        } 
                        ${step1Active ? 'step-button--active' : ''} 
                        ${step1Completed ? 'step-button--completed' : ''}`}
                        onClick={handleSeasonClick}
                        disabled={step1Current}
                    >
                        {step1Completed ? (
                            <div className="step-button__icon step-button__icon--completed">
                                <SvgIcon icon="checkmark" className="icon" />
                            </div>
                        ) : (
                            <div className="step-button__icon">{ONE}</div>
                        )}
                        {getTranslation('label.ladderPredictor.steps.season')}
                    </button>
                </div>

                <div
                    className={`ladder-predictor-progress-steps__steps-line ${
                        step2Active
                            ? 'ladder-predictor-progress-steps__steps-line--active'
                            : ''
                    }`}
                ></div>

                <div className="ladder-predictor-progress-steps__step">
                    <button
                        className={`step-button ${
                            step2Current ? 'step-button--current' : ''
                        } 
                        ${step2Active ? 'step-button--active' : ''} 
                        ${step2Completed ? 'step-button--completed' : ''}`}
                        onClick={handleFinalsClick}
                        disabled={step2Current}
                    >
                        {step2Completed ? (
                            <div className="step-button__icon step-button__icon--completed">
                                <SvgIcon icon="checkmark" className="icon" />
                            </div>
                        ) : (
                            <div className="step-button__icon">{TWO}</div>
                        )}
                        {getTranslation('label.ladderPredictor.steps.finals')}
                    </button>

                    {stepToolTipOpen && <StepToolTip />}
                </div>
            </div>

            <SettingsButton />
        </div>
    );
};
