import React, { useMemo } from 'react';
import { mapPlayersToPositions } from 'widgets/team-lineups-v2/js/utils/helpers';
import ListRow from './ListRow';
import PropTypes from 'prop-types';
import {
    getTeamLineupsListViewPositions,
    MATCH_STATUS_SHOWING_SUBS,
    TEAM_LINEUPS_POSITIONS
} from 'widgets/team-lineups-v2/js/utils/constants';

/**
 * Team Lineups List View
 *
 * @param {object} props
 * @param {object} props.match Match details
 * @param {object} props.matchRoster match roster data
 * @param {boolean} props.isAFLW Is the match AFLW
 * @returns
 */
export const TeamLineupsListView = ({ match, matchRoster, isAFLW = false }) => {
    /**
     * memoized list of home team players mapped to the field positions
     */
    const homeTeamPlayers = useMemo(() => {
        if (matchRoster?.homeTeam?.positions) {
            return mapPlayersToPositions(
                matchRoster.homeTeam.positions,
                isAFLW
            );
        }

        return null;
    }, [matchRoster?.homeTeam]);

    /**
     * memoized list of away team players mapped to the field positions
     */
    const awayTeamPlayers = useMemo(() => {
        if (matchRoster?.awayTeam?.positions) {
            return mapPlayersToPositions(
                matchRoster.awayTeam.positions,
                isAFLW
            );
        }

        return null;
    }, [matchRoster?.awayTeam]);

    return (
        <div className="team-lineups__list-view flex-column">
            {getTeamLineupsListViewPositions(isAFLW).map(
                (position) =>
                    (position.id !== TEAM_LINEUPS_POSITIONS.SUBSTITUTES.id ||
                        MATCH_STATUS_SHOWING_SUBS.includes(match.status)) && (
                        <ListRow
                            key={`team-lineup-${match.matchId}-list-row-${position.id}`}
                            position={position}
                            homeTeam={matchRoster?.homeTeam}
                            awayTeam={matchRoster?.awayTeam}
                            homeTeamPlayers={homeTeamPlayers}
                            awayTeamPlayers={awayTeamPlayers}
                            isAFLW={isAFLW}
                        />
                    )
            )}
        </div>
    );
};

TeamLineupsListView.propTypes = {
    match: PropTypes.object,
    matchRoster: PropTypes.object,
    isAFLW: PropTypes.bool
};
