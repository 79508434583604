import React, { useCallback, useMemo, useState } from 'react';
import { getTranslation } from 'common/react/utils/translations';
import { ToggleInput } from 'common/react/components/ToggleInput';
import { mapPlayersToPositions } from 'widgets/team-lineups-v2/js/utils/helpers';
import {
    getTeamLineupsFieldPositions,
    MATCH_STATUS_SHOWING_SUBS,
    TEAM_LINEUPS_POSITIONS
} from 'widgets/team-lineups-v2/js/utils/constants';
import { OvalSection } from './OvalSection';
import { TeamIndicator } from './TeamIndicator';
import { OtherPositionsSection } from './OtherPositionsSection';
import PropTypes from 'prop-types';

/**
 * Field view of team lineups
 *
 * @param {object} props
 * @param {object} props.match Match details
 * @param {object} props.matchRoster match roster data
 * @param {boolean} props.isAFLW Is the match AFLW
 * @returns
 */
export const TeamLineupsFieldView = ({
    match,
    matchRoster,
    isAFLW = false
}) => {
    const [selectedTeam, setSelectedTeam] = useState('-1');

    /**
     * Team Filter change handler
     *
     * @param {string} team Selected team
     */
    const onTeamFilterChange = (team) => {
        // tracking
        if (team !== selectedTeam) {
            /* eslint-disable camelcase */
            PULSE.app.tracking.recordEvent(
                PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.TAB,
                {
                    eventData: {
                        tab_name:
                            teamOptions().find(
                                (option) => option.value === team
                            )?.label ?? '',
                        component_name: match.name,
                        component_content_type:
                            PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES
                                .LINEUPS
                    }
                }
            );
            /* eslint-enable camelcase */
        }

        setSelectedTeam(team);
    };

    // Team Filter options list
    const teamOptions = useCallback(() => {
        if (matchRoster?.homeTeam && matchRoster?.awayTeam) {
            return [
                {
                    label: getTranslation('label.location.all'),
                    value: '-1'
                },
                {
                    label: matchRoster.homeTeam.teamName.teamAbbr.toUpperCase(),
                    value: matchRoster.homeTeam.teamId
                },
                {
                    label: matchRoster.awayTeam.teamName.teamAbbr.toUpperCase(),
                    value: matchRoster.awayTeam.teamId
                }
            ];
        }

        return [];
    }, [matchRoster?.awayTeam, matchRoster?.homeTeam]);

    /**
     * memoized list of home team players mapped to the field positions
     */
    const homeTeamPlayers = useMemo(() => {
        if (matchRoster?.homeTeam?.positions) {
            return mapPlayersToPositions(
                matchRoster.homeTeam.positions,
                isAFLW
            );
        }

        return null;
    }, [matchRoster?.homeTeam]);

    /**
     * memoized list of away team players mapped to the field positions
     */
    const awayTeamPlayers = useMemo(() => {
        if (matchRoster?.awayTeam?.positions) {
            return mapPlayersToPositions(
                matchRoster.awayTeam.positions,
                isAFLW
            );
        }

        return null;
    }, [matchRoster?.awayTeam]);

    /**
     * memoized object for the single selected team
     * used by the Team Indicator component
     */
    const selectedTeamData = useMemo(() => {
        if (selectedTeam === '-1') {
            return {};
        }

        return selectedTeam === matchRoster?.homeTeam?.teamId
            ? { team: matchRoster.homeTeam, isHomeTeam: true }
            : { team: matchRoster.awayTeam };
    }, [selectedTeam, matchRoster?.homeTeam, matchRoster?.awayTeam]);

    return (
        <div className="team-lineups__field-view">
            <div className="team-lineups__team-toggle">
                <ToggleInput
                    id={`team-lineups-${match.matchId}-field-view-team-toggle`}
                    value={selectedTeam}
                    onChange={(value) => onTeamFilterChange(value)}
                    options={teamOptions()}
                />
            </div>
            <div className="team-lineups__field">
                <div className="team-lineups__field-column team-lineups__field-column--left flex-column">
                    <OtherPositionsSection
                        positions={{
                            ...TEAM_LINEUPS_POSITIONS.FOLLOWERS,
                            positions:
                                TEAM_LINEUPS_POSITIONS.FOLLOWERS.positions(
                                    isAFLW
                                )
                        }}
                        homeTeamPlayers={homeTeamPlayers}
                        awayTeamPlayers={awayTeamPlayers}
                        homeTeam={matchRoster?.homeTeam}
                        awayTeam={matchRoster?.awayTeam}
                        teamToShow={selectedTeam}
                        isAFLW={isAFLW}
                    />
                </div>
                <div className="team-lineups__field-column team-lineups__field-column--starters">
                    <div className="team-lineups__teams-labels flex-row flex-row--nowrap">
                        {selectedTeam === '-1' ? (
                            <>
                                <TeamIndicator
                                    team={matchRoster?.homeTeam}
                                    isHomeTeam
                                />
                                <TeamIndicator team={matchRoster?.awayTeam} />
                            </>
                        ) : (
                            <TeamIndicator {...selectedTeamData} showCrest />
                        )}
                    </div>
                    <div className="team-lineups__oval">
                        <div
                            className={`team-lineups__starters-grid flex-column ${
                                selectedTeam !== '-1'
                                    ? 'team-lineups__starters-grid--single-team'
                                    : ''
                            }`}
                        >
                            {getTeamLineupsFieldPositions(isAFLW).map(
                                ({ homeTeam, awayTeam }) => (
                                    <OvalSection
                                        key={`team-lineups-oval-section_${homeTeam.id}`}
                                        homeTeamPositions={homeTeam}
                                        awayTeamPositions={awayTeam}
                                        homeTeamPlayers={homeTeamPlayers}
                                        awayTeamPlayers={awayTeamPlayers}
                                        homeTeam={matchRoster?.homeTeam}
                                        awayTeam={matchRoster?.awayTeam}
                                        teamToShow={selectedTeam}
                                        isAFLW={isAFLW}
                                    />
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className="team-lineups__field-column team-lineups__field-column--right">
                    <OtherPositionsSection
                        positions={{
                            ...TEAM_LINEUPS_POSITIONS.INTERCHANGES,
                            positions:
                                TEAM_LINEUPS_POSITIONS.INTERCHANGES.positions(
                                    isAFLW
                                )
                        }}
                        homeTeamPlayers={homeTeamPlayers}
                        awayTeamPlayers={awayTeamPlayers}
                        homeTeam={matchRoster?.homeTeam}
                        awayTeam={matchRoster?.awayTeam}
                        teamToShow={selectedTeam}
                        isAFLW={isAFLW}
                    />
                    {MATCH_STATUS_SHOWING_SUBS.includes(match.status) && (
                        <OtherPositionsSection
                            positions={{
                                ...TEAM_LINEUPS_POSITIONS.SUBSTITUTES,
                                positions:
                                    TEAM_LINEUPS_POSITIONS.SUBSTITUTES.positions(
                                        isAFLW
                                    )
                            }}
                            homeTeamPlayers={homeTeamPlayers}
                            awayTeamPlayers={awayTeamPlayers}
                            homeTeam={matchRoster?.homeTeam}
                            awayTeam={matchRoster?.awayTeam}
                            teamToShow={selectedTeam}
                            isAFLW={isAFLW}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

TeamLineupsFieldView.propTypes = {
    match: PropTypes.object,
    matchRoster: PropTypes.object,
    isAFLW: PropTypes.bool
};
