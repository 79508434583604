import React from 'react';
import PropTypes from 'prop-types';
// import { useGetLiveLadderQuery } from 'store/modules/api/cfs-api';
import { LadderTableFetcher } from './LadderTableFetcher';

export const LiveLadderDataFetcher = ({
    competitionId,
    compSeason,
    round,
    data
}) => {
    return (
        <>
            <LadderTableFetcher
                competitionId={competitionId}
                compSeason={compSeason}
                round={round}
                data={data}
                displayFormTooltip={true}
            />
        </>
    );
};

LiveLadderDataFetcher.propTypes = {
    competitionId: PropTypes.number,
    compSeason: PropTypes.object,
    round: PropTypes.object,
    data: PropTypes.object
};
