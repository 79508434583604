import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useDataType } from '../../../common/hooks/use-data-type';
import { DATA_TYPE } from '../../../common/config/data-type';
import {
    roundSelector,
    statsLeadersBenchmarkingSelector,
    statsLeadersDataTypeSelector
} from 'store/modules/statspro/stats-leaders/selectors';
import { composeGetTranslation } from 'common/react/utils/translations';
import { ToggleInput } from 'common/react/components/ToggleInput';
import { UPDATE_DATA_TYPE } from 'store/modules/statspro/stats-leaders/actions';

export const dataTypeOptions = [
    {
        label: composeGetTranslation('label.statsLeaders.dataType.')(
            DATA_TYPE.TOTALS
        ),
        value: DATA_TYPE.TOTALS
    },
    {
        label: composeGetTranslation('label.statsLeaders.dataType.')(
            DATA_TYPE.AVERAGES
        ),
        value: DATA_TYPE.AVERAGES
    }
];

export const DataTypeToggle = () => {
    const round = useSelector(roundSelector);
    const benchmarking = useSelector(statsLeadersBenchmarkingSelector);
    const initialDataType = useSelector(statsLeadersDataTypeSelector);

    const { dataType, updateDataType } = useDataType({
        initialDataType,
        onUpdateActionCreator: UPDATE_DATA_TYPE.request,
        isBenchmarkingActive: benchmarking.isActive
    });

    const options = useMemo(() => {
        return dataTypeOptions.map((option) => {
            if (option.value === DATA_TYPE.AVERAGES) {
                return {
                    ...option,
                    disabled: !!round
                };
            }

            return option;
        });
    }, [round]);

    return (
        <ToggleInput
            id="stats-leaders-data-type-toggle"
            value={
                dataType.type.toLowerCase().includes(DATA_TYPE.TOTALS)
                    ? DATA_TYPE.TOTALS
                    : DATA_TYPE.AVERAGES
            }
            onChange={updateDataType}
            options={options}
        />
    );
};
