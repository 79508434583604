import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { TeamLineups } from '../components/TeamLineups';

export const TeamLineupsProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <TeamLineups data={data} />
        </Provider>
    );
};

TeamLineupsProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
