import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Select } from 'common/react/components/select';
import { getTranslation } from 'common/react/utils/translations';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';

export const SeasonNavItem = ({ seasons }) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    if (!seasons.find((s) => s.id === Number(nav.season.id)) && seasons) {
        return '';
    }

    return (
        <div className="competition-nav__season-select">
            <Select
                items={seasons}
                itemToString={(season) => season.name}
                initialSelectedItem={
                    seasons && nav.season.id
                        ? seasons.find((s) => s.id === Number(nav.season.id))
                        : ''
                }
                activeItem={
                    seasons && nav.season.id
                        ? seasons.find((s) => s.id === Number(nav.season.id))
                        : ''
                }
                isReadyToRender={() => seasons?.length && Number(nav.season.id)}
                label={getTranslation('label.stats.season')}
                onSelectedItemChange={({ selectedItem }) => {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'season',
                            value: {
                                id: selectedItem.id,
                                providerId: selectedItem.providerId
                            }
                        })
                    );
                }}
            />
        </div>
    );
};

SeasonNavItem.propTypes = {
    seasons: PropTypes.array,
    title: PropTypes.string
};
