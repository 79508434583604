import React from 'react';
import PropTypes from 'prop-types';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { getTranslation } from 'common/react/utils/translations';

/*
 * Render filtered byes group
 */
export const FilteredByesGroup = ({ teams, roundName }) => {
    return (
        <>
            {teams.length ? (
                <>
                    <h3 className="ladder-predictor-sidebar__list-title">
                        {getTranslation('label.fixtures.byeRound')}
                    </h3>

                    <p className="ladder-predictor-sidebar__list-round">
                        {roundName}
                    </p>

                    <div className="ladder-predictor-sidebar__bye-fixtures">
                        {teams.map((team) => (
                            <TeamIcon
                                key={team.id}
                                className="ladder-predictor-sidebar__bye-fixture"
                                providerId={team.providerId}
                            />
                        ))}
                    </div>
                </>
            ) : null}
        </>
    );
};

FilteredByesGroup.propTypes = {
    teams: PropTypes.array,
    roundName: PropTypes.string
};
