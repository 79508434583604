import { useEffect, useState } from 'react';
/**
 * Returns the favourite team from the localstorage
 * Also subscribes to the FAVOURITE_SET event and returns the updated value
 *
 * @returns {string} Favourite team abbreviation
 */
export const useFavouriteTeam = () => {
    const [favouriteTeam, setFavouriteTeam] = useState(
        PULSE.app.common.favouriteTeam.team()
    );
    useEffect(() => {
        PULSE.app.common.favouriteTeam.subscribe(({ team }) => {
            setFavouriteTeam(team);
        });
    }, []);

    return favouriteTeam;
};
