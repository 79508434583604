import React from 'react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

/*
 * Settings button
 */
export const SettingsButton = () => {
    return (
        <button
            className="filter-button"
            onClick={() => PULSE.app.common.siteSettings.openSettingsModal()}
        >
            <SvgIcon icon="settings" className="filter-button__icon" />

            <span className="filter-button__text">
                {getTranslation('label.settings')}
            </span>
        </button>
    );
};
