import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { StatsTableHead } from 'widgets/statspro/js/components/common/stats-table/StatsTableHead';

export const TableHead = ({
    headerGroup,
    sortByFavourites,
    setSortByFavourites
}) => {
    const [favouritePlayerSelected, setFavouritePlayerSelected] =
        useState(false);

    return (
        <StatsTableHead
            headerGroup={headerGroup}
            customCells={{
                player: (column) => (
                    <th
                        className="stats-table__header-cell"
                        scope="col"
                        key={column.id}
                    >
                        <div className="stats-table__header-cell-button">
                            <button
                                onClick={(event) => {
                                    event.preventDefault();
                                    setSortByFavourites(!sortByFavourites);
                                    setFavouritePlayerSelected(
                                        !favouritePlayerSelected
                                    );
                                }}
                                className="stats-table__header-cell-favourite-button"
                            >
                                <span className="u-screen-reader">
                                    Sort by favourites
                                </span>
                                {sortByFavourites ? (
                                    <SvgIcon
                                        icon="pin-filled"
                                        className="icon favourites-filled"
                                    />
                                ) : (
                                    <SvgIcon
                                        icon="pin-empty"
                                        className="icon favourites-empty"
                                    />
                                )}
                            </button>
                            <span className="stats-table__header-cell-text">
                                {column.render('Header')}
                            </span>
                        </div>
                    </th>
                ),
                bettingOdds: (column) => (
                    <th
                        className="stats-table__header-cell"
                        scope="col"
                        colSpan="1"
                        role="columnheader"
                        key={column.id}
                    >
                        <button
                            className="stats-table__header-cell-button"
                            disabled={true}
                        >
                            <img
                                className="stats-table__sportsbet-callout"
                                src={`/resources/${window.RESOURCES_VERSION}/i/elements/sportsbet-callout.svg`}
                            />
                        </button>
                    </th>
                )
            }}
        />
    );
};

TableHead.propTypes = {
    headerGroup: PropTypes.object.isRequired,
    sortByFavourites: PropTypes.bool,
    setSortByFavourites: PropTypes.func
};
