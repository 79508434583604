import React from 'react';
import PropTypes from 'prop-types';

import { FixtureVideoThumbnail } from './FixtureVideoThumbnail';
import { useGetContentQuery } from 'store/modules/api/content-api';
import { useSiteSettings } from 'common/react/hooks/use-site-settings';
import { MEDIA_TAGS_BY_STATUS } from 'widgets/fixtures/js/utils/media-tags';
import { prioritiseVideo } from 'widgets/fixtures/js/utils/prioritise-video';
import { FixtureArticleThumbnail } from './FixtureArticleThumbnail';
import { formatTagsForTagExpression } from 'common/react/utils/format-tag-expression';

const PAGE_SIZE = 10;

const THUMBNAIL_VARIANT = [
    {
        name: 'all',
        size: { width: 304, height: 172 },
        minWidth: 0,
        multipliers: [1, 2]
    },
    {
        name: 'default',
        size: { width: 304, height: 172 }
    }
];

const BETTING_TAG = 'sponsor:betting';

export const FixtureMedia = ({ match, matchStatus }) => {
    const { siteSettings, settingsConfig } = useSiteSettings();

    const { video, category, isVideoSuccess } = useGetContentQuery(
        {
            type: 'VIDEO',
            params: {
                pageSize: PAGE_SIZE,
                references: `AFL_MATCH:${match.id}`,
                tagExpression: formatTagsForTagExpression(
                    MEDIA_TAGS_BY_STATUS[matchStatus]
                )
            }
        },
        {
            selectFromResult: ({ data, isSuccess }) => {
                if (!data?.content) {
                    return {
                        video: null,
                        category: null,
                        isVideoSuccess: isSuccess
                    };
                }

                const { video: foundVideo, category: foundCategory } =
                    prioritiseVideo(
                        data.content,
                        MEDIA_TAGS_BY_STATUS[matchStatus]
                    );

                return {
                    video: PULSE.app.common.content.getContentModel(
                        foundVideo,
                        THUMBNAIL_VARIANT
                    ),
                    category: foundCategory,
                    isVideoSuccess: isSuccess
                };
            }
        }
    );

    const { previewArticle } = useGetContentQuery(
        {
            type: 'TEXT',
            params: {
                pageSize: PAGE_SIZE,
                references: `AFL_MATCH:${match.id}`,
                tagExpression: formatTagsForTagExpression(
                    MEDIA_TAGS_BY_STATUS[
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING
                    ]
                )
            }
        },
        {
            skip: !(
                isVideoSuccess &&
                !video &&
                matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING
            ),
            selectFromResult: ({ data }) => ({
                previewArticle: data?.content?.length
                    ? PULSE.app.common.content.getContentModel(
                          data.content[0],
                          THUMBNAIL_VARIANT
                      )
                    : null
            })
        }
    );

    // don't show video if betting is hidden and has a sponsor:betting tag
    const hasBettingTag = video?.tags.find((tag) => tag.label === BETTING_TAG);
    const shouldHideDueToBetting =
        !siteSettings[settingsConfig.displayWagering.key] && hasBettingTag;

    return isVideoSuccess && video && !shouldHideDueToBetting ? (
        // Video query has finished and video found
        <FixtureVideoThumbnail
            video={video}
            match={match}
            category={category}
        />
    ) : isVideoSuccess &&
      !video &&
      matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING &&
      previewArticle ? (
        // Video query has fininished, no video found, game is upcoming and we have a preview article
        <FixtureArticleThumbnail match={match} article={previewArticle} />
    ) : null;
};

FixtureMedia.propTypes = {
    match: PropTypes.object.isRequired,
    matchStatus: PropTypes.string
};
