import { useGetTeamsQuery } from 'common/store/modules/api/afl-api';

export const getId = (cell, row) =>
    `brownlow-leaderboard-${row.original.player.givenName}-${row.original.player.surname}-${cell.column.id}`;

export const getTitle = (cell, row) =>
    `${row.original.player.givenName} ${row.original.player.surname}: ${
        cell.value.actual ? cell.value.actual : 'No'
    } votes.`;

export const useTeamsList = (seasonId) => {
    const { data: [...teamsList] = [] } = useGetTeamsQuery(seasonId, {});
    return teamsList;
};
