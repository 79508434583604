/**
 *
 * @param {string} cookieName
 * @param {string} newItem - identifier for the param in the state
 * @param {string|number} newValue - new value for the param
 * @param {object} currentItems - the nav selector object
 *
 * Currently used in the Content Grid Nav.
 * Creates/updates a cookie to keep track of the currently selected nav items.
 */
export const setCookieState = (cookieName, newItem, newValue, currentItems) => {
    // take our current values
    const currentItemsObj = {
        competition: currentItems.competition.id,
        teams: currentItems.filters.teams
    };

    // replace changed nav item with new value
    const newObj = { ...currentItemsObj, [newItem]: newValue };

    // update localstorage
    PULSE.core.localStorage.setStorage(
        cookieName,
        encodeURIComponent(JSON.stringify(newObj)),
        null,
        true,
        document.location.hostname
    );
};
