/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelect, useMultipleSelection } from 'downshift';
import classNames from 'classnames';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { DrawerBottom } from 'common/react/components/drawer';

/**
 * @param {object} props - Props.
 * @param {string} props.label - Label.
 * @param props.icon
 * @param {any[]} props.items - Items.
 * @param {any[]} props.selectedItems - Selected items.
 * @param {Function} props.getItemText - Given an item, get the text that
 * represents the item.
 * @param {Function} props.getItemLabel - Given an item, get the label for the
 * item. It can be text or some JSX.
 * @param {Function} props.getItemDisabled - Get disabled state for item if required.
 * @param {Function} props.onSelectedItemsChange - Fires when selectedItems changes
 * @returns {JSX.Element} Component.
 */

const isMobile = window.matchMedia(
    `(max-width: ${PULSE.app.measurements.desktop}px)`
);

const getSelectedItem = (itemsList, targetItem) => {
    for (let item of itemsList) {
        if (_.isEqual(item, targetItem)) {
            return item;
        }
    }
    return null;
};

export const SelectMultipleWithLimit = ({
    label,
    icon,
    items,
    getItemText,
    getItemLabel,
    getItemDisabled = null,
    onSelectedItemsChange,
    selections,
    addOnResetCallback,
    counterThreshold,
    maximumSelections,
    maximumSelectionsLabel,
    title
}) => {
    const [itemsSelected, setItemsSelected] = useState(selections);
    const [maxItemsSelected, setMaxItemsSelected] = useState(false);

    const {
        getDropdownProps,
        addSelectedItem,
        removeSelectedItem,
        selectedItems,
        reset
    } = useMultipleSelection({
        selectedItems: itemsSelected,
        onSelectedItemsChange: ({ selectedItems: newSelectedItems }) => {
            /**
             * Check for custom index value selection, e.g. Header, maximum threshold message. If these are selected,
             * prevent these these values from being add to the 'newSelectedItems' array that's passed to the
             * 'onSelectedItemsChange' function. This ensures the correct count is generated for the counter display
             * and the maximum threshold calculation.
             */
            const customIndex = newSelectedItems.findIndex(
                (newSelItems) => newSelItems.type
            );
            customIndex !== -1 && newSelectedItems.splice(customIndex, 1);
            onSelectedItemsChange(newSelectedItems);

            selectRef?.current.dispatchEvent(
                new Event(
                    PULSE.app.common.CONSTANTS.EVENTS.INTERACTION.CHANGE,
                    { bubbles: true }
                )
            );
        },
        stateReducer: (state, actionAndChanges) => {
            const { type, changes } = actionAndChanges;

            switch (type) {
                case useMultipleSelection.stateChangeTypes
                    .FunctionAddSelectedItem:
                    return {
                        ...changes,
                        // De duplicate any selectedItems as it fires twice in some scenarios
                        selectedItems: [...new Set(changes.selectedItems)]
                    };
                default:
                    return changes;
            }
        }
    });

    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        getLabelProps,
        closeMenu
    } = useSelect({
        items,
        selectedItem: null,
        stateReducer: (state, actionAndChanges) => {
            const { type, changes } = actionAndChanges;

            const selectedItemObj = getSelectedItem(
                selectedItems,
                changes.selectedItem
            );

            selectedItems?.length === maximumSelections
                ? setMaxItemsSelected(true)
                : setMaxItemsSelected(false);

            switch (type) {
                case useSelect.stateChangeTypes.MenuKeyDownEnter:
                case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
                case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
                case useSelect.stateChangeTypes.ItemClick:
                    if (!changes.selectedItem) {
                        return changes;
                    }

                    if (
                        selectedItems.some((selectedItem) =>
                            _.isEqual(selectedItem, changes.selectedItem)
                        )
                    ) {
                        removeSelectedItem(selectedItemObj);
                    } else if (!maxItemsSelected) {
                        addSelectedItem(changes.selectedItem);
                    }

                    return {
                        ...changes,
                        isOpen: true
                    };
                case useSelect.stateChangeTypes.ToggleButtonBlur:
                    return {
                        ...changes,
                        isOpen: isMobile.matches ? true : false
                    };
                default:
                    return changes;
            }
        },
        isItemDisabled: (itm) => {
            return (
                itm.type === 'threshold' ||
                (itm.type === 'clear' && selectedItems?.length === 0)
            );
        }
    });

    // Unchecks selected options after 'Clear' button click
    const clearClickHandler = () => {
        reset();
        setMaxItemsSelected(false);
    };

    const selectRef = useRef();
    const optionsWrapper = useRef();

    /**
     * This specific event is needed to close selectWrapper without any interruption
     * when the 'Clear' button is clicked. It could perhaps be optimised, but adding
     * to the 'clearClickHandler' method results in a momentary delay where this is
     * visible to the user, before it is hidden.
     */
    document.addEventListener('mousedown', (evt) => {
        const selectWrapper = document.querySelector(
            '.select__options-wrapper'
        );

        if (evt?.target?.classList.contains('select-multiple__header-clear')) {
            selectWrapper?.classList.add('u-hide');
        } else {
            selectWrapper?.classList.remove('u-hide');
        }
    });

    /**
     * Hookup reset method to the list of reset functions to execute when the
     * reset button is clicked.
     */
    useEffect(() => {
        addOnResetCallback(reset);
    }, []);

    useEffect(() => {
        setItemsSelected(selections);
    }, [selections]);

    const dropdownItems = (item, index) => {
        const itemText = getItemLabel(item);

        const isSelected = selectedItems.some((selectedItem) =>
            _.isEqual(selectedItem, item)
        );

        // Checks if highlighted item has already been selected
        const highlightedItemSelected = selectedItems.some(
            (selectedItem) => selectedItem.id === item.id
        );

        /**
         * Handles the class names assigned to the individual items in the list.
         * Some additional logic is applied to handle custom items, e.g. header
         * and threshold messaging. These should behave differently to other
         * selectable elements, i.e. shouldn't have a highlighted effect.
         */
        const className = classNames('select-multiple__option', {
            'select-multiple__option--selected': isSelected,
            'select-multiple__option--highlighted':
                highlightedIndex === index && !item.type,
            'select-multiple__option--disabled':
                maxItemsSelected && !isSelected && !item.type,
            'select-multiple__option--disabled-highlighted':
                maxItemsSelected &&
                !highlightedItemSelected &&
                highlightedIndex === index &&
                !item.type,
            'select-multiple__option--custom': item.type
        });

        const svgIcon = isSelected ? (
            <SvgIcon
                className="select-multiple__checkmark"
                icon="checkmark-square"
            />
        ) : (
            <SvgIcon
                className="select-multiple__checkmark-empty"
                icon="checkmark-square-empty"
            />
        );

        return (
            <li
                key={`${itemText}${index}`}
                {...getItemProps({
                    item,
                    index
                })}
                className={className}
            >
                {item.type === 'threshold' ? (
                    <span
                        className={`select-multiple__max-threshold select-multiple__option--disabled ${
                            !maxItemsSelected && 'u-hide'
                        }`}
                    >
                        {item.value}
                    </span>
                ) : item.type === 'clear' ? (
                    <div className={`select-multiple__header`}>
                        {item.title}
                        {selectedItems?.length > 0 ? (
                            <button
                                type="button"
                                className="select-multiple__header-clear button-new button-new--secondary"
                                onClick={() => clearClickHandler()}
                            >
                                {getTranslation('label.clear')}
                            </button>
                        ) : null}
                    </div>
                ) : (
                    <>
                        {svgIcon}
                        <span className="select-multiple__option-label-wrapper">
                            {getItemLabel(item)}
                        </span>
                    </>
                )}
            </li>
        );
    };

    const selectedItemsLabelsReducer = (acc, item) => {
        return acc ? acc + ', ' + getItemText(item) : acc + getItemText(item);
    };

    const toggleButtonLabelText = () => {
        if (counterThreshold && counterThreshold <= selectedItems?.length) {
            return (
                <p className="counter-wrapper">
                    <span className="select__current-text">{title}</span>
                    <span className="counter counter--large">
                        {selectedItems?.length}
                    </span>
                </p>
            );
        }

        if (
            selectedItems?.length > 0 &&
            selectedItems?.length < counterThreshold
        ) {
            return selectedItems?.reduce(selectedItemsLabelsReducer, '');
        }

        return null;
    };

    const toggleButtonLabel = () => {
        return selectedItems?.length === 0 ? label : null;
    };

    const toggleButtonCurrentElement = (
        <div className="select-multiple__current">
            {icon && <SvgIcon className="select-multiple__icon" icon={icon} />}
            {toggleButtonLabel()}
            {toggleButtonLabelText()}
        </div>
    );

    const onToggleButtonBlur = (evt) => {
        if (
            (evt?.nativeEvent?.relatedTarget &&
                evt?.nativeEvent?.relatedTarget?.matches(
                    '.select-multiple__header-clear'
                )) ||
            evt?.target.getAttribute('aria-activedescendant') !== ''
        ) {
            evt.nativeEvent.preventDownshiftDefault = true;
        } else {
            evt.nativeEvent.preventDownshiftDefault = false;
        }
    };

    return (
        <div className="w-100" ref={selectRef}>
            <div
                className={classNames('select-multiple', {
                    'select-multiple--open': isOpen
                })}
            >
                <div
                    className={classNames('select-multiple__display-wrapper', {
                        'select-multiple__display-wrapper--has-selected-items':
                            !!selectedItems.length
                    })}
                >
                    <button
                        type="button"
                        aria-label={`${label?.toLowerCase()}-dropdown-button`}
                        {...getToggleButtonProps(getDropdownProps())}
                        className="select-multiple__display"
                        onBlur={onToggleButtonBlur}
                    >
                        {toggleButtonCurrentElement}

                        {isOpen && !selectedItems.length && (
                            <SvgIcon
                                className="select-multiple__close"
                                icon="close"
                            />
                        )}

                        {!isOpen && !selectedItems.length && (
                            <SvgIcon
                                className="select-multiple__chevron"
                                icon="chevron-down"
                            />
                        )}
                    </button>
                    {!!selectedItems.length && (
                        <button
                            className="select-multiple__reset"
                            onClick={reset}
                            aria-label={`Reset ${label} dropdown button`}
                        >
                            <SvgIcon
                                className="select-multiple__reset-icon"
                                icon="close-circle-fill"
                            />
                        </button>
                    )}
                </div>
                <div
                    className={classNames('select-multiple__options-wrapper', {
                        'u-hide': isMobile.matches
                    })}
                >
                    <ul
                        {...getMenuProps({}, { suppressRefError: true })}
                        className={classNames('select-multiple__options-list', {
                            'u-hide': !isOpen
                        })}
                        ref={optionsWrapper}
                    >
                        {items.map(dropdownItems)}
                    </ul>
                </div>
            </div>

            {isMobile.matches ? (
                <DrawerBottom
                    isOpen={isOpen}
                    onClose={() => closeMenu()}
                    heading={label}
                >
                    <div className="select-multiple select-multiple--mobile select-multiple--open">
                        {/*
                        An invisible label for accessibility purposes.
                    */}
                        <label className="u-hide" {...getLabelProps()}>
                            {label}
                        </label>

                        {/*
                        An invisible dummy toggle button just so we're calling
                        getToggleButtonProps to ensure rc-drawer doesn't crash.
                    */}
                        <button
                            className="u-hide"
                            {...getToggleButtonProps(getDropdownProps())}
                        ></button>

                        <div className="select-multiple__options-wrapper">
                            {selectedItems.length === maximumSelections ? (
                                <p className="select-multiple__max-threshold">
                                    {maximumSelectionsLabel}
                                </p>
                            ) : null}
                            <ul
                                {...getMenuProps(
                                    {},
                                    { suppressRefError: true }
                                )}
                                className={classNames(
                                    'select-multiple__options-list',
                                    {
                                        'u-hide': !isOpen
                                    }
                                )}
                                ref={optionsWrapper}
                            >
                                {items.map((item, index) => {
                                    const itemText = getItemLabel(item);
                                    const isSelected = selectedItems.some(
                                        (selectedItem) =>
                                            _.isEqual(selectedItem, item)
                                    );
                                    /* eslint-disable-next-line */
                                    const isDisabled =
                                        typeof getItemDisabled === 'function'
                                            ? getItemDisabled(item)
                                            : false;

                                    return (
                                        !item.type && (
                                            <li
                                                key={`${itemText}${index}`}
                                                {...getItemProps({
                                                    item,
                                                    index
                                                })}
                                                className={classNames(
                                                    'select-multiple__option',
                                                    {
                                                        'select-multiple__option--selected':
                                                            isSelected,
                                                        'select-multiple__option--highlighted':
                                                            highlightedIndex ===
                                                            index,
                                                        'select-multiple__option--disabled':
                                                            isDisabled
                                                    }
                                                )}
                                            >
                                                {isSelected ? (
                                                    <SvgIcon
                                                        className="select-multiple__checkmark"
                                                        icon="checkmark-square"
                                                    />
                                                ) : (
                                                    <SvgIcon
                                                        className="select-multiple__checkmark-empty"
                                                        icon="checkmark-square-empty"
                                                    />
                                                )}

                                                <span className="select-multiple__option-label-wrapper">
                                                    {getItemLabel(item)}
                                                </span>
                                            </li>
                                        )
                                    );
                                })}
                            </ul>
                            {isOpen && selectedItems.length > 0 ? (
                                <div className="select-multiple__header">
                                    <button
                                        type="button"
                                        className="select-multiple__header-clear button-new button-new--secondary u-margin-left-auto"
                                        onClick={() => clearClickHandler()}
                                    >
                                        {getTranslation('label.clear')}
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </DrawerBottom>
            ) : null}
        </div>
    );
};

SelectMultipleWithLimit.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any),
    itemToString: PropTypes.func,
    selections: PropTypes.array,
    onSelectedItemsChange: PropTypes.func.isRequired,
    addOnResetCallback: PropTypes.func.isRequired,
    getItemText: PropTypes.func.isRequired,
    getItemLabel: PropTypes.func.isRequired,
    getItemDisabled: PropTypes.func,
    counterThreshold: PropTypes.number,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    maximumSelections: PropTypes.number,
    maximumSelectionsLabel: PropTypes.string
};
