import { getTranslation } from 'common/react/utils/translations';

export const TEAM_LINEUPS_POSITIONS = {
    BACKS: {
        id: 'backs',
        label: getTranslation('label.playingposition.group.backs'),
        labelShort: getTranslation('label.playingposition.group.abbr.backs'),
        positions: (aflw = false) =>
            aflw ? ['BPL', 'FB', ''] : ['BPL', 'FB', 'BPR']
    },
    FULL_BACKS: {
        id: 'fullBacks',
        label: getTranslation('label.playingposition.group.fullBacks'),
        labelShort: getTranslation(
            'label.playingposition.group.abbr.fullBacks'
        ),
        positions: () => ['FB']
    },
    HALF_BACKS: {
        id: 'halfBacks',
        label: getTranslation('label.playingposition.group.halfBacks'),
        labelShort: getTranslation(
            'label.playingposition.group.abbr.halfbacks'
        ),
        positions: () => ['HBFL', 'CHB', 'HBFR']
    },
    CENTRES: {
        id: 'centres',
        label: getTranslation('label.playingposition.group.centres'),
        labelShort: getTranslation('label.playingposition.group.abbr.centres'),
        positions: () => ['WL', 'C', 'WR']
    },
    HALF_FORWARDS: {
        id: 'halfForwards',
        label: getTranslation('label.playingposition.group.halfForwards'),
        labelShort: getTranslation(
            'label.playingposition.group.abbr.halfforwards'
        ),
        positions: () => ['HFFL', 'CHF', 'HFFR']
    },
    FORWARDS: {
        id: 'forwards',
        label: getTranslation('label.playingposition.group.forwards'),
        labelShort: getTranslation('label.playingposition.group.abbr.forwards'),
        positions: (aflw = false) =>
            aflw ? ['', 'FF', 'FPR'] : ['FPL', 'FF', 'FPR']
    },
    FULL_FORWARDS: {
        id: 'fullForwards',
        label: getTranslation('label.playingposition.group.fullForwards'),
        labelShort: getTranslation(
            'label.playingposition.group.abbr.fullForwards'
        ),
        positions: () => ['FF']
    },
    FOLLOWERS: {
        id: 'followers',
        label: getTranslation('label.playingposition.group.followers'),
        labelShort: getTranslation(
            'label.playingposition.group.abbr.followers'
        ),
        positions: () => ['RK', 'RR', 'R']
    },
    INTERCHANGES: {
        id: 'interchanges',
        label: getTranslation('label.playingposition.group.interchanges'),
        labelShort: getTranslation(
            'label.playingposition.group.abbr.interchanges'
        ),
        positions: () => ['INT']
    },
    SUBSTITUTES: {
        id: 'substitutes',
        label: getTranslation('label.playingposition.group.substitutes'),
        labelShort: getTranslation(
            'label.playingposition.group.abbr.substitutes'
        ),
        positions: () => ['SUB']
    },
    EMERGENCIES: {
        id: 'emergencies',
        label: getTranslation('label.playingposition.group.emergencies'),
        labelShort: getTranslation(
            'label.playingposition.group.abbr.emergencies'
        ),
        positions: () => ['EME']
    }
};

export const getTeamLineupsFieldPositions = (isAFLW = false) => [
    {
        homeTeam: {
            ...TEAM_LINEUPS_POSITIONS.BACKS,
            positions: TEAM_LINEUPS_POSITIONS.BACKS.positions(isAFLW)
        },
        awayTeam: {
            ...TEAM_LINEUPS_POSITIONS.FORWARDS,
            positions:
                TEAM_LINEUPS_POSITIONS.FORWARDS.positions(isAFLW).reverse()
        }
    },
    {
        homeTeam: {
            ...TEAM_LINEUPS_POSITIONS.HALF_BACKS,
            positions: TEAM_LINEUPS_POSITIONS.HALF_BACKS.positions(isAFLW)
        },
        awayTeam: {
            ...TEAM_LINEUPS_POSITIONS.HALF_FORWARDS,
            positions:
                TEAM_LINEUPS_POSITIONS.HALF_FORWARDS.positions(isAFLW).reverse()
        }
    },
    {
        homeTeam: {
            ...TEAM_LINEUPS_POSITIONS.CENTRES,
            positions: TEAM_LINEUPS_POSITIONS.CENTRES.positions(isAFLW)
        },
        awayTeam: {
            ...TEAM_LINEUPS_POSITIONS.CENTRES,
            positions:
                TEAM_LINEUPS_POSITIONS.CENTRES.positions(isAFLW).reverse()
        }
    },
    {
        homeTeam: {
            ...TEAM_LINEUPS_POSITIONS.HALF_FORWARDS,
            positions: TEAM_LINEUPS_POSITIONS.HALF_FORWARDS.positions(isAFLW)
        },
        awayTeam: {
            ...TEAM_LINEUPS_POSITIONS.HALF_BACKS,
            positions:
                TEAM_LINEUPS_POSITIONS.HALF_BACKS.positions(isAFLW).reverse()
        }
    },
    {
        homeTeam: {
            ...TEAM_LINEUPS_POSITIONS.FORWARDS,
            positions: TEAM_LINEUPS_POSITIONS.FORWARDS.positions(isAFLW)
        },
        awayTeam: {
            ...TEAM_LINEUPS_POSITIONS.BACKS,
            positions: TEAM_LINEUPS_POSITIONS.BACKS.positions(isAFLW).reverse()
        }
    }
];

export const getTeamLineupsListViewPositions = (isAFLW = false) => [
    {
        ...TEAM_LINEUPS_POSITIONS.BACKS,
        positions: TEAM_LINEUPS_POSITIONS.BACKS.positions(isAFLW)
    },
    {
        ...TEAM_LINEUPS_POSITIONS.HALF_BACKS,
        positions: TEAM_LINEUPS_POSITIONS.HALF_BACKS.positions(isAFLW)
    },
    {
        ...TEAM_LINEUPS_POSITIONS.CENTRES,
        positions: TEAM_LINEUPS_POSITIONS.CENTRES.positions(isAFLW)
    },
    {
        ...TEAM_LINEUPS_POSITIONS.HALF_FORWARDS,
        positions: TEAM_LINEUPS_POSITIONS.HALF_FORWARDS.positions(isAFLW)
    },
    {
        ...TEAM_LINEUPS_POSITIONS.FORWARDS,
        positions: TEAM_LINEUPS_POSITIONS.FORWARDS.positions(isAFLW)
    },
    {
        ...TEAM_LINEUPS_POSITIONS.FOLLOWERS,
        positions: TEAM_LINEUPS_POSITIONS.FOLLOWERS.positions(isAFLW)
    },
    {
        ...TEAM_LINEUPS_POSITIONS.INTERCHANGES,
        positions: TEAM_LINEUPS_POSITIONS.INTERCHANGES.positions(isAFLW)
    },
    {
        ...TEAM_LINEUPS_POSITIONS.SUBSTITUTES,
        positions: TEAM_LINEUPS_POSITIONS.SUBSTITUTES.positions(isAFLW)
    }
];

export const MATCH_STATUS_SHOWING_SUBS = [
    PULSE.app.common.CONSTANTS.MATCH_STATUS.CONFIRMED_TEAMS,
    PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE,
    PULSE.app.common.CONSTANTS.MATCH_STATUS.POSTGAME,
    PULSE.app.common.CONSTANTS.MATCH_STATUS.POST_GAME,
    PULSE.app.common.CONSTANTS.MATCH_STATUS.CONCLUDED,
    PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED
];
