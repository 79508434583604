import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';

const MAX_ITEMS = 5;
/*
 * Render the form (e.g WLWWD)
 */
export const Form = ({ form }) => {
    const maxItems = MAX_ITEMS;
    const formLetters = form.map((item) => item.result);

    while (formLetters.length < maxItems) {
        formLetters.push('-');
    }

    return (
        <td className="stats-table__cell stats-table__cell--form">
            <ul className="stats-table__form">
                {formLetters.slice(-maxItems).map((formLetter, index) => (
                    <li
                        key={index}
                        className={`stats-table__form-item stats-table__form-item--${formLetter.toLowerCase()}`}
                    >
                        {formLetter === '-' ? (
                            <span>{formLetter}</span>
                        ) : (
                            <div>
                                <SvgIcon
                                    icon={`ladder-form-icon-${formLetter.toLowerCase()}`}
                                    className="icon"
                                />
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </td>
    );
};

Form.propTypes = {
    form: PropTypes.array
};
