import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { FixtureLinkButton } from './FixtureLinkButton';

import { getTranslation } from 'common/react/utils/translations';
import { useGetContentQuery } from 'store/modules/api/content-api';
import { FixtureCountContext } from '../../fixtures-list/FixturesList';

export const FixtureMatchReportLink = ({ match, reportLabel }) => {
    const requestConfig = {
        type: 'TEXT',
        params: {
            pageSize: 1,
            references: `AFL_MATCH:${match.id}`,
            tagNames: `match-report`
        }
    };

    const cardsCount = useContext(FixtureCountContext);

    const { data: matchReport } = useGetContentQuery(requestConfig);

    if (matchReport && matchReport.content.length) {
        return (
            <FixtureLinkButton
                href={PULSE.app.common.url.getMatchURL(match, reportLabel)}
                icon="report"
                label={getTranslation('label.fixtures.match-report')}
                theme="alt"
                onClick={() => {
                    PULSE.app.tracking.selectFixtureCardHandler(
                        match,
                        getTranslation('label.fixtures.match-report'),
                        PULSE.app.common.url.getMatchURL(match, reportLabel),
                        cardsCount
                    );
                }}
            />
        );
    }

    return <></>;
};

FixtureMatchReportLink.propTypes = {
    match: PropTypes.object.isRequired,
    reportLabel: PropTypes.string
};
