import { Navigation, A11y, Pagination } from 'swiper';

export const sliderSettings = (name) => {
    return {
        modules: [Navigation, A11y, Pagination],
        slidesPerView: 3.15,
        navigation: {
            nextEl: '.swiper-button-next-unique-' + name,
            prevEl: '.swiper-button-prev-unique-' + name
        },
        pagination: {
            el: '.js-content-grid-video-slider-pagination',
            clickable: true,
            renderBullet: (index, className) =>
                `<span class="${className}"></span>`
        },
        watchSlidesProgress: true
    };
};

export const BREAKPOINTS_FOR_FOUR_SLIDES = {
    breakpoints: {
        840: {
            slidesPerView: 'auto',
            slidesPerGroup: 3
        },
        1024: {
            slidesPerView: 'auto',
            slidesPerGroup: 4
        }
    }
};
