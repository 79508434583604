import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';

import {
    CompNavItem,
    ResponsiveRoundNavItem,
    ResponsiveSeasonNavItem,
    RoundSelectNavItem,
    SeasonNavItem
} from 'common/react/components/competition-nav/nav-items';
import { navSelector } from 'common/store/modules/competition-nav/selectors';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useNavSync } from 'common/react/components/competition-nav/hooks/use-nav-sync';
import { ManageStats } from './components/ManageStats';
import PropTypes from 'prop-types';
import { ALLOWED_COMPS } from 'widgets/ladder-v2/js/components/LadderNav';
import { SettingsButton } from 'common/react/components/SettingsButton';

const TIMEOUT = 500;

export const CustomLadderNav = ({ seasonId }) => {
    const dispatch = useDispatch();

    const initialValues = {
        seasonId: seasonId
    };

    const nav = useSelector(navSelector);
    const options = useCompetitionNavigationOptions(nav);

    const compseason = nav?.season.id;
    const competition = nav?.competition.id;
    const roundId = nav?.round.id;

    let timeoutId = null;

    useNavSync({ ...options, initialValues, removeFinals: true });
    const competitions = options.competitions;

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    const season = seasons.find((item) => item.id === nav.season.id);

    const showResponsiveItems = useMediaQuery({
        maxWidth: PULSE.app.measurements.desktop
    });

    const wrapManageStatsButton = useMediaQuery({
        maxWidth: 1100
    });

    useEffect(() => {
        // clear prev timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            dispatchLadderInit();
        }, TIMEOUT);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [competition, compseason, roundId]);

    const dispatchLadderInit = () => {
        const params = {
            roundId: nav.round.id
        };

        const string = Object.keys(params)
            .map((key) => {
                return (
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(params[key])
                );
            })
            .join('&');

        const currentRoundNumber =
            season?.currentRoundNumber === 0 ? 1 : season?.currentRoundNumber;

        const isFutureRound = nav.round.roundNumber > currentRoundNumber;

        const endpoint = `${PULSE.app.environment.api}compseasons/${
            nav.season.id
        }/ladders${!isFutureRound ? '?' + string : ''}`;

        dispatch(
            PULSE.app.redux.actions.customtable.RE_INIT_TABLE.request({
                tableName: 'ladder',
                apiUrl: endpoint,
                retryEndPoint: false
            })
        );
    };

    return (
        <div className="competition-nav">
            <StatsSection.Nav>
                <StatsSection.NavItems>
                    <CompNavItem
                        competitions={competitions}
                        allowListOrder={ALLOWED_COMPS}
                        isCustomTable={true}
                    />
                    {!showResponsiveItems ? (
                        <SeasonNavItem seasons={seasons} />
                    ) : null}

                    {!showResponsiveItems ? (
                        <RoundSelectNavItem
                            removeFinals={true}
                            classes={'u-margin-right-auto'}
                        />
                    ) : null}
                </StatsSection.NavItems>

                {showResponsiveItems ? (
                    <StatsSection.NavFilterItems hideReset={true}>
                        <ResponsiveSeasonNavItem seasons={seasons} />
                        <ResponsiveRoundNavItem removeFinals={true} />
                    </StatsSection.NavFilterItems>
                ) : null}

                <StatsSection.SettingsButton>
                    {!wrapManageStatsButton ? <ManageStats /> : ''}
                    <SettingsButton />
                </StatsSection.SettingsButton>

                {wrapManageStatsButton ? (
                    <StatsSection.ResponsiveRowItems>
                        <ManageStats fullsize />
                    </StatsSection.ResponsiveRowItems>
                ) : null}
            </StatsSection.Nav>
        </div>
    );
};

CustomLadderNav.propTypes = {
    seasonId: PropTypes.string
};
