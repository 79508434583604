import React from 'react';
import PropTypes from 'prop-types';

import { InsAndOutsPlayer } from './InsAndOutsPlayer';
import { TooltipButton } from 'common/react/components/tooltip-button';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const InsAndOutsRow = ({ data, title, cssClass, tooltipContent }) => {
    const renderPlayer = (item, match) => {
        if (!item) {
            return null;
        }

        const player = item.player ? item.player : item;

        return (
            <InsAndOutsPlayer
                key={player.playerId}
                player={player}
                reason={item.reason}
                match={match}
            />
        );
    };

    return (
        <div className="team-lineups-ins-and-outs">
            {/* Mobile Title */}
            <div className="team-lineups__title-section u-hide-from-tablet">
                <div className="team-lineups-ins-and-outs__title-wrapper ">
                    <p
                        className={`team-lineups-ins-and-outs__title ${
                            cssClass
                                ? `team-lineups-ins-and-outs__title--${cssClass}`
                                : ''
                        }`}
                    >
                        {title}
                    </p>
                    {tooltipContent ? (
                        <TooltipButton
                            disableTitle={true}
                            id={`team-lineups-${title}`}
                            primaryTooltipContent={<div>{tooltipContent}</div>}
                        >
                            <SvgIcon
                                icon="info-circle"
                                className="team-lineups__tooltip-icon"
                            />
                        </TooltipButton>
                    ) : null}
                </div>
            </div>
            <div className="team-lineups__grid">
                {data.home.length ? (
                    <div className="team-lineups-ins-and-outs__grid team-lineups-ins-and-outs__grid--home">
                        {[...data.home]
                            .reverse()
                            .map((item) => renderPlayer(item, data.match))}
                    </div>
                ) : (
                    <span className="team-lineups-ins-and-outs__empty team-lineups-ins-and-outs__empty--home">
                        &ndash;
                    </span>
                )}
                <div className="team-lineups__title-section u-hide-until-tablet">
                    <div className="team-lineups-ins-and-outs__title-wrapper">
                        <p
                            className={`team-lineups-ins-and-outs__title ${
                                cssClass
                                    ? `team-lineups-ins-and-outs__title--${cssClass}`
                                    : ''
                            }`}
                        >
                            {title}
                        </p>
                        {tooltipContent ? (
                            <TooltipButton
                                disableTitle={true}
                                id={`team-lineups-${title}`}
                                primaryTooltipContent={
                                    <div>{tooltipContent}</div>
                                }
                                title={`${title} tooltip`}
                            >
                                <SvgIcon
                                    icon="info-circle"
                                    className="team-lineups__tooltip-icon"
                                />
                            </TooltipButton>
                        ) : null}
                    </div>
                </div>
                {data.away.length ? (
                    <div className="team-lineups-ins-and-outs__grid team-lineups-ins-and-outs__grid--away">
                        {data.away.map((item) =>
                            renderPlayer(item, data.match)
                        )}
                    </div>
                ) : (
                    <span className="team-lineups-ins-and-outs__empty team-lineups-ins-and-outs__empty--away">
                        &ndash;
                    </span>
                )}
            </div>
        </div>
    );
};

InsAndOutsRow.propTypes = {
    data: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    cssClass: PropTypes.string,
    tooltipContent: PropTypes.string
};
