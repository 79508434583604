/**
 * @param {ReduxState} state - The redux state.
 * @param {string} matchId - The match ID you want the prediction for
 * @returns {object} The prediction object for the match ID
 */
export const predictionSelector = (state, matchId) =>
    state.ladderPredictor.predictions.find(
        (prediction) => prediction.i === matchId
    ) || null;

export const predictionFinalsSelector = (state, matchId) =>
    state.ladderPredictor.predictionsFinals.find(
        (prediction) => prediction?.i === matchId
    ) || null;

export const predictionsFinalsSelector = (state) =>
    state.ladderPredictor?.predictionsFinals || null;
