import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { ImageElement } from './ImageElement';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { selectCardHandler } from './util';
import { MediaThumbnailFooter } from './MediaThumbnailFooter';

export const MediaThumbnailVideo = ({ video, index, tags }) => {
    const videoRef = useRef();

    const VARIANT_CONFIG = [
        {
            name: 'thumbnailMobile',
            maxWidth: 640,
            size: { width: 120 },
            multipliers: [1, 2, 3]
        },
        {
            name: 'thumbnailDesktop',
            minWidth: 641,
            size: { width: 160 },
            multipliers: [1, 2, 3]
        },
        {
            name: 'default',
            size: { width: 160 },
            multipliers: [1]
        }
    ];

    const videoData = PULSE.app.common.content.getContentModel(
        video,
        VARIANT_CONFIG
    );

    const refs = video.references;
    const compId = refs.find((ref) => ref.type === 'AFL_COMPETITION')?.sid;

    // Responsive
    const windowSize = useWindowSize();

    const isResponsive = () =>
        windowSize.width <= PULSE.app.measurements.phablet;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    const videoUrlParams = {
        pageSize: 50,
        tagNames: `ProgramCategory:${videoData.tags
            .find((item) => item.label.includes('ProgramCategory:'))
            ?.label.replace('ProgramCategory:', '')}`,
        references: ['AFL_COMPSEASON', 'AFL_ROUND']
            .map((ref) => {
                const refValue = videoData.references.find(
                    (item) => item.type === ref
                );
                if (refValue) {
                    return `${ref}:${refValue.id}`;
                }
                return null;
            })
            .filter((ref) => !!ref)
            .join(',')
    };

    const videoLink = `${videoData.link}&${PULSE.core.url.buildQueryString(
        videoUrlParams
    )}`;

    const videoModalData = {
        contentId: videoData.id,
        mediaId: videoData.mediaId,
        mediaTitle: PULSE.app.templating.helper.cleanString(videoData.title),
        mediaDescription: PULSE.app.templating.helper.cleanString(
            videoData.description
        ),
        subtitle: PULSE.app.templating.helper.cleanString(videoData.subtitle),
        type: videoData.type,
        link: videoLink,
        publishFrom: videoData.publishFrom + 1,
        mediaDate: PULSE.app.templating.helper.getDateNice(
            videoData.publishFrom
        )
    };

    useEffect(() => {
        if (videoRef.current) {
            PULSE.app.VideoPlayerModal.createInstance(videoRef.current);
            videoRef.current.dataset.modalAdded = true;
        }
    }, []);

    return (
        <article className={`media-thumbnail`}>
            <div className="media-thumbnail__link">
                <a
                    ref={videoRef}
                    className={`media-thumbnail__absolute-link js-media-thumbnail__link`}
                    data-ui-modal="#VideoModal"
                    href={videoLink}
                    data-content-id={videoData.id}
                    data-ui-args={JSON.stringify(videoModalData)}
                    data-index-position={index + 1}
                    onClick={(evt) => selectCardHandler(evt, videoData?.title)}
                    data-content-type={
                        PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES.VIDEO
                    }
                ></a>
                <header
                    className={`media-thumbnail__image-container placeholder placeholder--small placeholder--dark`}
                >
                    <div className="js-lazy-load lazy-image-wrapper is-loaded">
                        <ImageElement
                            ImageUrl={videoData.onDemandUrl}
                            ImageTitle={videoData.title}
                        />
                    </div>

                    <div className={`media-thumbnail__video-duration`}>
                        <SvgIcon
                            icon={'video-play-small'}
                            className={`icon media-thumbnail__duration-icon`}
                        />
                        <span className={`media-thumbnail__duration-text`}>
                            {PULSE.app.common.date.durationToTime(
                                videoData.duration
                            )}
                        </span>
                    </div>
                </header>
                <div className={`media-thumbnail__caption`}>
                    <h2 className={`media-thumbnail__title`}>
                        {videoData.title}
                    </h2>
                    <p className={`media-thumbnail__description`}>
                        {videoData?.description}
                    </p>
                    {!showResponsiveItems ? (
                        <MediaThumbnailFooter
                            compId={compId}
                            data={video}
                            tags={tags}
                        />
                    ) : null}
                </div>
            </div>
            {showResponsiveItems ? (
                <MediaThumbnailFooter
                    compId={compId}
                    data={video}
                    tags={tags}
                />
            ) : null}
        </article>
    );
};

MediaThumbnailVideo.propTypes = {
    video: PropTypes.object,
    index: PropTypes.number,
    tags: PropTypes.string
};
