import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';

/*
 * Render list title
 */
export const ListTitle = ({ dateKey }) => {
    const label =
        dateKey === 'TBCPC'
            ? getTranslation('label.fixtures.tbc-postponed-cancelled')
            : moment(dateKey, 'YYYYMMDD').format('dddd MMMM D');

    return <h2 className="ladder-predictor-sidebar__list-title">{label}</h2>;
};

ListTitle.propTypes = {
    dateKey: PropTypes.string.isRequired
};
