import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const ModalTitle = ({
    icon,
    title,
    closeBtn = true,
    bgColour,
    type
}) => {
    const onCloseClick = (evnt) => {
        evnt.stopPropagation();

        if (type === 'add-favourite-team') {
            PULSE.app.common.siteSettings.changeModalType('settings');
        } else {
            PULSE.app.common.siteSettings.closeSettingsModal();
        }
    };

    return (
        <section
            className="modal-title"
            style={{
                background: bgColour,
                borderBottom: `0.1rem solid ${bgColour}`
            }}
        >
            {icon ? (
                <SvgIcon icon={icon} className="icon modal-title__icon" />
            ) : null}
            {title ? <h2 className="modal-title__text">{title}</h2> : null}
            {closeBtn ? (
                <button
                    className="modal-title__close-btn js-close-modal"
                    aria-label="Close"
                    onClick={onCloseClick}
                >
                    <SvgIcon icon="close" className="icon" />
                </button>
            ) : null}
        </section>
    );
};

ModalTitle.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    closeBtn: PropTypes.bool,
    bgColour: PropTypes.string,
    type: PropTypes.string
};
