import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useGetPaginatedContentQuery } from 'store/modules/api/content-api';

import { EmptyState } from 'common/react/components/EmptyState';
import { MediaThumbnailArticle } from 'common/react/components/media-thumbnail/MediaThumbnailArticle';
import { MediaThumbnailVideo } from 'common/react/components/media-thumbnail/MediaThumbnailVideo';
import { SkeletonLoader } from 'common/react/components/SkeletonLoader';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { formatTagsForTagExpression } from 'common/react/utils/format-tag-expression';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { getTranslation } from 'common/react/utils/translations';
import { Loader } from 'common/react/components/Loader';

const AFLW_COMP_ID = window.PULSE.envPaths.competitions.AFLW,
    AFLM_COMP_ID = window.PULSE.envPaths.competitions.AFL;

export const ContentGrid = ({
    title,
    tags,
    mediaType,
    linkTo,
    pageSize,
    showMore
}) => {
    // Responsive logic
    const windowSize = useWindowSize();
    const isResponsive = () =>
        windowSize.width < PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );
    const [page, setPage] = useState(0);

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    // Formatting variables
    const tagsArray = tags?.split(',');
    const nav = useSelector(navSelector);
    const compAbbr = PULSE.app.common.match
        .getCompAbbr(nav.season.providerId)
        .toLowerCase();
    const formattedTeamString = formatReferenceExpression(
        nav?.competition?.id,
        nav?.filters?.teams
    );

    // RTK Query Configuration
    const requestConfig = {
        type: mediaType,
        params: {
            referenceExpression: formattedTeamString,
            tagExpression: tags ? formatTagsForTagExpression(tagsArray) : null
        },
        pageSize: parseInt(pageSize)
    };

    // RTK Query Dispatch
    const {
        data: requestData = [],
        isFetching: contentGridIsFetching,
        isError: contentGridIsError
    } = useGetPaginatedContentQuery(
        {
            ...requestConfig,
            instanceId: JSON.stringify(requestConfig),
            page: page
        },
        {
            skip: !mediaType || nav?.competition?.id === ''
        }
    );

    const contentData = requestData?.content;

    // If the navigation changes, clear any newly-fetched data and reset page to 0
    useEffect(() => {
        setPage(0);
    }, [nav]);

    // Fallback states
    if (contentGridIsFetching && page === 0) {
        return (
            <>
                <div className="widget-header">
                    {showResponsiveItems ? (
                        <SkeletonLoader
                            height={26}
                            width={200}
                            className={'content-grid__skeleton-loader-title'}
                        />
                    ) : (
                        <SkeletonLoader
                            height={40}
                            width={250}
                            className={'content-grid__skeleton-loader-title'}
                        />
                    )}
                </div>
                <div className="media-list__list block-list-4 block-list-3-tablet block-list-1-phablet">
                    {showResponsiveItems ? (
                        <>
                            <SkeletonLoader height={108} />
                            <SkeletonLoader height={108} />
                            <SkeletonLoader height={108} />
                            <SkeletonLoader height={108} />
                        </>
                    ) : (
                        <>
                            <SkeletonLoader height={320} />
                            <SkeletonLoader height={320} />
                            <SkeletonLoader height={320} />
                            <SkeletonLoader height={320} />
                        </>
                    )}
                </div>
            </>
        );
    } else if (contentGridIsError) {
        <EmptyState />;
    } else {
        // Render media thumbnails
        return (
            title &&
            contentData?.length > 0 && (
                <div className="content-grid content-grid__inner-wrapper">
                    <header className="widget-header js-widget-header">
                        <div className={'widget-header__content'}>
                            {linkTo ? (
                                <a
                                    href={linkTo}
                                    className="widget-header__title-link"
                                >
                                    <h2 className="widget-header__title">
                                        {title}
                                    </h2>
                                    <SvgIcon
                                        icon="arrow-right"
                                        className="icon"
                                    />
                                </a>
                            ) : (
                                <h2 className="widget-header__title">
                                    {title}
                                </h2>
                            )}
                        </div>
                    </header>
                    <ul
                        data-title={title}
                        className="media-list__list block-list-4 block-list-3-tablet block-list-1-phablet"
                    >
                        {contentData?.map((item, index) => (
                            <li className="media-list__item" key={item.id}>
                                {mediaType === 'text' ? (
                                    <MediaThumbnailArticle
                                        article={item}
                                        index={index}
                                        compIcon={compAbbr}
                                        tags={tags}
                                    />
                                ) : (
                                    <MediaThumbnailVideo
                                        video={item}
                                        index={index}
                                        compIcon={compAbbr}
                                        tags={tags}
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                    {showMore &&
                    (mediaType === 'text' || (mediaType === 'video' && tags)) &&
                    requestData?.hasMore === true ? (
                        contentGridIsFetching ? (
                            <footer className="widget-footer">
                                <Loader />
                            </footer>
                        ) : (
                            <footer className="widget-footer">
                                <button
                                    className="widget-footer__button button button--loading"
                                    onClick={() => {
                                        setPage(page + 1);
                                        /* eslint-disable camelcase */
                                        PULSE.app.tracking.recordEvent(
                                            PULSE.app.common.CONSTANTS.TRACKING
                                                .EVENTS.SELECT.LINK,
                                            {
                                                eventData: {
                                                    link_text:
                                                        getTranslation(
                                                            'label.showMore'
                                                        ),
                                                    link_url: '',
                                                    outbound: false,
                                                    type: 'internal',
                                                    component_name: title,
                                                    component_content_type:
                                                        mediaType === 'video'
                                                            ? PULSE.app.common
                                                                  .CONSTANTS
                                                                  .TRACKING
                                                                  .CONTENT_TYPES
                                                                  .VIDEOS
                                                            : mediaType ===
                                                              'text'
                                                            ? PULSE.app.common
                                                                  .CONSTANTS
                                                                  .TRACKING
                                                                  .CONTENT_TYPES
                                                                  .ARTICLE
                                                            : null
                                                }
                                            }
                                        );
                                        /* eslint-enable camelcase */
                                    }}
                                >
                                    <span className="u-screen-reader">
                                        {getTranslation('label.showMore.items')}
                                    </span>
                                    <span>
                                        {getTranslation('label.showMore')}
                                    </span>
                                    <SvgIcon
                                        className="icon"
                                        icon="chevron-down"
                                    />
                                </button>
                            </footer>
                        )
                    ) : null}
                </div>
            )
        );
    }
};

/**
 * helper returning formatted referenceExpression query based on the provided competition and teams
 * When the comp equals "" or *, it also adds AFLW comp references and converts the AFLM team ids to AFLW team ids for that.
 *
 * @param {number|string} comp Competition id
 * @param {Array} teams Array of numbers
 *
 * @returns {string} referenceExpression string
 */
function formatReferenceExpression(comp, teams) {
    let comps = [comp];

    // No competition has been selected
    // We get content for AFL and AFLW
    if (comp === '*' || comp === '') {
        comps = [AFLM_COMP_ID, AFLW_COMP_ID];
    }

    let referenceQueryElements = [];

    if (teams.length > 0) {
        referenceQueryElements = teams.map((teamId) => {
            return comps
                .map((compId) => {
                    // Both AFLM and AFLW comps are on the list
                    // but we have only AFLM team ids.
                    // Need to convert AFLM to AFLW
                    if (compId === AFLW_COMP_ID && comps.length === 2) {
                        return `(AFL_TEAM:${PULSE.app.common.team.getAflwTeamId(
                            teamId
                        )} and AFL_COMPETITION:${compId})`;
                    }

                    return `(AFL_TEAM:${teamId} and AFL_COMPETITION:${compId})`;
                })
                .join(' or ');
        });
    } else {
        referenceQueryElements = comps.map(
            (compId) => `(AFL_COMPETITION:${compId})`
        );
    }

    return referenceQueryElements.join(' or ');
}

ContentGrid.propTypes = {
    title: PropTypes.string,
    tags: PropTypes.string,
    mediaType: PropTypes.string,
    linkTo: PropTypes.string,
    pageSize: PropTypes.string,
    showMore: PropTypes.string
};
