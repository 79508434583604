import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import {
    RoundListNavItem,
    LocationNavItem,
    RegionNavItem
} from 'common/react/components/competition-nav/nav-items';
import { BroadcastGuideCards } from './BroadcastGuideCards';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useNavSync } from 'common/react/components/competition-nav/hooks/use-nav-sync';
import { useGetRegionsQuery } from 'common/store/modules/api/content-api';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';
import { useFiltersTracking } from 'common/react/hooks/use-filters-tracking';

export const BroadcastGuide = ({ competitionId, seasonId, title }) => {
    const initialValues = {
        competitionId,
        seasonId,
        location: useSingleSiteSetting('region')
    };

    const nav = useSelector(navSelector);
    const options = useCompetitionNavigationOptions(nav);
    useNavSync({ ...options, initialValues });

    const { data = [] } = useGetRegionsQuery();

    const locations = useMemo(() => {
        if (data.length) {
            return data.reduce((regions, region) => {
                const value =
                    PULSE.app.common.broadcast.getLocationFromTimezone(
                        region.timezone
                    );

                const returnValue = {
                    name: value,
                    value,
                    extra: region
                };

                if (!regions.find((obj) => obj.name === returnValue.name)) {
                    regions.push(returnValue);
                }

                return regions.sort((textA, textB) =>
                    textA.name < textB.name
                        ? -1
                        : textA.name > textB.name
                        ? 1
                        : 0
                );
            }, []);
        }
    }, [data]);

    const competitionNavRef = useRef();

    /**
     * custom hook providing tracking of filters
     */
    useFiltersTracking({
        title,
        filtersState: nav,
        options,
        filtersFields: ['location', 'region', 'round'],
        filtersRef: competitionNavRef
    });

    return (
        <>
            <div className="competition-nav" ref={competitionNavRef}>
                <StatsSection.Main>
                    <StatsSection.Nav>
                        <StatsSection.NavItems>
                            <LocationNavItem
                                locations={locations}
                                classes={'competition-nav__location-wrapper'}
                            />
                            <RegionNavItem
                                regions={data}
                                classes={'competition-nav__region-wrapper'}
                            />
                        </StatsSection.NavItems>

                        <StatsSection.RoundButtons>
                            <RoundListNavItem
                                classes={
                                    'competition-nav__round-filter-wrapper'
                                }
                            />
                        </StatsSection.RoundButtons>
                    </StatsSection.Nav>
                </StatsSection.Main>
            </div>
            <div className="wrapper widget">
                <BroadcastGuideCards
                    competitionId={competitionId}
                    seasonId={seasonId}
                />
            </div>
        </>
    );
};

BroadcastGuide.propTypes = {
    competitionId: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    title: PropTypes.string
};
