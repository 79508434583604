import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
    useGetLadderQuery,
    useGetMatchesQuery
} from 'store/modules/api/afl-api';

import { composeColumnData } from './utils/compose-column-data';
import { composeRowData } from './utils/compose-row-data';
import { modelLadderData } from './utils/model-ladder-data';
import { useWindowWidth } from 'common/react/hooks/use-window-resize';
import { getLeadingScore } from 'common/react/utils/get-leading-score';
import { EmptyState } from 'common/react/components/EmptyState';
import { Table } from './Table';
import { getTranslation } from 'common/react/utils/translations';
import { bettingOddsSelector } from 'common/store/modules/competition-nav/selectors';
import { useBettingVis } from 'common/react/hooks/use-betting-vis';
import { useGetBettingOddsQuery } from 'common/store/modules/api/cfs-api';

import { LadderLegend } from './LadderLegend';
import { SkeletonLoader } from 'common/react/components/SkeletonLoader';
import { useFavouriteTeam } from 'common/react/hooks/use-favourite-team';

export const LadderTableFetcher = ({
    competitionId,
    compSeason,
    round,
    liveLadderData
}) => {
    // eslint-disable-next-line no-magic-numbers
    const windowWidth = useWindowWidth(500); //500 is the debounce not the viewport!!
    const isBettingOddsToggled = useSelector(bettingOddsSelector);
    const isBettingVis = useBettingVis();
    const { data: bettingData } = useGetBettingOddsQuery({
        competitionType: PULSE.app.common.match.getCompAbbr(
            compSeason.providerId
        )
    });

    const favouriteTeam = useFavouriteTeam();

    const liveLadder = liveLadderData?.liveLadderPositions;
    const liveLadderCutoff = liveLadderData?.finalsCutOff || null;

    // Check for a book for Premiership Winner and that the comp id matches
    const hasPremiershipWinBook = useMemo(
        () =>
            bettingData?.competition?.books.find((book) => {
                return book.name.includes('Premiership Winner');
            }) && bettingData?.competition?.seasonId === compSeason.providerId,
        [
            bettingData?.competition?.books,
            bettingData?.competition?.seasonId,
            compSeason.providerId
        ]
    );

    // Check the conditions to show betting and then either hide or show the CGM message
    useEffect(() => {
        if (
            isBettingOddsToggled.isActive &&
            isBettingVis &&
            hasPremiershipWinBook
        ) {
            PULSE.app.common.betting.cgmDisplay();
        } else if (window.location.hash !== '#predictor') {
            PULSE.app.common.betting.cgmHide();
        }
    }, [hasPremiershipWinBook, isBettingOddsToggled, isBettingVis]);

    const {
        data: ladderData,
        isFetching,
        isError
    } = useGetLadderQuery({
        compSeasonId: compSeason.id,
        params: {
            roundId: round?.id
        }
    });

    /**
     * Workout the winner of the comp by looking for the last match and see if
     * it is the GF (Grand Final), then return the winner's teams object
     */
    const { winner } = useGetMatchesQuery(
        {
            compSeasonId: compSeason.id,
            pageSize: 1,
            sort: 'desc'
        },
        {
            skip: !compSeason?.id,
            selectFromResult: ({ data }) => {
                if (!data?.length) {
                    return { winner: false };
                }

                const match = data[0];

                if (match?.round?.abbreviation === 'GF') {
                    return {
                        winner: data?.length
                            ? getLeadingScore(
                                  match?.home,
                                  match?.away,
                                  match?.home?.score?.totalScore,
                                  match?.away?.score?.totalScore
                              )
                            : false
                    };
                }

                return { winner: false };
            }
        }
    );

    /**
     * Get column details.
     */
    const columns = useMemo(
        () =>
            composeColumnData(
                windowWidth,
                isBettingOddsToggled?.isActive,
                isBettingVis,
                hasPremiershipWinBook
            ),
        [
            windowWidth,
            isBettingOddsToggled?.isActive,
            isBettingVis,
            hasPremiershipWinBook
        ]
    );

    // Model ladder data for each ladder entry
    const tables = useMemo(() => {
        // we want our live ladder in an array so it better matches the structure of the static data
        // ladder - makes for easier data modelling later
        let ladders = ladderData?.ladders;

        // extract only ladder from the liveLadder data

        let liveLadders = [liveLadder];

        // Obtain a non-live ladder to fill in any missing information from the live ladder.
        const staticLadder = ladderData?.ladders?.[0];

        // If there is no populated static ladder, stop here
        if (!staticLadder?.entries?.length) {
            return [];
        }

        // Override the ladders variable with the liveLadders array if we are rendering a live ladder based on the component's prop.
        if (liveLadder) {
            ladders = liveLadders;
        }

        // If we are not using liveLadders, ensure that the ladders array contains at least one ladder.
        if (!liveLadder && (!ladders || ladders.length === 0)) {
            return [];
        }

        // If we are using the liveLadders array, ensure that it contains at least one ladder.
        if ((liveLadder && !ladders) || liveLadders.length === 0) {
            return [];
        }

        // Model the data into the desired shape for our live or static ladder.
        return ladders.map((ladder) => {
            const data = liveLadder
                ? modelLadderData(staticLadder, bettingData, ladder)
                : modelLadderData(ladder, bettingData);

            const premWinner = winner;
            const conferenceLabel = ladder?.conference || false;

            const qualifierRowIndex = liveLadderCutoff
                ? liveLadderCutoff
                : // eslint-disable-next-line no-magic-numbers
                  ladder?.finalsCutOff;

            const qualiferRow = qualifierRowIndex - 1;

            // this util will match the column data with the correct row data
            const rowData = data.map((item) =>
                composeRowData(
                    item,
                    columns,
                    qualiferRow,
                    conferenceLabel,
                    premWinner,
                    competitionId,
                    compSeason?.id,
                    round?.roundNumber || '',
                    PULSE.app.common.match.getCompAbbr(compSeason.providerId),
                    favouriteTeam
                )
            );

            return { columns, data, rowData, conferenceLabel };
        });
    }, [
        bettingData,
        columns,
        compSeason?.id,
        competitionId,
        ladderData?.ladders,
        liveLadder,
        round?.roundNumber,
        winner,
        favouriteTeam
    ]);

    // controls legend qualifiers label
    const qualifiers = tables?.[0]?.rowData?.[0]?.qualiferRow
        ? tables?.[0]?.rowData?.[0]?.qualiferRow + 1
        : 8;

    // used by ladder legend component to know what to render
    const competition =
        ladderData?.compSeason?.shortName ||
        getTranslation(`label.ladder.premiershipWinner`);

    const compLabel = competition.toUpperCase().includes('PREMIERSHIP')
        ? getTranslation(`label.ladder.premiershipWinner`)
        : `${ladderData?.compSeason?.shortName} ${getTranslation(
              `label.ladder.winner`
          )}`;

    const isWinRatio = tables?.[0]?.data[0]?.orderedBy === 'winRatio';

    // ===== Render ============================================== //

    if (isFetching) {
        return <SkeletonLoader className="ladder__item-skeleton" count={1} />;
    }

    if (isError || tables.length === 0 || tables[0]?.rowData?.length === 0) {
        return (
            <div style={{ padding: '3.2rem 1.6rem' }}>
                <EmptyState cssClass="competition-nav__empty-state" />
            </div>
        );
    }

    return (
        <>
            {tables.map((table, index) => (
                <Table
                    key={index}
                    columns={table.columns}
                    data={table.rowData}
                    modifier={`ladder
                    ${
                        table.conferenceLabel
                            ? 'is-' +
                              table.conferenceLabel
                                  .toLowerCase()
                                  .replaceAll(' ', '')
                            : ''
                    }
                    ${
                        table?.data[0]?.orderedBy === 'winRatio'
                            ? 'is-win-ratio'
                            : ''
                    }`}
                    cssClass="js-ladder"
                    conferenceLabel={table.conferenceLabel}
                    conferenceModifier={`${
                        table.conferenceLabel
                            ? 'is-' +
                              table.conferenceLabel
                                  .toLowerCase()
                                  .replaceAll(' ', '')
                            : ''
                    }`}
                />
            ))}

            <LadderLegend
                qualifiers={qualifiers}
                compLabel={compLabel}
                isWinRatio={isWinRatio}
            />
        </>
    );
};

LadderTableFetcher.propTypes = {
    competitionId: PropTypes.number,
    compSeason: PropTypes.object,
    round: PropTypes.object,
    liveLadderData: PropTypes.any
};
