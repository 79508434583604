import { TEAM_LINEUPS_POSITIONS } from './constants';

/**
 * Maps team positions data to position groups needed for displaying the team lineups
 *
 * @param {Array} teamPositions positions field from matchRoster.team
 * @param {boolean} isAFLW If the match is aflw the positions are different
 * @returns {object} Object of position ids with mapped players
 */
export const mapPlayersToPositions = (teamPositions, isAFLW = false) => {
    const team = {};

    Object.keys(TEAM_LINEUPS_POSITIONS).forEach((key) => {
        team[TEAM_LINEUPS_POSITIONS[key].id] = teamPositions
            .filter((pos) =>
                TEAM_LINEUPS_POSITIONS[key]
                    .positions(isAFLW)
                    .includes(pos.position)
            )
            .map((pos) => ({ position: pos.position, ...pos.player }));
    });

    return team;
};

/**
 * For given positions, team and players, returns object to be used to display players on their positions
 *
 * @param {object} positions
 * @param {object} team
 * @param {object} players
 *
 * @returns {object}
 */
export const getTeamPlayersForPositions = (positions, team, players) => {
    const sectionId = positions.id;

    return {
        id: team.teamId,
        position: sectionId,
        labelShort: positions.labelShort,
        players: positions.positions.map((pos) =>
            players[sectionId].find((player) => player.position === pos)
        ),
        team: team
    };
};
