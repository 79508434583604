import React from 'react';
import PropTypes from 'prop-types';

/*
 * Render the betting odds
 */
export const BettingOdds = ({ proposition }) => {
    return (
        <td className="stats-table__cell stats-table__cell--betting-odds">
            {!isNaN(proposition?.premiership) ? (
                <a
                    className="stats-table__betting-cell-button button button--small button--sportsbet"
                    target="_blank"
                    rel="noreferrer"
                    href={PULSE.app.common.betting.getClickThroughUrl(
                        proposition
                    )}
                >
                    {new Intl.NumberFormat('en-AU', {
                        style: 'currency',
                        currency: 'AUD'
                    }).format(proposition.premiership)}
                </a>
            ) : null}
        </td>
    );
};

BettingOdds.propTypes = {
    proposition: PropTypes.object
};
