import React from 'react';
import PropTypes from 'prop-types';

import { InformationCategoryHeader } from './InformationCategoryHeader';
import { InformationCard } from './InformationCard';
import { MapLocationSelector } from './MapLocationSelector';
import { InformationSideBarItem } from './InformationSideBarItem';

export const InformationBottomDrawer = ({
    selectedLocation,
    googlePlacesData,
    selectedCategory,
    locations,
    timezone,
    setSelectedVenue,
    isFetching
}) => {
    return (
        <div className="cupertino-pane">
            {selectedCategory === null && selectedLocation === null ? (
                <MapLocationSelector setVenue={setSelectedVenue} />
            ) : null}

            <InformationCard
                selectedLocation={selectedLocation}
                googlePlacesData={googlePlacesData}
                timezone={timezone}
            />

            <InformationCategoryHeader
                selectedCategory={selectedCategory}
                selectedLocation={selectedLocation}
            />

            <ul
                className={`interactive-event-map__information-list js-interactive-event-map__information-list ${
                    selectedLocation !== null ? 'u-hide' : ''
                }`}
            >
                {locations?.map((location) => (
                    <InformationSideBarItem
                        key={location.id}
                        location={location}
                        selectedCategory={selectedCategory}
                        isFetching={isFetching}
                    />
                ))}
            </ul>
        </div>
    );
};

InformationBottomDrawer.propTypes = {
    selectedLocation: PropTypes.object,
    googlePlacesData: PropTypes.object,
    selectedCategory: PropTypes.string,
    locations: PropTypes.array,
    timezone: PropTypes.string,
    setSelectedVenue: PropTypes.func,
    isFetching: PropTypes.bool
};
