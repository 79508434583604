import React from 'react';
import PropTypes from 'prop-types';

import { PlayerStatsTooltipHeader } from './PlayerStatsTooltipHeader';
import { PlayerStatsTooltipMainStat } from './PlayerStatsTooltipMainStat';
import { PlayerStatsTooltipRelatedStats } from './PlayerStatsTooltipRelatedStats';
import { PlayerStatsTooltipLink } from './PlayerStatsTooltipLink';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @param {object} [props.benchmark] - Benchmark.
 * @param {string} props.opponent - Opponent.
 * @param {boolean} props.isAFLW - Is this an AFLW match.
 * @returns {JSX.Element} Component.
 */
export const PlayerStatsTooltipContent = (props) => {
    /**
     * As there could be more than one match per round, this method only returns a
     * stats object when the player ID and opponent team ID match. This ensures only
     * the relevant stats are returned for the match in the table's row.
     */
    const player = props.players.find(
        (_player) =>
            _player.details.playerId === props.row.player.playerId &&
            _player?.stats?.opponent?.teamId === props.opponent
    );

    return (
        <div>
            {player && <PlayerStatsTooltipHeader player={player} />}
            <PlayerStatsTooltipMainStat {...props} />
            <PlayerStatsTooltipRelatedStats {...props} player={player} />
            <PlayerStatsTooltipLink player={player} isAFLW={props.isAFLW} />
        </div>
    );
};

PlayerStatsTooltipContent.propTypes = {
    row: PropTypes.object.isRequired,
    cell: PropTypes.object.isRequired,
    players: PropTypes.array.isRequired,
    benchmark: PropTypes.string,
    opponent: PropTypes.string,
    isAFLW: PropTypes.bool
};
