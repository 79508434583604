import React from 'react';
import PropTypes from 'prop-types';

export const ModalHeading = ({ text }) => {
    return (
        <div className={`modal-heading`}>
            {text ? <p className="modal-heading__text">{text}</p> : null}
        </div>
    );
};

ModalHeading.propTypes = {
    text: PropTypes.string.isRequired
};
