import React from 'react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { useDispatch } from 'react-redux';

import {
    setToolTip,
    setSeasonStep,
    setFinalsStep,
    setActiveView
} from 'common/store/modules/ladder-predictor/init';

/*
 * Render step tooltip
 */
export const StepToolTip = () => {
    const dispatch = useDispatch();

    // Handle button click
    const handleButtonClick = () => {
        dispatch(
            setToolTip({
                open: false,
                userClosed: false
            })
        );
        dispatch(
            setSeasonStep({
                active: false,
                current: false,
                complete: true
            })
        );
        dispatch(
            setFinalsStep({
                active: true,
                current: true,
                complete: false
            })
        );
        dispatch(setActiveView('finals'));
    };

    // Handle close click
    const handleCloseClick = () => {
        dispatch(
            setToolTip({
                open: false,
                userClosed: true
            })
        );
    };

    return (
        <div className="ladder-predictor-step-tooltip">
            <button
                className="ladder-predictor-step-tooltip__close"
                onClick={() => {
                    handleCloseClick();
                }}
            >
                <SvgIcon icon="close" className="icon" />
            </button>
            <div className="ladder-predictor-step-tooltip__content">
                <p className="ladder-predictor-step-tooltip__title">
                    {getTranslation('label.ladderPredictor.stepToolTip.title')}
                </p>
                <p className="ladder-predictor-step-tooltip__summary">
                    {getTranslation(
                        'label.ladderPredictor.stepToolTip.summary'
                    )}
                </p>
                <div className="ladder-predictor-step-tooltip__button-container">
                    <button
                        className="button-new button-new--medium button-new--primary"
                        onClick={() => {
                            handleButtonClick();
                        }}
                    >
                        {getTranslation(
                            'label.ladderPredictor.stepToolTip.button'
                        )}
                        <SvgIcon icon="arrow-right" className="icon" />
                    </button>
                </div>
                <div className="ladder-predictor-step-tooltip__arrow"></div>
            </div>
        </div>
    );
};
