import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { RELATED_STATS } from '../../../../config/related-stats';
import { STATS_COLUMNS } from 'widgets/statspro/js/components/common/config/columns';
import { statsLeadersDataTypeSelector } from 'store/modules/statspro/stats-leaders/selectors';

const CLASS_BLOCK = 'player-stats-tooltip-related-stats';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @param {object} props.player - Player.
 * @param {object} [props.benchmark] - Benchmark.
 * @returns {JSX.Element} Component.
 */
export const PlayerStatsTooltipRelatedStats = (props) => {
    const stat = props.cell.column.id;
    const dataType = useSelector(statsLeadersDataTypeSelector);

    return (
        <ul className={CLASS_BLOCK}>
            {RELATED_STATS[stat].map((relatedStat) => {
                const statValues = props.player.stats[dataType.type];
                if (typeof relatedStat === 'function') {
                    const { id, name, value } = relatedStat(statValues);
                    return (
                        <RelatedStatListItem
                            key={id}
                            name={name}
                            value={value ?? '-'}
                        />
                    );
                } else if (typeof relatedStat === 'string') {
                    const relatedStatValue = statValues[relatedStat] ?? '-';
                    return (
                        <RelatedStatListItem
                            key={relatedStat}
                            name={STATS_COLUMNS[relatedStat].name}
                            value={relatedStatValue.value ?? relatedStatValue}
                        />
                    );
                }

                return <></>;
            })}
        </ul>
    );
};

PlayerStatsTooltipRelatedStats.propTypes = {
    row: PropTypes.object.isRequired,
    cell: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    benchmark: PropTypes.string
};

const RelatedStatListItem = ({ name, value }) => {
    return (
        <li className={`${CLASS_BLOCK}__row`}>
            <span className={`${CLASS_BLOCK}__name`}>{name}</span>
            <span>{value}</span>
        </li>
    );
};

RelatedStatListItem.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
};
