import { useState, useEffect } from 'react';

export const useWindowSize = (debounce = 1) => {
    const [windowSize, setWindowSize] = useState({
        width: null,
        height: null
    });

    useEffect(() => {
        let timeout;
        const handler = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            }, debounce);
        };

        window.addEventListener('resize', handler);

        handler();

        return () => window.removeEventListener('resize', handler);
    }, []);

    return windowSize;
};

export const useWindowWidth = (debounce = 1) => {
    const [windowWidthSize, setWindowWidthSize] = useState(null);

    useEffect(() => {
        let timeout;
        const handler = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                setWindowWidthSize(window.innerWidth);
            }, debounce);
        };

        window.addEventListener('resize', handler);

        handler();

        return () => window.removeEventListener('resize', handler);
    }, []);

    return windowWidthSize;
};

export const useWindowHeight = (debounce = 1) => {
    const [windowHeightSize, setWindowHeightSize] = useState(
        window.innerHeight
    );

    useEffect(() => {
        let timeout;
        const handler = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                setWindowHeightSize(window.innerHeight);
            }, debounce);
        };

        window.addEventListener('resize', handler);

        setWindowHeightSize(window.innerHeight);

        return () => window.removeEventListener('resize', handler);
    }, []);

    return windowHeightSize;
};
