import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';
import { SettingsButton } from 'common/react/components/SettingsButton';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useNavSync } from 'common/react/components/competition-nav/hooks/use-nav-sync';

import {
    UPDATE_FILTER_ITEM,
    UPDATE_NAV_ITEM
} from 'common/store/modules/competition-nav/actions';
import { getTranslation } from 'common/react/utils/translations';
import { setCookieState } from 'widgets/content-grid/js/helpers/set-cookie-state';
import { CGCompNavItem } from './components/nav-filter-items/CGCompNavItem';
import { CGTeamFilter } from './components/nav-filter-items/CGTeamFilter';
import { useFiltersTracking } from 'common/react/hooks/use-filters-tracking';
import { EmptyState } from 'common/react/components/EmptyState';

export const CGNav = ({
    competitionId,
    counterThreshold,
    maximumSelections,
    userAuthenticated,
    title,
    cookieName
}) => {
    const isAFLW = PULSE.app.common.url.checkBaseRoute('aflw');

    const classPrefix = 'content-grid-filters';

    const windowSize = useWindowSize();
    const isResponsive = () =>
        windowSize.width < PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );
    const currentFilters = useGetCurrentCompFilters();
    const cgFiltersRef = useRef();

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    // specify our default item config
    const defaultComp = {
        name: getTranslation('label.filters.competition'),
        id: '*',
        default: true
    };

    const competitionFallback = isAFLW
        ? { id: window.PULSE.envPaths.competitions.AFLW }
        : defaultComp;

    // On load check for session storage properties
    const initialValues = useMemo(() => {
        const sessionStorageJson = PULSE.core.localStorage.getStorage(
            cookieName,
            true
        )
            ? JSON.parse(
                  decodeURIComponent(
                      PULSE.core.localStorage.getStorage(cookieName, true)
                  )
              )
            : '';

        // if logged in, and session storage values exist
        if (sessionStorageJson && userAuthenticated) {
            // Use stored item as initial values
            const { competition, teams } = sessionStorageJson;

            return {
                competitionId: competition ? competition : competitionFallback,
                filters: {
                    teams: teams
                }
            };
        }

        // if logged out
        return {
            competitionId: isAFLW
                ? window.PULSE.envPaths.competitions.AFLW
                : '*',
            filters: {
                teams: []
            }
        };
    }, [userAuthenticated]);

    // Redux
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);
    let options = useCompetitionNavigationOptions({
        competition: {
            id:
                nav.competition.id === '*'
                    ? isAFLW
                        ? window.PULSE.envPaths.competitions.AFLW
                        : window.PULSE.envPaths.competitions.AFL
                    : nav.competition.id
        },
        season: nav.season
    });
    useNavSync({ ...options, initialValues });

    const competitions = options.competitions;

    const resetCompetition = () => {
        dispatch(
            UPDATE_NAV_ITEM.request({
                navItem: 'competition',
                value: defaultComp
            })
        );
    };

    const resetTeams = () => {
        dispatch(
            UPDATE_FILTER_ITEM.request({
                filterItem: 'teams',
                value: []
            })
        );
    };

    const resetFilters = () => {
        dispatch(
            UPDATE_NAV_ITEM.request({
                navItem: 'competition',
                value: competitionFallback
            })
        );

        if (nav.competition.id === competitionFallback.id) {
            resetTeams();
        }

        // reset cookie value
        PULSE.core.localStorage.clearStorage(
            cookieName,
            true,
            document.location.hostname
        );
    };

    useFiltersTracking({
        title,
        filtersState: {
            ...nav,
            season:
                nav.season?.id !== ''
                    ? nav.season
                    : { id: options.seasons?.[0]?.id }
        },
        options,
        filtersFields: ['competition', 'teams'],
        filtersRef: cgFiltersRef
    });

    const handleCookieUpdate = (item, value) => {
        setCookieState(cookieName, item, value, nav);
    };

    return (
        <>
            <div className={classPrefix} ref={cgFiltersRef}>
                <StatsSection.Main>
                    <StatsSection.Nav showFilterLabel={false}>
                        <StatsSection.NavItems>
                            <div className="stats-leaders-nav__item-filter-container">
                                <CGCompNavItem
                                    competitions={competitions}
                                    competitionId={competitionId}
                                    onReset={resetCompetition}
                                    className={
                                        !showResponsiveItems
                                            ? 'select--rounded'
                                            : null
                                    }
                                    defaultItem={defaultComp}
                                    handleCookieUpdate={handleCookieUpdate}
                                />

                                {!showResponsiveItems ? (
                                    <>
                                        <CGTeamFilter
                                            addOnResetCallback={() =>
                                                resetTeams
                                            }
                                            isBrownlow={false}
                                            icon="guernsey"
                                            counterThreshold={parseInt(
                                                counterThreshold
                                            )}
                                            maximumSelections={parseInt(
                                                maximumSelections
                                            )}
                                            title={getTranslation(
                                                'label.filters.teams'
                                            )}
                                            defaultComp={defaultComp}
                                            isAFLW={isAFLW}
                                            handleCookieUpdate={
                                                handleCookieUpdate
                                            }
                                        />
                                        {currentFilters.competitionId !==
                                            competitionFallback.id ||
                                        nav?.filters?.teams?.length > 0 ? (
                                            <button
                                                className="stats-leaders-nav__reset-button u-margin-right-auto"
                                                onClick={() => resetFilters()}
                                            >
                                                {getTranslation(
                                                    'label.statsLeaders.filters.resetButtonText'
                                                )}
                                            </button>
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                        </StatsSection.NavItems>
                        {showResponsiveItems ? (
                            <StatsSection.NavFilterItems hideReset={true}>
                                <CGTeamFilter
                                    addOnResetCallback={() => resetTeams}
                                    isBrownlow={false}
                                    icon="guernsey"
                                    counterThreshold={parseInt(
                                        counterThreshold
                                    )}
                                    maximumSelections={parseInt(
                                        maximumSelections
                                    )}
                                    title={getTranslation(
                                        'label.filters.teams'
                                    )}
                                    defaultComp={defaultComp}
                                    isAFLW={isAFLW}
                                    handleCookieUpdate={handleCookieUpdate}
                                />
                                {currentFilters.competitionId !==
                                    competitionFallback.id ||
                                nav?.filters?.teams?.length > 0 ? (
                                    <button
                                        className="stats-leaders-nav__reset-button u-margin-right-auto"
                                        onClick={resetFilters}
                                    >
                                        {getTranslation(
                                            'label.statsLeaders.filters.resetButtonText'
                                        )}
                                    </button>
                                ) : null}
                            </StatsSection.NavFilterItems>
                        ) : null}
                        <StatsSection.SettingsButton>
                            <SettingsButton hideLabel={true} />
                        </StatsSection.SettingsButton>
                    </StatsSection.Nav>
                </StatsSection.Main>
            </div>
            <div className="content-grid-filters__empty-state">
                <EmptyState
                    titleTranslation="label.empty.state.contentGrid.title"
                    summaryTranslation="label.empty.state.contentGrid.summary"
                >
                    <button
                        className="filter-button filter-button--full-size"
                        onClick={resetFilters}
                    >
                        <span>
                            {getTranslation(
                                'label.empty.state.filters.resetButtonText'
                            )}
                        </span>
                    </button>
                </EmptyState>
            </div>
        </>
    );
};

CGNav.propTypes = {
    seasonsToShow: PropTypes.string,
    seasonId: PropTypes.string,
    compId: PropTypes.string,
    competitionId: PropTypes.string,
    counterThreshold: PropTypes.string,
    maximumSelections: PropTypes.string,
    userAuthenticated: PropTypes.bool,
    title: PropTypes.string,
    cookieName: PropTypes.string
};
