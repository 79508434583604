import React from 'react';
import PropTypes from 'prop-types';

import { getTranslation } from 'common/react/utils/translations';
import { ModalHeading } from './ModalHeading';
import { ModalNote } from './ModalNote';
import { ModalLink } from './ModalLink';
import { ModalButton } from './ModalButton';
import { useFavouriteTeam } from 'common/react/hooks/use-favourite-team';

export const ModalFavouriteTeam = ({ loggedIn }) => {
    const componentClass = 'favourite-team';

    const favouriteTeam = useFavouriteTeam();

    return (
        <div
            className={`modal-${componentClass} ${
                loggedIn
                    ? `modal-${componentClass}--logged-in`
                    : `modal-${componentClass}--logged-out`
            }`}
        >
            {loggedIn ? (
                <>
                    <ModalHeading
                        text={getTranslation('settingsModal.favouriteTeam')}
                    />
                    {favouriteTeam ? (
                        <ModalLink
                            icon={`aflc-${favouriteTeam}`}
                            label={PULSE.app.common.team.getTeamNameFromAbbr(
                                favouriteTeam
                            )}
                            link={PULSE.app.environment.okta.accountUrl}
                            btnLabel={getTranslation(
                                'settingsModal.changeTeam.short'
                            )}
                            cssModifier={componentClass}
                            openNewTab={true}
                            onClick={() => {
                                PULSE.app.tracking.recordEvent(
                                    PULSE.app.common.CONSTANTS.TRACKING.EVENTS
                                        .SELECT.CTA,
                                    {
                                        /* eslint-disable camelcase */
                                        eventData: {
                                            link_text: 'change favourite team',
                                            link_url:
                                                PULSE.app.environment.okta
                                                    .accountUrl,
                                            outbound: true,
                                            type: 'external',
                                            component_name: 'settings modal'
                                        }
                                        /* eslint-enable camelcase */
                                    }
                                );
                            }}
                        />
                    ) : (
                        <ModalLink
                            icon={'star'}
                            label={getTranslation('settingsModal.addTeam')}
                            link={PULSE.app.environment.okta.accountUrl}
                            cssModifier="no-favourite-team"
                            openNewTab={true}
                            onClick={() => {
                                PULSE.app.tracking.recordEvent(
                                    PULSE.app.common.CONSTANTS.TRACKING.EVENTS
                                        .SELECT.CTA,
                                    {
                                        /* eslint-disable camelcase */
                                        eventData: {
                                            link_text: getTranslation(
                                                'settingsModal.addTeam'
                                            ),
                                            link_url:
                                                PULSE.app.environment.okta
                                                    .accountUrl,
                                            outbound: true,
                                            type: 'external',
                                            component_name: 'settings modal'
                                        }
                                        /* eslint-enable camelcase */
                                    }
                                );
                            }}
                        />
                    )}
                    <ModalNote
                        text={getTranslation(
                            'settingsModal.favouriteTeam.changeNote'
                        )}
                        cssModifier={componentClass}
                        isEmbedded={true}
                    />
                </>
            ) : (
                <>
                    <ModalButton
                        icon={'star'}
                        label={getTranslation('settingsModal.addTeam')}
                        onClick={() => {
                            PULSE.app.common.siteSettings.openModalGeneric(
                                'addFavouriteTeam'
                            );

                            PULSE.app.tracking.recordEvent(
                                PULSE.app.common.CONSTANTS.TRACKING.EVENTS
                                    .SELECT.CTA,
                                {
                                    /* eslint-disable camelcase */
                                    eventData: {
                                        link_text: getTranslation(
                                            'settingsModal.addTeam'
                                        ),
                                        link_url: '',
                                        outbound: false,
                                        type: 'internal',
                                        component_name: 'settings modal'
                                    }
                                    /* eslint-enable camelcase */
                                }
                            );
                        }}
                        cssModifier={componentClass}
                    />
                    <ModalNote
                        text={getTranslation(
                            'settingsModal.favouriteTeam.addNote'
                        )}
                        cssModifier={componentClass}
                        isEmbedded={true}
                    />
                </>
            )}
        </div>
    );
};

ModalFavouriteTeam.propTypes = {
    loggedIn: PropTypes.bool
};
