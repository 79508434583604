const formatTime = (hour) => {
    // Ensure the hour is a valid 24-hour format
    if (hour < 0 || hour >= 2400) {
        return 'Invalid hour';
    }

    // Extract hours and minutes
    const hours = Math.floor(hour / 100);
    const minutes = hour % 100;

    // Determine AM or PM
    const period = hours >= 12 ? 'pm' : 'am';

    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12;

    // Add leading zero to minutes if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Construct the formatted time string
    const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;

    return formattedTime;
};

/**
 *
 * @param { number } openingTime - Opening times
 * @param { number } closingTime - Closing times
 * @param timezone
 * @returns {boolean} - returns true if open false if not
 */
function isPlaceOpenCustom(openingTime, closingTime, timezone) {
    const currentDate = moment.tz(new Date(), timezone);

    let numericOpeningTime = moment.tz(new Date(), timezone);
    numericOpeningTime.set({
        hour: Math.floor(openingTime / 100),
        minute: openingTime % 100,
        second: 0
    });

    let numericClosingTime = moment.tz(new Date(), timezone);
    numericClosingTime.set({
        hour: Math.floor(closingTime / 100),
        minute: closingTime % 100,
        second: 0
    });

    const openingTimezoned = numericOpeningTime.format();
    const closingTimezoned = numericClosingTime.format();

    return (
        currentDate.isSameOrAfter(openingTimezoned) &&
        currentDate.isSameOrBefore(closingTimezoned)
    );
}

export const isLocationOpen = (
    selectedLocation,
    googlePlacesData,
    timezone
) => {
    const customOpeningTime =
        selectedLocation?.metadata?.map_event_opening_hour;
    const customClosingTime =
        selectedLocation?.metadata?.map_event_closing_hour;
    const isOpenGoogle = googlePlacesData?.isPlaceOpen;

    if (customOpeningTime && customClosingTime) {
        return isPlaceOpenCustom(
            customOpeningTime,
            customClosingTime,
            timezone
        );
    } else if (!customOpeningTime && !customClosingTime) {
        if (isOpenGoogle) {
            return true;
        }
        return false;
    }
    return false;
};

/**
 *
 * @param { object } googlePlacesData - Data from Google's Places API.
 * @param { object } selectedLocation - Data from CMS.
 * @param { string } timezone         - The timezone, e.g. Australia/Adelaide.
 * @returns {string} - Returns a string of time to display.
 */
export function getOpeningTimes(googlePlacesData, selectedLocation, timezone) {
    const date = new Date();
    const dayToday = (date.getDay() + 6) % 7; // Ensures that Monday is at index 0 & Sunday at 6

    const customOpeningTime =
        selectedLocation?.metadata?.map_event_opening_hour;
    const customClosingTime =
        selectedLocation?.metadata?.map_event_closing_hour;
    const isOpenCustom = isPlaceOpenCustom(
        customOpeningTime,
        customClosingTime,
        timezone
    );
    const isOpenGoogle = googlePlacesData?.isPlaceOpen;
    const googleClosingTime =
        googlePlacesData?.opening_hours?.periods[dayToday]?.close?.time;
    const googleOpeningTime =
        googlePlacesData?.opening_hours?.periods[dayToday + 1]?.open?.time;

    let openingTimes;

    if (customOpeningTime && customClosingTime) {
        if (isOpenCustom) {
            openingTimes = formatTime(customClosingTime);
        } else if (!isOpenCustom) {
            openingTimes = formatTime(customOpeningTime);
        }
    } else if (!customOpeningTime && !customClosingTime) {
        if (isOpenGoogle) {
            openingTimes = formatTime(googleClosingTime);
        } else if (!isOpenGoogle) {
            openingTimes = formatTime(googleOpeningTime);
        }
    }

    return openingTimes;
}

/**
 * Checks to see if a location has any custom time values assigned and, if not,
 * checks to see if the location data returned from Google has a matching opening
 * and closing time. If it does, this location is open all day and returns true,
 * otherwise it is false.
 *
 * @param { object } googlePlacesData - Data from Google's Places API.
 * @param { object } selectedLocation - Data from CMS.
 * @param { string } timezone         - The timezone, e.g. Australia/Adelaide.
 * @returns { boolean } - Returns a boolean if the location is open all day, or not.
 */
export function isLocationOpenAllDay(
    googlePlacesData,
    selectedLocation,
    timezone
) {
    const date = new Date();
    const dayToday = (moment.tz(date.getDay(), timezone) + 6) % 7;

    const customOpeningTime =
        selectedLocation?.metadata?.map_event_opening_hour;
    const customClosingTime =
        selectedLocation?.metadata?.map_event_closing_hour;
    const isOpenCustom = isPlaceOpenCustom(
        customOpeningTime,
        customClosingTime,
        timezone
    );
    const isOpenGoogle = googlePlacesData?.isPlaceOpen;
    const googleClosingTime =
        googlePlacesData?.opening_hours?.periods[dayToday]?.close?.time;
    const googleOpeningTime =
        googlePlacesData?.opening_hours?.periods[dayToday + 1]?.open?.time;

    let isOpenAllDay = false;

    /**
     * The Google Maps API groups consecutive days with the same opening hours in the following 
     * format.
     * 
     * A. SINGLE DAY 
     * Opening hours are defined with 'open' and 'close' object having the same day value.
     * 
     * Example: Monday 8am - 11pm
        {
            "close": {
                "day": 1,
                "time": "2300",
                "hours": 23,
                "minutes": 0,
                "nextDate": 1724104800000
            },
            "open": {
                "day": 1,
                "time": "0800",
                "hours": 8,
                "minutes": 0,
                "nextDate": 1724050800000
            }
        }
     
        B. MULTIPLE DAYS
        Wednesday is treated as Day 3, so the 'open' object has a day value of and 'close' is 1. 
        Google treats Sunday at midnight as the start of Monday, hence why there is a day of 1 here.
     
        Example: Wednesday - Sunday (open all day) 
        {
            "close": {
                "day": 1,
                "time": "0000",
                "hours": 0,
                "minutes": 0,
                "nextDate": 1724022000000
            },
            "open": {
                "day": 3,
                "time": "0000",
                "hours": 0,
                "minutes": 0,
                "nextDate": 1723590000000
            }
        }
    ]
     
    Given this data structure, googleOpeninngTime and googleClosingTime which takes a number between 0-6 will sometimes 
    be undefined if there are a block of days with consecutive times listed and the periods array has < 6 items.
     */

    if (!isOpenCustom) {
        if (
            (isOpenGoogle && !googleOpeningTime && !googleClosingTime) ||
            (!googleOpeningTime && googleClosingTime === '0000')
        ) {
            isOpenAllDay = true;
        }
    }

    return isOpenAllDay;
}

export const getCategory = (location) => {
    const locationCategory = location?.tags?.length
        ? location?.tags[0]?.label
        : '';

    return PULSE.app.common.getMarkerCategory(locationCategory);
};

export const filterItems = (category, selectedCategoryName) => {
    if (selectedCategoryName !== null && category !== selectedCategoryName) {
        return 'u-hide';
    }
    return '';
};
