import React, { useRef, useState, useEffect, useMemo } from 'react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { useSelector } from 'react-redux';
import {
    removeAllPredictions,
    removePredictionsByFilter,
    autoFillPredictions,
    removeAllFinalsPredictions
} from 'common/store/modules/ladder-predictor/init';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { CONFIG } from '../constants';

/*
 * Render progress bar
 */
export const ProgressBar = ({ matchesData }) => {
    const dispatch = useDispatch();
    const [showResetDropdown, setShowResetDropdown] = useState(false);
    const [showAutoFillDropdown, setAutoFillDropdown] = useState(false);
    const [showResetFinalsDropdown, setShowResetFinalsDropdown] =
        useState(false);
    const resetRef = useRef(null);
    const autoFillRef = useRef(null);
    const resetFinalsRef = useRef(null);

    // Get state

    const filter = useSelector((state) => state.ladderPredictor.currentFilter);

    const currentTeam = useSelector(
        (state) => state.ladderPredictor.currentTeam
    );
    const currentRound = useSelector(
        (state) => state.ladderPredictor.currentRound
    );

    const activeView = useSelector((state) => state.ladderPredictor.activeView);

    const max = useSelector((state) =>
        activeView === CONFIG.SEASON
            ? state.ladderPredictor.progressBar.totalMatches
            : state.ladderPredictor.progressBar.totalFinalsMatches
    );

    const current = useSelector((state) =>
        activeView === CONFIG.SEASON
            ? state.ladderPredictor.predictions.length
            : state.ladderPredictor.predictionsFinals.filter(
                  (prediction) => prediction.c === false
              ).length
    );

    // Ensure matches ordered by round
    const matchesOrderedByRound = useMemo(() => {
        return [...matchesData].sort((a, b) => {
            return a.round?.roundNumber - b.round?.roundNumber;
        });
    }, [matchesData]);

    // Group matches by team and round
    const groupMatchesByTeamAndRound = useMemo(() => {
        if (matchesData) {
            const organised = {
                byTeam: {},
                byRound: {}
            };

            matchesData.forEach((match) => {
                const homeTeam = match.home.team.id;
                const awayTeam = match.away.team.id;
                const roundName = match.round.id;

                // Add match to the home team's bucket
                if (!organised.byTeam[homeTeam]) {
                    organised.byTeam[homeTeam] = [];
                }
                organised.byTeam[homeTeam].push(match);

                // Add match to the away team's bucket
                if (!organised.byTeam[awayTeam]) {
                    organised.byTeam[awayTeam] = [];
                }
                organised.byTeam[awayTeam].push(match);

                // Add match to the round's bucket
                if (!organised.byRound[roundName]) {
                    organised.byRound[roundName] = [];
                }
                organised.byRound[roundName].push(match);
            });
            return organised;
        }
        return [];
    }, [matchesData]);

    /**
     * Close the dropdown when clicking outside.
     */
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (resetRef.current && !resetRef.current.contains(event.target)) {
                setShowResetDropdown(false);
            }
        };

        window.addEventListener('mousedown', handleOutsideClick);
        return () =>
            window.removeEventListener('mousedown', handleOutsideClick);
    }, []);

    /**
     * Close the dropdown when clicking outside.
     */
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                autoFillRef.current &&
                !autoFillRef.current.contains(event.target)
            ) {
                setAutoFillDropdown(false);
            }
        };

        window.addEventListener('mousedown', handleOutsideClick);
        return () =>
            window.removeEventListener('mousedown', handleOutsideClick);
    }, []);

    /**
     * Close the dropdown when clicking outside.
     */
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                resetFinalsRef.current &&
                !resetFinalsRef.current.contains(event.target)
            ) {
                setShowResetFinalsDropdown(false);
            }
        };

        window.addEventListener('mousedown', handleOutsideClick);
        return () =>
            window.removeEventListener('mousedown', handleOutsideClick);
    }, []);

    /**
     * Calculate percentage complete.
     */
    const percentageComplete = useMemo(() => {
        if (max <= 0) {
            return 0;
        }
        return Math.min((current / max) * 100, 100);
    }, [current, max]);

    /**
     * Generate the progress bar label.
     */
    const progressLabel = getTranslation('label.ladderPredictor.progressLabel')
        .replace('{{current}}', current)
        .replace('{{max}}', max);

    /**
     * Toggle the auto-fill dropdown visibility.
     *
     * @param event
     */
    const toggleAutoFillDropdown = (event) => {
        event.stopPropagation();
        setAutoFillDropdown((prev) => !prev);
    };

    /**
     * Toggle the reset dropdown visibility.
     *
     * @param event
     */
    const toggleResetDropdown = (event) => {
        event.stopPropagation();
        setShowResetDropdown((prev) => !prev);
    };

    /**
     * Toggle the reset dropdown visibility.
     *
     * @param event
     */
    const toggleResetFinalsDropdown = (event) => {
        event.stopPropagation();
        setShowResetFinalsDropdown((prev) => !prev);
    };

    // Handle reset all
    const handleResetAll = () => {
        dispatch(removeAllPredictions());
        setShowResetDropdown(false);
    };

    // Handle reset by filer
    const handleResetbyFilter = () => {
        dispatch(removePredictionsByFilter());
        setShowResetDropdown(false);
    };

    // Handle auto-fill by filter
    const handleAutoFillbyFilter = () => {
        let matchesList = [];

        if (filter === CONFIG.ROUND) {
            matchesList = groupMatchesByTeamAndRound.byRound[currentRound.id];
        } else if (filter === CONFIG.TEAM) {
            matchesList = groupMatchesByTeamAndRound.byTeam[currentTeam.id];
        }

        dispatch(
            autoFillPredictions({
                matchList: matchesList
            })
        );
        setAutoFillDropdown(false);
    };

    // Handle autofill
    const handleAutoFill = () => {
        dispatch(
            autoFillPredictions({
                matchList: matchesOrderedByRound
            })
        );
        setAutoFillDropdown(false);
    };

    // Handle remove finals predictions
    const handResetFinalsPredictions = () => {
        dispatch(removeAllFinalsPredictions());
        setShowResetFinalsDropdown(false);
    };

    return (
        <div className="ladder-predictor-progress-bar">
            <div className="ladder-predictor-progress-bar__container">
                {max > 0 ? (
                    <p
                        className="ladder-predictor-progress-bar__label"
                        dangerouslySetInnerHTML={{ __html: progressLabel }}
                    ></p>
                ) : (
                    <p className="ladder-predictor-progress-bar__label">
                        {getTranslation(
                            'label.ladderPredictor.noMatchesToPredict'
                        )}
                    </p>
                )}
                <div className="ladder-predictor-progress-bar__bar">
                    <div
                        className="ladder-predictor-progress-bar__progress"
                        role="progressbar"
                        style={{ width: `${percentageComplete}%` }}
                        aria-valuenow={percentageComplete}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        aria-label={`${percentageComplete}% complete.`}
                    ></div>
                </div>
            </div>
            <div className="ladder-predictor-progress-bar__buttons">
                {activeView === CONFIG.SEASON && (
                    <>
                        <div
                            className="ladder-predictor-progress-bar__autofill"
                            ref={autoFillRef}
                        >
                            <button
                                className="filter-button filter-button--reverse"
                                disabled={current === max}
                                onClick={toggleAutoFillDropdown}
                            >
                                {getTranslation(
                                    'label.ladderPredictor.autoFill'
                                )}
                            </button>
                            {showAutoFillDropdown && (
                                <div className="ladder-predictor-progress-bar__reset-dropdown">
                                    <button
                                        className="ladder-predictor-progress-bar__reset-button"
                                        onClick={() => handleAutoFillbyFilter()}
                                    >
                                        {getTranslation(
                                            'label.ladderPredictor.autoFill'
                                        )}{' '}
                                        {filter.charAt(0).toUpperCase() +
                                            filter.slice(1)}
                                    </button>
                                    <button
                                        className="ladder-predictor-progress-bar__reset-button"
                                        onClick={handleAutoFill}
                                    >
                                        {getTranslation(
                                            'label.ladderPredictor.autoFillAll'
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>

                        <div
                            className="ladder-predictor-progress-bar__reset"
                            ref={resetRef}
                        >
                            <button
                                className="filter-button"
                                onClick={toggleResetDropdown}
                                disabled={current === 0}
                            >
                                <SvgIcon
                                    icon="refresh-alt"
                                    className="filter-button__icon"
                                />
                                {getTranslation('label.ladderPredictor.reset')}
                            </button>

                            {showResetDropdown && (
                                <div className="ladder-predictor-progress-bar__reset-dropdown">
                                    <button
                                        className="ladder-predictor-progress-bar__reset-button"
                                        onClick={() => handleResetbyFilter()}
                                    >
                                        {getTranslation(
                                            'label.ladderPredictor.reset'
                                        )}{' '}
                                        {filter.charAt(0).toUpperCase() +
                                            filter.slice(1)}
                                    </button>
                                    <button
                                        className="ladder-predictor-progress-bar__reset-button"
                                        onClick={handleResetAll}
                                    >
                                        {getTranslation(
                                            'label.ladderPredictor.resetAll'
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                )}
                {activeView === CONFIG.FINALS && (
                    <div
                        className="ladder-predictor-progress-bar__reset"
                        ref={resetFinalsRef}
                    >
                        <button
                            className="filter-button"
                            onClick={toggleResetFinalsDropdown}
                            disabled={current === 0}
                        >
                            <SvgIcon
                                icon="refresh-alt"
                                className="filter-button__icon"
                            />
                            {getTranslation('label.ladderPredictor.reset')}
                        </button>

                        {showResetFinalsDropdown && (
                            <div className="ladder-predictor-progress-bar__reset-dropdown">
                                <button
                                    className="ladder-predictor-progress-bar__reset-button"
                                    onClick={handResetFinalsPredictions}
                                >
                                    {getTranslation(
                                        'label.ladderPredictor.resetFinals'
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    matchesData: PropTypes.arrayOf(Object)
};
