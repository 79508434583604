import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { PlayerSelectionSearchResult } from './PlayerSelectionSearchResult';
import { playersSelector } from 'store/modules/statspro/player-comparison/selectors';

import { Loader } from 'common/react/components/Loader';
import { getTranslation } from 'common/react/utils/translations';

import { useGetPlayerProfileQuery } from 'store/modules/api/statsPro-api';

const RESULTS_TO_SHOW = 20;

export const PlayerSelectionSearchResults = ({
    isLoading,
    results,
    query,
    seasonId
}) => {
    const currentPlayers = useSelector(playersSelector);
    const [currentPlayersLength, setCurrentPlayersLength] = useState();

    const { data: playerProfile1Data } = useGetPlayerProfileQuery(
        { seasonId, playerId: Object.values(currentPlayers)[0] },
        { skip: !Object.values(currentPlayers)[0] }
    );

    const { data: playerProfile2Data } = useGetPlayerProfileQuery(
        { seasonId, playerId: Object.values(currentPlayers)[1] },
        { skip: !Object.values(currentPlayers)[1] }
    );

    // player profile data is stored in a useMemo, with a default value
    // of {} when data not available to stop component erroring
    const playerProfile1 = useMemo(() => {
        if (!Object.values(currentPlayers)[0]) {
            return {};
        }

        return {
            ...playerProfile1Data
        };
    }, [playerProfile1Data, currentPlayers]);

    const playerProfile2 = useMemo(() => {
        if (!Object.values(currentPlayers)[1]) {
            return {};
        }

        return {
            ...playerProfile2Data
        };
    }, [playerProfile2Data, currentPlayers]);

    useEffect(() => {
        if (
            Object.values(currentPlayers)[0] &&
            Object.values(currentPlayers)[1]
        ) {
            setCurrentPlayersLength(2);
        } else if (
            !Object.values(currentPlayers)[0] &&
            !Object.values(currentPlayers)[1]
        ) {
            setCurrentPlayersLength(0);
        } else {
            setCurrentPlayersLength(1);
        }
    }, [currentPlayers]);

    return (
        <div className="player-selection__results">
            <div className="player-selection__results-info">
                <span>
                    {getTranslation(
                        'label.statspro.playerComparison.addMultiplePlayers'
                    )}
                </span>
                <span>
                    {currentPlayersLength}{' '}
                    {getTranslation('label.of').toLowerCase()} 2
                </span>
            </div>

            <ul className="player-selection__current-players">
                {Object.keys(playerProfile1).length > 0 && (
                    <PlayerSelectionSearchResult
                        result={playerProfile1}
                        isFixed={true}
                    />
                )}
                {Object.keys(playerProfile2).length > 0 && (
                    <PlayerSelectionSearchResult
                        result={playerProfile2}
                        isFixed={true}
                    />
                )}
            </ul>

            <ul
                className={
                    'stats-search__results is-visible' +
                    (!results?.length ? ' has-no-results' : '')
                }
            >
                {isLoading ? (
                    <Loader />
                ) : results && results.length ? (
                    results.slice(0, RESULTS_TO_SHOW).map((result, i) => {
                        return (
                            <PlayerSelectionSearchResult
                                key={i}
                                result={result}
                                query={query}
                                count={i}
                            />
                        );
                    })
                ) : (
                    getTranslation('label.search.noResultsFound')
                )}
            </ul>
        </div>
    );
};

PlayerSelectionSearchResults.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    results: PropTypes.array,
    query: PropTypes.string,
    seasonId: PropTypes.string
};
