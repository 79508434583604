import React, { useEffect, useMemo, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import {
    assignPositions,
    composeColumnData,
    composeRowData,
    getLeaderboardWithAllMatches
} from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { useRoundMatches } from '../../../hooks/use-round-matches';
import {
    leaderboardFavouriteSelector,
    leaderboardFilterSelector,
    leaderboardNavSelector
} from 'common/store/modules/brownlow-tracker/leaderboard/selectors';
import FocusableTooltipArea from 'common/react/components/tooltip-button/focusable-tooltip/FocusableTootlipArea';
import { Table } from './components/Table';
import {
    useGetBrownlowPredictorQuery,
    useGetTeamsQuery
} from 'common/store/modules/api/afl-api';
import { SeasonContext } from './LeaderboardTableFetcher';
import { sortFavourites } from 'widgets/brownlow-predictor-v2/js/utils/utils';
import { TooltipContent } from '../../../Tooltip';
import {
    UPDATE_FAVOURITE_PLAYER,
    UPDATE_NAV_ITEM
} from 'common/store/modules/brownlow-tracker/leaderboard/actions';

export const LeaderboardTable = ({
    shouldHidePredicted,
    leaderboardData,
    fetchMoreData,
    setData,
    shownData,
    filteredData,
    pageSize,
    currentPage,
    shouldShowBettingOdds,
    bettingOddsData,
    showLeader,
    title
}) => {
    const dispatch = useDispatch();

    const [currentPlayerIds, setCurrentPlayerIds] = useState([]);

    const nav = useSelector(leaderboardNavSelector);
    const showPredictedData = nav?.showPredicted;

    const season = useContext(SeasonContext);
    const seasonPid = season?.providerId ?? '';

    const rounds = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list ?? []
    );

    const { data: teamsData } = useGetTeamsQuery(nav.season.id, {
        skip: !nav.season.id ? true : false
    });
    const teams = teamsData || [];

    const team = teams.find((item) => item.id === nav.team.id);
    const teamId = team?.providerId ?? '';

    const { matches: allSeasonMatches } = useRoundMatches({
        competitionId: nav.competition.id,
        compSeasonId: nav.season.id,
        pageSize: 250
    });

    const favourites = useSelector(leaderboardFavouriteSelector);
    const leaderboardFilter = useSelector(leaderboardFilterSelector);

    /**
     * On Player Favourite Click
     * Modify favourites on player add/remove
     *
     * @param {*} playerId
     */
    const onPlayerFavouriteClick = (playerId) => {
        dispatch(
            UPDATE_FAVOURITE_PLAYER.request({
                id: null,
                providerId: playerId
            })
        );
    };

    const onSortByFavouriteClick = (favourite) => {
        dispatch(
            UPDATE_NAV_ITEM.request({
                navItem: 'sortByFavourites',
                value: favourite
            })
        );
    };

    const leaderboardWithAllMatches = useMemo(() => {
        if (
            leaderboardData?.leaderboard.length &&
            allSeasonMatches?.length &&
            rounds
        ) {
            return getLeaderboardWithAllMatches(
                leaderboardData.leaderboard,
                allSeasonMatches,
                rounds
            );
        }

        return [];
    }, [rounds, leaderboardData, allSeasonMatches]);

    useEffect(() => {
        if (leaderboardWithAllMatches.length) {
            let items = leaderboardWithAllMatches.map((item) => ({
                eligible: item.eligible ?? false,
                player: {
                    ...item.player,
                    winner: item.winner ?? false
                },
                roundByRoundVotes: item.roundByRoundVotes ?? [],
                roundByRoundTotalVotes: item.roundByRoundTotalVotes ?? [],
                team: { ...item.team },
                totalVotes: item.totalVotes ?? '',
                bettingOdds: shouldShowBettingOdds
                    ? bettingOddsData?.outcomes?.find(
                          (outcome) => item.player.playerId === outcome.playerId
                      )?.price ?? null
                    : null
            }));
            items = assignPositions(items);
            if (favourites.length && nav.sortByFavourites) {
                items = sortFavourites(items, favourites, teamId);
            }
            setData(items);
        }
    }, [
        leaderboardWithAllMatches,
        favourites,
        nav?.sortByFavourites,
        teamId,
        bettingOddsData?.outcomes,
        shouldShowBettingOdds
    ]);

    /**
     * Get predictor data
     */
    const { data: predicted } = useGetBrownlowPredictorQuery(
        {
            compSeasonId: season?.id,
            players: currentPlayerIds?.join(',')
        },
        {
            skip: !(currentPlayerIds.length && season?.id)
        }
    );

    /**
     * Get columns
     */
    const columns = useMemo(() => composeColumnData(rounds), [rounds]);

    /**
     * Get rows
     */
    const rowData = useMemo(
        () =>
            shownData.map((item) =>
                composeRowData(
                    item,
                    columns.filter(
                        (col) =>
                            !['player', 'totalVotes', 'bettingOdds'].includes(
                                col.accessor
                            )
                    ),
                    rounds,
                    false,
                    predicted?.results
                )
            ),
        [columns, shownData, rounds, predicted?.results]
    );

    // ===== Fetch Predictor data ================================ //
    useEffect(() => {
        // only request the new items
        const playerDataItems = [...rowData].splice(
            pageSize * (currentPage - 1)
        );

        const playerPulseIdPromises = playerDataItems.map((row) => {
            return PULSE.app.common.pulseId
                .getPlayerPulseId(row.player.playerId)
                .then((res) => {
                    return res;
                });
        });

        Promise.all(playerPulseIdPromises).then((res) => {
            setCurrentPlayerIds(res);
        });
    }, [rowData]);

    // ===== Uses weeks or rounds ================================ //
    const isWeekly =
        (rounds?.length && rounds[0].name.toLowerCase().includes('week')) ||
        false;

    // ===== Render ============================================== //
    return (
        <FocusableTooltipArea>
            <Table
                key={rowData[0]?.player.playerId}
                columns={columns}
                data={rowData}
                fullData={filteredData}
                modifier={`brownlow-tracker-leaderboard ${
                    shouldShowBettingOdds
                        ? 'stats-table--brownlow-tracker-leaderboard--show-odds'
                        : ''
                }`}
                update={fetchMoreData}
                seasonPid={seasonPid}
                showLeader={showLeader}
                favourites={favourites}
                setSortByFavourites={onSortByFavouriteClick}
                sortByFavourites={nav?.sortByFavourites}
                onPlayerFavouriteClick={onPlayerFavouriteClick}
                filteredResults={leaderboardFilter?.query?.length > 0}
                showPredictedData={showPredictedData}
                isBrownlowTracker={true}
                shouldHidePredicted={shouldHidePredicted}
                isWeekly={isWeekly}
                primaryTooltipComponent={(row, cell) => (
                    <TooltipContent
                        row={row.original}
                        cell={cell}
                        player={row.original?.player.playerId}
                        seasonPid={seasonPid}
                        leaderboardData={leaderboardData}
                    />
                )}
                title={title}
            />
        </FocusableTooltipArea>
    );
};

LeaderboardTable.propTypes = {
    fetchMoreData: PropTypes.func,
    setData: PropTypes.func,
    leaderboardData: PropTypes.object.isRequired,
    filteredData: PropTypes.array.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    shownData: PropTypes.array.isRequired,
    shouldShowBettingOdds: PropTypes.bool,
    showLeader: PropTypes.bool,
    shouldHidePredicted: PropTypes.bool,
    bettingOddsData: PropTypes.object,
    title: PropTypes.string
};
