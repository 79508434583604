import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

export const FixtureLinkButton = ({
    href,
    label = '',
    icon = '',
    theme = '',
    target = '_self',
    cssClass = '',
    onClick = null
}) => {
    if (onClick && !href) {
        return (
            <button
                aria-label={label}
                className={`fixtures__link-button button-new ${
                    theme ? 'fixtures__link-button--' + theme : ''
                } ${cssClass}`}
                onClick={onClick}
            >
                <SvgIcon
                    icon={icon}
                    className={`icon fixtures__button-icon ${
                        theme ? 'fixtures__button-icon--' + theme : ''
                    }`}
                />
                <span>{label}</span>
            </button>
        );
    }
    return (
        <a
            href={href}
            target={target}
            title={label}
            className={`fixtures__link-button button-new ${
                theme ? 'fixtures__link-button--' + theme : ''
            } ${cssClass}`}
            onClick={onClick}
        >
            <SvgIcon
                icon={icon}
                className={`icon fixtures__button-icon ${
                    theme ? 'fixtures__button-icon--' + theme : ''
                }`}
            />
            <span>{label}</span>
        </a>
    );
};

FixtureLinkButton.propTypes = {
    href: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    theme: PropTypes.string,
    target: PropTypes.string,
    cssClass: PropTypes.string,
    onClick: PropTypes.func
};
