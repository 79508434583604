import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from 'react-redux';
import { ContentGrid } from '../components/ContentGrid';

export const ContentGridProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <ContentGrid {...data} />
        </Provider>
    );
};

ContentGridProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
