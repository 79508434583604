import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const TeamLineupsSkeleton = ({ items }) => {
    return (
        <>
            <h2 className="fixtures__date-header">
                <Skeleton width={200} />
            </h2>
            <Skeleton className="team-lineups__item-skeleton" count={items} />
        </>
    );
};

TeamLineupsSkeleton.propTypes = {
    items: PropTypes.number.isRequired
};
