import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { PlayerSearchInput } from 'common/react/components/PlayerSearchInput';

export const Search = () => {
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState(query);
    const [startSearchFired, setStartSearchFired] = useState(false);

    const elementRef = useRef('');

    const dispatch = useDispatch();

    const handleElementChange = (element) => {
        elementRef.current = element;
    };

    const onSearchSubmit = async (newQuery) => {
        dispatch(
            PULSE.app.redux.actions.draftTracker.updateNav({
                name: 'filter',
                value: newQuery
            })
        );
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setQuery(debouncedQuery.trim());

            if (debouncedQuery.trim().length > 0 && !startSearchFired) {
                PULSE.app.analytics.addSearchTracking(elementRef.current);
                setStartSearchFired(true);
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    useEffect(() => {
        if (query !== '' && query.length > 2) {
            onSearchSubmit(query);
        } else {
            onSearchSubmit('');
        }
    }, [query]);

    return (
        <PlayerSearchInput
            setDebouncedQuery={setDebouncedQuery}
            onElementChange={handleElementChange}
        />
    );
};
