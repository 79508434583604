import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

/*
 * Render match card header
 */
export const MatchCardHeader = ({
    showComplete,
    startDateTime,
    venueName,
    showDate
}) => {
    const selectedRegion = useSingleSiteSetting('region');
    const startTime = moment(startDateTime);

    return (
        <div className="ladder-predictor-match-card__header">
            <p className="ladder-predictor-match-card__detail">
                {startDateTime ? (
                    <>
                        {startTime
                            .tz(selectedRegion.timezone)
                            .format(
                                PULSE.app.common.CONSTANTS.MATCH_TIME_FORMAT
                            )}

                        <span className="ladder-predictor-match-card__detail-time-ampm">
                            {startTime.tz(selectedRegion.timezone).format('a')}
                        </span>

                        {showDate &&
                            ' · ' +
                                startTime
                                    .tz(selectedRegion.timezone)
                                    .format('MMM D')}
                    </>
                ) : (
                    getTranslation('label.ladderPredictor.finalsCard.DateBC')
                )}{' '}
                ·{' '}
                {venueName
                    ? venueName
                    : getTranslation(
                          'label.ladderPredictor.finalsCard.StadiumTBC'
                      )}
            </p>
            {showComplete && (
                <p className="ladder-predictor-match-card__complete-label">
                    {getTranslation('label.ladderPredictor.complete')}
                </p>
            )}
        </div>
    );
};

MatchCardHeader.propTypes = {
    showComplete: PropTypes.bool.isRequired,
    startDateTime: PropTypes.string,
    venueName: PropTypes.string,
    showDate: PropTypes.bool.isRequired
};
