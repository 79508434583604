import React from 'react';
import DefaultCellRenderer from 'custom-table/js/components/DefaultCell/DefaultCellRenderer';
import PropTypes from 'prop-types';
import { getCellRenderer } from './TableRow';

import RankingsTotalTableRow from 'custom-table/js/components/RankingsTable/TotalTableRow';

const getTotalTableRowRenderer = (rowRenderer) => {
    switch (rowRenderer) {
        case 'RankingsTotalTableRow':
            return RankingsTotalTableRow;
        default:
            return;
    }
};

const TotalTableRow = ({
    sortedTableData,
    state,
    customTable,
    displayedFields,
    totalTableRows,
    getTranslation
}) => {
    if (!sortedTableData || !customTable.config.totalTableRow) {
        return null;
    }
    let tableRowMarkup;

    if (customTable.config.totalTableRow.tableRowRenderer) {
        tableRowMarkup = React.createElement(
            getTotalTableRowRenderer(
                customTable.config.totalTableRow.tableRowRenderer
            ),
            {
                displayedFields: displayedFields,
                sortedTableData: sortedTableData,
                totalTableRows: totalTableRows,
                customTable: customTable,
                state: state,
                getTranslation: getTranslation
            }
        );
    } else {
        const fieldsToDisplay = displayedFields.reduce((accum, field) => {
            let totalColValue;
            sortedTableData.forEach((tableRow) => {
                const value = tableRow[field.fieldName];

                if (!isNaN(value)) {
                    totalColValue = totalColValue + value;
                }
            });

            let tableCellClass = field.tableCellCustomClass
                ? field.tableCellCustomClass
                : '';
            tableCellClass =
                customTable.sortBy.fieldName === field.fieldName
                    ? tableCellClass + ' custom-table__cell--sorted'
                    : tableCellClass;

            if (totalColValue) {
                return [
                    ...accum,
                    {
                        customCellRenderer: field.customCellRenderer,
                        value: totalColValue,
                        fieldName: field.fieldName,
                        tableCellClass: tableCellClass
                    }
                ];
            }

            return [];
        }, []);

        tableRowMarkup = fieldsToDisplay.map((field, index) => {
            if (field.customCellRenderer) {
                // use the custom renderer; the 2nd argument can also be used to pass custom props
                return React.createElement(
                    getCellRenderer(field.customCellRenderer),
                    {
                        value: field.value,
                        key: `tableRow_Total_${displayedFields[index].fieldName}`,
                        customTable: customTable,
                        field: field,
                        getTranslation: getTranslation
                    }
                );
            }

            // use the default renderer
            return (
                <DefaultCellRenderer
                    className={field.tableCellClass}
                    value={field.value}
                    key={`tableRow_Total_${displayedFields[index].fieldName}`}
                />
            );
        });
    }

    return (
        <tr className="custom-table__row custom-table__row--totals">
            {tableRowMarkup}
            <td
                className={`custom-table__cell custom-table__cell--empty u-hide-until-tablet ${
                    state.manageFieldsBarDisplayed === true ? '' : 'u-hide'
                }`}
            ></td>
        </tr>
    );
};

export default TotalTableRow;

TotalTableRow.propTypes = {
    sortedTableData: PropTypes.array,
    state: PropTypes.object,
    customTable: PropTypes.object,
    displayedFields: PropTypes.array,
    totalTableRows: PropTypes.array,
    getTranslation: PropTypes.func
};
