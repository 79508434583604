import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import LadderPredictor from '../components/LadderPredictor';

export const LadderPredictorProvider = ({ store, data }) => {
    return (
        <Provider store={store}>
            <LadderPredictor {...data} />
        </Provider>
    );
};

LadderPredictorProvider.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object
};
