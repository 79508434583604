/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { StatsTableBody } from 'widgets/statspro/js/components/common/stats-table/StatsTableBody';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { getTranslation } from 'common/react/utils/translations';
import { PlayerPhoto } from 'common/react/components/PlayerPhoto';
import {
    draftTrackerFilterSelector,
    draftTrackerNavSelector
} from 'common/store/modules/draft-tracker/leaderboard/selectors';

/**
 * @param {object} props - Props.
 * @param {object[]} props.rows - Rows to render.
 * @param {Function} props.getTableBodyProps - Get table body props function.
 * @param {Function} props.prepareRow - Prepare row function.
 * @param props.status
 * @param props.title
 * @returns {JSX.Element} Component.
 */
export const TableBody = ({
    rows,
    getTableBodyProps,
    prepareRow,
    status,
    title
}) => {
    const dispatch = useDispatch();

    const query = useSelector(draftTrackerFilterSelector);
    const nav = useSelector(draftTrackerNavSelector);

    // Activate the player modal
    const activatePlayerModal = (event, playerData, index) => {
        if (
            event.keyCode &&
            event.keyCode !== PULSE.app.common.CONSTANTS.KEY_CODES.ENTER &&
            event.keyCode !== PULSE.app.common.CONSTANTS.KEY_CODES.SPACE
        ) {
            return;
        }

        dispatch(
            PULSE.app.redux.actions.draftTracker.updateModal({
                visible: true,
                data: playerData
            })
        );
        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.VIEW,
            {
                eventData: {
                    /* eslint-disable-next-line camelcase */
                    modal_name: `${playerData?.player?.fullName} draft tracker modal`
                }
            }
        );

        if (query?.length) {
            PULSE.app.analytics.playerSelectionTracking(
                query,
                '',
                title ? title : 'Draft tracker',
                `${playerData?.player?.fullName}`,
                index,
                composeFiltersString(nav)
            );
        }
    };
    return (
        <StatsTableBody
            rows={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            getTrProps={(row) => {
                let classes = 'stats-table__body-row';

                if (row.values.player.selecting) {
                    classes =
                        classes +
                        ' stats-table__row--is-selecting ' +
                        row.values.club;
                }

                return {
                    className: classes
                };
            }}
            customCells={{
                pick: (row, cell, cellProps) => (
                    <th {...cellProps} className="stats-table__row-header-cell">
                        {cell.value}
                    </th>
                ),
                club: (row, cell, cellProps) => (
                    <td {...cellProps} className="stats-table__cell">
                        <div className="draft-tracker-leaderboard__club-icon-container">
                            <TeamIcon
                                className={
                                    'icon draft-tracker-leaderboard__club-icon'
                                }
                                teamAbbreviation={cell.value}
                            />
                            <TeamIcon
                                className={
                                    'icon draft-tracker-leaderboard__club-icon draft-tracker-leaderboard__club-icon--light'
                                }
                                teamAbbreviation={cell.value}
                                theme={PULSE.app.common.team.getTeamLogoTheme(
                                    cell.value
                                )}
                            />
                        </div>
                    </td>
                ),
                player: (row, cell, cellProps) => {
                    const PlayerContent = (
                        <>
                            <PlayerPhoto
                                photoUrl={
                                    cell.value.photoUrl
                                        ? cell.value.photoUrl
                                        : ''
                                }
                                scale={'0.2'}
                                alt={`${getTranslation(
                                    'label.stats.headshot.alt'
                                )} ${cell.value.fullName}`}
                            />
                            <span className="draft-tracker-leaderboard__player-name">
                                {cell.value.fullName}
                            </span>
                        </>
                    );

                    return (
                        <td
                            {...cellProps}
                            className="stats-table__cell stats-table__cell--player"
                        >
                            <div className="draft-tracker-leaderboard__player">
                                {!cell.value.passed ? (
                                    <>
                                        {cell.value.selecting ? (
                                            status ===
                                            PULSE.app.common.CONSTANTS
                                                .DRAFT_TRACKER_STATUS.PAUSED ? (
                                                getTranslation(
                                                    'label.draftTracker.paused'
                                                )
                                            ) : (
                                                cell.value.selecting
                                            )
                                        ) : cell.value.profileUrl ? (
                                            <button
                                                className="draft-tracker-leaderboard__player-link--button"
                                                onClick={(event) =>
                                                    activatePlayerModal(
                                                        event,
                                                        cell?.row?.original,
                                                        rows?.length - 1
                                                    )
                                                }
                                            >
                                                {PlayerContent}
                                            </button>
                                        ) : (
                                            PlayerContent
                                        )}
                                        {cell.value.fatherSon && (
                                            <span className="draft-tracker-leaderboard__father-son">
                                                {getTranslation(
                                                    'label.draftTracker.fatherSon.abbreviation'
                                                )}
                                            </span>
                                        )}
                                        {cell.value.academy && (
                                            <span className="draft-tracker-leaderboard__academy">
                                                {getTranslation(
                                                    'label.draftTracker.academy.abbreviation'
                                                )}
                                            </span>
                                        )}
                                    </>
                                ) : (
                                    <span className="draft-tracker-leaderboard__passed">
                                        {getTranslation(
                                            'label.draftTracker.passed'
                                        )}
                                    </span>
                                )}
                            </div>
                        </td>
                    );
                }
            }}
        />
    );
};

TableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    status: PropTypes.string,
    title: PropTypes.string
};

const composeFiltersString = (nav) => {
    let round = '';
    let club = '';
    let position = '';
    if (nav?.round !== -1) {
        round = nav.round.toString();
    }
    if (nav?.club !== -1) {
        club = PULSE.app.common.team.getTeamName(nav.club);
    }
    if (nav?.position !== -1) {
        position = nav.position;
    }

    return `${round && `round: ${round}, `}${club && `club: ${club}, `}${
        position && `position: ${position}`
    }`;
};
