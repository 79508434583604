import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const PlayerProfileLink = ({
    firstPlayer = {},
    player = {},
    isAFLW,
    classes,
    trackingInfo,
    isExternal = false,
    children
}) => {
    const [playerURL, setPlayerURL] = useState('');

    useEffect(() => {
        if (player?.playerId) {
            PULSE.app.common.pulseId
                .getPlayerPulseId(player.playerId)
                .then((id) => {
                    setPlayerURL(
                        PULSE.app.common.url.getPlayerProfileURL(
                            {
                                firstName: player.firstName.toLowerCase(),
                                surname: player.surname.toLowerCase(),
                                id: id
                            },
                            isAFLW
                        )
                    );
                });
        }
    }, [player.playerId]);

    /**
     * Link click handler
     * triggers various tracking functions depending on the `trackingInfo` values
     */
    const onClick = () => {
        /* eslint-disable camelcase */
        if (trackingInfo?.searchTerm) {
            // tracking in context of players search
            PULSE.app.analytics.playerSelectionTracking(
                trackingInfo.searchTerm,
                playerURL,
                trackingInfo.component_name,
                `${player?.firstName} ${player?.surname}`,
                trackingInfo.count,
                ''
            );
        } else if (trackingInfo) {
            if (
                trackingInfo.event ===
                PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.LINK
            ) {
                // tracking as select_link
                const url = new URL(playerURL, location.origin);
                PULSE.app.tracking.recordEvent(
                    PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.LINK,
                    {
                        eventData: {
                            link_text: `${player?.firstName} ${player?.surname}`,
                            link_url: playerURL,
                            outbound: url.origin !== location.origin,
                            type:
                                url.origin !== location.origin
                                    ? 'external'
                                    : 'internal',
                            component_name:
                                trackingInfo.component_name || 'team lineups',
                            component_content_type:
                                trackingInfo.component_content_type
                        }
                    }
                );
            } else {
                // tracking as select_card
                PULSE.app.tracking.selectPlayerCardHandler(
                    firstPlayer?.player?.givenName
                        ? firstPlayer?.player?.givenName +
                              ' ' +
                              firstPlayer?.player?.surname
                        : player?.firstName + ' ' + player?.surname,
                    trackingInfo?.component_name,
                    PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES.PLAYER,
                    `${player?.firstName} ${player?.surname}`,
                    playerURL,
                    trackingInfo?.index,
                    trackingInfo?.count
                );
            }
        }
        /* eslint-enable camelcase */
    };

    return playerURL ? (
        <a
            className={classes}
            href={playerURL}
            onClick={onClick}
            target={isExternal ? '_blank' : null}
            rel={isExternal ? 'noreferrer' : null}
        >
            {children}
        </a>
    ) : (
        <></>
    );
};

/* eslint-disable camelcase */
PlayerProfileLink.propTypes = {
    firstPlayer: PropTypes.object,
    player: PropTypes.shape({
        playerId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired
    }).isRequired,
    isAFLW: PropTypes.bool,
    classes: PropTypes.string,
    trackingInfo: PropTypes.shape({
        event: PropTypes.string,
        component_name: PropTypes.string,
        component_content_type: PropTypes.string,
        index: PropTypes.number,
        count: PropTypes.number,
        searchTerm: PropTypes.string
    }),
    children: PropTypes.any,
    isExternal: PropTypes.bool
};
