import React from 'react';
import PropTypes from 'prop-types';

import { TeamIcon } from 'common/react/components/TeamIcon';
import { MatchCardHeader } from '../components/header';
import { MatchCardFooter } from '../components/footer';
import { generateTBCCardText } from 'widgets/ladder-predictor-v2/js/utils/generate-bracket';

/*
 * Render finals awaiting match card
 */
export const MatchCardFinalAwaiting = ({ finalsStage, index }) => {
    const cardText = generateTBCCardText(finalsStage, index);
    return (
        <div className="ladder-predictor-match-card">
            <MatchCardHeader showComplete={false} showDate={true} />
            <div className="ladder-predictor-match-card__teams">
                <div className="ladder-predictor-match-card__team">
                    <TeamIcon
                        className="ladder-predictor-match-card__team-logo ladder-predictor-match-card__team-logo--placeholder"
                        providerId={''}
                    />
                    <p
                        className="ladder-predictor-match-card__name"
                        aria-label="Team Name"
                    >
                        {cardText.topText}
                    </p>
                </div>
                <div className="ladder-predictor-match-card__team">
                    <TeamIcon
                        className="ladder-predictor-match-card__team-logo ladder-predictor-match-card__team-logo--placeholder"
                        providerId={''}
                    />
                    <p
                        className="ladder-predictor-match-card__name"
                        aria-label="Team Name"
                    >
                        {cardText.bottomText}
                    </p>
                </div>
            </div>
            <MatchCardFooter
                matchName={cardText.roundName}
                showComplete={false}
            />
        </div>
    );
};

MatchCardFinalAwaiting.propTypes = {
    finalsStage: PropTypes.oneOf(['semiFinal', 'preliminary', 'grandFinal']),
    index: PropTypes.number
};
