import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { ImageElement } from './image';

export const MediaThumbnail = ({ video, index }) => {
    const ref = useRef();

    const VARIANT_CONFIG = [
        {
            name: 'thumbnailMobile',
            maxWidth: 640,
            size: { width: 120 },
            multipliers: [1, 2, 3]
        },
        {
            name: 'thumbnailDesktop',
            minWidth: 641,
            size: { width: 160 },
            multipliers: [1, 2, 3]
        },
        {
            name: 'default',
            size: { width: 160 },
            multipliers: [1]
        }
    ];

    const videoData = PULSE.app.common.content.getContentModel(
        video,
        VARIANT_CONFIG
    );

    const videoUrlParams = {
        pageSize: 50,
        tagNames: `ProgramCategory:${videoData.tags
            .find((item) => item.label.includes('ProgramCategory:'))
            ?.label.replace('ProgramCategory:', '')}`,
        references: ['AFL_COMPSEASON', 'AFL_ROUND']
            .map((reference) => {
                const refValue = video.references.find(
                    (item) => item.type === reference
                );
                if (refValue) {
                    return `${reference}:${refValue.id}`;
                }
                return null;
            })
            .filter((reference) => !!reference)
            .join(',')
    };

    const videoLink = `${videoData.link}&${PULSE.core.url.buildQueryString(
        videoUrlParams
    )}`;

    const videoModalData = {
        contentId: videoData.id,
        mediaId: videoData.mediaId,
        mediaTitle: PULSE.app.templating.helper.cleanString(videoData.title),
        mediaDescription: PULSE.app.templating.helper.cleanString(
            videoData.description
        ),
        subtitle: PULSE.app.templating.helper.cleanString(videoData.subtitle),
        type: videoData.type,
        link: videoLink,
        publishFrom: videoData.publishFrom + 1,
        mediaDate: PULSE.app.templating.helper.getDateNice(
            videoData.publishFrom
        )
    };

    const cardClickHandler = (evt) => {
        const cardLink = evt.target;
        const container = cardLink.closest('.video-hub');
        const cardLinks = container.querySelectorAll(
            '.js-media-thumbnail__link'
        );
        cardLink.title = container.querySelector(
            '.media-thumbnail__title'
        ).textContent;

        PULSE.app.tracking.trackCardClicks(
            container,
            cardLink,
            cardLinks.length
        );
    };

    useEffect(() => {
        if (ref.current) {
            PULSE.app.VideoPlayerModal.createInstance(ref.current);
            ref.current.dataset.modalAdded = true;
        }
    }, []);

    return (
        <article className={`media-thumbnail`}>
            <div className="media-thumbnail__link">
                <a
                    ref={ref}
                    className={`media-thumbnail__absolute-link js-media-thumbnail__link`}
                    data-ui-modal="#VideoModal"
                    href={videoLink}
                    data-content-id={videoData.id}
                    data-ui-args={JSON.stringify(videoModalData)}
                    data-index-position={index + 1}
                    onClick={cardClickHandler}
                    data-content-type={
                        PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES.VIDEO
                    }
                ></a>
                <header
                    className={`media-thumbnail__image-container placeholder placeholder--small placeholder--dark`}
                >
                    <div className="js-lazy-load lazy-image-wrapper is-loaded">
                        <ImageElement
                            ImageUrl={videoData.onDemandUrl}
                            ImageTitle={videoData.title}
                        />
                    </div>

                    <div className={`media-thumbnail__video-duration`}>
                        <SvgIcon
                            icon={'video-play-small'}
                            className={`icon media-thumbnail__duration-icon`}
                        />
                        <span className={`media-thumbnail__duration-text`}>
                            {PULSE.app.common.date.durationToTime(
                                videoData.duration
                            )}
                        </span>
                    </div>
                </header>
                <div className={`media-thumbnail__caption`}>
                    <h2 className={`media-thumbnail__title`}>
                        {videoData.title}
                    </h2>
                </div>
            </div>
        </article>
    );
};

MediaThumbnail.propTypes = {
    video: PropTypes.object,
    index: PropTypes.number
};
