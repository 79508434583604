import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectLadder } from '../../../selector/ladder';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { getTranslation } from 'common/react/utils/translations';

export const PredictedLadderMovementCallout = () => {
    const ladder = useSelector(selectLadder);
    const [changedTeams, setChangedTeams] = useState([]);
    const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
    const intervalRef = useRef(null);

    useEffect(() => {
        // Extract teams with position changes (UP or DOWN)
        const teamsWithChanges = ladder.filter(
            (team) =>
                team.positionChange === 'UP' || team.positionChange === 'DOWN'
        );

        if (teamsWithChanges.length > 0) {
            setChangedTeams(teamsWithChanges);
        } else {
            setChangedTeams([]);
        }
        setCurrentTeamIndex(0);
    }, [ladder]);

    useEffect(() => {
        if (changedTeams.length > 0 && currentTeamIndex < changedTeams.length) {
            // Set interval to display each team for 2 seconds
            intervalRef.current = setInterval(() => {
                setCurrentTeamIndex((prevIndex) => {
                    const nextIndex = prevIndex + 1;
                    if (nextIndex >= changedTeams.length) {
                        clearInterval(intervalRef.current);
                        setChangedTeams([]);
                    }
                    return nextIndex;
                });
            }, 2000);
        }

        // Cleanup the interval when the component unmounts or changes
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [changedTeams]);

    const currentTeam = changedTeams[currentTeamIndex];

    return (
        <div className="ladder-table-drawer__preview-row">
            {currentTeam ? (
                <>
                    <div className="ladder-table-drawer__preview-row-position">
                        <div className="ladder-table-drawer__preview-row-position-value">
                            {currentTeam.position}
                        </div>
                        <div className="ladder-table-drawer__preview-row-position-change">
                            {currentTeam.positionChange === 'UP' && (
                                <SvgIcon
                                    icon="pos-change-up"
                                    className="ladder-table-drawer__preview-row-position-change-icon"
                                />
                            )}
                            {currentTeam.positionChange === 'DOWN' && (
                                <SvgIcon
                                    icon="pos-change-down"
                                    className="ladder-table-drawer__preview-row-position-change-icon"
                                />
                            )}
                            {currentTeam.positionChange === 'NONE' && (
                                <SvgIcon
                                    icon="pos-change-none"
                                    className="ladder-table-drawer__preview-row-position-change-icon"
                                />
                            )}
                        </div>
                    </div>
                    <div className="ladder-table-drawer__preview-row-name">
                        <TeamIcon
                            className="ladder-predictor-match-card__team-logo"
                            providerId={currentTeam.team.providerId}
                        />

                        <div className="ladder-table-drawer__preview-row-name-value">
                            {currentTeam.team.name}
                        </div>
                    </div>
                    <div className="ladder-table-drawer__preview-row-played">
                        {currentTeam.played}
                    </div>
                    <div className="ladder-table-drawer__preview-row-points">
                        {currentTeam.thisSeasonRecord.aggregatePoints}
                    </div>
                    <div className="ladder-table-drawer__preview-row-win-percentage">
                        {currentTeam.thisSeasonRecord.percentage}%
                    </div>
                </>
            ) : (
                <div className="ladder-table-drawer__preview-row-placeholder">
                    {getTranslation(`label.ladderPredictor.previewText`)}
                </div>
            )}
        </div>
    );
};
