import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { MediaThumbnail } from './media-thumbnail';
import { EmptyState } from 'common/react/components/EmptyState';
import { useGetContentQuery } from 'store/modules/api/content-api';
import { Loader } from 'common/react/components/Loader';

export const VideoListing = ({ seasonId, roundId, matchID, tagName }) => {
    const requestConfig = {
        type: 'VIDEO',
        params: {
            pageSize: 50,
            referenceExpression: `AFL_COMPSEASON:${seasonId} and AFL_ROUND:${roundId} ${
                matchID ? `and AFL_MATCH:${matchID}` : ''
            }`,
            tagNames: matchID ? '' : tagName
        }
    };

    const { data: requestData = [], isFetching } =
        useGetContentQuery(requestConfig);
    const videoData = requestData?.content;

    useEffect(() => {
        const viewByMatchContainer = document.querySelector(
            '.js-video-hub-view-by-match'
        );
        const roundHeader = document.querySelector('.js-round-header');
        const videoFilters = document.querySelector('.js-video-filters');
        if (!videoData || !videoData.length) {
            viewByMatchContainer.classList.add('u-hide');

            if (roundHeader) {
                roundHeader.classList.add('u-hide');
            }

            if (videoFilters) {
                videoFilters.classList.add('u-hide');
            }
        } else {
            viewByMatchContainer.classList.remove('u-hide');

            if (roundHeader) {
                roundHeader.classList.remove('u-hide');
            }

            if (videoFilters) {
                videoFilters.classList.remove('u-hide');
            }
        }
    }, [videoData]);

    return (
        <div>
            {isFetching ? (
                <Loader />
            ) : !videoData || !videoData.length ? (
                <div className="key-stats-and-performance__empty-state">
                    <EmptyState />
                </div>
            ) : (
                <ol className="media-list__list video-hub-video-listing__list block-list-4 block-list-3-tablet block-list-1-phablet js-media-list">
                    {videoData.map((videoDetail, index) => {
                        return (
                            <li
                                className="media-list__item video-hub-video-listing__item "
                                key={videoDetail.id}
                            >
                                <MediaThumbnail
                                    video={videoDetail}
                                    index={index}
                                />
                            </li>
                        );
                    })}
                </ol>
            )}
        </div>
    );
};

VideoListing.propTypes = {
    videoData: PropTypes.array,
    seasonId: PropTypes.number,
    roundId: PropTypes.number,
    matchID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tagName: PropTypes.string
};
