import React from 'react';
import PropTypes from 'prop-types';

export const ModalNote = ({ text, isEmbedded, cssModifier }) => {
    return (
        <div
            className={`modal-note modal-note--${cssModifier} ${
                isEmbedded ? 'modal-note--is-embedded' : ''
            } ${
                cssModifier ? `modal-note modal-note--${cssModifier}` : ''
            }`.trim()}
        >
            {text ? <p className="modal-note__text">{text}</p> : null}
        </div>
    );
};

ModalNote.propTypes = {
    text: PropTypes.string.isRequired,
    isEmbedded: PropTypes.bool,
    cssModifier: PropTypes.string
};
