import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TooltipHeader } from './TooltipHeader';
import { TooltipVotes } from './TooltipVotes';
import { TooltipStats } from './TooltipStats';
import { TooltipLink } from './TooltipLink';
import { TooltipLoading } from './TooltipLoading';
import { FETCH_TOOLTIP_PLAYER_STATS } from 'store/modules/brownlow-tracker/tooltip/actions';
import {
    tooltipDataSelector,
    tooltipRequestsSelector
} from 'store/modules/brownlow-tracker/tooltip/selectors';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @returns {JSX.Element} Component.
 */
export const TooltipContent = (props) => {
    const dispatch = useDispatch();
    const data = useSelector(tooltipDataSelector);
    const playerData = data?.[props.seasonPid]?.[props.player];
    const requests = useSelector(tooltipRequestsSelector);

    const tooltipContentRef = useRef();

    useEffect(() => {
        if (!playerData) {
            dispatch(
                FETCH_TOOLTIP_PLAYER_STATS.request({
                    seasonPid: props.seasonPid,
                    playerId: props.player
                })
            );
        }
    }, [props.player, props.seasonPid]);

    useEffect(() => {
        if (tooltipContentRef.current) {
            tooltipContentRef.current.focus();
        }
    }, [tooltipContentRef.current, playerData]);

    return (
        <>
            {playerData && !requests.length ? (
                <div key={playerData.player.playerId} ref={tooltipContentRef}>
                    {playerData.player && (
                        <TooltipHeader player={playerData.player} />
                    )}
                    <TooltipVotes
                        {...props}
                        player={playerData.player}
                        rounds={playerData.rounds}
                    />
                    <TooltipStats
                        {...props}
                        player={playerData.player}
                        rounds={playerData.rounds}
                    />
                    <TooltipLink
                        {...props}
                        player={playerData.player}
                        rounds={playerData.rounds}
                    />
                </div>
            ) : (
                <TooltipLoading />
            )}
        </>
    );
};

TooltipContent.propTypes = {
    row: PropTypes.object.isRequired,
    cell: PropTypes.object.isRequired,
    benchmark: PropTypes.string,
    seasonPid: PropTypes.string,
    player: PropTypes.string
};
