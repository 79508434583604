import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import {
    CompNavItem,
    ResponsiveSeasonNavItem,
    RoundListNavItem,
    SeasonNavItem
} from 'common/react/components/competition-nav/nav-items';
import { navSelector } from 'common/store/modules/competition-nav/selectors';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { SettingsButton } from 'common/react/components/SettingsButton';
import { useNavSync } from 'common/react/components/competition-nav/hooks/use-nav-sync';
import {
    CityFilterItem,
    TeamFilterItem,
    VenueFilterItem
} from 'common/react/components/competition-nav/filter-items';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';
import { ExpandLineupsToggle } from './components/ExpandLineupsToggle';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useFiltersTracking } from 'common/react/hooks/use-filters-tracking';

const TOGGLE_WRAP_MAX_WIDTH = 1125;

export const TeamLineupsNav = ({
    competitionId,
    seasonId,
    expandLineups,
    setExpandLineups,
    title,
    fixtureParam
}) => {
    const showResponsiveItems = useMediaQuery({
        maxWidth: PULSE.app.measurements.desktop
    });

    const wrapToggle = useMediaQuery({
        maxWidth: TOGGLE_WRAP_MAX_WIDTH
    });

    const initialValues = fixtureParam
        ? fixtureParam
        : {
              competitionId,
              seasonId
          };

    const nav = useSelector(navSelector);
    const options = useCompetitionNavigationOptions(nav);

    useNavSync({ ...options, initialValues });

    const competitions = options.competitions;

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    const currentFilters = useGetCurrentCompFilters();
    const competitionNavRef = useRef();

    useFiltersTracking({
        title,
        filtersState: {
            ...nav,
            expandLineups
        },
        options,
        filtersFields: [
            'competition',
            'season',
            'round',
            'teams',
            'venues',
            'cities',
            'expandLineups'
        ],
        filtersRef: competitionNavRef
    });

    return (
        <div className="competition-nav" ref={competitionNavRef}>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <CompNavItem
                            competitions={competitions}
                            title={title}
                        />
                        {!showResponsiveItems ? (
                            <SeasonNavItem
                                seasons={seasons}
                                classes={'u-margin-right-auto'}
                                title={title}
                            />
                        ) : null}

                        {currentFilters?.round?.providerId && !wrapToggle ? (
                            <ExpandLineupsToggle
                                isActive={expandLineups}
                                onChange={setExpandLineups}
                                classes={
                                    'competition-nav__lineups-toggle-wrapper'
                                }
                            />
                        ) : null}
                    </StatsSection.NavItems>

                    {currentFilters.competitionId &&
                    currentFilters.compSeason?.id ? (
                        <StatsSection.NavFilterItems>
                            {showResponsiveItems ? (
                                <ResponsiveSeasonNavItem
                                    seasons={seasons}
                                    title={title}
                                />
                            ) : null}

                            <TeamFilterItem
                                currentFilters={currentFilters}
                                title={title}
                            />
                            <VenueFilterItem
                                currentFilters={currentFilters}
                                title={title}
                            />
                            <CityFilterItem
                                currentFilters={currentFilters}
                                title={title}
                            />
                        </StatsSection.NavFilterItems>
                    ) : null}

                    <StatsSection.SettingsButton>
                        <SettingsButton />
                    </StatsSection.SettingsButton>

                    {wrapToggle ? (
                        <StatsSection.AdditionalNavItems isResponsive={true}>
                            <ExpandLineupsToggle
                                isActive={expandLineups}
                                onChange={setExpandLineups}
                                classes={
                                    'competition-nav__lineups-toggle-wrapper'
                                }
                                reversed={true}
                            />
                        </StatsSection.AdditionalNavItems>
                    ) : null}

                    <StatsSection.RoundButtons>
                        <RoundListNavItem
                            classes={'competition-nav__round-filter-wrapper'}
                            title={title}
                        />
                    </StatsSection.RoundButtons>
                </StatsSection.Nav>
            </StatsSection.Main>
        </div>
    );
};

TeamLineupsNav.propTypes = {
    competitionId: PropTypes.string,
    seasonId: PropTypes.string,
    expandLineups: PropTypes.bool,
    setExpandLineups: PropTypes.func,
    title: PropTypes.string,
    fixtureParam: PropTypes.object
};
