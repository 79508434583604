import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { ImageElement } from '../fixture-details/fixture-media/ImageElement';
import { getTranslation } from 'common/react/utils/translations';
import { FixtureCountContext } from './FixturesList';

export const WatchLive = ({ link, imgUrl, match }) => {
    const cardsCount = useContext(FixtureCountContext);

    return (
        <article className={`media-thumbnail`}>
            <div className="media-thumbnail__link">
                <a
                    className={`media-thumbnail__absolute-link`}
                    href={link}
                    onClick={() => {
                        PULSE.app.tracking.selectFixtureCardHandler(
                            match,
                            getTranslation('label.video.watch-live'),
                            link,
                            cardsCount
                        );
                    }}
                ></a>
                <header
                    className={`media-thumbnail__image-container placeholder placeholder--small placeholder--dark`}
                >
                    {imgUrl && (
                        <ImageElement
                            ImageUrl={imgUrl}
                            ImageTitle={getTranslation('label.watch-live')}
                        />
                    )}

                    <div className={`media-thumbnail__video-duration`}>
                        <SvgIcon
                            icon={'video-play-small'}
                            className={`icon media-thumbnail__duration-icon`}
                        />
                    </div>
                </header>
                <div className={`media-thumbnail__caption`}>
                    {getTranslation('label.watch-live')}
                </div>
            </div>
        </article>
    );
};

WatchLive.propTypes = {
    link: PropTypes.string.isRequired,
    imgUrl: PropTypes.string,
    match: PropTypes.object
};
