import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    FieldListView,
    Header,
    InsAndOuts,
    LateChanges,
    Milestones,
    Umpires
} from './components';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

const MATCH_STATUS = PULSE.app.common.CONSTANTS.MATCH_STATUS;
const LAST_UPDATED_FORMAT = 'h:mmA dddd, MMMM D, YYYY';
const OFFSET = 150;

export const TeamLineupsItem = ({
    match,
    expandLineups,
    setExpandLineups,
    shouldScrollTo,
    isAFLW = false
}) => {
    const ref = useRef();
    const [showLineups, setShowLineups] = useState(expandLineups);

    const roster = match.rosterItem;

    const status = match.status || null;

    const hasMilestones =
        roster.homeTeam?.milestones.length > 0 ||
        roster.awayTeam?.milestones.length > 0;

    const isActiveMatch =
        status === MATCH_STATUS.CONFIRMED_TEAMS ||
        status === MATCH_STATUS.LIVE ||
        status === MATCH_STATUS.POSTGAME ||
        status === MATCH_STATUS.CONCLUDED;

    useEffect(() => {
        setShowLineups(expandLineups);
    }, [expandLineups]);

    const handleToggle = () => {
        if (showLineups) {
            setShowLineups(false);
            // populating toggle state for this particular match
            setExpandLineups({ id: match.id, expanded: false });

            /* eslint-disable camelcase */
            PULSE.app.tracking.recordEvent(
                PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.COLLAPSE,
                {
                    eventData: {
                        component_name: roster.match.name,
                        component_content_type:
                            PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES
                                .LINEUPS,
                        type: 'additional content',
                        name: 'lineups'
                    }
                }
            );
            /* eslint-enable camelcase */
        } else {
            setShowLineups(true);
            // populating toggle state for this particular match
            setExpandLineups({ id: match.id, expanded: true });

            /* eslint-disable camelcase */
            PULSE.app.tracking.recordEvent(
                PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.EXPAND,
                {
                    eventData: {
                        component_name: roster.match.name,
                        component_content_type:
                            PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES
                                .LINEUPS,
                        type: 'additional content',
                        name: 'lineups'
                    }
                }
            );
            /* eslint-enable camelcase */
        }
    };

    useEffect(() => {
        if (shouldScrollTo && ref.current) {
            const element = ref.current;
            const topPosition =
                element.getBoundingClientRect().top + window.scrollY - OFFSET;

            window.scrollTo({
                top: topPosition,
                behavior: 'smooth'
            });
        }
    }, [shouldScrollTo]);

    const selectedRegion = useSingleSiteSetting('region');
    const lastUpdated = moment(roster.matchRoster.lastUpdated);

    return (
        <div className="team-lineups__item" ref={ref}>
            <Header match={match} />
            <InsAndOuts match={roster} isActiveMatch={isActiveMatch} />
            {isActiveMatch ||
            hasMilestones ||
            (hasMilestones && status === MATCH_STATUS.UNCONFIRMED_TEAMS) ? ( // stops empty section being rendered
                <div className="team-lineups__sub-section">
                    <Milestones match={roster} />
                    {status !== MATCH_STATUS.UNCONFIRMED_TEAMS ? (
                        <LateChanges match={roster} />
                    ) : null}
                </div>
            ) : null}

            {showLineups ? (
                <>
                    <FieldListView match={roster} isAFLW={isAFLW} />
                    <Umpires match={roster} />
                </>
            ) : null}

            {/* Hide Lineups */}
            <div className="team-lineups__sub-section team-lineups__sub-section--last">
                <button
                    className="team-lineups__hide-lineups-button"
                    onClick={handleToggle}
                >
                    {showLineups
                        ? getTranslation('label.teamLineups.hideLineups')
                        : getTranslation('label.teamLineups.showLineups')}
                    <SvgIcon
                        icon="chevron-v2-right"
                        className={`icon ${!showLineups ? 'is-rotated' : ''}`}
                    />
                </button>
            </div>

            <div className="team-lineups__last-updated">
                <SvgIcon icon="info" className="icon" />
                <p>
                    {getTranslation('label.teamlineups.lastUpdated')}
                    <span>
                        {lastUpdated
                            .tz(selectedRegion.timezone)
                            .format(LAST_UPDATED_FORMAT)}
                    </span>
                </p>
            </div>
        </div>
    );
};

TeamLineupsItem.propTypes = {
    match: PropTypes.object.isRequired,
    expandLineups: PropTypes.bool,
    setExpandLineups: PropTypes.func,
    shouldScrollTo: PropTypes.bool,
    isAFLW: PropTypes.bool
};
