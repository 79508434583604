import { useMemo } from 'react';

const ORIGINAL_PHOTO_SIZE = 1080;
const PLAYER_PHOTO_SIZE = {
    DEFAULT: 104,
    TABLET: 80,
    PHABLET: 70,
    MOBILE: 56
};

export const useGetImageScale = () => {
    const imageScale = useMemo(() => {
        let renderedSize = PLAYER_PHOTO_SIZE.DEFAULT;

        if (
            window.matchMedia(
                `(max-width: ${PULSE.app.measurements.desktop}px)`
            )?.matches
        ) {
            renderedSize = PLAYER_PHOTO_SIZE.TABLET;
        } else if (
            window.matchMedia(
                `(max-width: ${PULSE.app.measurements.phablet}px)`
            )?.matches
        ) {
            renderedSize = PLAYER_PHOTO_SIZE.PHABLET;
        } else if (
            window.matchMedia(
                `(max-width: ${PULSE.app.measurements.mobileUltraWide}px)`
            )?.matches
        ) {
            renderedSize = PLAYER_PHOTO_SIZE.MOBILE;
        }

        return PULSE.app.common.deviceHelper.isRetinaDisplay()
            ? `${(renderedSize / ORIGINAL_PHOTO_SIZE) * 2}`
            : `${renderedSize / ORIGINAL_PHOTO_SIZE}`;
    }, []);

    return imageScale;
};
