import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { RoundListNavItem, SeasonNavItem } from './nav-items';
import { RoundByRoundCards } from './round-by-round-cards';
import { roundByRoundNavSelector } from 'store/modules/brownlow-tracker/round-by-round/selectors';
import { useCompetitionNavigationOptions } from 'common/react/hooks/use-competition-navigation-options';
import { useRoundByRoundNavSync } from './hooks/use-round-by-round-nav-sync';
import { useFiltersTracking } from 'common/react/hooks/use-filters-tracking';

export const RoundByRound = ({
    seasonsToShow,
    seasonPid,
    competitionId,
    shouldHidePredicted,
    title
}) => {
    const nav = useSelector(roundByRoundNavSelector);
    const roundByRoundRef = useRef();

    const options = useCompetitionNavigationOptions(nav);

    options.defaultSeason = nav.season.providerId ?? seasonPid;
    options.seasonsToShow = seasonsToShow ?? seasonsToShow;

    const newOpts = {
        ...options,
        competitionId: competitionId
    };
    useRoundByRoundNavSync(newOpts);

    /**
     * custom hook providing tracking of filters
     */
    useFiltersTracking({
        title,
        filtersState: {
            season: nav.season,
            round: { roundNumber: nav.round }
        },
        options,
        filtersFields: ['season', 'round'],
        filtersRef: roundByRoundRef
    });

    return (
        <div ref={roundByRoundRef}>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        <SeasonNavItem
                            seasonsToShow={seasonsToShow}
                            title={title}
                        />
                        <RoundListNavItem title={title} />
                    </StatsSection.NavItems>
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentBody>
                        <RoundByRoundCards
                            shouldHidePredicted={shouldHidePredicted === 'true'}
                        />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
        </div>
    );
};

RoundByRound.propTypes = {
    seasonsToShow: PropTypes.string,
    seasonPid: PropTypes.string,
    competitionId: PropTypes.string,
    shouldHidePredicted: PropTypes.string,
    title: PropTypes.string
};
