import PropTypes from 'prop-types';
import React from 'react';

import { getTranslation } from 'common/react/utils/translations';
import { TooltipButton } from 'common/react/components/tooltip-button';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const LateChanges = ({ match }) => {
    const { homeTeam, awayTeam } = match.matchRoster;

    const renderTitle = (classes) => (
        <div className={`team-lineups-late-changes__title-section ${classes}`}>
            {!homeTeam.lateChanges && !awayTeam.lateChanges ? (
                <div className="team-lineups-late-changes__title-wrapper">
                    <span>
                        {getTranslation('label.teamlineups.noLateChanges')}
                    </span>
                    <TooltipButton
                        disableTitle={true}
                        id={`team-lineups-late-changes`}
                        title="Late changes tooltip"
                        primaryTooltipContent={getTranslation(
                            'label.teamLineups.lateChanges.tooltip'
                        )}
                    >
                        <SvgIcon
                            icon="info-circle"
                            className="team-lineups__tooltip-icon"
                        />
                    </TooltipButton>
                </div>
            ) : (
                <span className="team-lineups__title">
                    {getTranslation('label.teamlineups.lateChanges.short')}
                </span>
            )}
        </div>
    );

    return (
        <div className="team-lineups-milestones">
            {renderTitle('u-hide-from-tablet')}
            <div className="team-lineups__grid">
                <div className="team-lineups__event-text team-lineups__event-text--home">
                    {homeTeam.lateChanges ? (
                        <span>{homeTeam.lateChanges}</span>
                    ) : awayTeam.lateChanges ? (
                        <span className="team-lineups-ins-and-outs__empty">
                            &ndash;
                        </span>
                    ) : null}
                </div>
                {renderTitle('u-hide-until-tablet')}
                <div className="team-lineups__event-text team-lineups__event-text">
                    {awayTeam.lateChanges ? (
                        <span>{awayTeam.lateChanges}</span>
                    ) : homeTeam.lateChanges ? (
                        <span className="team-lineups-ins-and-outs__empty">
                            &ndash;
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

LateChanges.propTypes = {
    match: PropTypes.object.isRequired
};
