/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table';
import { StatsTable } from '../../../../statspro/js/components/common/stats-table/StatsTable';
import { StatsTableHead } from '../../../../statspro/js/components/common/stats-table/StatsTableHead';
import { StatsTableBody } from '../../../../statspro/js/components/common/stats-table/StatsTableBody';

import { HeaderCellPosition } from './custom-cells/HeaderCellPosition';
import { HeaderCellForm } from './custom-cells/HeaderCellForm';
import { HeaderCellPoints } from './custom-cells/HeaderCellPoints';
import { HeaderCellWinRatio } from './custom-cells/HeaderCellWinRatio';
import { HeaderCellBettingOdds } from './custom-cells/HeaderCellBettingOdds';
import { HeaderCellClubName } from './custom-cells/HeaderCellClubName';

import { BodyCellPoints } from './custom-cells/BodyCellPoints';
import { BodyCellWinRatio } from './custom-cells/BodyCellWinRatio';

import { BodyCellForm } from './custom-cells/BodyCellForm';
import { BodyCellPosition } from './custom-cells/BodyCellPosition';
import { BodyCellBettingOdds } from './custom-cells/BodyCellBettingOdds';
import { BodyCellClubName } from './custom-cells/BodyCellClubName';

import { useBettingVis } from 'common/react/hooks/use-betting-vis';

export const Table = ({
    columns,
    data,
    modifier,
    cssClass,
    conferenceLabel,
    conferenceModifier,
    displayFormTooltip
}) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                disableSortRemove: true,
                disableSortBy: true
            },
            useSortBy
        );

    const isBettingVisible = useBettingVis();

    const stickyMainNav = document.querySelector('.js-main-navigation');

    const positionCellIndex = 1;
    const pointsOrWinRatioCellIndex = 5;
    const formCellIndex = 12;
    const nextOpponentCellIndex = 13;
    const bettingOddsCellIndex = 14;

    const longCells = useMemo(
        () =>
            isBettingVisible
                ? [
                      positionCellIndex,
                      pointsOrWinRatioCellIndex,
                      formCellIndex,
                      nextOpponentCellIndex,
                      bettingOddsCellIndex
                  ]
                : [
                      positionCellIndex,
                      pointsOrWinRatioCellIndex,
                      formCellIndex,
                      nextOpponentCellIndex
                  ],
        [isBettingVisible]
    );

    // ===== Render ===== //

    return (
        <>
            {conferenceLabel && (
                <h1
                    className={`stats-table__conference-title ${conferenceModifier}`}
                >
                    {conferenceLabel}
                </h1>
            )}

            <StatsTable
                modifier={modifier}
                getTableProps={getTableProps}
                cssClass={cssClass}
                stickyPageNav={stickyMainNav}
                // eslint-disable-next-line no-magic-numbers
                longCells={longCells}
            >
                <StatsTableHead
                    headerGroup={headerGroups[0]}
                    skipColumns={['positionChange', 'club', 'isPlaying']}
                    customCells={{
                        position: (column, key) => (
                            <HeaderCellPosition
                                column={column}
                                key={`header-position--${key}`}
                            />
                        ),
                        clubName: (column, key) => (
                            <HeaderCellClubName
                                column={column}
                                key={`header-club-name--${key}`}
                            />
                        ),
                        points: (column, key) => (
                            <HeaderCellPoints
                                column={column}
                                key={`header-points--${key}`}
                            />
                        ),
                        winRatio: (column, key) => (
                            <HeaderCellWinRatio
                                column={column}
                                key={`header-win-ratio--${key}`}
                            />
                        ),
                        form: (column, key) => (
                            <HeaderCellForm
                                column={column}
                                key={`header-form--${key}`}
                            />
                        ),
                        bettingOdds: (column, key) => (
                            <HeaderCellBettingOdds
                                column={column}
                                key={`header-betting-odds--${key}`}
                            />
                        )
                    }}
                />

                <StatsTableBody
                    rows={rows}
                    prepareRow={prepareRow}
                    getTableBodyProps={getTableBodyProps}
                    skipCells={['positionChange', 'club', 'isPlaying']}
                    customCells={{
                        position: (row, cell, cellProps, key) => (
                            <BodyCellPosition
                                cell={cell}
                                cellProps={cellProps}
                                key={`body-position--${key}`}
                            />
                        ),
                        clubName: (row, cell, cellProps, key) => (
                            <BodyCellClubName
                                cell={cell}
                                cellProps={cellProps}
                                key={`body-club-name--${key}`}
                            />
                        ),
                        points: (row, cell, cellProps, key) => (
                            <BodyCellPoints
                                cell={cell}
                                cellProps={cellProps}
                                key={`body-points--${key}`}
                            />
                        ),
                        winRatio: (row, cell, cellProps, key) => (
                            <BodyCellWinRatio
                                cell={cell}
                                cellProps={cellProps}
                                key={`body-win-ratio--${key}`}
                            />
                        ),
                        form: (row, cell, cellProps, key) => (
                            <BodyCellForm
                                cell={cell}
                                cellProps={cellProps}
                                key={`body-form--${key}`}
                                displayFormTooltip={displayFormTooltip}
                            />
                        ),
                        bettingOdds: (row, cell, cellProps, key) => (
                            <BodyCellBettingOdds
                                cell={cell}
                                cellProps={cellProps}
                                key={`body-betting-odds--${key}`}
                            />
                        )
                    }}
                />
            </StatsTable>
        </>
    );
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    modifier: PropTypes.string,
    cssClass: PropTypes.string,
    conferenceLabel: PropTypes.any,
    conferenceModifier: PropTypes.any,
    displayFormTooltip: PropTypes.bool
};
