import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { filtersSelector } from 'common/store/modules/events-listing/selectors/filters-selector';
import { useGetTeamsQuery } from 'common/store/modules/api/afl-api';
import { SelectMultiple } from 'common/react/components/select-multiple';
import { getTranslation } from 'common/react/utils/translations';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { UPDATE_FILTER_ITEM } from 'common/store/modules/events-listing/actions';

import { useFavouriteTeam } from 'common/react/hooks/use-favourite-team';

export const TeamFilter = ({ compseasonId, addOnResetCallback }) => {
    const dispatch = useDispatch();
    const filters = useSelector(filtersSelector);

    const { data: teamsList } = useGetTeamsQuery(compseasonId);

    const favouriteTeam = useFavouriteTeam();

    const teams = useMemo(() => {
        if (!teamsList) {
            return null;
        }

        const sortedTeams = [...teamsList].sort((a, b) =>
            a.name.localeCompare(b.name)
        );

        if (favouriteTeam) {
            const index = sortedTeams.findIndex(
                (team) =>
                    PULSE.app.common.team.getTeamAbbr(team?.providerId) ===
                    favouriteTeam
            );

            if (index > -1) {
                let [team] = sortedTeams.splice(index, 1);
                team = {
                    ...team,
                    favouriteTeam: true
                };
                sortedTeams.unshift(team);
            }
        }

        return sortedTeams;
    }, [teamsList, favouriteTeam]);

    const updateFilterItem = (filterItem, value) => {
        dispatch(UPDATE_FILTER_ITEM.request({ filterItem, value }));
    };

    return (
        <SelectMultiple
            getInitialSelectedItems={() => {
                return teams.filter((t) => filters.teams.includes(t.id));
            }}
            items={teams}
            label={getTranslation('label.filters.teams')}
            itemToString={(team) => team.name}
            onSelectedItemsChange={(team) => {
                updateFilterItem(
                    'teams',
                    team.map((t) => t.id)
                );
            }}
            addOnResetCallback={addOnResetCallback}
            getItemText={(item) => item.name}
            getItemLabel={(item) => (
                <span
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: 1,
                        width: '100%'
                    }}
                    className={`${
                        item.favouriteTeam
                            ? 'select-multiple__favourite-team'
                            : ''
                    }`}
                >
                    <span style={{ marginRight: '1rem' }}>{item.name}</span>
                    <TeamIcon
                        className="icon"
                        providerId={item.providerId}
                        style={{ width: '3.6rem', height: '3.6rem' }}
                    />
                </span>
            )}
            icon="guernsey"
        />
    );
};

TeamFilter.propTypes = {
    compseasonId: PropTypes.string.isRequired,
    addOnResetCallback: PropTypes.func
};
