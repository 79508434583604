import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Select } from '../../select';
import { getTranslation } from '../../../utils/translations';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';
import { getCompetitionList } from 'widgets/fixtures/js/utils/helpers';

export const DEFAULT_COMP_ORDER = window.DEFAULT_COMP_ORDER
    ? window.DEFAULT_COMP_ORDER.split(',')
    : [
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.WOMENS,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.PRE_SEASON,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.PREMIERSHIP,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.VFL_PREMIERSHIP,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.VFL_WOMENS,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.WAFL_PREMIERSHIP,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.SANFL_PREMIERSHIP,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.U18_BOYS_CHAMPIONSHIPS,
          PULSE.app.common.CONSTANTS.PROVIDER_IDS.U18_GIRLS_CHAMPIONSHIPS
      ];

export const CompNavItem = ({
    competitions,
    allowListOrder = DEFAULT_COMP_ORDER
}) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    if (!competitions || nav.competition.id === '') {
        return '';
    }

    const competitionList = getCompetitionList(competitions, allowListOrder);

    return (
        <div className="stats-leaders-nav__select competition-nav__competition-select">
            <Select
                items={competitionList}
                itemToString={(competition) => competition.name}
                initialSelectedItem={competitions.find(
                    (c) => c.id === Number(nav.competition.id)
                )}
                showCompLogo={true}
                activeItem={competitionList.find(
                    (c) => c.id === Number(nav.competition.id)
                )}
                isReadyToRender={() =>
                    competitionList?.length && Number(nav.competition.id)
                }
                label={getTranslation('label.filters.competition')}
                onSelectedItemChange={({ selectedItem }) => {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'competition',
                            value: {
                                id: selectedItem.id,
                                providerId: selectedItem.providerId
                            }
                        })
                    );
                }}
            />
        </div>
    );
};

CompNavItem.propTypes = {
    competitions: PropTypes.array,
    allowListOrder: PropTypes.array,
    title: PropTypes.string
};
