import PropTypes from 'prop-types';
import React from 'react';

import { getTranslation } from 'common/react/utils/translations';

export const Umpires = ({ match }) => {
    const { umpires } = match.matchRoster;

    if (!umpires) {
        return;
    }

    return (
        <div className="team-lineups__sub-section">
            <span className="team-lineups__title">
                {getTranslation('label.teamlineups.umpires')}
            </span>
            <div className="team-lineups__event-text team-lineups__event-text--centered">
                {umpires.map(
                    (umpire, index) =>
                        `${umpire.umpireName.givenName} ${
                            umpire.umpireName.surname
                        }${index + 1 !== umpires.length ? ', ' : ''}`
                )}
            </div>
        </div>
    );
};

Umpires.propTypes = {
    match: PropTypes.object.isRequired
};
