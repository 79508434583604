/* eslint-disable id-length */
/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
/**
 * Use a class component to get access to lifecycle methods, otherwise use a functional component.
 * Should only contain rendering logic; business logic should be contained to Redux Sagas.
 */

import React from 'react';
import DefaultTable from 'custom-table/js/components/DefaultTable';
import PropTypes from 'prop-types';

class RankingsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            favouriteTeam: false,
            data: []
        };

        this.setFavouriteTeam = this.setFavouriteTeam.bind(this);
    }

    setFavouriteTeam(eventProps) {
        const favTeam =
            eventProps?.detail ??
            PULSE.core.localStorage.getStorage('favouriteTeam', false);
        this.setState({
            favouriteTeam: favTeam
        });
    }

    componentDidMount() {
        this.setFavouriteTeam();
        document.addEventListener(
            PULSE.app.common.CONSTANTS.EVENTS.AFLID.FAVOURITE_SET,
            this.setFavouriteTeam
        );

        /*
            ideally, this can eventually be passed into DefaultCustomTable as a prop.
            then we don't need to have componentDidMount or call this.props.addCustomTable
            in extended versions of the table.
            We could potentially simplify this into a functional component rather than a class.
        */
        const tableConfig = {
            tableName: 'rankings',
            groups: [
                {
                    name: 'PersistentGroup',
                    label: 'Persistent Group',
                    isPersistent: true,
                    fields: [
                        {
                            fieldName: 'club',
                            fieldLabel: 'label.ladder.club',
                            fieldLabelShort: 'label.ladder.club',
                            sortFunction: 'teamNameSort',
                            tableHeadCustomClass:
                                'custom-table__header-cell--club',
                            customCellRenderer: 'RankingsClubCellRenderer',
                            apiMap: 0,
                            displayByDefault: true,
                            isPersistent: true,
                            selector: {
                                abbreviation: 'team.teamAbbr',
                                teamName: 'team.teamName',
                                providerId: 'team.teamId'
                            },
                            parentSelector: 'lists'
                        }
                    ]
                },
                {
                    name: 'general',
                    label: 'label.statsTable.general',
                    fields: [
                        {
                            fieldName: 'rankingPoints',
                            fieldLabel: 'label.stats.rankingPoints',
                            fieldLabelShort: 'label.stats.rankingPoints.short',
                            tableHeadTitle: 'label.stats.rankingPoints.short',
                            description:
                                'label.stats.rankingPoints.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.ratingPoints',
                                total: 'stats.totals.ratingPoints'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'freeFor',
                            fieldLabel: 'label.stats.freeFor',
                            fieldLabelShort: 'label.stats.freeFor.short',
                            tableHeadTitle: 'label.stats.freeFor.short',
                            description: 'label.stats.freeFor.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.freesFor',
                                total: 'stats.totals.freesFor'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'freeAgainst',
                            fieldLabel: 'label.stats.freeAgainst',
                            fieldLabelShort: 'label.stats.freeAgainst.short',
                            tableHeadTitle: 'label.stats.freeAgainst.short',
                            description: 'label.stats.freeAgainst.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.freesAgainst',
                                total: 'stats.totals.freesAgainst'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'bounces',
                            fieldLabel: 'label.stats.bounces',
                            fieldLabelShort: 'label.stats.bounces.short',
                            tableHeadTitle: 'label.stats.bounces.short',
                            description: 'label.stats.bounces.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.bounces',
                                total: 'stats.totals.bounces'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'onePercenters',
                            fieldLabel: 'label.stats.onePercenters',
                            fieldLabelShort: 'label.stats.onePercenters.short',
                            tableHeadTitle: 'label.stats.onePercenters.short',
                            description:
                                'label.stats.onePercenters.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.onePercenters',
                                total: 'stats.totals.onePercenters'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'aflFantasy',
                            fieldLabel: 'label.stats.aflFantasy',
                            fieldLabelShort: 'label.stats.aflFantasy.short',
                            tableHeadTitle: 'label.stats.aflFantasy.short',
                            description: 'label.stats.aflFantasy.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.dreamTeamPoints',
                                total: 'stats.totals.dreamTeamPoints'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        }
                    ]
                },
                {
                    name: 'possessions',
                    label: 'label.statsTable.possessions',
                    fields: [
                        {
                            fieldName: 'uncontestedPossessions',
                            fieldLabel: 'label.stats.uncontestedPossessions',
                            fieldLabelShort:
                                'label.stats.uncontestedPossessions.short',
                            tableHeadTitle:
                                'label.stats.uncontestedPossessions.short',
                            description:
                                'label.stats.uncontestedPossessions.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average:
                                    'stats.averages.uncontestedPossessions',
                                total: 'stats.totals.uncontestedPossessions'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'turnovers',
                            fieldLabel: 'label.stats.turnovers',
                            fieldLabelShort: 'label.stats.turnovers.short',
                            tableHeadTitle: 'label.stats.turnovers.short',
                            description: 'label.stats.turnovers.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.turnovers',
                                total: 'stats.totals.turnovers'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'contestedPossessions',
                            fieldLabel: 'label.stats.contestedPossessions',
                            fieldLabelShort:
                                'label.stats.contestedPossessions.short',
                            tableHeadTitle:
                                'label.stats.contestedPossessions.short',
                            description:
                                'label.stats.contestedPossessions.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.contestedPossessions',
                                total: 'stats.totals.contestedPossessions'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        }
                    ]
                },
                {
                    name: 'marks',
                    label: 'label.statsTable.marks',
                    fields: [
                        {
                            fieldName: 'marks',
                            fieldLabel: 'label.stats.marks',
                            fieldLabelShort: 'label.stats.marks.short',
                            tableHeadTitle: 'label.stats.marks.short',
                            description: 'label.stats.marks.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.marks',
                                total: 'stats.totals.marks'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'marksInside50',
                            fieldLabel: 'label.stats.marksInside50',
                            fieldLabelShort: 'label.stats.marksInside50.short',
                            tableHeadTitle: 'label.stats.marksInside50.short',
                            description:
                                'label.stats.marksInside50.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.marksInside50',
                                total: 'stats.totals.marksInside50'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'contestedMarks',
                            fieldLabel: 'label.stats.contestedMarks',
                            fieldLabelShort: 'label.stats.contestedMarks.short',
                            tableHeadTitle: 'label.stats.contestedMarks.short',
                            description:
                                'label.stats.contestedMarks.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.contestedMarks',
                                total: 'stats.totals.contestedMarks'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        }
                    ]
                },
                {
                    name: 'disposals',
                    label: 'label.statsTable.disposals',
                    fields: [
                        {
                            fieldName: 'disposals',
                            fieldLabel: 'label.stats.disposals',
                            fieldLabelShort: 'label.stats.disposals.short',
                            tableHeadTitle: 'label.stats.disposals.short',
                            description: 'label.stats.disposals.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.disposals',
                                total: 'stats.totals.disposals'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'kicks',
                            fieldLabel: 'label.stats.kicks',
                            fieldLabelShort: 'label.stats.kicks.short',
                            tableHeadTitle: 'label.stats.kicks.short',
                            description: 'label.stats.kicks.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.kicks',
                                total: 'stats.totals.kicks'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'handballs',
                            fieldLabel: 'label.stats.handballs',
                            fieldLabelShort: 'label.stats.handballs.short',
                            tableHeadTitle: 'label.stats.handballs.short',
                            description: 'label.stats.handballs.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.handballs',
                                total: 'stats.totals.handballs'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'rebound50s',
                            fieldLabel: 'label.stats.rebound50s',
                            fieldLabelShort: 'label.stats.rebound50s.short',
                            tableHeadTitle: 'label.stats.rebound50s.short',
                            description: 'label.stats.rebound50s.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.rebound50s',
                                total: 'stats.totals.rebound50s'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'inside50',
                            fieldLabel: 'label.stats.inside50',
                            fieldLabelShort: 'label.stats.inside50.short',
                            tableHeadTitle: 'label.stats.inside50.short',
                            description: 'label.stats.inside50.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.inside50s',
                                total: 'stats.totals.inside50s'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'clangers',
                            fieldLabel: 'label.stats.clangers',
                            fieldLabelShort: 'label.stats.clangers.short',
                            tableHeadTitle: 'label.stats.clangers.short',
                            description: 'label.stats.clangers.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.clangers',
                                total: 'stats.totals.clangers'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        }
                    ]
                },
                {
                    name: 'stoppages',
                    label: 'label.statsTable.stoppages',
                    fields: [
                        {
                            fieldName: 'hitOuts',
                            fieldLabel: 'label.stats.hitouts',
                            fieldLabelShort: 'label.stats.hitouts.short',
                            tableHeadTitle: 'label.stats.hitouts.short',
                            description: 'label.stats.hitouts.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.hitouts',
                                total: 'stats.totals.hitouts'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'centreClearances',
                            fieldLabel: 'label.stats.centreClearances',
                            fieldLabelShort:
                                'label.stats.centreClearances.short',
                            tableHeadTitle:
                                'label.stats.centreClearances.short',
                            description:
                                'label.stats.centreClearances.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average:
                                    'stats.averages.clearances.centreClearances',
                                total: 'stats.totals.clearances.centreClearances'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'stoppageClearances',
                            fieldLabel: 'label.stats.stoppageClearances',
                            fieldLabelShort:
                                'label.stats.stoppageClearances.short',
                            tableHeadTitle:
                                'label.stats.stoppageClearances.short',
                            description:
                                'label.stats.stoppageClearances.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average:
                                    'stats.averages.clearances.stoppageClearances',
                                total: 'stats.totals.clearances.stoppageClearances'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'totalClearances',
                            fieldLabel: 'label.stats.totalClearances',
                            fieldLabelShort:
                                'label.stats.totalClearances.short',
                            tableHeadTitle: 'label.stats.totalClearances.short',
                            description:
                                'label.stats.totalClearances.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average:
                                    'stats.averages.clearances.totalClearances',
                                total: 'stats.totals.clearances.totalClearances'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        }
                    ]
                },
                {
                    name: 'scoring',
                    label: 'label.statsTable.scoring',
                    fields: [
                        {
                            fieldName: 'goals',
                            fieldLabel: 'label.stats.goals',
                            fieldLabelShort: 'label.stats.goals.short',
                            tableHeadTitle: 'label.stats.goals.short',
                            description: 'label.stats.goals.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.goals',
                                total: 'stats.totals.goals'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'behinds',
                            fieldLabel: 'label.stats.behinds',
                            fieldLabelShort: 'label.stats.behinds.short',
                            tableHeadTitle: 'label.stats.behinds.short',
                            description: 'label.stats.behinds.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.behinds',
                                total: 'stats.totals.behinds'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'goalAssists',
                            fieldLabel: 'label.stats.goalAssists',
                            fieldLabelShort: 'label.stats.goalAssists.short',
                            tableHeadTitle: 'label.stats.goalAssists.short',
                            description: 'label.stats.goalAssists.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.goalAssists',
                                total: 'stats.totals.goalAssists'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'goalAccuracy',
                            fieldLabel: 'label.stats.goalAccuracy',
                            fieldLabelShort: 'label.stats.goalAccuracy.short',
                            tableHeadTitle: 'label.stats.goalAccuracy.short',
                            description: 'label.stats.goalAccuracy.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.goalAccuracy',
                                total: 'stats.totals.goalAccuracy'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'shotsOnGoal',
                            fieldLabel: 'label.stats.shotsOnGoal',
                            fieldLabelShort: 'label.stats.shotsOnGoal.short',
                            tableHeadTitle: 'label.stats.shotsOnGoal.short',
                            description: 'label.stats.shotsOnGoal.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.shotsAtGoal',
                                total: 'stats.totals.shotsAtGoal'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'disposalEffiency',
                            fieldLabel: 'label.stats.disposalEffiency',
                            fieldLabelShort:
                                'label.stats.disposalEffiency.short',
                            tableHeadTitle:
                                'label.stats.disposalEffiency.short',
                            description:
                                'label.stats.disposalEffiency.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.disposalEfficiency',
                                total: 'stats.totals.disposalEfficiency'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'goalEffiency',
                            fieldLabel: 'label.stats.goalEffiency',
                            fieldLabelShort: 'label.stats.goalEffiency.short',
                            tableHeadTitle: 'label.stats.goalEffiency.short',
                            description: 'label.stats.goalEffiency.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.goalEfficiency',
                                total: 'stats.totals.goalEfficiency'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'shotEffiency',
                            fieldLabel: 'label.stats.shotEffiency',
                            fieldLabelShort: 'label.stats.shotEffiency.short',
                            tableHeadTitle: 'label.stats.shotEffiency.short',
                            description: 'label.stats.shotEffiency.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.shotEfficiency',
                                total: 'stats.totals.shotEfficiency'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'scoreInvolvements',
                            fieldLabel: 'label.stats.scoreInvolvements',
                            fieldLabelShort:
                                'label.stats.scoreInvolvements.short',
                            tableHeadTitle:
                                'label.stats.scoreInvolvements.short',
                            description:
                                'label.stats.scoreInvolvements.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.scoreInvolvements',
                                total: 'stats.totals.scoreInvolvements'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'intercepts',
                            fieldLabel: 'label.stats.intercepts',
                            fieldLabelShort: 'label.stats.intercepts.short',
                            tableHeadTitle: 'label.stats.intercepts.short',
                            description: 'label.stats.intercepts.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.intercepts',
                                total: 'stats.totals.intercepts'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        }
                    ]
                },
                {
                    name: 'defence',
                    label: 'label.statsTable.defence',
                    fields: [
                        {
                            fieldName: 'tackles',
                            fieldLabel: 'label.stats.tackles',
                            fieldLabelShort: 'label.stats.tackles.short',
                            tableHeadTitle: 'label.stats.tackles.short',
                            description: 'label.stats.tackles.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.tackles',
                                total: 'stats.totals.tackles'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        },
                        {
                            fieldName: 'tacklesInside50',
                            fieldLabel: 'label.stats.tacklesInside50',
                            fieldLabelShort:
                                'label.stats.tacklesInside50.short',
                            tableHeadTitle: 'label.stats.tacklesInside50.short',
                            description:
                                'label.stats.tacklesInside50.description',
                            sortFunction: 'customNumberCompare',
                            apiMap: 0,
                            displayByDefault: false,
                            selector: {
                                average: 'stats.averages.tacklesInside50',
                                total: 'stats.totals.tacklesInside50'
                            },
                            customCellRenderer: 'TotalAverageCellRenderer',
                            customTableHeadRenderer:
                                'MultipleTableHeadRenderer',
                            tableHeadLabels: {
                                average: 'label.average.abbr',
                                total: 'label.total.abbr'
                            },
                            parentSelector: 'lists'
                        }
                    ]
                }
            ],
            jsonApis: [
                {
                    api: `${PULSE.app.environment.cfsApi}/statsCentre/teams?competitionId=CD_S2020014`,
                    token: true
                }
            ],
            headers: {
                [PULSE.app.accountHeader.label]: PULSE.app.accountHeader.value
            },
            totalTableRow: {
                tableRowRenderer: 'RankingsTotalTableRow'
            },
            sortBy: {
                fieldName: 'club',
                direction: 'ASC'
            },
            infoText: 'Your custom team rankings',
            rowIdentifier: {
                parentSelector: 'lists',
                selector: 'team.teamId',
                apiMap: 0
            }
        };

        this.props.addCustomTable(tableConfig);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.sortedTableData !== prevProps.sortedTableData ||
            this.state.favouriteTeam !== prevState.favouriteTeam ||
            this.props.customTable?.sortBy.fieldName !==
                prevProps.customTable?.sortBy.fieldName ||
            this.props.customTable?.sortBy.direction !==
                prevProps.customTable?.sortBy.direction
        ) {
            this.enrichTableDataWithFavTeam();
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            PULSE.app.common.CONSTANTS.EVENTS.AFLID.FAVOURITE_SET,
            this.setFavouriteTeam
        );
    }

    enrichTableDataWithFavTeam() {
        const enrichedData = this.props.sortedTableData.map((row) => {
            if (
                row.club?.abbreviation?.toLowerCase() ===
                this.state.favouriteTeam
            ) {
                return { ...row, isFavouriteTeam: true };
            }

            return row;
        });
        this.setState({ data: enrichedData });
    }

    render() {
        return (
            <DefaultTable {...this.props} sortedTableData={this.state.data} />
        );
    }
}

RankingsTable.propTypes = {
    addCustomTable: PropTypes.any
};

export default RankingsTable;
