import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

import { LoggedOutModal } from './modal-elements/LoggedOutModal';
import { LoggedInModal } from './modal-elements/LoggedInModal';
import { Over18ModalWrapper } from './modal-elements/Over18ModalWrapper';
import { UpdateCommPrefModalWrapper } from './modal-elements/UpdateCommPrefModalWrapper';
import { CreateCommPrefModalWrapper } from './modal-elements/CreateCommPrefModalWrapper';
import { ModalGeneric } from '../../../modal/ModalGeneric';

import { useSiteSettings } from 'common/react/hooks/use-site-settings';
import { Over18ErrorModalWrapper } from './modal-elements/error-handling-modals/Over18ErrorModalWrapper';
import { MarketingPrefsErrorWrapper } from './modal-elements/error-handling-modals/MarketingPreferencesErrorModalWrapper';

// Add Your Favourite Team Modal properties
const ADD_FAVOURITE_TEAM_BACKGROUND_COLOUR = '#000000';
const ADD_FAVOURITE_TEAM_PROMO_TAG = 'aflid-add-favourite-team';
const ADD_FAVOURITE_TEAM_CLASS = 'add-favourite-team';

export const SiteSettingsModal = ({ modalType, regionsPage }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const { siteSettings, setSiteSettings, settingsConfig } = useSiteSettings();

    const selectedRegion = siteSettings[settingsConfig.region.key];
    const displayWagering = !siteSettings[settingsConfig.displayWagering.key];
    const over18 = siteSettings[settingsConfig.over18.key];

    useEffect(() => {
        // Create handler here so  we can reference it in the sub and unsub call
        const authUpdateHandler = (authState) => {
            setIsAuthenticated(authState?.isAuthenticated);
        };

        fetchData(authUpdateHandler);

        // Subscribe to authState change event.
        PULSE.app.authClient.authStateManager.subscribe(authUpdateHandler);

        // Remove subscription when component is removed
        return () => {
            PULSE.app.authClient.authStateManager.unsubscribe(
                authUpdateHandler
            );
        };
    }, []);

    /**
     * Setup a callback to add to the onClick prop of the overlay. It checks if
     * it was the overlay itself that has been clicked and if we're showing
     * anything other than the over18 modal then we close the modal.
     *
     * Caveat: If the addFavouriteTeam modal is being closed, then display
     * the settings modal.
     */
    const onOverlayClick = useCallback(
        (event) => {
            if (
                event.target === event.currentTarget &&
                modalType !== 'over18' &&
                modalType !== 'createComms'
            ) {
                if (modalType === 'addFavouriteTeam') {
                    PULSE.app.common.siteSettings.changeModalType('settings');
                } else {
                    PULSE.app.common.siteSettings.closeSettingsModal();
                }
            }
        },
        [modalType]
    );

    /**
     * Click handler for the "Add your Favourite Team" ctas
     *
     * @param {MouseEvent} evnt
     * @param {object} linkObj Link object defined in the PROMO
     */
    const onFavouriteTeamLoginClick = (evnt, linkObj) => {
        evnt.preventDefault();

        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.CTA,
            {
                /* eslint-disable camelcase */
                eventData: {
                    link_text: linkObj.linkText,
                    link_url: PULSE.app.environment.okta.url,
                    outbound: true,
                    type: 'external',
                    component_name: 'settings modal favourite team'
                }
                /* eslint-enable camelcase */
            }
        );

        PULSE.app.authHelpers.signInWithRedirect();
        PULSE.app.common.siteSettings.closeSettingsModal(false);
    };

    /**
     * Click handler for the "Add your Favourite Team" ctas
     *
     * @param {MouseEvent} evnt
     * @param {object} linkObj Link object defined in the PROMO
     */
    const onFavouriteTeamRegisterClick = (evnt, linkObj) => {
        evnt.preventDefault();

        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.CTA,
            {
                /* eslint-disable camelcase */
                eventData: {
                    link_text: linkObj.linkText,
                    link_url: PULSE.app.environment.okta.url,
                    outbound: true,
                    type: 'external',
                    component_name: 'settings modal favourite team'
                }
                /* eslint-enable camelcase */
            }
        );

        PULSE.app.authHelpers.signInWithRedirect();
        PULSE.app.common.siteSettings.closeSettingsModal(false);
    };

    return (
        <FocusTrap>
            <div className="site-settings-overlay" onClick={onOverlayClick}>
                <div
                    className={`site-settings-overlay__modal site-settings-overlay__modal--${modalType}`}
                >
                    {
                        /**
                         * If user wants to add their favourite team
                         */
                        isAuthenticated === false &&
                        modalType === 'addFavouriteTeam' ? (
                            // Hard-coded values for now but would come from the CMS, perhaps promo metadata
                            <ModalGeneric
                                tag={ADD_FAVOURITE_TEAM_PROMO_TAG}
                                displayTitle={false}
                                bgColour={ADD_FAVOURITE_TEAM_BACKGROUND_COLOUR}
                                darkMode={true}
                                isAflId={true}
                                type={ADD_FAVOURITE_TEAM_CLASS}
                                modalName="settings modal favourite team"
                                ctaCallbacks={[
                                    onFavouriteTeamRegisterClick,
                                    onFavouriteTeamLoginClick
                                ]}
                                preventModals={true}
                            />
                        ) : /**
                         * If user is logged out
                         */
                        isAuthenticated === false ? (
                            <LoggedOutModal
                                siteSettings={siteSettings}
                                setSiteSettings={setSiteSettings}
                                settingsConfig={settingsConfig}
                                selectedRegion={selectedRegion}
                                displayWagering={displayWagering}
                                regionsPage={regionsPage}
                                isOVer18Setting={over18}
                            />
                        ) : /**
                         * If user hasn't set their age
                         */
                        isAuthenticated && modalType === 'over18' ? (
                            <Over18ModalWrapper />
                        ) : /**
                         * If user hasn't set their age and there's an error on submission.
                         */
                        isAuthenticated && modalType === 'over18Error' ? (
                            <Over18ErrorModalWrapper />
                        ) : /**
                         * If user hasn't set their communication preference
                         */
                        isAuthenticated && modalType === 'createComms' ? (
                            <CreateCommPrefModalWrapper />
                        ) : /**
                         * if user has requested to see the communication preferences.
                         */
                        isAuthenticated && modalType === 'updateComms' ? (
                            <UpdateCommPrefModalWrapper />
                        ) : /**
                         * If user hasn't set their communication preference and there's an error on submission.
                         */
                        isAuthenticated && modalType === 'commsError' ? (
                            <MarketingPrefsErrorWrapper />
                        ) : /**
                        /**
                             * User is logged in and has their age
                             * and communications preferences set
                             */
                        isAuthenticated ? (
                            <LoggedInModal
                                siteSettings={siteSettings}
                                setSiteSettings={setSiteSettings}
                                settingsConfig={settingsConfig}
                                selectedRegion={selectedRegion}
                                displayWagering={displayWagering}
                                regionsPage={regionsPage}
                                isOVer18Setting={over18}
                            />
                        ) : (
                            <div tabIndex={0} />
                        )
                    }
                </div>
            </div>
        </FocusTrap>
    );
};

SiteSettingsModal.propTypes = {
    modalType: PropTypes.string,
    regionsPage: PropTypes.string.isRequired
};

const fetchData = async (setIsAuthenticated) => {
    try {
        const response = await PULSE.app.authClient.isAuthenticated();
        setIsAuthenticated({ isAuthenticated: response });
    } catch (error) {
        console.error('Error fetching isAuthenticated status:', error);
    }
};
