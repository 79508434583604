import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Select } from '../../select';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { getTranslation } from '../../../utils/translations';
import { useSiteSettings } from 'common/react/hooks/use-site-settings';

export const LocationNavItem = ({ locations }) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);
    const { setSiteSettings, settingsConfig } = useSiteSettings();

    const initialLocation = useMemo(() => {
        return locations?.find((l) => l.value === nav.location.location);
    }, [nav.location, locations]);

    return (
        <div className="stats-leaders-nav__select competition-nav__competition-select">
            <Select
                items={locations}
                itemToString={(location) => location?.value}
                initialSelectedItem={initialLocation?.value}
                activeItem={initialLocation}
                label={getTranslation('label.filters.location')}
                onSelectedItemChange={({ selectedItem }) => {
                    const newRegion = {
                        timezone: selectedItem.extra.timezone,
                        id: selectedItem.extra.id,
                        name: selectedItem.extra.name
                    };

                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'location',
                            value: newRegion
                        })
                    );

                    setSiteSettings({
                        [settingsConfig.region.key]: newRegion
                    });
                }}
            />
        </div>
    );
};

LocationNavItem.propTypes = {
    locations: PropTypes.array,
    title: PropTypes.string
};
