import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { filtersSelector } from 'store/modules/competition-nav/selectors/filters-selector';
import {
    useGetCompSeasonsQuery,
    useGetTeamsQuery
} from 'store/modules/api/afl-api';
import { UPDATE_FILTER_ITEM } from 'store/modules/competition-nav/actions';

import { TeamIcon } from 'common/react/components/TeamIcon';
import { useFavouriteTeam } from 'common/react/hooks/use-favourite-team';
import { getTranslation } from 'common/react/utils/translations';
import { SelectMultiple } from 'common/react/components/select-multiple';
import { navSelector } from 'common/store/modules/competition-nav/selectors';
import { Loader } from 'common/react/components/Loader';

export const CGTeamFilter = ({
    addOnResetCallback,
    counterThreshold,
    maximumSelections,
    icon,
    defaultComp,
    isAFLW,
    handleCookieUpdate
}) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);
    const isDefault = nav.competition.id === defaultComp.id;
    const filters = useSelector(filtersSelector);

    const { data: compSeasonsData = [] } = useGetCompSeasonsQuery(
        {
            competitionId: isAFLW
                ? window.PULSE.envPaths.competitions.AFLW
                : window.PULSE.envPaths.competitions.AFL
        },
        {
            skip: !isDefault
        }
    );

    const favouriteTeam = useFavouriteTeam();

    const shouldTeamsSkip =
        (isDefault && !compSeasonsData[0]?.id) ||
        (!isDefault && !nav?.season?.id);

    const { data: [...teamsList] = [], isFetching } = useGetTeamsQuery(
        isDefault ? compSeasonsData[0]?.id : nav.season.id,
        {
            refetchOnMountOrArgChange: true, // this is needed to force select to re-render
            skip: shouldTeamsSkip
        }
    );

    const formattedTeamsList = () => {
        return [...teamsList].sort((a, b) => a.name.localeCompare(b.name));
    };

    const teams = useMemo(() => {
        if (!teamsList) {
            return null;
        }

        let formattedList = formattedTeamsList();

        if (favouriteTeam) {
            const index = formattedList.findIndex((team) => {
                return (
                    PULSE.app.common.team.getTeamAbbr(team?.providerId) ===
                    favouriteTeam
                );
            });

            if (index > -1) {
                let [team] = formattedList.splice(index, 1);
                team = {
                    ...team,
                    favouriteTeam: true
                };
                formattedList.unshift(team);
            }
        }

        return formattedList;
    }, [teamsList, favouriteTeam]);

    const updateFilterItem = (filterItem, value) => {
        dispatch(UPDATE_FILTER_ITEM.request({ filterItem, value }));
        handleCookieUpdate(filterItem, value);
    };

    if (isFetching) {
        return (
            <ul className="content-grid-filters__loader">
                <Loader />
            </ul>
        );
    }

    /**
     * Update 'teams' array to include custom elements, e.g. 'clear' header and
     * maximum items threshold messaging. Adding this here means the list that
     * Downshift uses contains all the items we need and does't need to be
     * added later outside of the list.
     */
    teams.unshift(
        {
            type: 'clear',
            title: `${getTranslation('label.fixtures.filters.teams')}`
        },
        {
            type: 'threshold',
            value: `${getTranslation('label.filters.teams.maxSelected')}`
        }
    );

    return (
        <SelectMultiple
            items={teams}
            selections={teams.filter((t) => filters.teams.includes(t.id))}
            label={getTranslation('label.fixtures.filters.team')}
            title={getTranslation('label.fixtures.filters.teams')}
            icon={icon}
            itemToString={(team) => team.name}
            onSelectedItemsChange={(team) => {
                updateFilterItem(
                    'teams',
                    team.map((t) => t.id)
                );
            }}
            getItemDisabled={(item) => item.type === 'clear'}
            addOnResetCallback={addOnResetCallback}
            getItemText={(item) => item.name}
            getItemLabel={(item) => (
                <span
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: 1,
                        width: '100%'
                    }}
                    className={`${
                        item.favouriteTeam
                            ? 'select-multiple__favourite-team'
                            : ''
                    }`}
                >
                    <span style={{ marginRight: '1rem' }}>{item.name}</span>
                    <TeamIcon
                        providerId={item.providerId}
                        className="icon"
                        style={{ width: '3.6rem', height: '3.6rem' }}
                    />
                </span>
            )}
            counterThreshold={counterThreshold}
            maximumSelections={maximumSelections}
            maximumSelectionsLabel={getTranslation(
                'label.filters.teams.maxSelected'
            )}
            type="with-limit"
        />
    );
};

CGTeamFilter.propTypes = {
    addOnResetCallback: PropTypes.func,
    counterThreshold: PropTypes.number,
    maximumSelections: PropTypes.number,
    icon: PropTypes.string,
    defaultComp: PropTypes.object,
    isAFLW: PropTypes.bool,
    handleCookieUpdate: PropTypes.func
};
