import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { LadderTableFetcher } from './LadderTableFetcher';
import { SwitchInput } from 'common/react/components/SwitchInput';
import { getTranslation } from 'common/react/utils/translations';
import { useWindowHeight } from 'common/react/hooks/use-window-resize';
import { PredictedLadderTable } from './predicted-ladder/PredictedLadderTable';
import { PredictedLadderMovementCallout } from './predicted-ladder/PredictedLadderMovementCallout';

/*
 * Render ladder drawer
 */
export const LadderDrawer = (props) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const drawerRef = useRef(null);
    const drawerDragRef = useRef(null);
    const startY = useRef(0);
    const currentY = useRef(0);
    const isDragging = useRef(false);
    const dragThreshold = 60;
    const initialHeight = 135;
    const maxHeightWhenClosed = 300;
    const windowHeight = useWindowHeight(500);

    const handleDrawerStatus = () => {
        setIsDrawerOpen(!isDrawerOpen);
        drawerRef.current.style.height = isDrawerOpen
            ? `${initialHeight}px`
            : `${windowHeight}px`;

        document.body.style.overflowY = isDrawerOpen ? '' : 'hidden';
    };

    const handleChange = (toggleStatus) => {
        props.onToggle(toggleStatus);
    };

    const handleEnd = () => {
        if (!isDragging.current) {
            return;
        }

        const deltaY = currentY.current - startY.current;

        if (Math.abs(deltaY) >= dragThreshold) {
            if (deltaY < 0 && !isDrawerOpen) {
                setIsDrawerOpen(true);
                drawerRef.current.style.height = `${windowHeight}px`;
                document.body.style.overflowY = 'hidden';
            } else if (deltaY > 0 && isDrawerOpen) {
                setIsDrawerOpen(false);
                drawerRef.current.style.height = `${initialHeight}px`;
                document.body.style.overflowY = '';
            }
        } else if (!isDrawerOpen) {
            drawerRef.current.style.height = `${initialHeight}px`;
        }

        isDragging.current = false;
        startY.current = 0;
        currentY.current = 0;
    };

    const handleStart = (y, target, e) => {
        if (drawerDragRef.current && drawerDragRef.current.contains(target)) {
            startY.current = y;
            currentY.current = y;
            isDragging.current = true;
        }
        e.preventDefault();
    };

    const handleMove = (y, e) => {
        if (!isDragging.current) {
            return;
        }
        e.preventDefault();
        currentY.current = y;

        e.preventDefault();

        const deltaY = currentY.current - startY.current;

        if (deltaY < 0 && !isDrawerOpen) {
            const newHeight = Math.min(
                initialHeight - deltaY,
                maxHeightWhenClosed
            );
            drawerRef.current.style.height = `${newHeight}px`;
        }
    };

    useEffect(() => {
        if (isDrawerOpen && windowHeight && drawerRef.current) {
            drawerRef.current.style.height = `${windowHeight}px`;
        }
    }, [windowHeight, isDrawerOpen]);

    useEffect(() => {
        const handleMouseDown = (e) => handleStart(e.clientY, e.target, e);
        const handleMouseMove = (e) => handleMove(e.clientY, e);
        const handleMouseUp = () => handleEnd();

        const handleTouchStart = (e) =>
            handleStart(e.touches[0].clientY, e.target, e);
        const handleTouchMove = (e) => handleMove(e.touches[0].clientY, e);
        const handleTouchEnd = () => handleEnd();

        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);

            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [isDrawerOpen]);

    return (
        <div
            ref={drawerRef}
            className={`ladder-table-drawer ${
                isDrawerOpen ? 'ladder-table-drawer--expanded' : ''
            }`}
        >
            <div
                className="ladder-table-drawer__handle-wrapper"
                ref={drawerDragRef}
            >
                <div className="ladder-table-drawer__handle">
                    <div className="ladder-table-drawer__handle-ui"></div>
                </div>
                <div className="ladder-table-drawer__heading">
                    <div className="ladder-table-drawer__heading-content">
                        <SvgIcon
                            icon="ladder"
                            className="ladder-table-drawer__heading-content-icon-ladder"
                        />
                        <h2 className="ladder-table-drawer__heading-content-title">
                            {props.heading}
                        </h2>
                    </div>
                    <button
                        className="ladder-table-drawer__heading-content-icon-container"
                        aria-label={
                            isDrawerOpen
                                ? getTranslation('label.ladderPredictor.open')
                                : getTranslation('label.ladderPredictor.close')
                        }
                        onClick={handleDrawerStatus}
                    >
                        <SvgIcon
                            icon="chevron-down"
                            className={`ladder-table-drawer__heading-content-icon-display ${
                                isDrawerOpen
                                    ? 'ladder-table-drawer__heading-content-icon-display--open'
                                    : ''
                            }`}
                        />
                    </button>
                </div>

                {isDrawerOpen ? (
                    <SwitchInput
                        id={'switch-input'}
                        className="ladder-table-drawer"
                        label={getTranslation(
                            'label.ladderPredictor.actualLadder'
                        )}
                        isChecked={props.isLiveLadder}
                        onChange={(newValue) => handleChange(newValue)}
                    />
                ) : null}
            </div>
            <PredictedLadderMovementCallout />
            {isDrawerOpen && (
                <div className="ladder-table-drawer__table-wrapper">
                    {props.isLiveLadder ? (
                        <LadderTableFetcher
                            competitionId={props.competitionId}
                            compSeason={props.compSeason}
                            round={props.round}
                            displayFormTooltip={true}
                        />
                    ) : (
                        <PredictedLadderTable compSeason={props.compSeason} />
                    )}
                </div>
            )}
        </div>
    );
};

LadderDrawer.propTypes = {
    competitionId: PropTypes.string,
    compSeason: PropTypes.object,
    round: PropTypes.object,
    onToggle: PropTypes.func,
    heading: PropTypes.string.isRequired,
    isLiveLadder: PropTypes.bool,
    team: PropTypes.object.isRequired
};
