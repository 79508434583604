import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { FilteredByesGroup } from './filtered-byes-group';
import { MatchCardSeasonCompleted } from '../../match-cards/season/completed';
import { MatchCardSeasonUpcoming } from '../../match-cards/season/upcoming';
import { ListTitle } from './list-title';

const DIGIT_ONLY_REGEX = /^[0-9]*$/;

/*
 * Render matches list components
 */
export const TypeRenderer = ({ item, selectedTeam, index, isTeam }) => {
    const itemType = useMemo(() => {
        if (typeof item === 'string') {
            if (item.match(DIGIT_ONLY_REGEX) || item === 'TBCPC') {
                return 'date';
            }
            return 'round';
        }
        if (
            typeof item === 'object' &&
            'isBye' in item &&
            item.isBye === true
        ) {
            return 'bye';
        }

        return 'match';
    }, [item]);

    switch (itemType) {
        case 'date': {
            return <ListTitle dateKey={item} />;
        }
        case 'round': {
            return isTeam ? (
                <p className="ladder-predictor-sidebar__list-round">{item}</p>
            ) : (
                <></>
            );
        }
        case 'bye':
            return (
                <FilteredByesGroup
                    teams={[selectedTeam]}
                    roundName={item.name}
                />
            );
        default:
            return (
                <ErrorBoundary key={item.id} fallback={<></>}>
                    {item.status === 'CONCLUDED' ? (
                        <MatchCardSeasonCompleted
                            match={item}
                            key={`match__${index}`}
                        />
                    ) : (
                        <MatchCardSeasonUpcoming
                            match={item}
                            key={`match__${index}`}
                        />
                    )}
                </ErrorBoundary>
            );
    }
};

TypeRenderer.propTypes = {
    item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    selectedTeam: PropTypes.object,
    index: PropTypes.number.isRequired,
    isTeam: PropTypes.bool
};
