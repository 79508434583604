import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { FixtureStatus } from './FixtureStatus';
import { FixtureDetails } from '../fixture-details/FixtureDetails';
import { FixtureWatermarks } from '../fixture-details/FixtureWatermarks';
import { FixtureMedia } from '../fixture-details/fixture-media/FixtureMedia';
import { FixtureLinks } from '../fixture-details/fixture-links/FixtureLinks';
import { ResponsiveFixtureLinks } from '../fixture-details/fixture-links/ResponsiveFixtureLinks';
import { WatchLive } from './WatchLive';
import { Broadcasters } from './broadcasters/Broadcasters';

import { useGetLiveStreamsQuery } from 'store/modules/api/content-api';

import { useAutoPollingMatchItem } from 'store/modules/api/hooks/useAutoPollingMatchItem';
import { useGetBettingOddsQuery } from 'store/modules/api/cfs-api';
import { useBettingVis } from 'common/react/hooks/use-betting-vis';
import { bettingOddsSelector } from 'store/modules/competition-nav/selectors';

const matchStatuses = PULSE.app.common.CONSTANTS.MATCH_STATUS;

const setFetchLiveMatch = (match) => {
    const matchInfoStatus = PULSE.app.common.match.getMatchStatus(match.status);

    if (
        matchInfoStatus === matchStatuses.LIVE ||
        PULSE.app.common.match.startsWithinAnHour(
            matchInfoStatus,
            match.utcStartTime
        ) ||
        (PULSE.app.common.match.isPastStartTime(match.utcStartTime) &&
            matchInfoStatus !== matchStatuses.COMPLETED)
    ) {
        return true;
    }
};

export const Fixture = ({
    match,
    specialRound,
    mcLink,
    reportLabel,
    broadcastGuideUrl,
    compseasonId,
    roundNumber,
    disableRounds
}) => {
    const { data: liveMatch } = useAutoPollingMatchItem(match.providerId, {
        skip: !setFetchLiveMatch(match)
    });

    // if match is live or polling before starting
    // we should use the status from the match data
    const matchStatus = PULSE.app.common.match.getMatchStatus(
        liveMatch?.match.status ? liveMatch.match.status : match.status
    );

    const fixtureData = {
        ...match,
        mcLink,
        matchLink: PULSE.app.common.url.getMatchURL(match),
        metadata: match.metadata || {},
        compseasonProviderId: match.compSeason.providerId
    };

    const { data: bettingData } = useGetBettingOddsQuery({
        competitionType: PULSE.app.common.match.getCompAbbr(
            match.compSeason.providerId
        )
    });

    const isBettingMatch = PULSE.app.common.betting.isBettingMatch(
        match.providerId,
        bettingData?.competition?.books
    );

    const isBettingVis = useBettingVis();
    const bettingOddsToggle = useSelector(bettingOddsSelector);

    // Parameters to get broadcaster match events for this round
    const params = {
        compseason: match?.compSeason?.id,
        round: match?.round?.roundNumber
    };

    /**
     * Fetch live video livestreams for the round, then check to see if there
     * is one for this match with the right attributes
     */
    const { liveStream } = useGetLiveStreamsQuery(
        { type: 'video', params },
        {
            selectFromResult: ({ data }) => ({
                liveStream: data?.content?.find(
                    (stream) =>
                        stream?.streams?.length &&
                        stream?.matchId === match.providerId &&
                        stream?.streams?.some((item) =>
                            item.customAttributes?.some(
                                (attr) =>
                                    attr.attrName === 'AFL Live Video' &&
                                    attr.attrValue === 'AFL Live Video'
                            )
                        )
                )
            })
        }
    );

    // Pull out the right stream item
    const liveStreamStream = liveStream?.streams?.find((item) =>
        item.customAttributes?.some(
            (attr) =>
                attr.attrName === 'AFL Live Video' &&
                attr.attrValue === 'AFL Live Video'
        )
    );

    // create a live stream url for video modal if a live stream exists for the fixture
    const streamUrl = liveStream
        ? PULSE.app.common.url.getLiveStreamUrl(liveStreamStream)
        : null;
    const streamImgUrl = liveStream ? liveStreamStream?.thumbnailURL : null;

    return (
        <div
            className={cn(`fixtures__item fixtures__item--${matchStatus}`, {
                'fixtures__item--betting-odds':
                    isBettingMatch &&
                    isBettingVis &&
                    bettingOddsToggle?.isActive
            })}
            data-match-id={match.id}
            data-match-provider-id={match.providerId}
            data-match-status={matchStatus}
            data-round-id={match.round.id}
        >
            {matchStatus === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE && (
                <FixtureWatermarks
                    home={match?.home?.team}
                    away={match?.away?.team}
                />
            )}

            <div className="fixtures__content">
                <FixtureStatus
                    match={match}
                    status={matchStatus}
                    liveMatch={liveMatch}
                />

                <div className="fixtures__media-container">
                    {matchStatus ===
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE &&
                    streamUrl ? (
                        <WatchLive
                            link={streamUrl}
                            imgUrl={streamImgUrl}
                            match={match}
                        />
                    ) : matchStatus ===
                          PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING ||
                      matchStatus ===
                          PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED ? (
                        <FixtureMedia match={match} matchStatus={matchStatus} />
                    ) : null}
                </div>

                <FixtureDetails
                    match={match}
                    specialRound={specialRound}
                    liveMatch={liveMatch}
                    link={fixtureData.matchLink}
                    matchStatus={matchStatus}
                />

                <div className="fixtures__link-container">
                    <FixtureLinks
                        match={match}
                        matchStatus={matchStatus}
                        metadata={fixtureData.metadata}
                        reportLabel={reportLabel}
                        broadcastGuideUrl={broadcastGuideUrl}
                    />
                </div>

                {(matchStatus ===
                    PULSE.app.common.CONSTANTS.MATCH_STATUS.UPCOMING ||
                    matchStatus ===
                        PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE) && (
                    <Broadcasters
                        match={match}
                        matchId={match.id}
                        compseasonId={compseasonId}
                        roundNumber={roundNumber}
                        disableRounds={disableRounds}
                    />
                )}

                <ResponsiveFixtureLinks
                    match={match}
                    matchStatus={matchStatus}
                    metadata={fixtureData.metadata}
                    reportLabel={reportLabel}
                    broadcastGuideUrl={broadcastGuideUrl}
                />
            </div>
        </div>
    );
};

Fixture.propTypes = {
    match: PropTypes.object.isRequired,
    specialRound: PropTypes.string,
    mcLink: PropTypes.bool,
    reportLabel: PropTypes.string,
    broadcastGuideUrl: PropTypes.string,
    compseasonId: PropTypes.number.isRequired,
    roundNumber: PropTypes.number.isRequired,
    disableRounds: PropTypes.bool
};
