import React, { useMemo } from 'react';
import { getTeamPlayersForPositions } from 'widgets/team-lineups-v2/js/utils/helpers';
import { PlayerEntry } from './PlayerEntry';
import PropTypes from 'prop-types';

/**
 * Section displaying players for the given positions
 *
 * @param {object} props
 * @param {object} props.positions Positions to display players for
 * @param {object} props.homeTeamPlayers Object containing home team's positions mapped to players. This is the object returned by mapPlayersToPositions helper
 * @param {object} props.awayTeamPlayers Object containing home team's positions mapped to players. This is the object returned by mapPlayersToPositions helper
 * @param {object} props.homeTeam Home team object
 * @param {object} props.awayTeam Away team object
 * @param {string} props.teamToShow Id of the team to display.
 * @param {boolean} [props.isAFLW] Default FALSE
 *
 * @returns {HTMLDivElement} Positions section element
 */
export const OtherPositionsSection = ({
    positions,
    homeTeamPlayers,
    awayTeamPlayers,
    homeTeam,
    awayTeam,
    teamToShow = '-1',
    isAFLW = false
}) => {
    // mapping home team players to oval section positions
    const sectionHomePlayers = useMemo(() => {
        if (positions) {
            const homeTeamObject = getTeamPlayersForPositions(
                positions,
                homeTeam,
                homeTeamPlayers
            );
            homeTeamObject.homeTeam = true;
            return homeTeamObject;
        }

        return;
    }, [positions, homeTeam, homeTeamPlayers]);

    // mapping away team players to oval section positions
    const sectionAwayPlayers = useMemo(() => {
        if (positions) {
            return getTeamPlayersForPositions(
                positions,
                awayTeam,
                awayTeamPlayers
            );
        }

        return;
    }, [positions, awayTeam, awayTeamPlayers]);

    // returns array to iterate over to render the players.
    const playersPerSection = useMemo(() => {
        const maxPlayers = Math.max(
            sectionHomePlayers?.players.filter((player) => !!player).length,
            sectionAwayPlayers?.players.filter((player) => !!player).length
        );

        if (maxPlayers === 0) {
            return [];
        }

        return Array.from(
            { length: maxPlayers },
            (value, iterator) => iterator
        );
    }, [sectionHomePlayers, sectionAwayPlayers]);

    return (
        <>
            {playersPerSection.length > 0 && (
                <div className="team-lineups__positions-section flex-column">
                    <div className="team-lineups__field-column-header">
                        {positions?.label}
                    </div>
                    <div className="team-lineups__players-list">
                        {playersPerSection.map((playerIndex) => {
                            return (
                                <div
                                    className="team-lineups__position-group flex-column"
                                    key={`team-lineups-${positions?.id}-group-${playerIndex}`}
                                >
                                    {(teamToShow === '-1' ||
                                        teamToShow === homeTeam.teamId) &&
                                        sectionHomePlayers.players[
                                            playerIndex
                                        ] && (
                                            <PlayerEntry
                                                player={
                                                    sectionHomePlayers.players[
                                                        playerIndex
                                                    ]
                                                }
                                                team={homeTeam}
                                                isHomeTeam={true}
                                                showPhoto={teamToShow !== '-1'}
                                                isAFLW={isAFLW}
                                                className="team-lineups__player-entry--fixed-width"
                                                trackedView="field view"
                                            />
                                        )}
                                    {(teamToShow === '-1' ||
                                        teamToShow === awayTeam.teamId) &&
                                        sectionAwayPlayers.players[
                                            playerIndex
                                        ] && (
                                            <PlayerEntry
                                                player={
                                                    sectionAwayPlayers.players[
                                                        playerIndex
                                                    ]
                                                }
                                                team={awayTeam}
                                                showPhoto={teamToShow !== '-1'}
                                                isAFLW={isAFLW}
                                                className="team-lineups__player-entry--fixed-width"
                                                trackedView="field view"
                                            />
                                        )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

OtherPositionsSection.propTypes = {
    positions: PropTypes.object,
    homeTeamPlayers: PropTypes.object,
    awayTeamPlayers: PropTypes.object,
    homeTeam: PropTypes.object,
    awayTeam: PropTypes.object,
    teamToShow: PropTypes.string,
    isAFLW: PropTypes.bool
};
