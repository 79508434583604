export const CONFIG = {
    CONCLUDED: PULSE.app.common.CONSTANTS.MATCH_STATUS.CONCLUDED,
    DEFAULT_MARGIN: 20,
    FINALS: 'finals',
    FLATTEN_DEPTH: 4,
    ICON_THEME_LIGHT: 'light',
    LAST_BYE_ROUND: 23,
    LOCAL_STORAGE_NAME: 'season_predictions',
    LOCAL_STORAGE_NAME_BRACKET: 'season_predictions_finals',
    MAX_MATCHES: 300,
    MAX_ROUNDS: 50,
    ROUND: 'round',
    SEASON: 'season',
    TEAM: 'team',
    ROUND_INDENTIFIER: 'Round',
    WEEK_INDENTIFIER: 'Week',
    FINAL_IDENTIFIER: 'Final',
    FINALS_MATCHES: 9
};

export const VENUE_NAMES = {
    CD_T10: 'Adelaide Oval', // Adelaide Crows
    CD_T20: 'Gabba', // Brisbane Lions
    CD_T30: 'MCG', // Carlton
    CD_T40: 'MCG', // Collingwood
    CD_T50: 'Marvel Stadium', // Essendon
    CD_T60: 'Optus Stadium', // Fremantle
    CD_T70: 'GMHBA Stadium', // Geelong Cats
    CD_T1000: 'People First Stadium', // Gold Coast SUNS
    CD_T1010: 'ENGIE Stadium', // GWS GIANTS
    CD_T80: 'MCG', // Hawthorn
    CD_T90: 'MCG', // Melbourne
    CD_T100: 'Marvel Stadium', // North Melbourne
    CD_T110: 'Adelaide Oval', // Port Adelaide
    CD_T120: 'MCG', // Richmond
    CD_T130: 'Marvel Stadium', // St Kilda
    CD_T160: 'SCG', // Sydney Swans
    CD_T150: 'Optus Stadium', // West Coast Eagles
    CD_T140: 'Marvel Stadium', // Western Bulldogs
    CD_T8098: 'Norwood Oval', // Adelaide Crows
    CD_T7887: 'Brighton Homes Arena', // Brisbane Lions (W)
    CD_T8096: 'Ikon Park', // Carlton (W)
    CD_T8097: 'Victoria Park', // Collingwood (W)
    CD_T9406: 'Windy Hill', // Essendon (W)
    CD_T7886: 'Fremantle Oval', // Freemantle (W)
    CD_T8467: 'GMHBA Stadium', // Geelong Cats (W)
    CD_T8786: 'People First Stadium', // Gold Coast SUNS (W)
    CD_T7889: 'Henson Park', // GWS GIANTS (W)
    CD_T9407: 'Kinetic Stadium', // Hawthorn (W)
    CD_T7386: 'Casey Fields', // Melbourne (W)
    CD_T8466: 'Arden Street Oval', // Kangaroos (W)
    CD_T9409: 'Alberton Oval', // Port Adelaide (W)
    CD_T8788: 'Swinburne Centre', // Richmond (W)
    CD_T8796: 'RSEA Park', // St Kilda (W)
    CD_T9408: 'Henson Park', // Sydney Swans (W)
    CD_T8787: 'Mineral Resources Park', // West Coast Eagles (W)
    CD_T7387: 'Whitten Oval', // Western Bulldogs (W)
    FINAL: 'MCG'
};
