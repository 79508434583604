import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { DraftTrackerLeaderboardTable } from './table/DraftTrackerLeaderboardTable';
import {
    ClubNavItem,
    positions,
    PositionsNavItem,
    RoundNavItem,
    Search
} from './nav-items';
import { Legend } from './table/components/Legend';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { SettingsButton } from 'common/react/components/SettingsButton';
import { useGetDraftByYearQuery } from './api/draft-tracker-api';
import { useFiltersTracking } from 'common/react/hooks/use-filters-tracking';
import { draftTrackerNavSelector } from 'store/modules/draft-tracker/leaderboard/selectors';

export const DraftTrackerLeaderboard = ({ seasonPid, seasonId, title }) => {
    document.body.classList.add('remove-sticky-nav');
    const year = seasonPid.substring(4, 8);

    const windowSize = useWindowSize();

    const isResponsive = () =>
        windowSize.width <= PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    const { data: draftData } = useGetDraftByYearQuery(year);

    const nav = useSelector(draftTrackerNavSelector);
    const statsSectionNavRef = useRef();

    /**
     * custom hook providing tracking of filters
     * because useFiltersTracking expects `nav` to have the structure as `state.competitionNav.nav` we need to craft the correct object structure
     */
    useFiltersTracking({
        title,
        filtersState: {
            season: { id: seasonId },
            round: { roundNumber: nav.round },
            filters: {
                teams: [nav.club]
            },
            position: { id: nav.position }
        },
        options: {
            positions: positions
        },
        filtersFields: ['round', 'teams', 'position'],
        filtersRef: statsSectionNavRef
    });

    return (
        <div ref={statsSectionNavRef}>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        {draftData && draftData.selections?.length && (
                            <RoundNavItem year={year} />
                        )}
                        {draftData && draftData.selections?.length && (
                            <ClubNavItem seasonId={seasonId} year={year} />
                        )}
                        {!showResponsiveItems ||
                        !(draftData && draftData.selections?.length) ? (
                            <PositionsNavItem
                                classes={'u-margin-right-auto'}
                                className={
                                    draftData && draftData.selections?.length
                                        ? 'select--rounded'
                                        : ''
                                }
                            />
                        ) : null}
                        {!showResponsiveItems ? (
                            <Search classes="search-filter-item" />
                        ) : null}
                        {showResponsiveItems ? (
                            <SettingsButton classes="settings-button-mobile" />
                        ) : null}
                    </StatsSection.NavItems>

                    {showResponsiveItems &&
                    draftData &&
                    draftData.selections?.length ? (
                        <StatsSection.NavFilterItems>
                            <PositionsNavItem year={year} title={title} />
                        </StatsSection.NavFilterItems>
                    ) : null}

                    {!showResponsiveItems ? (
                        <StatsSection.SettingsButton>
                            <SettingsButton />
                        </StatsSection.SettingsButton>
                    ) : null}

                    {showResponsiveItems ? (
                        <StatsSection.SearchBarMobile>
                            <Search />
                        </StatsSection.SearchBarMobile>
                    ) : null}
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentBody>
                        <DraftTrackerLeaderboardTable
                            year={year}
                            title={title}
                        />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
            <Legend />
        </div>
    );
};

DraftTrackerLeaderboard.propTypes = {
    seasonPid: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    title: PropTypes.string
};
