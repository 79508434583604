import { PlayerPhoto } from 'common/react/components/PlayerPhoto';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getTranslation } from 'common/react/utils/translations';
import { useGetImageScale } from 'widgets/team-lineups-v2/js/utils/use-get-image-scale';

/**
 * Player entry for Team Lineups field view
 * Renders player shirt number, name, and conditionally player photo.
 *
 * @param {object} props
 * @param {object} props.player Player object
 * @param {object} props.team Team object
 * @param {boolean} [props.isHomeTeam] Is the team acting as home team in that match
 * @param {boolean} [props.showPhoto] Shoul'd the player photo be displayed
 * @param {boolean} [props.isAFLW] Is the player playing in AFLW. Default FALSE.
 * @param {boolean} [props.className]  Custom css class to add to the element.
 * @param {boolean} [props.trackedView] View in which the PlayerEntry is used. Must be a human readable string. example: "list view"
 *
 * @returns {HTMLDivElement} Player entry element
 */
export const PlayerEntry = ({
    player,
    team,
    isHomeTeam = false,
    showPhoto = false,
    isAFLW = false,
    className = '',
    trackedView = 'team lineups'
}) => {
    const teamAbbr = PULSE.app.common.team.getTeamAbbr(team.teamId);
    const [playerProfileUrl, setPlayerProfileUrl] = useState();

    // getting player profile url
    const getPlayerPulseId = async () => {
        const pulseId = await PULSE.app.common.pulseId.getPlayerPulseId(
            player.playerId
        );
        setPlayerProfileUrl(
            PULSE.app.common.url.getPlayerProfileURL(
                {
                    firstName: player.playerName.givenName,
                    surname: player.playerName.surname,
                    id: pulseId
                },
                isAFLW
            )
        );
    };

    useEffect(() => {
        if (player) {
            getPlayerPulseId();
        }
    }, [player, isAFLW]);

    /**
     * Player link click handler
     * Triggers dataLayer event
     */
    const onClick = () => {
        if (playerProfileUrl) {
            const url = new URL(playerProfileUrl, location.origin);

            /* eslint-disable camelcase */
            PULSE.app.tracking.recordEvent(
                PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.LINK,
                {
                    eventData: {
                        link_text: `${player?.playerName?.givenName} ${player?.playerName?.surname}`,
                        link_url: playerProfileUrl,
                        outbound: url.origin !== location.origin,
                        type:
                            url.origin !== location.origin
                                ? 'external'
                                : 'internal',
                        component_name: trackedView,
                        component_content_type:
                            PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES
                                .LINEUPS
                    }
                }
            );
            /* eslint-enable camelcase */
        }
    };

    const imageScale = useGetImageScale();

    return (
        <a
            className={`team-lineups__player-entry ${className} flex-column ${
                isHomeTeam
                    ? 'team-lineups__player-entry--home-team'
                    : 'team-lineups__player-entry--away-team'
            }`}
            title={`${player?.playerName?.givenName} ${player?.playerName?.surname}`}
            aria-label={`${player?.playerName?.givenName} ${
                player?.playerName?.surname
            }. ${getTranslation(
                `label.playingposition.${player.position.toLowerCase()}`
            )}. ${team.teamName.teamName}.`}
            href={playerProfileUrl}
            onClick={onClick}
            tabIndex={0}
        >
            {showPhoto && (
                <div className="team-lineups__player-entry-photo">
                    <PlayerPhoto
                        className="team-lineups__player-entry-photo--image"
                        photoUrl={player.photoURL}
                        alt={`${player?.playerName?.givenName} ${player?.playerName?.surname}`}
                        scale={imageScale}
                    />
                </div>
            )}
            <div className="team-lineups__player-entry-label flex-row flex-row--nowrap">
                <div
                    className={`team-lineups__player-entry--shirt ${teamAbbr}`}
                >
                    {player?.playerJumperNumber ?? ''}
                </div>
                <div className="team-lineups__player-entry--name">
                    <span className="team-lineups__player-entry--name-first">
                        {player?.playerName?.givenName}
                    </span>
                    <span className="team-lineups__player-entry--name-letter">
                        {`${player?.playerName?.givenName?.charAt(0)}.`}
                    </span>{' '}
                    <span className="team-lineups__player-entry--name-last">
                        {player?.playerName?.surname}
                    </span>
                </div>
            </div>
        </a>
    );
};

PlayerEntry.propTypes = {
    player: PropTypes.object,
    team: PropTypes.object,
    isHomeTeam: PropTypes.bool,
    showPhoto: PropTypes.bool,
    isAFLW: PropTypes.bool,
    className: PropTypes.string,
    trackedView: PropTypes.string
};
