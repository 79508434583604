import React, { useEffect, useMemo } from 'react';
import { ProgressSteps } from './progress-steps/steps';
import { ProgressBar } from './progress-bar/progress-bar';
import { LadderTableController } from './ladder-table/LadderTableController';
import { Sidebar } from './sidebar/sidebar';
import { Bracket } from './bracket/bracket';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    useGetLadderQuery,
    useGetTeamsQuery,
    useGetMatchesQuery,
    useGetRoundsQuery
} from 'common/store/modules/api/afl-api';
import {
    setLadder,
    setTeams,
    setPrediction,
    setLocalStorageName
} from 'common/store/modules/ladder-predictor/init';
import { useSelector } from 'react-redux';

import { filterConcludedPredictions } from '../utils/filter-concluded-predictions';

import { CONFIG } from './constants';
import { LadderTableLegend } from './ladder-table/LadderTableLegend';

/**
 * Ladder predictor
 *
 * @param { object } root0 - Root
 * @param { string } root0.seasonId - The season ID.
 * @param { string } root0.competitionId - The competition ID.
 * @param { string } root0.seasonPid - The season provider ID.
 * @param { string } root0.seasonShortName - The season short name.
 * @param { string } root0.currentRoundNumber - The current round number.
 *
 * @returns {HTMLElement} - Returns the ladder predictor.
 */
export default function LadderPredictor({
    seasonId,
    competitionId,
    seasonPid,
    seasonShortName,
    roundNumber
}) {
    const dispatch = useDispatch();
    const activeView = useSelector((state) => state.ladderPredictor.activeView);

    // Retrieve data
    const { data: ladderData } = useGetLadderQuery(
        {
            compSeasonId: seasonId
        },
        {
            skip: !seasonId
        }
    );

    const { data: [...teamsData] = [] } = useGetTeamsQuery(seasonId, {
        skip: !seasonId ? true : false
    });

    const { data: [...matchesData] = [] } = useGetMatchesQuery({
        pageSize: CONFIG.MAX_MATCHES,
        competitionId: competitionId,
        compSeasonId: seasonId
    });

    const { data: [...roundsData] = [] } = useGetRoundsQuery(
        {
            compseasonId: seasonId,
            params: {
                pageSize: CONFIG.MAX_ROUNDS
            }
        },
        {
            skip: !seasonId ? true : false
        }
    );

    // Process retrieved data
    const orderedTeamsData = useMemo(
        () =>
            teamsData
                ? [...teamsData]
                      .filter((team) => team.name)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((team) => ({
                          ...team,
                          teamAbbr: team.abbreviation
                      }))
                : null,
        [teamsData]
    );

    // Process matches to remove finals
    const seasonMatches = useMemo(() => {
        if (matchesData) {
            const filteredMatches = matchesData.filter(
                (match) =>
                    (match.round.name.includes(CONFIG.ROUND_INDENTIFIER) &&
                        !match.round.name.includes(CONFIG.FINAL_IDENTIFIER)) ||
                    (match.round.name.includes(CONFIG.WEEK_INDENTIFIER) &&
                        !match.round.name.includes(CONFIG.FINAL_IDENTIFIER))
            );

            return filteredMatches;
        }

        return [];
    }, [matchesData]);

    // Set and process rounds
    const orderedRoundsData = useMemo(
        () =>
            roundsData
                ? [...roundsData]
                      .filter(PULSE.app.common.match.isNonFinalRound)
                      .sort((a, b) => a.roundNumber - b.roundNumber)
                      .map((round) => ({
                          ...round
                      }))
                : null,
        [roundsData]
    );

    // Set last round of season
    const lastRoundOfSeason = useMemo(() =>
        orderedRoundsData && orderedRoundsData.length > 0
            ? orderedRoundsData[orderedRoundsData.length - 1].roundNumber
            : (0)[orderedRoundsData]
    );

    // Set state required for ladder calculations
    useEffect(() => {
        if (ladderData?.ladders.length) {
            dispatch(setLadder(ladderData?.ladders[0].entries));
        }
    }, [ladderData]);

    useEffect(() => {
        if (orderedTeamsData?.length) {
            dispatch(setTeams(orderedTeamsData));
        }
    }, [orderedTeamsData]);

    // If all data retrieved check for stored predictions
    useEffect(() => {
        if (
            orderedTeamsData?.length &&
            ladderData?.ladders.length &&
            seasonMatches?.length &&
            orderedRoundsData?.length
        ) {
            let storageName =
                'comp' +
                competitionId +
                '_season' +
                seasonId +
                '_' +
                CONFIG.LOCAL_STORAGE_NAME;
            dispatch(setLocalStorageName(storageName));
            const storedPredictions = localStorage.getItem(storageName);

            if (storedPredictions?.length && activeView !== CONFIG.FINALS) {
                const predictions = JSON.parse(storedPredictions);
                const finalPredictions = filterConcludedPredictions(
                    predictions,
                    seasonMatches
                );
                // Loop through and set predictions like a user would do
                // in order to generate ladder incrementally.
                finalPredictions.forEach((prediction) => {
                    dispatch(setPrediction(prediction));
                });
            }
        }
    }, [
        orderedTeamsData,
        ladderData,
        seasonMatches,
        orderedRoundsData,
        activeView
    ]);

    return (
        <div className="ladder-predictor__container wrapper">
            <ProgressSteps />
            <ProgressBar
                seasonId={seasonId}
                competitionId={competitionId}
                matchesData={seasonMatches}
            />
            <div
                className={`ladder-predictor__season ${
                    activeView === CONFIG.SEASON ? 'is-active' : ''
                }`}
            >
                <aside className="ladder-predictor__sidebar">
                    <Sidebar
                        seasonId={seasonId}
                        teamsData={orderedTeamsData}
                        roundsData={orderedRoundsData}
                        matchesData={seasonMatches}
                        currentRoundNumber={roundNumber}
                    />
                </aside>

                <div className="ladder-predictor__ladder">
                    <LadderTableController
                        compSeason={{
                            id: seasonId,
                            providerId: seasonPid,
                            shortName: seasonShortName
                        }}
                        competitionId={competitionId}
                        round={{
                            roundNumber: lastRoundOfSeason
                        }}
                    />
                </div>
            </div>
            {activeView === CONFIG.FINALS ? (
                <div
                    className={`ladder-predictor__finals ${
                        activeView === CONFIG.FINALS ? 'is-active' : ''
                    }`}
                >
                    <Bracket
                        seasonId={seasonId}
                        competitionId={competitionId}
                    />
                </div>
            ) : null}

            {activeView === CONFIG.SEASON && <LadderTableLegend />}
        </div>
    );
}

LadderPredictor.propTypes = {
    seasonId: PropTypes.string,
    competitionId: PropTypes.string,
    seasonPid: PropTypes.string,
    seasonShortName: PropTypes.string,
    roundNumber: PropTypes.string
};
