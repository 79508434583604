import { TeamIcon } from 'common/react/components/TeamIcon';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Team indicator element - used in Team Lineups field view
 *
 * @param {object} props
 * @param {object} props.team Team object
 * @param {boolean} props.isHomeTeam Is the team acting as home team in the match
 * @param {boolean} [props.showCrest] Should the team crest be displayed instead of the shirt colours indicator. Default FALSE
 *
 * @returns {HTMLDivElement} Team Indicator element
 */
export const TeamIndicator = ({ team, isHomeTeam, showCrest = false }) => {
    const teamAbbr = PULSE.app.common.team.getTeamAbbr(team.teamId);

    return (
        <div className="team-lineups__team-indicator flex-row flex-row--nowrap flex-row--inline">
            {showCrest ? (
                <div className="team-lineups__team-indicator--crest">
                    <TeamIcon teamAbbreviation={teamAbbr} className="icon" />
                </div>
            ) : (
                <div
                    className={`team-lineups__team-indicator--colours ${teamAbbr.toLowerCase()} ${
                        isHomeTeam ? 'home-team' : 'away-team'
                    }`}
                />
            )}
            <div className="team-lineups__team-indicator--name">
                {team.teamName.teamName}
            </div>
        </div>
    );
};

TeamIndicator.propTypes = {
    team: PropTypes.object,
    isHomeTeam: PropTypes.bool,
    showCrest: PropTypes.bool
};
