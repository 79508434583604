import React, { useEffect, useMemo, useState } from 'react';
import { TeamLineupsNav } from './nav/TeamLineupsNav';
import PropTypes from 'prop-types';

import { TeamLineupsList } from './item/TeamLineupsList';
import { useGetCurrentCompFilters } from 'common/react/components/competition-nav/hooks/use-get-current-comp-filters';
import {
    useGetCompetitionsQuery,
    useGetMatchQuery
} from 'common/store/modules/api/afl-api';

const FIXTURE_PARAM = 'fixtureId';

export const TeamLineups = ({ data }) => {
    const { competitionId, competitionPid, compSeason, round } =
        useGetCurrentCompFilters();

    const fixtureIdParam = new URLSearchParams(window.location.search).get(
        FIXTURE_PARAM
    );

    const [expandLineups, setExpandLineups] = useState(false);

    // state of the Team Lineups toggles
    const [matchesToggles, setMatchesToggles] = useState([]);

    const { data: singleMatchData, isError } = useGetMatchQuery(
        { id: `${fixtureIdParam}` },
        { skip: !fixtureIdParam }
    );

    const { data: competitions = [] } = useGetCompetitionsQuery(
        {},
        { skip: !singleMatchData?.length }
    );

    /**
     * memo hook
     * Returns token out of currentMatchesToggles
     * used for array comparison
     *
     * @returns {string} "token" of the currentMatchesToggles list
     */
    const matchesTogglesToken = useMemo(() => {
        return JSON.stringify(matchesToggles);
    }, [matchesToggles]);

    const fixtureParamData =
        singleMatchData &&
        getComp(singleMatchData[0]?.compSeason.providerId, competitions) &&
        singleMatchData[0]?.compSeason &&
        singleMatchData[0]?.round
            ? {
                  competitionId: getComp(
                      singleMatchData[0]?.compSeason.providerId,
                      competitions
                  ).id,
                  seasonId: singleMatchData[0]?.compSeason.id,
                  roundId: singleMatchData[0]?.round.id
              }
            : null;

    /**
     * effect hook
     * reacting to change to matchesToggles updates the expandLineups values
     */
    useEffect(() => {
        setExpandLineups(
            matchesToggles.length > 0 &&
                !matchesToggles?.find((toggle) => !toggle.expanded)
        );
    }, [matchesToggles]);

    /**
     * "Expand All Lineups" toggle click handler
     * Updates the locally stored matchesToggles state with the new value from the toggle
     *
     * @param {boolean} expanded
     */
    const onExpandToggle = (expanded) => {
        // basic immutability
        const togglesCopy = matchesToggles.map((toggle) => ({
            ...toggle,
            expanded: expanded
        }));
        setMatchesToggles(togglesCopy);
    };

    /**
     * Team Lineups callback handler
     * Receives list of { id, expanded } objects representing each team lineup item in the view
     *
     * @param {Array} matches
     */
    const onMatchesToggleUpdate = (matches) => {
        // comparing the "token" of matches property with the matchesTogglesToken
        // this is done to prevent unnecessary rerenders
        if (
            matchesToggles.length === 0 ||
            JSON.stringify(matches) !== matchesTogglesToken
        ) {
            setMatchesToggles(matches);
        }
    };

    return (
        <>
            {isError || (!fixtureIdParam && !fixtureParamData) ? (
                <TeamLineupsNav
                    {...data}
                    expandLineups={expandLineups}
                    setExpandLineups={onExpandToggle}
                />
            ) : fixtureIdParam && fixtureParamData ? (
                <TeamLineupsNav
                    {...data}
                    expandLineups={expandLineups}
                    setExpandLineups={onExpandToggle}
                    fixtureParam={fixtureParamData}
                />
            ) : null}

            {!competitionId ||
            !compSeason?.id ||
            (!round?.roundNumber && round?.roundNumber !== 0) ? (
                <></>
            ) : (
                <TeamLineupsList
                    {...data}
                    onMatchesToggleUpdate={onMatchesToggleUpdate}
                    matchesToggles={matchesToggles}
                    competitionId={competitionId}
                    competitionPid={competitionPid}
                    compSeason={compSeason}
                    roundNumber={round.roundNumber}
                    fixtureIdParam={fixtureIdParam}
                />
            )}
        </>
    );
};

const getComp = (compSeasonId, competitions) => {
    if (!competitions) {
        return;
    }

    const compSeasonSuffixLength = 3;
    const compSeasonAbbr = compSeasonId.substr(
        compSeasonId.length - compSeasonSuffixLength
    );

    const found = competitions.find((competition) =>
        competition.providerId.includes(compSeasonAbbr)
    );

    return found ? found : null;
};

TeamLineups.propTypes = {
    data: PropTypes.object.isRequired
};
