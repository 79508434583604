import React from 'react';
import PropTypes from 'prop-types';

import { SearchResult } from './SearchResult';
import { Loader } from 'common/react/components/Loader';
import { getTranslation } from 'common/react/utils/translations';

export const SearchResultContainer = ({
    isLoading,
    results,
    query,
    focused,
    compseason
}) => {
    return (
        <ul
            className={
                'stats-search__results ' +
                (focused ? 'is-visible ' : '') +
                (!results?.length ? 'has-no-results' : '')
            }
        >
            {isLoading ? (
                <Loader />
            ) : results && results.length ? (
                results.map((result, i) => {
                    return (
                        <SearchResult
                            key={i}
                            result={result}
                            query={query}
                            compseason={compseason}
                            resultIndex={i}
                        />
                    );
                })
            ) : (
                getTranslation('label.search.noResultsFound')
            )}
        </ul>
    );
};

SearchResultContainer.propTypes = {
    isLoading: PropTypes.bool,
    results: PropTypes.array,
    query: PropTypes.string,
    focused: PropTypes.bool,
    compseason: PropTypes.string.isRequired
};
