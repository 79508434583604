import React from 'react';
import PropTypes from 'prop-types';

import { TeamIcon } from 'common/react/components/TeamIcon';

export const MatchCardTeamRowCompleted = ({
    match,
    isHomeTeam,
    homeTeam,
    awayTeam,
    score
}) => {
    const getTeamName = () => {
        let teamName = '';
        if (isHomeTeam) {
            if (homeTeam) {
                teamName = homeTeam.name;
            }
        } else if (awayTeam) {
            teamName = awayTeam.name;
        }
        return teamName;
    };

    return (
        <div className={`ladder-predictor-match-card__team `}>
            <TeamIcon
                className="ladder-predictor-match-card__team-logo"
                providerId={
                    isHomeTeam ? homeTeam?.providerId : awayTeam?.providerId
                }
            />
            <p
                className="ladder-predictor-match-card__position-static"
                aria-label="Team Position"
            >
                {isHomeTeam ? match?.home?.position : match?.away?.position}
            </p>
            <p
                className="ladder-predictor-match-card__name"
                aria-label="Team Name"
            >
                {getTeamName()}
            </p>
            <p
                className="ladder-predictor-match-card__score"
                aria-label="Team Score"
            >
                {score}
            </p>
        </div>
    );
};

MatchCardTeamRowCompleted.propTypes = {
    match: PropTypes.object.isRequired,
    isHomeTeam: PropTypes.bool,
    homeTeam: PropTypes.object,
    awayTeam: PropTypes.object,
    score: PropTypes.number
};
