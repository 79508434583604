import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ModalTitle } from './ModalTitle';
import { MarketingPreferences } from './ModalMarketingPreferences';
import { getTranslation } from 'common/react/utils/translations';
import {
    salesforceSitApi,
    useRetrieveMarketingPrefsQuery,
    useUpdateMarketingPrefsMutation
} from 'store/modules/api/salesforce-sit-api';
import { getUpdateBody } from 'common/store/modules/api/util/salesforce-helpers';
import { MarketingPreferencesSkeleton } from './MarketingPreferencesSkeleton';

export const UpdateCommPrefModalWrapper = () => {
    const dispatch = useDispatch();
    const {
        data: marketingPrefs,
        isLoading: isLoadingPreferences,
        isError
    } = useRetrieveMarketingPrefsQuery();

    // If we've opened the update one when they don't have prefs some how, then open the create one instead
    useEffect(() => {
        if (isError) {
            PULSE.app.common.siteSettings.openSettingsModal('createComms');
        }
    });

    /**
     * Callback for when a toggle all is changed. This updates the rtk query
     * data so that the UI updates properly before we actually send of the new
     * preferences. This means we don't need to mirror preferences in our own
     * state. The changes will be wiped on page reload unless they are saved and
     * the mutation is fired.
     *
     * @param {boolean} value - Is toggle checked?
     */
    function handleToggleAllClick(value) {
        dispatch(
            salesforceSitApi.util.updateQueryData(
                'retrieveMarketingPrefs',
                // eslint-disable-next-line no-undefined
                undefined,
                (draftPrefs) => {
                    draftPrefs.subscriptions.forEach((sub) => {
                        sub.status = value;
                    });
                }
            )
        );
    }

    /**
     * Callback for when a toggle is changed. This updates the rtk query data so
     * that the UI updates properly before we actually send of the new
     * preferences. This means we don't need to mirror preferences in our own
     * state. The changes will be wiped on page reload unless they are saved and
     * the mutation is fired.
     *
     * @param {string} key - What preference is being toggled?
     * @param {boolean} value - Is toggle checked?
     */
    function handleToggleClick(key, value) {
        dispatch(
            salesforceSitApi.util.updateQueryData(
                'retrieveMarketingPrefs',
                // eslint-disable-next-line no-undefined
                undefined,
                (draftPrefs) => {
                    const prefToUpdate = draftPrefs.preferences.find(
                        (sub) => sub.name === key
                    );
                    const subToUpdate = draftPrefs.subscriptions.find(
                        (sub) => sub.name === key
                    );

                    // Changing preferences
                    if (prefToUpdate) {
                        prefToUpdate.status = value;
                    } else if (subToUpdate) {
                        subToUpdate.status = value;
                    }

                    const hasActivePreferences = draftPrefs.preferences.some(
                        (pref) => pref.status
                    );
                    const hasActiveSubscriptions =
                        draftPrefs.subscriptions.some((pref) => pref.status);

                    if (prefToUpdate) {
                        // updated preferences

                        if (!hasActivePreferences) {
                            // no TRUE preferences
                            // setting all subscriptions to FALSE
                            draftPrefs.subscriptions.forEach((sub) => {
                                sub.status = false;
                            });
                        } else if (!hasActiveSubscriptions) {
                            // now TRUE subscriptions
                            // setting all subscriptions to TRUE
                            draftPrefs.subscriptions.forEach((sub) => {
                                sub.status = true;
                            });
                        }
                    } else if (subToUpdate) {
                        // updated subscriptions

                        if (!hasActiveSubscriptions) {
                            // no TRUE subscriptions
                            // setting all preferences to FALSE
                            draftPrefs.preferences.forEach((pref) => {
                                pref.status = false;
                            });
                        } else if (!hasActivePreferences) {
                            // no TRUE preferences
                            // settings all preferences to TRUE
                            draftPrefs.preferences.forEach((pref) => {
                                pref.status = true;
                            });
                        }
                    }
                }
            )
        );
    }

    const [updatePreferences] = useUpdateMarketingPrefsMutation();

    /**
     * Callback for when the save button is clicked, fires of the mutation call
     * to update preferences & subscriptions with salesforce
     *
     * If there's an error in the resposne, it triggers the error
     * handling modal to show.
     */
    async function handleSaveClick() {
        const body = getUpdateBody({
            preferences: marketingPrefs.preferences,
            subscriptions: marketingPrefs.subscriptions
        });

        try {
            await updatePreferences(body).unwrap();

            PULSE.app.common.siteSettings.closeSettingsModal();
        } catch (error) {
            PULSE.app.common.siteSettings.openSettingsModal('commsError');
        }
    }

    return (
        <>
            <ModalTitle
                title={getTranslation('settingsModal.aflidSettings')}
                closeBtn={false}
            />

            {isLoadingPreferences ? (
                <MarketingPreferencesSkeleton />
            ) : marketingPrefs?.subscriptions ? (
                <MarketingPreferences
                    title={getTranslation(
                        'settingsModal.communicationPreferences.title'
                    )}
                    subtitle={getTranslation(
                        'settingsModal.communicationPreferences.subtitle'
                    )}
                    marketingPrefs={marketingPrefs}
                    onToggleAllClick={handleToggleAllClick}
                    onToggleClick={handleToggleClick}
                    onSaveClick={handleSaveClick}
                    sectionTitles={true}
                />
            ) : null}
        </>
    );
};
