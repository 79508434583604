import React from 'react';
import { getTranslation } from 'common/react/utils/translations';

/*
 * Ladder Table Legend
 */
export const LadderTableLegend = () => {
    return (
        <div className="ladder-predictor-legend">
            <dl className="ladder-predictor-legend__list">
                {/* top X */}

                <dt className="ladder-predictor-legend__item ladder-predictor-legend__item--key">
                    {getTranslation(`label.ladder.top`)} 8:
                </dt>

                <dd className="ladder-predictor-legend__item">
                    {getTranslation(`label.ladder.qualifyTo`)}
                </dd>

                {/* played */}
                <dt className="ladder-predictor-legend__item ladder-predictor-legend__item--key">
                    {getTranslation(`label.ladder.played.short`)}:
                </dt>
                <dd className="ladder-predictor-legend__item">
                    {getTranslation(`label.ladder.legend.played`)}
                </dd>

                {/* points */}
                <dt className="ladder-predictor-legend__item ladder-predictor-legend__item--key">
                    {getTranslation(`label.ladder.points.short`)}:
                </dt>

                <dd className="ladder-predictor-legend__item">
                    {getTranslation(`label.ladder.points`)}
                </dd>

                {/* percentage */}
                <dt className="ladder-predictor-legend__item ladder-predictor-legend__item--key">
                    %:
                </dt>

                <dd className="ladder-predictor-legend__item">
                    {getTranslation(`label.ladder.legend.percentage`)}
                </dd>

                {/* won */}
                <dt className="ladder-predictor-legend__item ladder-predictor-legend__item--key">
                    {getTranslation(`label.ladder.won.short`)}:
                </dt>
                <dd className="ladder-predictor-legend__item">
                    {getTranslation(`label.ladder.won`)}
                </dd>

                {/* lost */}
                <dt className="ladder-predictor-legend__item ladder-predictor-legend__item--key">
                    {getTranslation(`label.ladder.lost.short`)}:
                </dt>

                <dd className="ladder-predictor-legend__item">
                    {getTranslation(`label.ladder.lost`)}
                </dd>

                {/* drawn */}
                <dt className="ladder-predictor-legend__item ladder-predictor-legend__item--key">
                    {getTranslation(`label.ladder.drawn.short`)}:
                </dt>
                <dd className="ladder-predictor-legend__item">
                    {getTranslation(`label.ladder.drawn`)}
                </dd>

                {/* last five (form) */}
                <dt className="ladder-predictor-legend__item ladder-predictor-legend__item--key">
                    {getTranslation(`label.form`)}:
                </dt>
                <dd className="ladder-predictor-legend__item">
                    {getTranslation(`label.ladder.lastFiveGames`)}
                </dd>
            </dl>
        </div>
    );
};
