import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getTranslation } from '../../../utils/translations';
import { Select } from '../../select';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { compSeasonSelector } from 'common/store/modules/competition-nav/selectors/compseason-selector';
import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';

export const RoundSelectNavItem = ({ removeFinals }) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);

    const roundsLoadError = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.error
    );

    const roundsFromStore = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list
    );

    // determine the current round of the competition so we can style that select box
    const currentRoundIndex =
        useSelector(compSeasonSelector)?.currentRoundNumber - 1 || false;

    const roundsList = removeFinals
        ? roundsFromStore?.filter(PULSE.app.common.match.isNonFinalRound)
        : roundsFromStore;

    return !roundsLoadError &&
        !(Array.isArray(roundsList) && roundsList.length === 0) ? (
        <div className="competition-nav__season-select">
            <Select
                items={roundsList}
                itemToString={(round) => round?.name}
                initialSelectedItem={
                    roundsList && nav.round.id
                        ? roundsList.find((s) => s.id === Number(nav.round.id))
                        : ''
                }
                activeItem={
                    roundsList && nav.round.id
                        ? roundsList.find((s) => s.id === Number(nav.round.id))
                        : ''
                }
                isReadyToRender={() =>
                    roundsList?.length && Number(nav.round.id)
                }
                label={getTranslation('label.stats.round')}
                onSelectedItemChange={({ selectedItem }) => {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'round',
                            value: selectedItem
                        })
                    );
                }}
                currentRoundIndex={currentRoundIndex}
            />
        </div>
    ) : null;
};

RoundSelectNavItem.propTypes = {
    removeFinals: PropTypes.bool,
    title: PropTypes.string
};
