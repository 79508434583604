import { getTranslation } from 'common/react/utils/translations';

export const composeColumnData = (
    windowWidth,
    isBettingOddsToggled,
    isBettingVis,
    hasBettingDataForSeason
) => {
    let cols = [];

    // display abbreviated columns in mobile
    if ((windowWidth ?? innerWidth) < PULSE.app.measurements.desktop) {
        cols = [
            {
                Header: getTranslation(`label.ladder.pos.short`),
                accessor: 'position'
            },
            {
                Header: getTranslation(`label.ladder.club`),
                accessor: 'club'
            },
            {
                Header: '',
                accessor: 'clubName'
            },
            {
                Header: getTranslation(`label.ladder.played.short`),
                accessor: 'played'
            },
            {
                Header: getTranslation(`label.ladder.winRatio`),
                accessor: 'winRatio'
            },
            {
                Header: getTranslation(`label.ladder.points.short`),
                accessor: 'points'
            },
            {
                Header: '%',
                accessor: 'percentage'
            },
            {
                Header: getTranslation(`label.ladderPredictor.allOutcomes`),
                accessor: 'won'
            },
            {
                Header: getTranslation(`label.ladder.clubForm`),
                accessor: 'form'
            },
            {
                Header: 'Position Gained/Lost (HIDDEN CELL)',
                accessor: 'positionChange'
            },
            {
                Header: 'Is Playing (HIDDEN CELL)',
                accessor: 'isPlaying'
            }
        ];
    } else {
        cols = [
            {
                Header: getTranslation(`label.ladder.pos.short`),
                accessor: 'position'
            },
            {
                Header: getTranslation(`label.ladder.club`),
                accessor: 'club'
            },
            {
                Header: '',
                accessor: 'clubName'
            },
            {
                Header: getTranslation(`label.ladder.played.short`),
                accessor: 'played'
            },
            {
                Header: getTranslation(`label.ladder.winRatio`),
                accessor: 'winRatio'
            },
            {
                Header: getTranslation(`label.ladder.points.short`),
                accessor: 'points'
            },
            {
                Header: '%',
                accessor: 'percentage'
            },
            {
                Header: getTranslation(`label.ladderPredictor.allOutcomes`),
                accessor: 'won'
            },
            {
                Header: getTranslation(`label.ladder.clubForm`),
                accessor: 'form'
            },
            {
                Header: 'Position Gained/Lost (HIDDEN CELL)',
                accessor: 'positionChange'
            },
            {
                Header: 'Is Playing (HIDDEN CELL)',
                accessor: 'isPlaying'
            }
        ];
    }

    if (isBettingOddsToggled && isBettingVis && hasBettingDataForSeason) {
        cols.push({
            Header: 'Odds',
            accessor: 'bettingOdds'
        });
    }

    return cols;
};
