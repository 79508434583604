/* eslint-disable id-length, camelcase, no-unused-vars, no-magic-numbers, no-undefined, no-self-assign */

// Constants
const DEFAULT_DRAW_VALUE = 90;
const ONE_HUNDRED = 100;
const MIN_PERCENTAGE = 1;
const MAX_PERCENTAGE = 999.9;

const SCORE_MAPPING = {
    0: [90, 90],
    10: [95, 85],
    20: [100, 80],
    30: [105, 75],
    40: [110, 70],
    50: [115, 65],
    60: [120, 60],
    70: [125, 55],
    80: [130, 50],
    90: [135, 45],
    100: [140, 40]
};

// Store the last generated ladder in order to work out movements.
let lastGeneratedLadder = {};

/**
 * Calculates the percentage.
 *
 * @param {number} pointsScored - The points scored by the team.
 * @param {number} pointsAgainst - The points scored against the team.
 * @returns {number} - The percentage, clamped between 1% and 999.99%, rounded to one decimal place.
 */
function calculatePercentage(pointsScored, pointsAgainst) {
    const percentage = (pointsScored / pointsAgainst) * ONE_HUNDRED;

    // Clamp the percentage between 1 and 999.9
    const clampedPercentage = Math.min(
        Math.max(percentage, MIN_PERCENTAGE),
        MAX_PERCENTAGE
    );

    // Round to 1 decimal place
    return parseFloat(clampedPercentage.toFixed(1));
}

/**
 * Generate the ladder based on predictions
 *
 * @param {Array} predictions - An array of prediction objects
 * @param {Array} teams - An array of team objects
 * @param {Array} previousLadder - An array representing the initial ladder
 * @returns {Array} - An updated array representing the ladder
 */
export const generateLadder = (predictions, teams, previousLadder = []) => {
    // If no predictions skip position change calculation as
    // there will be no changes.
    let skipPositionChangeCalculation = predictions.length === 0 ? true : false;

    // Initialize the map for previous ladder data
    const previousLadderMap = previousLadder.reduce((acc, team) => {
        acc[team.team.id] = team;
        return acc;
    }, {});

    // Initialize team stats
    const teamStats = teams.reduce((acc, team) => {
        const previousStats = previousLadderMap[team.id];
        acc[team.id] = previousStats
            ? {
                  ...previousStats,
                  position: previousStats.position || 0,
                  played: previousStats.played || 0,
                  pointsFor: previousStats.pointsFor || 0,
                  pointsAgainst: previousStats.pointsAgainst || 0,
                  thisSeasonRecord: {
                      ...previousStats.thisSeasonRecord,
                      percentage:
                          previousStats.thisSeasonRecord.percentage || 0,
                      aggregatePoints:
                          previousStats.thisSeasonRecord.aggregatePoints || 0,
                      winLossRecord: {
                          wins:
                              previousStats.thisSeasonRecord.winLossRecord
                                  .wins || 0,
                          losses:
                              previousStats.thisSeasonRecord.winLossRecord
                                  .losses || 0,
                          draws:
                              previousStats.thisSeasonRecord.winLossRecord
                                  .draws || 0
                      }
                  },
                  form: Array.isArray(previousStats.form)
                      ? previousStats.form // If already an array, keep it
                      : previousStats.form
                      ? previousStats.form.split('').map((result, index) => ({
                            round: index + 1,
                            result: result
                        })) // Convert string to array of objects
                      : [], // If form is empty, initialize it as an empty array
                  positionChange: previousStats.positionChange || null // Reset positionChange for this round
              }
            : {
                  position: 0,
                  team: {
                      id: team.id,
                      name: team.name,
                      providerId: team.providerId
                  },
                  played: 0,
                  pointsFor: 0,
                  pointsAgainst: 0,
                  thisSeasonRecord: {
                      percentage: 0,
                      aggregatePoints: 0,
                      winLossRecord: {
                          wins: 0,
                          losses: 0,
                          draws: 0
                      }
                  },
                  form: [], // Form is an array to hold all results
                  positionChange: null // To track movement
              };
        return acc;
    }, {});

    let sortedTeams = Object.values(teamStats).sort((a, b) => {
        if (
            b.thisSeasonRecord.aggregatePoints !==
            a.thisSeasonRecord.aggregatePoints
        ) {
            return (
                b.thisSeasonRecord.aggregatePoints -
                a.thisSeasonRecord.aggregatePoints
            );
        }
        if (b.thisSeasonRecord.percentage !== a.thisSeasonRecord.percentage) {
            return (
                b.thisSeasonRecord.percentage - a.thisSeasonRecord.percentage
            );
        }
        return a.team.name.localeCompare(b.team.name);
    });

    const updatePositions = () => {
        // Sort teams based on updated stats
        sortedTeams = Object.values(teamStats).sort((a, b) => {
            if (
                b.thisSeasonRecord.aggregatePoints !==
                a.thisSeasonRecord.aggregatePoints
            ) {
                return (
                    b.thisSeasonRecord.aggregatePoints -
                    a.thisSeasonRecord.aggregatePoints
                );
            }
            if (
                b.thisSeasonRecord.percentage !== a.thisSeasonRecord.percentage
            ) {
                return (
                    b.thisSeasonRecord.percentage -
                    a.thisSeasonRecord.percentage
                );
            }
            return a.team.name.localeCompare(b.team.name);
        });
    };

    // Ensure position updates happen after each match prediction
    predictions.forEach(({ i, r, w, l, d, m }) => {
        const winningTeam = teams.find((team) => team.id === w).id;
        const losingTeam = teams.find((team) => team.id === l).id;
        const [winnerScore, loserScore] = SCORE_MAPPING[m] || [100, 80];

        if (d) {
            teamStats[losingTeam].thisSeasonRecord.aggregatePoints += 2;
            teamStats[winningTeam].thisSeasonRecord.aggregatePoints += 2;
            teamStats[losingTeam].pointsFor += DEFAULT_DRAW_VALUE;
            teamStats[winningTeam].pointsFor += DEFAULT_DRAW_VALUE;
            teamStats[losingTeam].pointsAgainst += DEFAULT_DRAW_VALUE;
            teamStats[winningTeam].pointsAgainst += DEFAULT_DRAW_VALUE;
            teamStats[losingTeam].thisSeasonRecord.winLossRecord.draws += 1;
            teamStats[winningTeam].thisSeasonRecord.winLossRecord.draws += 1;
        } else {
            teamStats[winningTeam].thisSeasonRecord.aggregatePoints += 4;
            teamStats[losingTeam].pointsFor += loserScore;
            teamStats[winningTeam].pointsFor += winnerScore;
            teamStats[losingTeam].pointsAgainst += winnerScore;
            teamStats[winningTeam].pointsAgainst += loserScore;
            teamStats[winningTeam].thisSeasonRecord.winLossRecord.wins += 1;
            teamStats[losingTeam].thisSeasonRecord.winLossRecord.losses += 1;
        }

        teamStats[winningTeam].played += 1;
        teamStats[losingTeam].played += 1;

        // Add the result to form as an object with round and result
        teamStats[winningTeam].form.push({ round: r, result: d ? 'D' : 'W' });
        teamStats[losingTeam].form.push({ round: r, result: d ? 'D' : 'L' });

        // Sort the form by round to keep chronological order
        teamStats[winningTeam].form.sort((a, b) => a.round - b.round);
        teamStats[losingTeam].form.sort((a, b) => a.round - b.round);

        teamStats[winningTeam].thisSeasonRecord.percentage =
            calculatePercentage(
                teamStats[winningTeam].pointsFor,
                teamStats[winningTeam].pointsAgainst
            );
        teamStats[losingTeam].thisSeasonRecord.percentage = calculatePercentage(
            teamStats[losingTeam].pointsFor,
            teamStats[losingTeam].pointsAgainst
        );

        updatePositions();
    });

    // Assign new positions and calculate position changes
    sortedTeams.forEach((team, index) => {
        const newPosition = index + 1;
        const previousPosition = lastGeneratedLadder[team.team.id];

        team.position = newPosition;

        if (skipPositionChangeCalculation) {
            team.positionChange = team.positionChange;
        } else if (newPosition < previousPosition) {
            team.positionChange = 'UP';
        } else if (newPosition > previousPosition) {
            team.positionChange = 'DOWN';
        } else {
            team.positionChange = 'NONE';
        }
    });

    // Set the last generated ladder to be used for working out
    // position change on the next generated ladder.
    sortedTeams.forEach((team) => {
        lastGeneratedLadder[team.team.id] = team.position;
    });

    return sortedTeams;
};
