import React from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from 'common/react/utils/translations';

/*
 * Render match card footer
 */
export const MatchCardFooter = ({ matchName, showComplete }) => {
    return (
        <div className="ladder-predictor-match-card__footer">
            <p className="ladder-predictor-match-card__detail">
                <strong>{matchName ? matchName : 'Finals Game'}</strong>
            </p>

            {showComplete && (
                <p className="ladder-predictor-match-card__complete-label">
                    {getTranslation('label.ladderPredictor.complete')}
                </p>
            )}
        </div>
    );
};

MatchCardFooter.propTypes = {
    matchName: PropTypes.string.isRequired,
    showComplete: PropTypes.bool.isRequired
};
