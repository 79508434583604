import React, { useEffect, useRef, useState } from 'react';

import { InformationCategoryHeader } from './InformationCategoryHeader';
import { InformationCard } from './InformationCard';

import { InformationBottomDrawer } from './InformationBottomDrawer';
import { MapLocationSelector } from './MapLocationSelector';

import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
import { InformationSideBarItem } from './InformationSideBarItem';
import { useCupertinoPane } from '../hooks';

export const InformationSideBar = (data) => {
    const orientationChangeDelayRef = useRef();
    const mapContainer = document.querySelector(
        '.js-interactive-event-map-container'
    );
    const startingVenue = useGetContentItemQuery({
        type: 'PLAYLIST',
        id: mapContainer?.dataset?.startingVenueId
    }).data;
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [googlePlacesData, setGooglePlacesData] = useState(null);
    const [isMobile, setIsMobile] = useState(
        window.matchMedia(`(max-width: ${PULSE.app.measurements.tablet}px)`)
            .matches
    );
    const [locations, setLocations] = useState([]);
    const [selectedVenue, setSelectedVenue] = useState({
        name: startingVenue?.title,
        imgRef: null
    });

    const { data: selectedVenueItems, isFetching } = useGetContentItemQuery(
        {
            type: 'PLAYLIST',
            id: selectedVenue?.id
        },
        {
            skip: selectedVenue?.name === startingVenue?.title
        }
    );

    const onScreenOrientationChange = () => {
        clearTimeout(orientationChangeDelayRef.current);
        orientationChangeDelayRef.current = setTimeout(() => {
            setIsMobile(
                window.matchMedia(
                    `(max-width: ${PULSE.app.measurements.tablet}px)`
                ).matches
            );
        }, 500);
    };

    useEffect(() => {
        if (selectedVenue.name === startingVenue?.title) {
            setLocations(startingVenue?.items);
        } else {
            setLocations(selectedVenueItems?.items);
        }
    }, [selectedVenue, selectedVenueItems]);

    useEffect(() => {
        const updateStorageHandle = () => {
            setSelectedCategory(
                PULSE.core.localStorage.getStorage('mapSelectedCategory') ||
                    null
            );

            setSelectedLocation(
                JSON.parse(
                    PULSE.core.localStorage.getStorage('mapSelectedMarker')
                ) || null
            );

            setGooglePlacesData(
                JSON.parse(
                    PULSE.core.localStorage.getStorage(
                        'mapSelectedMarkerGoogleData'
                    )
                ) || null
            );
        };

        window.addEventListener('storageChange', updateStorageHandle);

        return () => {
            window.removeEventListener('storageChange', updateStorageHandle);
        };
    }, [selectedVenue]);

    useEffect(() => {
        PULSE.core.localStorage.setStorage(
            'mapMarkerLocations',
            JSON.stringify(locations)
        );
    }, [locations]);

    useEffect(() => {
        /* eslint-disable compat/compat */
        window.addEventListener('orientationchange', onScreenOrientationChange);
        window.screen?.orientation?.addEventListener(
            'change',
            onScreenOrientationChange
        );

        return () => {
            window.removeEventListener(
                'orientationchange',
                onScreenOrientationChange
            );
            window.screen?.orientation?.removeEventListener(
                'change',
                onScreenOrientationChange
            );
        };
        /* eslint-disable compat/compat */
    }, []);

    useCupertinoPane(isMobile);

    return (
        <>
            {isMobile ? (
                <div>
                    <InformationBottomDrawer
                        selectedCategory={selectedCategory}
                        googlePlacesData={googlePlacesData}
                        selectedLocation={selectedLocation}
                        locations={locations}
                        timezone={data.eventTimezone}
                        initialVenueData={startingVenue}
                        setSelectedVenue={setSelectedVenue}
                        isLoading={isFetching}
                    />
                </div>
            ) : (
                <>
                    {selectedCategory === null && selectedLocation === null ? (
                        <MapLocationSelector setVenue={setSelectedVenue} />
                    ) : null}

                    <div className="interactive-event-map__desktop-view">
                        <InformationCard
                            selectedLocation={selectedLocation}
                            googlePlacesData={googlePlacesData}
                            timezone={data.eventTimezone}
                        />

                        <InformationCategoryHeader
                            selectedCategory={selectedCategory}
                            selectedLocation={selectedLocation}
                        />

                        <ul
                            className={`interactive-event-map__information-list js-interactive-event-map__information-list ${
                                selectedLocation !== null ? 'u-hide' : ''
                            }`}
                        >
                            {locations?.map((location) => (
                                <InformationSideBarItem
                                    key={location.id}
                                    location={location}
                                    selectedCategory={selectedCategory}
                                    isLoading={isFetching}
                                />
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </>
    );
};
