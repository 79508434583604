import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { MatchCardHeader } from '../components/header';
import { MatchCardFooter } from '../components/footer';
import { MatchCardTeamRow } from './teamRow';

import { generateTBCCardText } from 'widgets/ladder-predictor-v2/js/utils/generate-bracket';

import { handleConcludedMatch } from 'widgets/ladder-predictor-v2/js/utils/generate-bracket';
import { setPredictionFinals } from 'common/store/modules/ladder-predictor/init';
import { CONFIG } from '../../constants';

export const MatchCardFinalUpcoming = ({
    match,
    finalsStage,
    index,
    matchesData,
    seasonId,
    competitionId
}) => {
    const [matchStatus, setMatchStatus] = useState('');
    const [venueName, setVenueName] = useState('');
    const [startTime, setStartTime] = useState('');
    const [homeTeamScore, setHomeTeamScore] = useState(0);
    const [awayTeamScore, setAwayTeamScore] = useState(0);
    const cardText = generateTBCCardText(finalsStage, index);

    const dispatch = useDispatch();

    const handleWinnerSelect = (winnerId, loserId, dataMatch) => {
        dispatch(
            setPredictionFinals({
                i: match.id, // Match id
                r: match.round.roundNumber, // Round number
                w: winnerId, // Winning team
                l: loserId, // Losing team
                n: match.round.name, // Round name
                c: dataMatch.status === CONFIG.CONCLUDED // Is match concluded
            })
        );
    };

    useMemo(() => {
        matchesData?.forEach((singleMatch) => {
            if (
                singleMatch?.home?.team?.id === match?.home?.team?.id &&
                singleMatch?.away?.team?.id === match?.away?.team?.id &&
                singleMatch?.home?.score
            ) {
                setMatchStatus(singleMatch?.status);
                setVenueName(singleMatch?.venue.name);
                setStartTime(singleMatch?.utcStartTime);
                setHomeTeamScore(singleMatch?.home?.score?.totalScore);
                setAwayTeamScore(singleMatch?.away?.score?.totalScore);
            }
        });
    }, [match, matchesData]);

    useEffect(() => {
        handleConcludedMatch(matchesData, match, handleWinnerSelect);
    }, [matchesData]);

    return (
        <div className="ladder-predictor-match-card">
            <MatchCardHeader
                showComplete={false}
                venueName={venueName}
                startDateTime={startTime}
                showDate={true}
            />
            <div className="ladder-predictor-match-card__teams">
                <MatchCardTeamRow
                    match={match}
                    isHomeTeam={true}
                    matchStatus={matchStatus}
                    cardText={cardText.topText}
                    score={homeTeamScore}
                    seasonId={seasonId}
                    competitionId={competitionId}
                    isSelectable={
                        match?.home?.team && match?.away?.team ? true : false
                    }
                />
                <MatchCardTeamRow
                    match={match}
                    isHomeTeam={false}
                    matchStatus={matchStatus}
                    cardText={cardText.bottomText}
                    score={awayTeamScore}
                    seasonId={seasonId}
                    competitionId={competitionId}
                    isSelectable={
                        match?.home?.team && match?.away?.team ? true : false
                    }
                />
            </div>
            <MatchCardFooter
                matchName={match?.round.name}
                showComplete={
                    matchStatus ===
                    PULSE.app.common.CONSTANTS.MATCH_STATUS.CONCLUDED
                        ? true
                        : false
                }
            />
        </div>
    );
};

MatchCardFinalUpcoming.propTypes = {
    match: PropTypes.object.isRequired,
    finalsStage: PropTypes.oneOf(['semiFinal', 'preliminary', 'grandFinal']),
    index: PropTypes.number,
    matchesData: PropTypes.array.isRequired,
    seasonId: PropTypes.string,
    competitionId: PropTypes.string
};
