import React from 'react';
import PropTypes from 'prop-types';

import { getCategory, filterItems } from '../util';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
import { SideBarItemSkeleton } from './SideBarItemSkeleton';

export const InformationSideBarItem = ({
    location,
    selectedCategory,
    isLoading
}) => {
    const resolvedLocation = useGetContentItemQuery({
        type: 'PROMO',
        id: location?.sid
    })?.data;
    const itemCategory = getCategory(resolvedLocation);

    return (
        <>
            {isLoading ? (
                <SideBarItemSkeleton />
            ) : (
                resolvedLocation && (
                    <li
                        className={`interactive-event-map__information-item js-interactive-event-map__information-item ${filterItems(
                            itemCategory.category,
                            selectedCategory
                        )}`}
                        key={resolvedLocation?.id}
                        data-item-id={resolvedLocation?.id}
                    >
                        <div
                            className={`interactive-event-map__information-item-icon interactive-event-map__information-item-icon--${itemCategory.category} `}
                        >
                            <SvgIcon
                                icon={itemCategory.filterIcon}
                                className="icon"
                                folder="elements"
                                subfolder="events-and-locations"
                            />
                        </div>
                        <div className="interactive-event-map__information-item-copy">
                            {resolvedLocation?.title && (
                                <h3 className="interactive-event-map__information-item-title">
                                    {resolvedLocation.title}
                                </h3>
                            )}
                            {resolvedLocation?.metadata?.map_event_type && (
                                <p className="interactive-event-map__information-item-description">
                                    {resolvedLocation.metadata?.map_event_type}
                                </p>
                            )}
                        </div>
                    </li>
                )
            )}
        </>
    );
};

InformationSideBarItem.propTypes = {
    location: PropTypes.object,
    selectedCategory: PropTypes.string,
    isLoading: PropTypes.bool
};
