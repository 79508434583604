import React from 'react';

import { ModalTitle } from '../ModalTitle';
import { getTranslation } from 'common/react/utils/translations';
import { Over18ErrorModal } from './Over18ErrorModal';

export const Over18ErrorModalWrapper = () => {
    return (
        <>
            <ModalTitle
                title={getTranslation('label.accountSettings.error.title')}
                closeBtn={false}
            />
            <Over18ErrorModal />
        </>
    );
};
