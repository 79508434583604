import React from 'react';

import { ModalTitle } from './ModalTitle';
import { MarketingPreferences } from './ModalMarketingPreferences';
import { getTranslation } from 'common/react/utils/translations';
import { useCreateMarketingPrefsMutation } from 'store/modules/api/salesforce-sit-api';
import {
    getCreateBody,
    DEFAULT_CONSENTS
} from 'common/store/modules/api/util/salesforce-helpers';

export const CreateCommPrefModalWrapper = () => {
    const [createPreferences] = useCreateMarketingPrefsMutation();

    /**
     * Callback for when the save button is clicked, fires of the mutation call
     * to create consents with salesforce.
     *
     * If there's an error in the resposne, it triggers the error
     * handling modal to show.
     *
     */
    async function handleSaveClick() {
        const body = getCreateBody({
            consents: {
                marketing: aflPrefs.subscriptions[0].status
            }
        });

        try {
            await createPreferences(body).unwrap();

            PULSE.app.common.siteSettings.closeSettingsModal();
        } catch (error) {
            PULSE.app.common.siteSettings.openSettingsModal('commsError');
        }
    }

    return (
        <>
            <ModalTitle
                title={getTranslation('settingsModal.aflidSettings')}
                closeBtn={false}
            />

            <MarketingPreferences
                title={getTranslation(
                    'settingsModal.communicationPreferences.title'
                )}
                subtitle={getTranslation(
                    'settingsModal.communicationPreferencesCreate.subtitle'
                )}
                marketingPrefs={aflPrefs}
                onToggleClick={handleToggleClick}
                onSaveClick={handleSaveClick}
                hideEnableAll={true}
                noteTitle={getTranslation(
                    'settingsModal.communicationPreferences.noteTitleCreate'
                )}
                noteText={getTranslation(
                    'settingsModal.communicationPreferences.noteTextCreate'
                )}
            />
        </>
    );
};

const aflPrefs = {
    subscriptions: [
        {
            name: getTranslation(
                'settingsModal.communicationPreferences.updatesToggle'
            ),
            status: DEFAULT_CONSENTS.marketing
        }
    ]
};

/**
 * Callback for when a toggle is changed. This updates the relevant changed
 * subscription in the subscriptions array of the marketingPreferences state
 *
 * @param {string} key - What preference is being toggled?
 * @param {boolean} value - Is toggle checked?
 */
function handleToggleClick(key, value) {
    aflPrefs.subscriptions[0].status = value;
}
