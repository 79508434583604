/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { TableScrollButtons } from './TableScrollButtons';
import { useIsScrollable } from 'common/react/hooks/use-is-scrollable';

const SCROLL_DEBOUNCE_MS = 10;

/**
 * @param root0
 * @param root0.children
 * @param root0.getTableProps
 * @param root0.nextPageButton
 * @param root0.modifier
 * @param root0.cssClass
 * @param root0.stickyHeader
 * @param root0.stickyPageNav
 * @param root0.longCells
 * @param root0.stickyOffset
 */
export function StatsTable({
    children,
    getTableProps,
    nextPageButton,
    modifier,
    cssClass,
    stickyHeader,
    stickyPageNav,
    longCells,
    stickyOffset
}) {
    const scrollWrapperRef = useRef();
    const wrapperRef = useRef();
    const wrapperDupRef = useRef();
    const leftButtonRef = useRef();
    const rightButtonRef = useRef();
    const leftButtonDupRef = useRef();
    const rightButtonDupRef = useRef();

    const [isHidden, setIsHidden] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const { isScrollable, updateIsScrollable } = useIsScrollable(
        scrollWrapperRef,
        wrapperRef
    );

    // Debounce needs to be set as a function to work correctly and then be called
    const debounceScrollPosition = _.debounce((value) => {
        setScrollPosition(value);
    }, SCROLL_DEBOUNCE_MS);

    const getHeaderOffset = (element) => {
        const rect = element?.getBoundingClientRect();
        return rect?.height ?? 0;
    };

    const getOffset = (element) => {
        const pageHeader = getHeaderOffset(stickyPageNav);

        const rect = element?.getBoundingClientRect(),
            scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;

        return rect?.top + scrollTop - pageHeader;
    };

    const listenToScroll = () => {
        let heightToHideFrom = getOffset(scrollWrapperRef.current);

        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        const tableElement = getHeaderOffset(scrollWrapperRef.current);
        let tableEnd = heightToHideFrom + tableElement;

        if (stickyOffset && matchMedia('(min-width: 1025px)').matches) {
            heightToHideFrom = heightToHideFrom - stickyOffset;
            tableEnd = tableEnd - stickyOffset;
        }

        if (winScroll > tableEnd || winScroll < heightToHideFrom) {
            // check if the sticky header has focus before removing it
            if (document.activeElement === leftButtonDupRef.current) {
                leftButtonRef.current?.focus();
            } else if (document.activeElement === rightButtonDupRef.current) {
                rightButtonRef.current?.focus();
            }
            setIsHidden(true);
        } else {
            setIsHidden(false);
        }
    };

    const handleScroll = (e) => {
        debounceScrollPosition(e.target.scrollLeft);
    };

    // ===== Effects ======================================================== //

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => window.removeEventListener('scroll', listenToScroll);
    }, []);

    useLayoutEffect(() => {
        longCells?.forEach((longCellPos) => {
            // set the min width of long cells in the sticky header for horizontal scrolling
            if (longCellPos && wrapperRef.current && wrapperDupRef.current) {
                const longCellWidth = wrapperRef.current
                    .querySelector(`thead > tr > th:nth-child(${longCellPos})`)
                    ?.getBoundingClientRect().width;
                const stickyTableLongCell = wrapperDupRef.current.querySelector(
                    `thead > tr > th:nth-child(${longCellPos})`
                );

                if (longCellWidth && stickyTableLongCell) {
                    stickyTableLongCell.style.minWidth = longCellWidth + 'px';

                    // sync the scroll left value
                    const stickyTableScrollArea =
                        wrapperDupRef.current.querySelector(
                            '.js-scrollable-container'
                        );

                    if (stickyTableScrollArea) {
                        stickyTableScrollArea.scrollLeft = scrollPosition;
                    }
                }
            }
        });

        updateIsScrollable();
    }, [isHidden, wrapperRef, wrapperDupRef, longCells, scrollPosition]);

    return (
        <ScrollSync>
            <>
                {isHidden ? (
                    ''
                ) : (
                    <div
                        className={classNames(
                            'stats-table ' + (cssClass ?? ''),
                            {
                                'stats-table--is-scrollable': isScrollable,
                                [`stats-table--${modifier}`]: !!modifier
                            },
                            'stats-table__dup'
                        )}
                        ref={wrapperDupRef}
                    >
                        <ScrollSyncPane>
                            <div className="stats-table__wrapper stats-table__dup-header stats-table__scroll-pane js-scrollable-container">
                                {isScrollable && (
                                    <TableScrollButtons
                                        scrollWrapperRef={scrollWrapperRef}
                                        wrapperRef={wrapperRef}
                                        scrollPosition={scrollPosition}
                                        leftButtonRef={leftButtonDupRef}
                                        rightButtonRef={rightButtonDupRef}
                                    />
                                )}
                                <table className="stats-table__table">
                                    {stickyHeader}
                                </table>
                            </div>
                        </ScrollSyncPane>
                    </div>
                )}
                <div
                    className={classNames('stats-table ' + (cssClass ?? ''), {
                        'stats-table--is-scrollable': isScrollable,
                        [`stats-table--${modifier}`]: !!modifier
                    })}
                    ref={wrapperRef}
                >
                    <ScrollSyncPane>
                        <div
                            style={{ overflow: 'auto', overflowY: 'hidden' }}
                            className="stats-table__wrapper js-scrollable-container"
                            onScroll={handleScroll}
                        >
                            {isScrollable && (
                                <TableScrollButtons
                                    scrollWrapperRef={scrollWrapperRef}
                                    wrapperRef={wrapperRef}
                                    scrollPosition={scrollPosition}
                                    leftButtonRef={leftButtonRef}
                                    rightButtonRef={rightButtonRef}
                                />
                            )}

                            <table
                                ref={scrollWrapperRef}
                                {...getTableProps()}
                                className="stats-table__table"
                            >
                                {children}
                            </table>
                        </div>
                    </ScrollSyncPane>

                    {nextPageButton}
                </div>
            </>
        </ScrollSync>
    );
}

StatsTable.propTypes = {
    children: PropTypes.any.isRequired,
    getTableProps: PropTypes.func.isRequired,
    nextPageButton: PropTypes.any,
    modifier: PropTypes.string,
    cssClass: PropTypes.string,
    stickyHeader: PropTypes.element,
    stickyPageNav: PropTypes.any,
    longCells: PropTypes.array,
    stickyOffset: PropTypes.number
};
