import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MapLocationSelectorOptionImage } from './MapLocationSelectorOptionImage';
import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
export const MapLocationSelectorOption = ({
    location,
    selectedVenue,
    handleImageUrl,
    isAdditionalLocation
}) => {
    const [isSelected, setIsSelected] = useState(false);
    let mapLocation = {};

    mapLocation = useGetContentItemQuery(
        {
            type: 'PLAYLIST',
            id: location.id
        },
        { skip: !isAdditionalLocation }
    ).data;

    useEffect(() => {
        if (isAdditionalLocation) {
            if (selectedVenue.name === mapLocation?.title) {
                setIsSelected(true);
            } else {
                setIsSelected(false);
            }
        } else if (selectedVenue.name === location.name) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [selectedVenue, mapLocation]);

    return (
        <li>
            <button
                className={`js-option-btn map-location-selector__options-item ${
                    isSelected
                        ? 'map-location-selector__options-item--selected'
                        : ''
                }`}
                data-venue-id={
                    isAdditionalLocation ? mapLocation?.id : location.id
                }
            >
                <div className="map-location-selector__options-image-container">
                    {location?.imgRef?.reference?.length > 0 ||
                    mapLocation?.references?.length ? (
                        <MapLocationSelectorOptionImage
                            location={
                                isAdditionalLocation
                                    ? mapLocation?.references?.filter(
                                          (reference) =>
                                              reference.type === 'PHOTO'
                                      )[0]
                                    : location
                            }
                            handleImageUrl={handleImageUrl}
                        />
                    ) : null}
                </div>
                <span className="js-option-btn__name">
                    {isAdditionalLocation ? mapLocation?.title : location.name}
                </span>
            </button>
        </li>
    );
};

MapLocationSelectorOption.propTypes = {
    location: PropTypes.shape({
        name: PropTypes.string,
        imgRef: PropTypes.object,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    selectedVenue: PropTypes.shape({
        name: PropTypes.string,
        imgRef: PropTypes.string
    }),
    handleImageUrl: PropTypes.func,
    isAdditionalLocation: PropTypes.bool
};
