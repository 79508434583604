import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';
import { SiteSettingsModal } from '../components/SiteSettingsModal';

/**
 * @constant {string} LOCAL_STORAGE_KEY - The key we use to store preferences locally
 */
const LOCAL_STORAGE_KEY = 'pulse_site_settings';

export const SiteSettingsModalWrapper = ({ regionsPage }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const modalType = useSingleSiteSetting('modalType');
    const previousModalType = useRef(null);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === LOCAL_STORAGE_KEY) {
                const updatedModalType = getModalTypeFromStorage();
                if (!updatedModalType.length) {
                    setModalOpen(false);
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        // checking, if the site url has the `?aflmodals=0` parameter
        // because if it does, the modals should not be displayed
        const url = new URL(location.href);
        if (url.searchParams.get('aflmodals') === '0') {
            return;
        }

        if (modalType?.length) {
            setModalOpen(true);
        }

        // Close modal if modalType transitioned from non-empty to empty
        if (previousModalType.current && !modalType?.length) {
            setModalOpen(false);
        }

        // Update the previousModalType ref
        previousModalType.current = modalType;
    }, [modalType]);

    return modalOpen ? (
        <SiteSettingsModal modalType={modalType[0]} regionsPage={regionsPage} />
    ) : null;
};

SiteSettingsModalWrapper.propTypes = {
    regionsPage: PropTypes.string.isRequired
};

const getModalTypeFromStorage = () => {
    const settings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
    return settings.modalType || [];
};
