import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { getPositionNameByKey } from '../../../../utils/positions';
import { useCompSeasonProvider } from '../../hooks';
import { TeamWatermarkBackground } from '../../../../../TeamWatermarkBackground';
import { getTranslation } from 'common/react/utils/translations';
import { TeamIcon } from 'common/react/components/TeamIcon';

const CLASS_BLOCK = 'player-stats-tooltip-header';

/**
 * @param {object} props - Props.
 * @param {object} props.player - Player.
 * @returns {JSX.Element} Component.
 */
export const PlayerStatsTooltipHeader = ({ player }) => {
    const compSeasonProvider = useCompSeasonProvider();
    const ref = useRef();

    useEffect(() => {
        if (ref.current && typeof compSeasonProvider !== 'undefined') {
            // @ts-ignore
            new PULSE.app.PlayerHeadshot(ref.current, compSeasonProvider);
        }
    }, [ref, compSeasonProvider]);

    const teamAbbreviation = player.team.teamAbbr.toLowerCase();

    return (
        <div className={CLASS_BLOCK} ref={ref}>
            <TeamWatermarkBackground teamAbbreviation={teamAbbreviation} />
            <div className={`${CLASS_BLOCK}__content`}>
                <div className={`${CLASS_BLOCK}__details-wrapper`}>
                    <div className={`${CLASS_BLOCK}__name`}>
                        <div className={`${CLASS_BLOCK}__first-name`}>
                            {player.details.givenName}
                        </div>
                        <div className={`${CLASS_BLOCK}__last-name`}>
                            {player.details.surname}
                        </div>
                    </div>
                    <div className={`${CLASS_BLOCK}__team-details`}>
                        <TeamIcon
                            className={`${CLASS_BLOCK}__team-icon`}
                            teamAbbreviation={teamAbbreviation}
                            theme="light"
                        />
                        <span
                            className={`${CLASS_BLOCK}__jumper-number`}
                        >{`#${player.details.jumperNumber}`}</span>
                        <span>
                            {getPositionNameByKey(player.details.position)}
                        </span>
                    </div>
                </div>
                <img
                    className={`${CLASS_BLOCK}__headshot-wrapper`}
                    src={PULSE.app.common.image.migratePlayerPhotoURL(
                        player.details.photoURL,
                        0.3
                    )}
                    alt={`${getTranslation('label.stats.headshot.alt')} ${
                        player.details.givenName
                    } ${player.details.surname}`}
                    width="1"
                    height="1"
                />
            </div>
        </div>
    );
};

PlayerStatsTooltipHeader.propTypes = {
    player: PropTypes.object.isRequired
};
