import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import DefaultCellRendererContainer from 'custom-table/js/components/DefaultCell/DefaultCellRendererContainer';

/*
    This is a simple example of a custom cell renderer, just to show how we could override the default.
    Shows how we can support selecting multiple attributes from the API response into a single table field.
*/

const TotalAverageCellRenderer = ({ className, value, ...additionalProps }) => {
    if (!value) {
        return (
            <DefaultCellRendererContainer
                className={cx(className, 'custom-table__cell')}
                {...additionalProps}
            >
                <span> - </span>
            </DefaultCellRendererContainer>
        );
    }

    const field = additionalProps.field;
    const sortBy = additionalProps.customTable.sortBy;
    let fieldIsSorted = false;

    if (field.fieldName === sortBy.fieldName) {
        fieldIsSorted = true;
    }

    const cellMarkup = Object.keys(value).map((item, index) => {
        const isLastCell = index === Object.keys(value).length - 1;
        let cellValue = value[item];
        const isSorted = fieldIsSorted && sortBy.additionalSortProps === item;

        return (
            <DefaultCellRendererContainer
                className={cx(className, 'custom-table__cell--multiple-cell', {
                    'custom-table__cell--multiple-cell-last': isLastCell,
                    'custom-table__cell--sorted': isSorted
                })}
                {...additionalProps}
                key={`${item}`}
            >
                <span>{cellValue}</span>
            </DefaultCellRendererContainer>
        );
    });

    return cellMarkup;
};

export default TotalAverageCellRenderer;

TotalAverageCellRenderer.propTypes = {
    className: PropTypes.string,
    value: PropTypes.object
};
