import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BREAKPOINTS_FOR_FOUR_SLIDES, sliderSettings } from '../config/slider';

import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { useGetContentQuery } from 'store/modules/api/content-api';

import { EmptyState } from 'common/react/components/EmptyState';
import { MediaThumbnailArticle } from 'common/react/components/media-thumbnail/MediaThumbnailArticle';
import { MediaThumbnailVideo } from 'common/react/components/media-thumbnail/MediaThumbnailVideo';
import { SkeletonLoader } from 'common/react/components/SkeletonLoader';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { formatTagsForTagExpression } from 'common/react/utils/format-tag-expression';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { useSwiperTracking } from 'common/react/hooks/use-swiper-tracking';

const AFLW_COMP_ID = window.PULSE.envPaths.competitions.AFLW,
    AFLM_COMP_ID = window.PULSE.envPaths.competitions.AFL;

export const CGSlider = ({
    title,
    tags,
    mediaType,
    linkTo,
    maxItems,
    slider
}) => {
    // Responsive
    const windowSize = useWindowSize();
    const isResponsive = () => windowSize.width < PULSE.app.measurements.tablet;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    // Redux
    const nav = useSelector(navSelector);
    const formattedTeamString = formatReferenceExpression(
        nav?.competition?.id,
        nav?.filters?.teams
    );

    // RTK query
    const tagsArray = tags?.split(',');
    const requestConfig = {
        type: mediaType,
        params: {
            pageSize: maxItems,
            referenceExpression: formattedTeamString,
            tagExpression: tags ? formatTagsForTagExpression(tagsArray) : null
        }
    };

    const {
        data: requestData = [],
        isFetching: contentGridIsFetching,
        isError: contentGridIsError
    } = useGetContentQuery(requestConfig, {
        skip: !mediaType || nav?.competition?.id === ''
    });
    const contentData = requestData?.content;

    // Slider
    const sliderName = `content-grid-video-slider`;

    // Tracking
    const [bullets, setBullets] = useState(null);
    const [bulletClicked, setBulletClicked] = useState(false);
    const bulletRefs = useRef([]);

    const handleClick = () => {
        setBulletClicked(true);
    };

    bullets?.forEach((node, index) => {
        bulletRefs.current[index] = node;
        node.addEventListener('click', handleClick);
    });

    const { handleTransitionEnd } = useSwiperTracking(
        sliderName,
        PULSE.app.common.CONSTANTS.TRACKING.EVENTS.CAROUSEL.CONTENT_TYPE.VIDEO
    );

    // Format variables
    const compAbbr = PULSE.app.common.match
        .getCompAbbr(nav.season.providerId)
        .toLowerCase();

    // Fallback states
    if (contentGridIsFetching) {
        return (
            <>
                <div className="widget-header">
                    {showResponsiveItems ? (
                        <SkeletonLoader
                            height={26}
                            width={200}
                            className={'content-grid__skeleton-loader-title'}
                        />
                    ) : (
                        <SkeletonLoader
                            height={40}
                            width={250}
                            className={'content-grid__skeleton-loader-title'}
                        />
                    )}
                </div>
                <div className="media-list__list block-list-4 block-list-3-tablet block-list-1-phablet">
                    {showResponsiveItems ? (
                        <>
                            <SkeletonLoader height={108} />
                            <SkeletonLoader height={108} />
                            <SkeletonLoader height={108} />
                            <SkeletonLoader height={108} />
                        </>
                    ) : (
                        <>
                            <SkeletonLoader height={320} />
                            <SkeletonLoader height={320} />
                            <SkeletonLoader height={320} />
                            <SkeletonLoader height={320} />
                        </>
                    )}
                </div>
            </>
        );
    } else if (contentGridIsError) {
        <EmptyState />;
    } else {
        // Render media thumbnails
        return (
            title &&
            contentData?.length > 0 && (
                <div className="content-grid content-grid__inner-wrapper">
                    <header className="widget-header js-widget-header">
                        <div className={'widget-header__content'}>
                            {linkTo ? (
                                <a
                                    href={linkTo}
                                    className="widget-header__title-link"
                                >
                                    <h2 className="widget-header__title">
                                        {title}
                                    </h2>
                                    <SvgIcon
                                        icon="arrow-right"
                                        className="icon"
                                    />
                                </a>
                            ) : (
                                <h2 className="widget-header__title">
                                    {title}
                                </h2>
                            )}
                        </div>
                    </header>
                    {slider ? (
                        <>
                            <div className="slider">
                                <div className="slider__wrapper">
                                    <div className="slider-controls">
                                        <div className="slider-controls__button-container slider-controls__button-container--prev"></div>
                                    </div>
                                </div>
                                <ul className="slider__list js-slide u-hide-from-tablet">
                                    {contentData?.map((content, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="slider__item js-thumb"
                                            >
                                                {mediaType === 'TEXT' ? (
                                                    <MediaThumbnailArticle
                                                        article={content}
                                                        index={index}
                                                        compIcon={compAbbr}
                                                        tags={tags}
                                                    />
                                                ) : (
                                                    <MediaThumbnailVideo
                                                        video={content}
                                                        index={index}
                                                        compIcon={compAbbr}
                                                        tags={tags}
                                                    />
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="u-hide-until-tablet">
                                <Swiper
                                    {...sliderSettings(sliderName)}
                                    {...BREAKPOINTS_FOR_FOUR_SLIDES}
                                    onTransitionEnd={(swiperCore) => {
                                        if (bulletClicked) {
                                            handleTransitionEnd(
                                                swiperCore,
                                                PULSE.app.common.CONSTANTS
                                                    .TRACKING.EVENTS.CAROUSEL
                                                    .TYPE.DOT
                                            );
                                        } else {
                                            handleTransitionEnd(swiperCore);
                                        }
                                        setBulletClicked(false);
                                    }}
                                    onUpdate={(swiper) => {
                                        setBullets(swiper.pagination.bullets);
                                    }}
                                >
                                    {contentData?.map((content, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                {mediaType === 'TEXT' ? (
                                                    <MediaThumbnailArticle
                                                        article={content}
                                                        index={index}
                                                        compIcon={compAbbr}
                                                        tags={tags}
                                                    />
                                                ) : (
                                                    <MediaThumbnailVideo
                                                        video={content}
                                                        index={index}
                                                        compIcon={compAbbr}
                                                        tags={tags}
                                                    />
                                                )}
                                            </SwiperSlide>
                                        );
                                    })}
                                    <button
                                        className={`slider-controls__button slider-controls__button--prev swiper-button-prev swiper-button-prev-unique-${sliderName}`}
                                    >
                                        <SvgIcon
                                            icon="slider-arrow"
                                            className="icon slider-controls__button-icon"
                                        />
                                    </button>
                                    <button
                                        className={`slider-controls__button slider-controls__button--next swiper-button-next swiper-button-next-unique-${sliderName}`}
                                    >
                                        <SvgIcon
                                            icon="slider-arrow"
                                            className="icon slider-controls__button-icon"
                                        />
                                    </button>
                                    <div className="swiper-pagination js-content-grid-video-slider-pagination"></div>
                                </Swiper>
                            </div>
                        </>
                    ) : (
                        <ul
                            data-title={title}
                            className="media-list__list block-list-4 block-list-3-tablet block-list-1-phablet"
                        >
                            {contentData?.map((content, index) => {
                                return (
                                    <li
                                        className="media-list__item"
                                        key={content.id}
                                    >
                                        {mediaType === 'TEXT' ? (
                                            <MediaThumbnailArticle
                                                article={content}
                                                index={index}
                                                compIcon={compAbbr}
                                                tags={tags}
                                            />
                                        ) : (
                                            <MediaThumbnailVideo
                                                video={content}
                                                index={index}
                                                compIcon={compAbbr}
                                                tags={tags}
                                            />
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            )
        );
    }
};

/**
 * helper returning formatted referenceExpression query based on the provided competition and teams
 * When the comp equals "" or *, it also adds AFLW comp references and converts the AFLM team ids to AFLW team ids for that.
 *
 * @param {number|string} comp Competition id
 * @param {Array} teams Array of numbers
 *
 * @returns {string} referenceExpression string
 */
function formatReferenceExpression(comp, teams) {
    let comps = [comp];

    // No competition has been selected
    // We get content for AFL and AFLW
    if (comp === '*' || comp === '') {
        comps = [AFLM_COMP_ID, AFLW_COMP_ID];
    }

    let referenceQueryElements = [];

    if (teams.length > 0) {
        referenceQueryElements = teams.map((teamId) => {
            return comps
                .map((compId) => {
                    // Both AFLM and AFLW comps are on the list
                    // but we have only AFLM team ids.
                    // Need to convert AFLM to AFLW
                    if (compId === AFLW_COMP_ID && comps.length === 2) {
                        return `(AFL_TEAM:${PULSE.app.common.team.getAflwTeamId(
                            teamId
                        )} and AFL_COMPETITION:${compId})`;
                    }

                    return `(AFL_TEAM:${teamId} and AFL_COMPETITION:${compId})`;
                })
                .join(' or ');
        });
    } else {
        referenceQueryElements = comps.map(
            (compId) => `(AFL_COMPETITION:${compId})`
        );
    }

    return referenceQueryElements.join(' or ');
}

CGSlider.propTypes = {
    title: PropTypes.string,
    tags: PropTypes.string,
    mediaType: PropTypes.string,
    linkTo: PropTypes.string,
    maxItems: PropTypes.string,
    slider: PropTypes.string
};
