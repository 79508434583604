import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getTeamPlayersForPositions } from 'widgets/team-lineups-v2/js/utils/helpers';
import { PlayerEntry } from '../fieldview/PlayerEntry';

/**
 * Team Lineups List view row element
 *
 * @param {object} props
 * @param {object} props.position Position object the players will be displayed for. It's one of the objects of TEAM_LINEUPS_POSITIONS
 * @param {object} props.homeTeam Home team object
 * @param {object} props.awayTeam Away team object
 * @param {object} props.homeTeamPlayers Object containing home team's positions mapped to players. This is the object returned by mapPlayersToPositions helper
 * @param {object} props.awayTeamPlayers Object containing home team's positions mapped to players. This is the object returned by mapPlayersToPositions helper
 * @param {boolean} [props.isAFLW] If the match is AFLW, needs to be set to TRUE to generate correct players profiles links. Default FALSE
 *
 * @returns ListRow element
 */
const ListRow = ({
    position,
    homeTeam,
    awayTeam,
    homeTeamPlayers,
    awayTeamPlayers,
    isAFLW = false
}) => {
    // mapping home team players to oval section positions
    const sectionHomePlayers = useMemo(() => {
        const homeTeamObject = getTeamPlayersForPositions(
            position,
            homeTeam,
            homeTeamPlayers
        );
        homeTeamObject.homeTeam = true;
        homeTeamObject.players = homeTeamObject.players.filter(
            (player) => !!player
        );
        return homeTeamObject;
    }, [position, homeTeamPlayers]);

    // mapping away team players to oval section positions
    const sectionAwayPlayers = useMemo(() => {
        const awayTeamObject = getTeamPlayersForPositions(
            position,
            awayTeam,
            awayTeamPlayers
        );
        awayTeamObject.players = awayTeamObject.players.filter(
            (player) => !!player
        );
        return awayTeamObject;
    }, [position, awayTeamPlayers]);

    return (
        <>
            {(sectionHomePlayers?.players.length > 0 ||
                sectionAwayPlayers?.players.length > 0) && (
                <div className="team-lineups__list-view-row">
                    <div className="team-lineups__list-view-row--home-team">
                        {sectionHomePlayers.players.map((player) => (
                            <div
                                className="team-lineups__list-view-player"
                                key={`team-lineups-row-${homeTeam.id}-${player.playerId}`}
                            >
                                <PlayerEntry
                                    player={player}
                                    team={homeTeam}
                                    isHomeTeam={true}
                                    isAFLW={isAFLW}
                                    trackedView="list view"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="team-lineups__list-view-row--label">
                        <span className="team-lineups__list-view-label">
                            {position?.label}
                        </span>
                    </div>
                    <div className="team-lineups__list-view-row--away-team">
                        {sectionAwayPlayers.players.map((player) => (
                            <div
                                className="team-lineups__list-view-player"
                                key={`team-lineups-row-${awayTeam.id}-${player.playerId}`}
                            >
                                <PlayerEntry
                                    player={player}
                                    team={awayTeam}
                                    isAFLW={isAFLW}
                                    trackedView="list view"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default ListRow;

ListRow.propTypes = {
    position: PropTypes.object,
    homeTeam: PropTypes.object,
    awayTeam: PropTypes.object,
    homeTeamPlayers: PropTypes.object,
    awayTeamPlayers: PropTypes.object,
    isAFLW: PropTypes.bool
};
