import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SwitchInput } from 'common/react/components/SwitchInput';
import { careerAndSeasonStatsBenchmarkingSelector } from 'store/modules/statspro/career-and-season-stats/selectors';
import { getTranslation } from 'common/react/utils/translations';
import { UPDATE_BENCHMARKING } from 'store/modules/statspro/career-and-season-stats/actions';

export const BenchmarkingSwitch = () => {
    const dispatch = useDispatch();
    const benchmarking = useSelector(careerAndSeasonStatsBenchmarkingSelector);

    return (
        <SwitchInput
            id="careers-and-season-stats-benchmarking-toggle"
            label={getTranslation(
                'label.statsLeaders.benchmarking.toggleText'
            ).toUpperCase()}
            isChecked={benchmarking.isActive}
            onChange={(isActive) => {
                dispatch(UPDATE_BENCHMARKING.request({ isActive }));
            }}
        />
    );
};
