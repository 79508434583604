import React from 'react';

import { TEAM_LINEUPS_POSITIONS } from '../../utils/constants';

export const TeamLineupsLegend = () => {
    const legendItems = [
        'FULL_BACKS',
        'HALF_BACKS',
        'CENTRES',
        'HALF_FORWARDS',
        'FULL_FORWARDS'
    ];

    return (
        <div className="legend legend--team-lineups">
            <ul className="legend__list">
                {legendItems.map((item, index) => (
                    <li key={`item_${index}`} className="legend__item">
                        <strong>
                            {TEAM_LINEUPS_POSITIONS[item].labelShort}:{' '}
                        </strong>
                        {TEAM_LINEUPS_POSITIONS[item].label}
                    </li>
                ))}
            </ul>
        </div>
    );
};
