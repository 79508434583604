import React, { useState, useEffect, createContext } from 'react';
import { useInterval } from 'common/react/hooks/use-interval';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    leaderboardDataSelector,
    leaderboardFilterSelector,
    leaderboardNavSelector
} from 'common/store/modules/brownlow-tracker/leaderboard/selectors';
import { useGetTeamsQuery } from 'common/store/modules/api/afl-api';
import { Loader } from 'common/react/components/Loader';
import { EmptyState } from 'common/react/components/EmptyState';
import { useFetchTableData } from '../../../hooks/use-fetch-table-data';
import { usePagination } from '../../../hooks/use-fetch-more-data';
import { useFilteredData } from '../../../hooks/use-search-filter';
import { LeaderboardTable } from './LeaderboardTable.jsx';
import { useBettingVis } from 'common/react/hooks/use-betting-vis';
import { FETCH_LEADERBOARD_DATA } from 'common/store/modules/brownlow-tracker/leaderboard/actions';
import { useGetBrownlowBettingOddsQuery } from 'common/store/modules/api/cfs-api';

const PAGE_SIZE = 15;
const POLLING_DELAY = 30000;
/**
 * @constant {number} LEADER_ROUND_DELAY How many rounds until we start
 * to higlight the leader
 */
const LEADER_ROUND_DELAY = 3;

export const SeasonContext = createContext('');
export const CurrentRoundContext = createContext('');

export const LeaderboardTableFetcher = ({ shouldHidePredicted, title }) => {
    const dispatch = useDispatch();

    const nav = useSelector(leaderboardNavSelector);

    const seasons = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.list ?? []
    );

    const { data: teamsData } = useGetTeamsQuery(nav.season.id, {
        skip: !nav.season.id ? true : false
    });

    const teams = teamsData || [];

    const season = seasons.find((item) => item.id === nav.season.id);
    const seasonPid = season?.providerId ?? '';

    const currentRoundNumber = season?.currentRoundNumber;

    const team = teams.find((item) => item.id === nav.team.id);
    const teamId = team?.providerId ?? '';

    const leaderboardData = useSelector((state) =>
        leaderboardDataSelector(state, seasonPid)
    );

    const leaderboardFilter = useSelector(leaderboardFilterSelector);

    const [data, setData] = useState([]);
    let filteredData = useFilteredData(data, leaderboardFilter);

    const [noDataAvailable, setNoDataAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState();

    const [
        shownData,
        fetchMoreData,
        currentPage,
        setShownData,
        setCurrentPage
    ] = usePagination(data, PAGE_SIZE);

    const status = leaderboardData?.status
        ? PULSE.app.common.match.getMatchStatus(leaderboardData.status)
        : '';

    useFetchTableData(seasonPid, teamId, setCurrentPage);

    useEffect(() => {
        setIsLoading(true);
        setShownData([]);
        filteredData = [];
    }, [nav.season, nav.team, nav.competition]);

    useEffect(() => {
        if (leaderboardData?.leaderboard?.length) {
            setNoDataAvailable(false);
        } else {
            setNoDataAvailable(true);
            setShownData([]);
            filteredData = [];
        }

        setIsLoading(false);
    }, [leaderboardData]);

    // set the shown data based on the filtered results
    useEffect(() => {
        setShownData(filteredData.slice(0, PAGE_SIZE * currentPage));
    }, [filteredData, setShownData, currentPage]);

    // ===== Leader Total Highlighting ====================================== //
    const [showLeader, setShowLeader] = useState(false);

    useEffect(() => {
        if (!team) {
            if (status === PULSE.app.common.CONSTANTS.MATCH_STATUS.LIVE) {
                // Check if more than 3 rounds
                if (
                    leaderboardData?.leaderboard?.[0]?.roundByRoundVotes
                        ?.length >= LEADER_ROUND_DELAY
                ) {
                    setShowLeader(true);
                }
            } else if (
                status === PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED
            ) {
                setShowLeader(true);
            }
        }
    }, [status, team, leaderboardData]);

    // ===== Betting Odds  ======================================
    const { data: bettingOddsData } = useGetBrownlowBettingOddsQuery(null, {
        pollingInterval: PULSE.app.common.CONSTANTS.TIME.ONE_MINUTE_IN_MS
    });

    const shouldShowBettingOdds =
        useBettingVis() &&
        status !== PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED &&
        bettingOddsData?.displayInApp === true &&
        bettingOddsData?.outcomes?.length > 0;

    // ===== Poll when not concluded ========================================== //
    useInterval(() => {
        if (status !== PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED) {
            dispatch(
                FETCH_LEADERBOARD_DATA.request({
                    type: 'season',
                    seasonPid: seasonPid,
                    teamId: teamId ?? ''
                })
            );
        }
    }, POLLING_DELAY);

    // ===== Render ====================== //
    if (isLoading) {
        return (
            <div className="career-and-season-stats__loader">
                <Loader />
            </div>
        );
    }

    return noDataAvailable || !leaderboardData ? (
        <div className="career-and-season-stats__empty-state">
            <EmptyState
                titleTranslation="label.brownlow.tracker.error.noDataAvailable"
                summaryTranslation="label.brownlow.tracker.error.noDataAvailable.summary"
            />
        </div>
    ) : (
        <SeasonContext.Provider value={season}>
            <CurrentRoundContext.Provider value={currentRoundNumber}>
                <LeaderboardTable
                    fetchMoreData={fetchMoreData}
                    setData={setData}
                    leaderboardData={leaderboardData}
                    filteredData={filteredData}
                    pageSize={PAGE_SIZE}
                    currentPage={currentPage}
                    shownData={shownData}
                    shouldShowBettingOdds={shouldShowBettingOdds}
                    showLeader={showLeader}
                    shouldHidePredicted={shouldHidePredicted}
                    bettingOddsData={bettingOddsData}
                    title={title}
                />
            </CurrentRoundContext.Provider>
        </SeasonContext.Provider>
    );
};

LeaderboardTableFetcher.propTypes = {
    cssClass: PropTypes.string,
    shouldHidePredicted: PropTypes.bool,
    title: PropTypes.string
};
