import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LadderTableFetcher } from './LadderTableFetcher';
import { SwitchInput } from 'common/react/components/SwitchInput';

import { useWindowWidth } from 'common/react/hooks/use-window-resize';
import { LadderDrawer } from './LadderDrawer';
import { getTranslation } from 'common/react/utils/translations';

import { PredictedLadderTable } from './predicted-ladder/PredictedLadderTable';

export const LadderTableController = ({ competitionId, compSeason, round }) => {
    const [isLiveLadder, setIsLiveLadder] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    const handleChange = (toggleStatus) => {
        setIsLiveLadder(toggleStatus);
    };

    // eslint-disable-next-line no-magic-numbers
    const windowWidth = useWindowWidth(500); // 500 is the debounce not the viewport!!

    useEffect(() => {
        innerWidth < PULSE.app.measurements.tablet
            ? setIsTablet(true)
            : setIsTablet(false);
    }, [windowWidth]);

    const heading = isLiveLadder
        ? getTranslation('label.ladderPredictor.liveLadder')
        : getTranslation('label.ladderPredictor.predictedLadder');

    // Mock data: single team
    const team = {
        position: 3,
        positionChange: 'up',
        name: 'Geelong Cats',
        abbr: 'geel',
        played: 5,
        points: 3,
        winPercentage: '60%'
    };

    /**
     * Gets the toggle status from the child component (LadderDrawer)
     *
     * @param {boolean} ladderStatusDrawer
     */
    const getLadderStatusDrawer = (ladderStatusDrawer) => {
        setIsLiveLadder(ladderStatusDrawer);
    };

    return (
        <>
            {isTablet ? (
                <>
                    <LadderDrawer
                        competitionId={competitionId}
                        compSeason={compSeason}
                        round={round}
                        heading={heading}
                        team={team}
                        isLiveLadder={isLiveLadder}
                        onToggle={getLadderStatusDrawer}
                    />
                </>
            ) : (
                <div className="ladder-predictor-ladder-table">
                    <div className="ladder-predictor-ladder-table__title-wrapper">
                        <h3 className="ladder-predictor-ladder-table__title">
                            {heading}
                        </h3>
                        <SwitchInput
                            id={'switch-input'}
                            className="ladder-predictor-ladder-table"
                            label={getTranslation(
                                'label.ladderPredictor.actualLadder'
                            )}
                            isChecked={isLiveLadder}
                            onChange={(newValue) => handleChange(newValue)}
                        />
                    </div>
                    {isLiveLadder ? (
                        <LadderTableFetcher
                            competitionId={competitionId}
                            compSeason={compSeason}
                            round={round}
                            displayFormTooltip={true}
                        />
                    ) : (
                        <PredictedLadderTable compSeason={compSeason} />
                    )}
                </div>
            )}
        </>
    );
};

LadderTableController.propTypes = {
    competitionId: PropTypes.string,
    compSeason: PropTypes.object,
    round: PropTypes.object
};
