import React, { useEffect } from 'react';

import { getTranslation } from 'common/react/utils/translations';

export const MarketingPrefsErrorModal = () => {
    useEffect(() => {
        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.VIEW,
            {
                eventData: {
                    /* eslint-disable-next-line camelcase */
                    modal_name: 'Communication preferences error modal'
                }
            }
        );

        return () => {
            PULSE.app.tracking.recordEvent(
                PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.CLOSE,
                {
                    eventData: {
                        /* eslint-disable-next-line camelcase */
                        modal_name: 'Communication preferences error modal'
                    }
                }
            );
        };
    }, []);

    return (
        <>
            <div className="modal-setting-error">
                <p className="modal-setting-error__subtitle">
                    {' '}
                    {getTranslation(
                        'label.accountSettings.error.subtitle'
                    )}{' '}
                </p>
                <p className="modal-setting-error__text">
                    {getTranslation('label.accountSettings.error.text')}
                </p>
            </div>
            <div className="modal-setting-error__btn-container">
                <button
                    onClick={() =>
                        PULSE.app.common.siteSettings.closeSettingsModal()
                    }
                    className="modal-setting-error__button button-new button-new--primary"
                >
                    {getTranslation('label.button.continue')}
                </button>
            </div>
        </>
    );
};
