/**
 * Dispatches a Google Analytics tracking event to the dataLayer for client-side rendered
 * media thumbnails' clicks.
 *
 * @param {object} evt   - The media thumbnail click event.
 * @param {object} title - The media thumbnail title.
 */
export const selectCardHandler = (evt, title) => {
    const cardLink = evt.target;
    const container = cardLink.closest('.media-list__list');
    const cardLinks = container.querySelectorAll('.js-media-thumbnail__link');
    cardLink.title = title;

    PULSE.app.tracking.trackCardClicks(container, cardLink, cardLinks.length);
};
