import React from 'react';

import { useDataType } from '../../../common/hooks/use-data-type';
import { DATA_TYPE } from '../../../common/config/data-type';
import { composeGetTranslation } from 'common/react/utils/translations';
import { ToggleInput } from 'common/react/components/ToggleInput';
import { UPDATE_DATA_TYPE } from 'store/modules/statspro/stat-comparison/actions';
import { useSelector } from 'react-redux';
import { statComparisonDataTypeSelector } from 'store/modules/statspro/stat-comparison/selectors/data-type-selector';

export const dataTypeOptions = [
    {
        label: composeGetTranslation('label.statsLeaders.dataType.')(
            DATA_TYPE.TOTALS
        ),
        value: DATA_TYPE.TOTALS
    },
    {
        label: composeGetTranslation('label.statsLeaders.dataType.')(
            DATA_TYPE.AVERAGES
        ),
        value: DATA_TYPE.AVERAGES
    }
];

export const DataTypeToggle = () => {
    const initialDataType = useSelector(statComparisonDataTypeSelector);

    const { dataType, updateDataType } = useDataType({
        initialDataType,
        onUpdateActionCreator: UPDATE_DATA_TYPE.request
    });

    return (
        <ToggleInput
            id="careers-and-season-stats-data-type-toggle"
            value={
                dataType.type.toLowerCase().includes(DATA_TYPE.TOTALS)
                    ? DATA_TYPE.TOTALS
                    : DATA_TYPE.AVERAGES
            }
            onChange={updateDataType}
            options={dataTypeOptions}
        />
    );
};
