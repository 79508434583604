import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { UPDATE_NAV_ITEM } from 'store/modules/competition-nav/actions';
import { navSelector } from 'store/modules/competition-nav/selectors/nav-selector';
import { Loader } from 'common/react/components/Loader';

import { RoundButton } from './RoundButton';

export const RoundListNavItem = ({ onRoundClick }) => {
    const dispatch = useDispatch();
    const nav = useSelector(navSelector);
    const roundsListRef = useRef();

    const roundElements = useRef(new Array());
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const roundId = params.Round;

    const season = useSelector(
        (state) =>
            state.seasons[`competition_${nav.competition.id}`]?.[
                `season_${nav.season.id}`
            ]
    );

    const roundsLoadError = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.error
    );

    const roundsList = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list
    );

    useEffect(() => {
        if (roundElements?.current?.length) {
            for (const round of roundElements.current) {
                if (round && round.dataset.roundId === roundId) {
                    round.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'center'
                    });
                }
            }
        }
    }, [roundsList, roundId]);

    if (roundsLoadError) {
        return null;
    }

    if (!roundsList) {
        return (
            <ul className="competition-nav__round-list">
                <Loader />
            </ul>
        );
    }

    const onNavItemClick = (event, label, disableRounds) => {
        if (
            disableRounds &&
            label?.roundNumber &&
            typeof onRoundClick === 'function'
        ) {
            onRoundClick(label.roundNumber);
        }

        roundsListRef?.current.dispatchEvent(
            new Event(PULSE.app.common.CONSTANTS.EVENTS.INTERACTION.CHANGE, {
                bubbles: true
            })
        );

        dispatch(
            UPDATE_NAV_ITEM.request({
                navItem: 'round',
                value: label
            })
        );
    };

    return (
        <ul className="competition-nav__round-list" ref={roundsListRef}>
            {roundsList.length
                ? roundsList.map((round) => (
                      <li
                          className="competition-nav__round-list-item-wrapper"
                          key={round.name}
                          ref={(element) => roundElements.current.push(element)}
                          data-round-id={round.id}
                      >
                          <RoundButton
                              round={round}
                              currentRoundNumber={
                                  season?.currentRoundNumber ?? -1
                              }
                              onClick={onNavItemClick}
                          />
                      </li>
                  ))
                : ''}
        </ul>
    );
};

RoundListNavItem.propTypes = {
    onRoundClick: PropTypes.func,
    title: PropTypes.string
};
