import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

export const ModalLink = ({
    icon,
    label,
    link,
    btnLabel,
    onClick,
    cssModifier,
    openNewTab
}) => {
    return (
        <div className="modal-item__container">
            {link ? (
                <a
                    href={link}
                    className={`modal-link ${
                        cssModifier ? `modal-link--${cssModifier}` : ''
                    }`.trim()}
                    onClick={onClick}
                    target={openNewTab ? '_blank' : null}
                    rel="noreferrer"
                >
                    {icon ? (
                        <SvgIcon
                            icon={icon}
                            className={`icon modal-link__icon ${
                                cssModifier
                                    ? `modal-link__icon--${cssModifier}`
                                    : ''
                            }`.trim()}
                        />
                    ) : null}
                    <div
                        className={`modal-link__label ${
                            cssModifier
                                ? `modal-link__label--${cssModifier}`
                                : ''
                        }`.trim()}
                    >
                        <span className="modal-link__text-container">
                            {label}
                        </span>
                        {btnLabel ? (
                            <span className="filter-button">
                                {btnLabel}
                                <SvgIcon
                                    icon="arrow-right"
                                    className={`icon modal-link__icon ${
                                        cssModifier
                                            ? `modal-link__icon--${cssModifier}`
                                            : ''
                                    }`.trim()}
                                />
                            </span>
                        ) : null}
                    </div>
                </a>
            ) : null}
        </div>
    );
};

ModalLink.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    btnLabel: PropTypes.string,
    onClick: PropTypes.func,
    newTab: PropTypes.bool,
    cssModifier: PropTypes.string,
    openNewTab: PropTypes.bool
};
