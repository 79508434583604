import React, { useMemo } from 'react';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { MatchCardHeader } from '../components/header';
import { getTranslation } from 'common/react/utils/translations';
import { useTeamPosition } from '../../../hooks/get-position';
import PropTypes from 'prop-types';

/*
 * Render season completed match card
 */
export const MatchCardSeasonCompleted = ({ match }) => {
    const margin = useMemo(() => {
        const homeScore = match?.home?.score?.totalScore || 0;
        const awayScore = match?.away?.score?.totalScore || 0;
        const caculatedMargin = Math.abs(homeScore - awayScore);
        return caculatedMargin;
    }, [match]);

    // Team position
    const homeTeamPosition = useTeamPosition(match?.home.team.id);
    const awayTeamPosition = useTeamPosition(match?.away.team.id);

    return (
        <div className="ladder-predictor-match-card">
            <MatchCardHeader
                showComplete={true}
                startDateTime={match?.utcStartTime}
                venueName={match?.venue.name}
                showDate={false}
            />
            <div className="ladder-predictor-match-card__teams">
                <div className="ladder-predictor-match-card__team ladder-predictor-match-card__team--home-team">
                    <TeamIcon
                        className="ladder-predictor-match-card__team-logo"
                        providerId={match?.home.team.providerId}
                    />
                    <div className="ladder-predictor-match-card__position-dynamic">
                        <p
                            className="ladder-predictor-match-card__position-item"
                            aria-label="Team Position"
                        >
                            {homeTeamPosition.position}
                            {homeTeamPosition.positionChange === 'UP' && (
                                <SvgIcon
                                    icon="pos-change-up"
                                    className="icon"
                                />
                            )}
                            {homeTeamPosition.positionChange === 'DOWN' && (
                                <SvgIcon
                                    icon="pos-change-down"
                                    className="icon"
                                />
                            )}
                            {homeTeamPosition.positionChange === 'NONE' && (
                                <SvgIcon
                                    icon="pos-change-none"
                                    className="icon"
                                />
                            )}
                        </p>
                    </div>
                    <div
                        className="ladder-predictor-match-card__name"
                        aria-label="Team Name"
                    >
                        {match?.home.team.name}
                    </div>
                    <div
                        className="ladder-predictor-match-card__score"
                        aria-label="Team Score"
                    >
                        {match?.home.score?.totalScore}
                    </div>
                </div>
                <div className="ladder-predictor-match-card__team ladder-predictor-match-card__team--away-team">
                    <TeamIcon
                        className="ladder-predictor-match-card__team-logo"
                        providerId={match?.away.team.providerId}
                    />
                    <div className="ladder-predictor-match-card__position-dynamic">
                        <p
                            className="ladder-predictor-match-card__position-item"
                            aria-label="Team Position"
                        >
                            {awayTeamPosition.position}
                            {awayTeamPosition.positionChange === 'UP' && (
                                <SvgIcon
                                    icon="pos-change-up"
                                    className="icon"
                                />
                            )}
                            {awayTeamPosition.positionChange === 'DOWN' && (
                                <SvgIcon
                                    icon="pos-change-down"
                                    className="icon"
                                />
                            )}
                            {awayTeamPosition.positionChange === 'NONE' && (
                                <SvgIcon
                                    icon="pos-change-none"
                                    className="icon"
                                />
                            )}
                        </p>
                    </div>
                    <p
                        className="ladder-predictor-match-card__name"
                        aria-label="Team Name"
                    >
                        {match?.away.team.name}
                    </p>
                    <p
                        className="ladder-predictor-match-card__score"
                        aria-label="Team Score"
                    >
                        {match?.away.score?.totalScore}
                    </p>
                </div>
            </div>
            <div className="ladder-predictor-match-card__footer">
                <p className="ladder-predictor-match-card__detail">
                    {getTranslation('label.ladderPredictor.margin')}:
                    <strong>
                        {margin}{' '}
                        {getTranslation('label.ladderPredictor.ptsAbbr')}
                    </strong>
                </p>
            </div>
        </div>
    );
};

MatchCardSeasonCompleted.propTypes = {
    match: PropTypes.object.isRequired
};
