import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TooltipHeader } from './TooltipHeader';
import { TooltipVotes } from './TooltipVotes';
import { TooltipStats } from './TooltipStats';
import { TooltipLink } from './TooltipLink';
import { TooltipLoading } from './TooltipLoading';
import { FETCH_TOOLTIP_PLAYER_STATS } from 'store/modules/brownlow-tracker/tooltip/actions';
import {
    tooltipDataSelector,
    tooltipRequestsSelector
} from 'store/modules/brownlow-tracker/tooltip/selectors';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @returns {JSX.Element} Component.
 */
export const TooltipContent = (props) => {
    const dispatch = useDispatch();
    const data = useSelector(tooltipDataSelector);
    const playerData = data?.[props.seasonPid]?.[props.player];
    const requests = useSelector(tooltipRequestsSelector);

    const tooltipContentRef = useRef();

    useEffect(() => {
        if (!playerData) {
            dispatch(
                FETCH_TOOLTIP_PLAYER_STATS.request({
                    seasonPid: props.seasonPid,
                    playerId: props.player
                })
            );
        }
    }, [props.player, props.seasonPid]);

    useEffect(() => {
        if (tooltipContentRef.current) {
            tooltipContentRef.current.focus();
        }
    }, [tooltipContentRef.current, playerData]);

    const roundNumber = props?.cell?.column?.id?.replace('round', '');
    const roundMatches = props.row?.[props?.cell?.column?.id]?.matches ?? [];

    /**
     * For each match from the round, map the player stats.
     * If player stats don't exist (not played or just no data), creating a faux object so the tooltip won't crash
     */
    const matchesPerRound = useMemo(() => {
        if (playerData) {
            return roundMatches.map((match) => {
                return Object.assign(
                    {
                        utcStartTime: match.utcStartTime,
                        played: match.played
                    },
                    playerData?.rounds?.roundStats?.find(
                        (matchStats) => matchStats.matchId === match.matchId
                    ) ?? {
                        matchId: match.matchId,
                        roundNumber: roundNumber,
                        opponent: {
                            teamId: match.opponent.providerId,
                            teamAbbr: match.opponent.abbreviation,
                            teamName: match.opponent.name,
                            teamNickname: match.opponent.nickname
                        }
                    }
                );
            });
        }

        return [];
    }, [roundMatches, playerData?.rounds]);

    // get player leaderboard data
    const playerLeaderboardData = props.leaderboardData?.leaderboard.find(
        (item) => item.player.playerId === props.player
    );

    return (
        <>
            {playerData && !requests.length ? (
                <div key={playerData.player.playerId} ref={tooltipContentRef}>
                    {playerData.player && (
                        <TooltipHeader player={playerData.player} />
                    )}
                    <TooltipVotes
                        {...props}
                        player={playerData.player}
                        rounds={playerData.rounds}
                        matchesPerRound={matchesPerRound}
                    />
                    <TooltipStats
                        {...props}
                        player={playerData.player}
                        rounds={playerData.rounds}
                        matchesPerRound={matchesPerRound}
                        roundByRoundVotes={
                            playerLeaderboardData?.roundByRoundVotes
                        }
                    />
                    <TooltipLink
                        {...props}
                        player={playerData.player}
                        rounds={playerData.rounds}
                        matchesPerRound={matchesPerRound}
                    />
                </div>
            ) : (
                <TooltipLoading />
            )}
        </>
    );
};

TooltipContent.propTypes = {
    row: PropTypes.object.isRequired,
    cell: PropTypes.object.isRequired,
    benchmark: PropTypes.string,
    seasonPid: PropTypes.string,
    player: PropTypes.string,
    leaderboardData: PropTypes.object
};
