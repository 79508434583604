import React, { useRef, useEffect, createContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ViewportList } from 'react-viewport-list';

import { filtersSelector } from 'store/modules/competition-nav/selectors/filters-selector';
import { useGetMatchesQuery } from 'store/modules/api/afl-api';
import { filterByCity } from '../../utils/filter-by-city';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';

import { EmptyState } from 'common/react/components/EmptyState';

import { FixturesItem } from './FixturesItem';
import { ByesGroup } from './ByesGroup';
import { findFirstLiveOrUpcomingFixture } from '../../utils/helpers';
import { FixturesSkeleton } from './FixturesSkeleton';
import { FixturesLegend } from './FixturesLegend';

import { getTranslation } from 'common/react/utils/translations';
import { useGroupMatches } from 'common/react/hooks/use-group-matches';
import { useFilterMatches } from 'common/react/hooks/use-filter-matches';

const LAST_BYE_ROUND = 23;
const MARGIN_BOTTOM_DESKTOP = 24;
const MARGIN_BOTTOM_MOBILE = 16;

const checkIfBroadcasterGuide = (providerId) => {
    const translationString = getTranslation(
        `label.broadcastGuideURL.${providerId}`
    );

    if (translationString !== `label.broadcastGuideURL.${providerId}`) {
        return translationString;
    }

    return false;
};

export const FixtureCountContext = createContext('');

export const FixturesList = ({
    competitionId,
    competitionPid,
    compSeason,
    matchesVisibleNumber,
    roundNumber,
    broadcasterPromo,
    scrollToRound,
    setScrollToRound,
    specialRound
}) => {
    const filters = useSelector(filtersSelector);
    const selectedRegion = useSingleSiteSetting('region');
    const listRef = useRef(null);

    const disableRounds =
        filters?.cities.length > 0 ||
        filters?.teams.length > 0 ||
        filters?.venues.length > 0;

    const broadcastGuideUrl =
        checkIfBroadcasterGuide(compSeason.providerId) ?? '';

    const { matches = [], isMatchesFetching } = useGetMatchesQuery(
        {
            pageSize: 300,
            competitionId: competitionId,
            compSeasonId: compSeason.id,
            ...(!disableRounds && { roundNumber: roundNumber }),
            ...(filters.teams.length && { teamId: filters.teams }),
            ...(filters.venues.length && { venueId: filters.venues })
        },
        {
            selectFromResult: ({ data, isFetching }) => ({
                matches:
                    data && filters.cities.length
                        ? filterByCity(data, filters.cities)
                        : data,
                isMatchesFetching: isFetching
            })
        }
    );

    // Add index property for tracking
    const formattedMatches = matches.map((item, index) =>
        Object.assign({}, item, { index: index + 1 })
    );

    const isConferenceSeason =
        formattedMatches &&
        formattedMatches.some(
            (match) => match?.home?.conference || match?.away?.conference
        );

    // Group matches by date
    const matchItems = useGroupMatches(
        useFilterMatches(formattedMatches, matchesVisibleNumber),
        selectedRegion.timezone
    );

    /*
     * If rounds are disabled (so we're showing multiple rounds) we should
     * listen for when the round changes, i.e. a round button is clicked, and
     * scroll the viewport list to that clicked round.
     */
    useEffect(() => {
        if (scrollToRound && disableRounds) {
            const targetIndex = matchItems.findIndex(
                (item) => item?.round?.roundNumber === scrollToRound
            );
            if (targetIndex !== -1 && listRef.current) {
                listRef.current.scrollToIndex({
                    index: targetIndex,
                    offset: -160
                });
            }
            setScrollToRound(null);
        }
    }, [disableRounds, matchItems, scrollToRound, setScrollToRound]);

    if (isMatchesFetching) {
        return <FixturesSkeleton />;
    }

    if (!formattedMatches || !formattedMatches.length) {
        return <EmptyState cssClass="competition-nav__empty-state" />;
    }

    let firstLiveOrUpcomingFixture =
        findFirstLiveOrUpcomingFixture(formattedMatches);

    return (
        <>
            <FixtureCountContext.Provider value={formattedMatches?.length}>
                {matchItems?.length && disableRounds ? (
                    <ViewportList
                        ref={listRef}
                        items={matchItems}
                        itemMargin={
                            window.matchMedia('(max-width: 640px)')
                                .formattedMatches
                                ? MARGIN_BOTTOM_MOBILE
                                : MARGIN_BOTTOM_DESKTOP
                        }
                        overscan={10}
                        renderSpacer={({ ref, style }) => (
                            <div ref={ref} style={style}>
                                <FixturesSkeleton></FixturesSkeleton>
                            </div>
                        )}
                    >
                        {(item, index) => (
                            <FixturesItem
                                key={
                                    typeof item === 'string'
                                        ? `${item}-${index}`
                                        : item.id
                                }
                                index={index}
                                item={item}
                                broadcastGuideUrl={broadcastGuideUrl}
                                disableRounds={disableRounds}
                                broadcasterPromo={broadcasterPromo}
                                firstLiveOrUpcomingFixture={
                                    firstLiveOrUpcomingFixture?.id
                                }
                                competitionPid={competitionPid}
                                specialRound={specialRound}
                                compseasonId={compSeason.id}
                                roundNumber={roundNumber}
                            />
                        )}
                    </ViewportList>
                ) : matchItems?.length ? (
                    matchItems.map((item, index) => (
                        <FixturesItem
                            key={
                                typeof item === 'string'
                                    ? `${item}-${index}`
                                    : item.id
                            }
                            item={item}
                            broadcastGuideUrl={broadcastGuideUrl}
                            disableRounds={disableRounds}
                            broadcasterPromo={broadcasterPromo}
                            firstLiveOrUpcomingFixture={
                                firstLiveOrUpcomingFixture?.id
                            }
                            specialRound={specialRound}
                            competitionPid={competitionPid}
                            compseasonId={compSeason.id}
                            roundNumber={roundNumber}
                        />
                    ))
                ) : null}
            </FixtureCountContext.Provider>

            {!isNaN(roundNumber) &&
            roundNumber < LAST_BYE_ROUND &&
            !disableRounds &&
            compSeason.id ? (
                <ByesGroup
                    compseasonId={compSeason.id}
                    roundNumber={roundNumber}
                />
            ) : null}

            {isConferenceSeason && <FixturesLegend />}
        </>
    );
};

FixturesList.propTypes = {
    competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    competitionPid: PropTypes.string.isRequired,
    compSeason: PropTypes.object,
    matchesVisibleNumber: PropTypes.string,
    roundNumber: PropTypes.number,
    broadcasterPromo: PropTypes.object,
    scrollToRound: PropTypes.number,
    setScrollToRound: PropTypes.func,
    specialRound: PropTypes.string
};
