import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';

/**
 * Renders the MediaThumbnail for a news article.
 *
 * @param {string} compId - The competition ID.
 * @param {object} data - The media thumbnail's data.
 * @param {tags} string - The media thumbnail's tags.
 * @returns {JSX.Element} Component.
 */
export const MediaThumbnailFooter = ({ compId, data, tags }) => {
    const compAbbr =
        PULSE.app.common.competition.generateCompetitionAbbreviation(compId);
    const compAbbrLowercase = compAbbr?.toLowerCase();
    const url = PULSE.app.common.competition.generateCompetitionUrl(compId);
    const logo = PULSE.app.common.competition.generateCompetitionLogo(compId);

    const tagLabels = compAbbr
        ? data.tags.map((tag) => (tags.includes(tag.label) ? tag.label : null))
        : null;

    return (
        <div className={`media-thumbnail__footer`}>
            <div
                className={`media-thumbnail__reference ${
                    compAbbr
                        ? `media-thumbnail__reference--${compAbbrLowercase}`
                        : null
                }`}
            >
                {url ? (
                    <a href={url} className="media-thumbnail__comp-container">
                        <CompetitionLabel logo={logo} compAbbr={compAbbr} />
                    </a>
                ) : (
                    <CompetitionLabel logo={logo} compAbbr={compAbbr} />
                )}

                {/* Only display the chevron and label if there is a matching tag label on the content item */}
                {tagLabels?.map((tagLabel, tagIndex) => {
                    let tagUrl =
                        PULSE.app.common.content.getTagUrlFromTranslation(
                            tagLabel
                        );
                    let label = tagLabel?.replace('tag:', '');
                    return tagLabel ? (
                        <React.Fragment
                            key={`media-thumbnail_video-${tagIndex}`}
                        >
                            <SvgIcon
                                icon={`chevron-right`}
                                className={`icon media-thumbnail__chevron`}
                            />
                            <div
                                className={`media-thumbnail__tag-label media-thumbnail__tag-label--capitalize`}
                            >
                                {tagUrl ? (
                                    <a
                                        className="media-thumbnail__tag-link"
                                        href={tagUrl}
                                    >
                                        {label}
                                    </a>
                                ) : (
                                    label
                                )}
                            </div>
                        </React.Fragment>
                    ) : null;
                })}
            </div>
        </div>
    );
};

MediaThumbnailFooter.propTypes = {
    compId: PropTypes.string,
    data: PropTypes.object,
    tags: PropTypes.string
};

const CompetitionLabel = ({ logo, compAbbr }) => (
    <>
        {logo ? (
            <div className="media-thumbnail__comp-logo">
                <SvgIcon className={`icon`} icon={logo} />
            </div>
        ) : null}
        {compAbbr ? (
            <span className={`media-thumbnail__comp-label`}>{compAbbr}</span>
        ) : null}
    </>
);

CompetitionLabel.propTypes = {
    logo: PropTypes.string,
    compAbbr: PropTypes.string
};
