import React from 'react';
import PropTypes from 'prop-types';

import { Select } from './versions';
import { Loader } from '../Loader';

/**
 * @param {object} props - Props.
 * @param {string} props.label - Label.
 * @param {any[]} props.items - Items.
 * @param {Function} [props.itemToString] - Item to string.
 * @param {Function} [props.isReadyToRender] - Determines whether the select has
 *    all the info it needs to render.
 * @param {any} [props.initialSelectedItem] - Initial selected item.
 * @param {any} [props.activeItem] - Active item from state.
 * @param {Function} [props.onSelectedItemChange] - On change callback.
 * @param {bool} [props.isDisabled] - Is select disabled.
 * @returns {JSX.Element} Component.
 */
export const LoadableSelect = (props) => {
    const { isReadyToRender, ...otherProps } = props;

    const shouldShowLoader = (() => {
        if (isReadyToRender) {
            return !isReadyToRender();
        }

        return !props.items;
    })();

    if (shouldShowLoader) {
        return (
            <div className="select">
                <div className="select__loader-wrapper">
                    <Loader />
                </div>
            </div>
        );
    }

    return <Select {...otherProps} />;
};

LoadableSelect.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any),
    itemToString: PropTypes.func,
    isReadyToRender: PropTypes.func,
    initialSelectedItem: PropTypes.any,
    activeItem: PropTypes.any,
    onSelectedItemChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    hideLabel: PropTypes.bool,
    useDesktopOnly: PropTypes.bool,
    showTeamLogo: PropTypes.bool,
    displaySlim: PropTypes.bool,
    competitionSelected: PropTypes.bool,
    defaultItem: PropTypes.object,
    type: PropTypes.string
};
