import React from 'react';
import PropTypes from 'prop-types';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

const WOMEN_IDENTIFIER = 'WOMEN';
const WOMEN_SUFFIX = '-w';

/*
 * Render winner card
 */
export const WinnerCard = ({ teamName, teamAbbreviation, teamType }) => {
    return (
        <div
            className={`ladder-predictor-winner-card ${
                teamAbbreviation
                    ? 'ladder-predictor-winner-card--' +
                      teamAbbreviation.toLowerCase()
                    : ''
            }`}
            style={{
                backgroundImage: `url('${PULSE.app.environment.watermarksPath}${teamAbbreviation}-right-dark.png')`
            }}
        >
            <div className="ladder-predictor-winner-card__background">
                <div
                    className="ladder-predictor-winner-card__background-border"
                    style={{
                        backgroundImage: teamName
                            ? `url('/resources/${
                                  window.RESOURCES_VERSION
                              }/i/elements/trophy${
                                  teamType === WOMEN_IDENTIFIER
                                      ? WOMEN_SUFFIX
                                      : ''
                              }.png')`
                            : `url('/resources/${
                                  window.RESOURCES_VERSION
                              }/i/elements/trophy${
                                  teamType === WOMEN_IDENTIFIER
                                      ? WOMEN_SUFFIX
                                      : ''
                              }-20.png')`
                    }}
                >
                    <WinnerDetails
                        teamAbbreviation={teamAbbreviation}
                        teamName={teamName}
                    />
                    <PredictionSection teamName={teamName} />
                </div>
            </div>
        </div>
    );
};

const WinnerDetails = ({ teamAbbreviation, teamName }) => (
    <div className="ladder-predictor-winner-card__winner">
        <div className="ladder-predictor-winner-card__winner-border">
            <div
                className="ladder-predictor-winner-card__winner-image"
                style={{
                    backgroundImage: `url('${PULSE.app.environment.watermarksPath}${teamAbbreviation}-right-dark.png')`
                }}
            >
                {teamAbbreviation ? (
                    <TeamIcon
                        className="ladder-predictor-winner-card__team-icon icon"
                        teamAbbreviation={teamAbbreviation}
                        theme="light"
                    />
                ) : (
                    <SvgIcon
                        icon="question-mark"
                        className="ladder-predictor-winner-card__question-mark-icon icon"
                    />
                )}
            </div>
        </div>
        {teamName && (
            <div className="ladder-predictor-winner-card__winner-ribbon">
                {getTranslation('label.ladderPredictor.premiers')}
            </div>
        )}
    </div>
);

const PredictionSection = ({ teamName }) => (
    <div className="ladder-predictor-winner-card__prediction">
        <p className="ladder-predictor-winner-card__prediction-text">
            {teamName
                ? getTranslation('label.ladderPredictor.youPredicted')
                : getTranslation('label.ladderPredictor.yourPrediction')}
        </p>
        <h3 className="ladder-predictor-winner-card__prediction-team">
            {teamName ? teamName : '—'}
        </h3>
    </div>
);

WinnerCard.propTypes = {
    teamName: PropTypes.string,
    teamAbbreviation: PropTypes.string,
    teamType: PropTypes.string
};

WinnerDetails.propTypes = {
    teamName: PropTypes.string,
    teamAbbreviation: PropTypes.string
};

PredictionSection.propTypes = {
    teamName: PropTypes.string
};
