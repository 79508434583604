import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const SideBarItemSkeleton = () => {
    return (
        <li className="interactive-event-map__information-item--skeleton">
            <div className="interactive-event-map__information-item--skeleton-item">
                <Skeleton
                    className="interactive-event-map__information-item--skeleton-circle"
                    circle={true}
                    width={32}
                    height={32}
                />
                <Skeleton
                    className="interactive-event-map__information-item--skeleton-text"
                    width={200}
                    height={32}
                />
            </div>
        </li>
    );
};
