import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { LazyImage } from 'common/react/components/LazyImage';
import { FixtureCountContext } from '../../fixtures-list/FixturesList';

export const FixtureVideoThumbnail = ({ match, video }) => {
    const ref = useRef();
    const cardsCount = useContext(FixtureCountContext);

    const videoUrlParams = {
        pageSize: 50,
        tagNames: `ProgramCategory:${video.tags
            .find((item) => item.label.includes('ProgramCategory:'))
            ?.label.replace('ProgramCategory:', '')}`,
        references: ['AFL_COMPSEASON', 'AFL_ROUND']
            .map((reference) => {
                const refValue = video.references.find(
                    (item) => item.type === reference
                );
                if (refValue) {
                    return `${reference}:${refValue.id}`;
                }
                return null;
            })
            .filter((reference) => !!reference)
            .join(',')
    };

    const videoLink = `${video.link}&${PULSE.core.url.buildQueryString(
        videoUrlParams
    )}`;

    const videoModalData = {
        contentId: video.id,
        mediaId: video.mediaId,
        mediaTitle: PULSE.app.templating.helper.cleanString(video.title),
        mediaDescription: PULSE.app.templating.helper.cleanString(
            video.description
        ),
        subtitle: PULSE.app.templating.helper.cleanString(video.subtitle),
        type: video.type,
        link: videoLink,
        publishFrom: video.publishFrom + 1,
        mediaDate: PULSE.app.templating.helper.getDateNice(video.publishFrom)
    };

    useEffect(() => {
        if (ref.current) {
            PULSE.app.VideoPlayerModal?.createInstance(ref.current);
            ref.current.dataset.modalAdded = true;
        }
    }, []);

    return (
        <article className={`media-thumbnail`}>
            <div className="media-thumbnail__link">
                <a
                    ref={ref}
                    className={`media-thumbnail__absolute-link`}
                    data-ui-modal="#VideoModal"
                    href={videoLink}
                    data-content-id={video.id}
                    data-ui-args={JSON.stringify(videoModalData)}
                    onClick={() => {
                        PULSE.app.tracking.selectFixtureCardHandler(
                            match,
                            videoModalData?.mediaTitle,
                            videoLink,
                            cardsCount
                        );
                    }}
                >
                    <span className="u-screen-reader">{video.title}</span>
                </a>
                <header
                    className={`media-thumbnail__image-container placeholder placeholder--small placeholder--dark`}
                >
                    <LazyImage
                        image={video.thumbnail}
                        mediaQueryConfig={video.mediaQueryConfig}
                    />

                    <div className={`media-thumbnail__video-duration`}>
                        <SvgIcon
                            icon={'video-play-small'}
                            className={`icon media-thumbnail__duration-icon`}
                        />
                    </div>
                </header>
            </div>
        </article>
    );
};

FixtureVideoThumbnail.propTypes = {
    match: PropTypes.object,
    video: PropTypes.object.isRequired,
    category: PropTypes.string
};
