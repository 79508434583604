import { createApi } from '@reduxjs/toolkit/query/react';

import { accountHeaderWithRetryBQ } from './base-queries/account-header-with-retry';

const { app } = PULSE;

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const aflApi = createApi({
    reducerPath: 'aflAPI',
    baseQuery: accountHeaderWithRetryBQ(app.environment.api),
    endpoints: (build) => ({
        getCompetitions: build.query({
            query: () => 'competitions?pageSize=50',
            transformResponse: (response) => response?.competitions
        }),
        getCompSeasons: build.query({
            query: ({ competitionId, params = { pageSize: 20 } }) =>
                `competitions/${competitionId}/compseasons${app.common.prepareParams(
                    params
                )}`,
            transformResponse: (response) =>
                response?.compSeasons ? [...response.compSeasons] : []
        }),
        getCompSeason: build.query({
            query: ({ id }) => `compseasons/${id}`,
            transformResponse: (response) => response?.compSeasons?.[0]
        }),
        getTeams: build.query({
            query: (compSeasonId) =>
                `teams?compSeasonId=${compSeasonId}&pageSize=100`,
            transformResponse: (response) => response?.teams
        }),
        getMatches: build.query({
            query: (params) => `matches${app.common.prepareParams(params)}`,
            transformResponse: (response) => response?.matches
        }),
        getMatch: build.query({
            query: ({ id }) => `matches/${id}`,
            transformResponse: (response) => response?.matches
        }),
        getLadder: build.query({
            query: ({ compSeasonId, params }) =>
                `compseasons/${compSeasonId}/ladders${app.common.prepareParams(
                    params
                )}`
        }),
        getRounds: build.query({
            query: ({ compseasonId, params }) =>
                `compseasons/${compseasonId}/rounds${app.common.prepareParams(
                    params
                )}`,
            transformResponse: (response) => response?.rounds
        }),
        getVenues: build.query({
            query: (compSeasonId) =>
                `venues?compSeasonId=${compSeasonId}&pageSize=100`,
            transformResponse: (response) => response?.venues
        }),
        getBrownlowPredictor: build.query({
            query: ({ compSeasonId, page = 0, team = '', players = '' }) =>
                `/compseasons/${compSeasonId}/award/brownlow?page=${page}&pageSize=21${
                    team.length ? `&team=${team}` : ''
                }${players.length ? `&players=${players}` : ''}`,
            transformResponse: (response) => {
                return {
                    results: response?.players.slice(0, 20) || [],
                    newItems: response?.players || []
                };
            },
            serializeQueryArgs: ({ queryArgs }) => {
                const newQueryArgs = { ...queryArgs };
                if ('page' in newQueryArgs) {
                    delete newQueryArgs.page;
                }
                if ('players' in newQueryArgs) {
                    delete newQueryArgs.players;
                }
                return newQueryArgs;
            },
            merge: (currentCache, newResponse) => {
                if (currentCache) {
                    return {
                        results: [
                            ...currentCache.results,
                            ...newResponse.newItems.slice(0, 20)
                        ],
                        newItems: newResponse.newItems
                    };
                }
                return {
                    results: [...newResponse.newItems].slice(0, 20),
                    newItems: newResponse.newItems
                };
            },
            forceRefetch({ currentArg, previousArg }) {
                return (
                    currentArg?.page !== previousArg?.page ||
                    currentArg?.players[0] !== previousArg?.players[0]
                );
            }
        }),
        getBrownlowPredictorSearch: build.query({
            query: ({ compSeasonId, page = 0, term = '' }) =>
                `/compseasons/${compSeasonId}/award/brownlow/player-search?page=${page}&pageSize=21${
                    term !== '' ? `&term=${encodeURIComponent(term)}` : ''
                }`,
            transformResponse: (response) => {
                return {
                    results: response?.players.slice(0, 20) || [],
                    newItems: response?.players || []
                };
            },
            serializeQueryArgs: ({ queryArgs }) => {
                const newQueryArgs = { ...queryArgs };
                if ('page' in newQueryArgs) {
                    delete newQueryArgs.page;
                }
                return newQueryArgs;
            },
            merge: (currentCache, newResponse) => {
                if (currentCache) {
                    return {
                        results: [
                            ...currentCache.results,
                            ...newResponse.newItems.slice(0, 20)
                        ],
                        newItems: newResponse.newItems
                    };
                }
                return {
                    results: [...newResponse.newItems].slice(0, 20),
                    newItems: newResponse.newItems
                };
            }
        }),
        getMappedMatches: build.query({
            query: () => `matches/idmap`,
            transformResponse: (response) => response?.idMapResponse?.ids
        }),
        getMappedTeams: build.query({
            query: () => `teams/idmap`,
            transformResponse: (response) => response?.idMapResponse?.ids
        })
    })
});

export const {
    useGetCompetitionsQuery,
    useGetCompSeasonsQuery,
    useGetCompSeasonQuery,
    useGetTeamsQuery,
    useGetMatchesQuery,
    useGetMatchQuery,
    useGetLadderQuery,
    useGetRoundsQuery,
    useGetVenuesQuery,
    useGetBrownlowPredictorQuery,
    useGetBrownlowPredictorSearchQuery,
    useGetMappedMatchesQuery,
    useGetMappedTeamsQuery
} = aflApi;
