import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { PlayerEntry } from './PlayerEntry';
import { getTeamPlayersForPositions } from 'widgets/team-lineups-v2/js/utils/helpers';

/**
 * Oval Section - component displaying players in the sectino of the footy oval
 *
 * @param {object} props
 * @param {object} props.homeTeamPositions Positions of the home page as returned from the TEAM_LINEUPS_FIELD_VIEW_POSITIONS constant
 * @param {object} props.awayTeamPositions Positions of the away page as returned from the TEAM_LINEUPS_FIELD_VIEW_POSITIONS constant
 * @param {object} props.homeTeamPlayers Object containing home team's positions mapped to players. This is the object returned by mapPlayersToPositions helper
 * @param {object} props.awayTeamPlayers Object containing home team's positions mapped to players. This is the object returned by mapPlayersToPositions helper
 * @param {object} props.homeTeam Home team object
 * @param {object} props.awayTeam Away team object
 * @param {string} props.teamToShow Id of the team to display.
 * @param {boolean} [props.isAFLW] Default FALSE
 *
 * @returns {HTMLDivElement} Oval section element
 */
export const OvalSection = ({
    homeTeamPositions,
    awayTeamPositions,
    homeTeamPlayers,
    awayTeamPlayers,
    homeTeam,
    awayTeam,
    teamToShow = '-1',
    isAFLW = false
}) => {
    // mapping home team players to oval section positions
    const sectionHomePlayers = useMemo(() => {
        if (homeTeamPositions) {
            const homeTeamObject = getTeamPlayersForPositions(
                homeTeamPositions,
                homeTeam,
                homeTeamPlayers
            );
            homeTeamObject.homeTeam = true;
            return homeTeamObject;
        }

        return;
    }, [homeTeamPositions, homeTeamPlayers]);

    // mapping away team players to oval section positions
    const sectionAwayPlayers = useMemo(() => {
        if (awayTeamPositions) {
            return getTeamPlayersForPositions(
                awayTeamPositions,
                awayTeam,
                awayTeamPlayers
            );
        }

        return;
    }, [awayTeamPositions, awayTeamPlayers]);

    // list of teams to be displayed
    const teams = useCallback(() => {
        if (teamToShow === homeTeam?.teamId) {
            return [sectionHomePlayers];
        } else if (teamToShow === awayTeam?.teamId) {
            return [sectionAwayPlayers];
        }

        return [sectionHomePlayers, sectionAwayPlayers];
    }, [sectionHomePlayers, sectionAwayPlayers, teamToShow]);

    return (
        <div className="team-lineups__oval-section flex-column">
            {teams()
                .filter((team) => !!team)
                .map((team) => {
                    return (
                        <div
                            className="team-lineups__team-zone flex-row flex-row--nowrap"
                            key={`team-${team.id}-positions-${team.position}`}
                        >
                            <div className="team-lineups__team-zone-key">
                                <div
                                    className={`team-lineups__team-zone-position ${
                                        team.homeTeam
                                            ? 'team-lineups__team-zone-position--home-team'
                                            : 'team-lineups__team-zone-position--away-team'
                                    }`}
                                >
                                    {team.labelShort}
                                </div>
                            </div>
                            {team.players.map((player, playerIndex) =>
                                player ? (
                                    <PlayerEntry
                                        key={`team-lineups-${team.id}-${player.playerId}`}
                                        player={player}
                                        team={team.team}
                                        isHomeTeam={team.homeTeam}
                                        showPhoto={teamToShow !== '-1'}
                                        isAFLW={isAFLW}
                                        className="team-lineups__player-entry--fixed-width"
                                        trackedView="field view"
                                    />
                                ) : (
                                    <div
                                        className="team-lineups__player-entry--faux"
                                        key={`team-lineups-${team.id}-faux-${playerIndex}`}
                                    />
                                )
                            )}
                            <div className="team-lineups__team-zone-key" />
                        </div>
                    );
                })}
        </div>
    );
};

OvalSection.propTypes = {
    homeTeamPositions: PropTypes.object,
    awayTeamPositions: PropTypes.object,
    homeTeamPlayers: PropTypes.object,
    awayTeamPlayers: PropTypes.object,
    teamToShow: PropTypes.string,
    homeTeam: PropTypes.object,
    awayTeam: PropTypes.object,
    isAFLW: PropTypes.bool
};
