import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FixturesDateHeader } from 'widgets/fixtures/js/components/fixtures-list/FixturesDateHeader';
import { TeamLineupsItem } from './TeamLineupsItem';
import { TeamLineupsEmpty } from './TeamLineupsEmpty';

const DIGIT_ONLY_REGEX = /^[0-9]*$/;

export const TeamLineupsItemWrapper = ({
    item,
    expandLineups,
    setExpandLineups,
    shouldScrollTo,
    isAFLW = false
}) => {
    const itemType = useMemo(() => {
        if (typeof item === 'string') {
            if (item.match(DIGIT_ONLY_REGEX) || item === 'TBCPC') {
                return 'date';
            }
            return null;
        }

        if (
            typeof item === 'object' &&
            'providerId' in item &&
            !('roundNumber' in item)
        ) {
            return 'match';
        }

        return null;
    }, [item]);

    const hasLineups = useMemo(() => {
        return (
            item?.rosterItem?.matchRoster?.homeTeam?.positions?.length > 0 &&
            item?.rosterItem?.matchRoster?.awayTeam?.positions?.length > 0
        );
    }, [item?.rosterItem]);

    switch (itemType) {
        case 'date':
            return <FixturesDateHeader dateKey={item} />;
        case 'match':
            return item?.rosterItem && hasLineups ? (
                <TeamLineupsItem
                    match={item}
                    expandLineups={expandLineups}
                    setExpandLineups={setExpandLineups}
                    shouldScrollTo={shouldScrollTo}
                    isAFLW={isAFLW}
                />
            ) : (
                <TeamLineupsEmpty match={item} />
            );
        default:
            return null;
    }
};

TeamLineupsItemWrapper.propTypes = {
    item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    expandLineups: PropTypes.bool,
    setExpandLineups: PropTypes.func,
    shouldScrollTo: PropTypes.bool,
    isAFLW: PropTypes.bool
};
