import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { useGetContentQuery } from 'common/store/modules/api/content-api';
import { useSiteSettings } from 'common/react/hooks/use-site-settings';
import { prioritiseVideo } from 'widgets/fixtures/js/utils/prioritise-video';
import { MEDIA_TAGS_BY_STATUS } from 'widgets/fixtures/js/utils/media-tags';

const CAPTION_VARIANTS = {
    'ProgramCategory:Match Highlights': getTranslation(
        'label.fixtures.match-highlights'
    ),
    'ProgramCategory:Mini-Matches': getTranslation(
        'label.fixtures.mini-matches'
    ),
    'ProgramCategory:Match Replays': getTranslation(
        'label.fixtures.match-replays'
    )
};

export const THUMBNAIL_VARIANT = [
    {
        name: 'all',
        size: { width: 304, height: 172 },
        minWidth: 0,
        multipliers: [1, 2]
    },
    {
        name: 'default',
        size: { width: 304, height: 172 }
    }
];

const BETTING_TAG = 'sponsor:betting';

export const MobileFixtureMediaButton = ({ match }) => {
    const ref = useRef();
    const { siteSettings, settingsConfig } = useSiteSettings();

    const queryTags = MEDIA_TAGS_BY_STATUS[
        PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED
    ].map((item) => `"${item}"`);

    const requestConfig = {
        type: 'VIDEO',
        params: {
            pageSize: 10,
            references: `AFL_MATCH:${match.id}`,
            tagExpression: `(${queryTags?.join('or')})`
        }
    };

    const { video, category, isVideoLoading } = useGetContentQuery(
        requestConfig,
        {
            selectFromResult: ({ data, isLoading }) => {
                if (!data?.content) {
                    return {
                        video: null,
                        category: null,
                        isVideoLoading: isLoading
                    };
                }

                const { video: foundVideo, category: foundCategory } =
                    prioritiseVideo(data.content);

                return {
                    video: PULSE.app.common.content.getContentModel(
                        foundVideo,
                        THUMBNAIL_VARIANT
                    ),
                    category: foundCategory,
                    isVideoLoading: isLoading
                };
            }
        }
    );

    useEffect(() => {
        if (ref.current) {
            PULSE.app.VideoPlayerModal.createInstance(ref.current);
            ref.current.dataset.modalAdded = true;
        }
    }, []);

    if (!video || isVideoLoading) {
        return null;
    }

    // don't show video if betting is hidden and has a sponsor:betting tag
    const hasBettingTag = video.tags.find((tag) => tag.label === BETTING_TAG);

    if (!siteSettings[settingsConfig.displayWagering.key] && hasBettingTag) {
        return null;
    }

    const videoUrlParams = {
        pageSize: 50,
        tagNames: `ProgramCategory:${video.tags
            .find((item) => item.label.includes('ProgramCategory:'))
            ?.label.replace('ProgramCategory:', '')}`,
        references: ['AFL_COMPSEASON', 'AFL_ROUND']
            .map((reference) => {
                const refValue = video.references.find(
                    (item) => item.type === reference
                );
                if (refValue) {
                    return `${reference}:${refValue.id}`;
                }
                return null;
            })
            .filter((reference) => !!reference)
            .join(',')
    };

    const videoLink = `${video.link}&${PULSE.core.url.buildQueryString(
        videoUrlParams
    )}`;

    const videoModalData = {
        contentId: video.id,
        mediaId: video.mediaId,
        mediaTitle: PULSE.app.templating.helper.cleanString(video.title),
        mediaDescription: PULSE.app.templating.helper.cleanString(
            video.description
        ),
        subtitle: PULSE.app.templating.helper.cleanString(video.subtitle),
        type: video.type,
        link: videoLink,
        publishFrom: video.publishFrom + 1,
        mediaDate: PULSE.app.templating.helper.getDateNice(video.publishFrom)
    };

    return (
        <a
            ref={ref}
            className="fixtures__link-button fixtures__media-button button-new"
            data-ui-modal="#VideoModal"
            href={videoLink}
            data-content-id={video.id}
            data-ui-args={JSON.stringify(videoModalData)}
        >
            <SvgIcon
                icon={'video-playback-new'}
                className={`icon fixtures__button-icon`}
            />
            <span>{CAPTION_VARIANTS[category]}</span>
        </a>
    );
};

MobileFixtureMediaButton.propTypes = {
    match: PropTypes.object.isRequired
};
