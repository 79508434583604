/* eslint-disable no-param-reassign */

import { generateLadder } from './generate-ladder';

/**
 * Generates predictions for a given set of matches based on the current predicted ladder,
 * dynamically updating the ladder as predictions are made using the `generateLadder` utility.
 *
 * @param {Array} matches - An array of match objects
 * @param {Array} teams - An array of team objects
 * @param {Array} previousLadder - An array representing the initial ladder
 * @param {Array} existingPredictions - An array of existing prediction objects
 * @returns {Array} - An updated array of prediction objects
 */
export const generatePredictions = (
    matches,
    teams,
    previousLadder,
    existingPredictions = []
) => {
    let predictions = [];
    let currentLadder = [...previousLadder];

    // Get starting point for ladder if predictions already exist
    if (existingPredictions) {
        previousLadder = generateLadder(
            existingPredictions,
            teams,
            previousLadder
        );
    }

    matches.forEach((match) => {
        const { id, home, away, round } = match;

        // Update the ladder based on the current predictions in order to
        // always create predictions based on current ladder position.
        currentLadder = generateLadder(predictions, teams, previousLadder);

        // Find the position of the home and away teams in the updated ladder
        const homeRank = currentLadder.find(
            (entry) => entry.team.id === home.team.id
        )?.position;

        const awayRank = currentLadder.find(
            (entry) => entry.team.id === away.team.id
        )?.position;

        // Determine the winner and loser based on ranks
        const winner = homeRank < awayRank ? home.team.id : away.team.id;
        const loser = homeRank < awayRank ? away.team.id : home.team.id;

        // Create the prediction object
        const prediction = {
            i: id,
            r: round.roundNumber,
            w: winner,
            l: loser,
            d: false,
            m: 20
        };

        // Add the new prediction to the list
        predictions.push(prediction);
    });

    return predictions;
};
