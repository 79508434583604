import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LazyImage } from 'common/react/components/LazyImage';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { selectCardHandler } from './util';
import { MediaThumbnailFooter } from './MediaThumbnailFooter';

/**
 * Renders the MediaThumbnail for a news article.
 *
 * @param {string} title - The widget title.
 * @param {object} article - The club news article data.
 * @param {string} tags - The tags to provide as tag labels.
 * @returns {JSX.Element} Component.
 */

const BASE_URL_PATH = 'news';

export const MediaThumbnailArticle = ({ article, index, tags }) => {
    const refs = article.references;
    const compId = refs.find((ref) => ref.type === 'AFL_COMPETITION')?.sid;

    // Responsive
    const windowSize = useWindowSize();

    const isResponsive = () =>
        windowSize.width <= PULSE.app.measurements.phablet;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    return (
        <article className={`media-thumbnail`}>
            <div className={`media-thumbnail__link`}>
                <a
                    className={`media-thumbnail__absolute-link js-media-thumbnail__link`}
                    href={`/${BASE_URL_PATH}/${article?.id}/${article?.titleUrlSegment}`}
                    title={article?.title}
                    aria-label={article?.title}
                    data-index-position={index + 1}
                    onClick={(evt) => selectCardHandler(evt, article?.title)}
                ></a>
                <header
                    className={`media-thumbnail__image-container placeholder placeholder--small placeholder--dark`}
                >
                    <div
                        className={`js-lazy-load lazy-image-wrapper is-loaded`}
                    >
                        <LazyImage
                            url={article?.imageUrl}
                            cssClass="js-faded-image fade-in-on-load object-fit-cover-picture__img is-loaded"
                        />
                    </div>
                </header>
                <div className={`media-thumbnail__caption`}>
                    <h3 className={`media-thumbnail__title`}>
                        {article?.title}
                    </h3>
                    <p className={`media-thumbnail__description`}>
                        {article?.description}
                    </p>
                    {!showResponsiveItems ? (
                        <MediaThumbnailFooter
                            compId={compId}
                            data={article}
                            tags={tags}
                        />
                    ) : null}
                </div>
            </div>
            {showResponsiveItems ? (
                <MediaThumbnailFooter
                    compId={compId}
                    data={article}
                    tags={tags}
                />
            ) : null}
        </article>
    );
};

MediaThumbnailArticle.propTypes = {
    article: PropTypes.object,
    index: PropTypes.number,
    tags: PropTypes.string
};
