import { createSelector } from '@reduxjs/toolkit';
import { CONFIG } from '../components/constants';

export const selectPredictions = (state) => state.ladderPredictor.predictions;

export const selectRemainingPredictions = createSelector(
    [selectPredictions],
    (predictions) => (fixtures) => {
        const summary = {
            ByRound: {},
            ByTeam: {}
        };

        const predictionMap = new Set(predictions.map((p) => p.i));

        fixtures.forEach((fixture) => {
            const roundId = fixture.round.id;
            const homeTeamId = fixture.home.team.id;
            const awayTeamId = fixture.away.team.id;
            const fixtureId = fixture.id;
            const isCompleted = fixture.status === CONFIG.CONCLUDED;

            // Initialize round entry if not present
            if (!summary.ByRound[roundId]) {
                summary.ByRound[roundId] = {
                    totalGames: 0,
                    remainingPredictions: 0
                };
            }
            summary.ByRound[roundId].totalGames++;

            // Initialize team entries if not present
            [homeTeamId, awayTeamId].forEach((teamId) => {
                if (!summary.ByTeam[teamId]) {
                    summary.ByTeam[teamId] = {
                        totalGames: 0,
                        remainingPredictions: 0
                    };
                }
                summary.ByTeam[teamId].totalGames++;
            });

            // If the game is not completed, count remaining predictions
            if (!isCompleted && !predictionMap.has(fixtureId)) {
                summary.ByRound[roundId].remainingPredictions++;
                summary.ByTeam[homeTeamId].remainingPredictions++;
                summary.ByTeam[awayTeamId].remainingPredictions++;
            }
        });

        // Ensure all rounds and teams appear, even if they have zero fixtures
        fixtures.forEach((fixture) => {
            const roundId = fixture.round.id;
            const homeTeamId = fixture.home.team.id;
            const awayTeamId = fixture.away.team.id;

            if (!summary.ByRound[roundId]) {
                summary.ByRound[roundId] = {
                    totalGames: 0,
                    remainingPredictions: 0
                };
            }

            [homeTeamId, awayTeamId].forEach((teamId) => {
                if (!summary.ByTeam[teamId]) {
                    summary.ByTeam[teamId] = {
                        totalGames: 0,
                        remainingPredictions: 0
                    };
                }
            });
        });

        return summary;
    }
);
