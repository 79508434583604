import PropTypes from 'prop-types';
import React from 'react';

import { TeamIcon } from 'common/react/components/TeamIcon';
import { TeamWatermarkBackground } from 'widgets/statspro/js/components/TeamWatermarkBackground';
import { useSingleSiteSetting } from 'common/react/hooks/use-single-site-setting';
import { SvgIcon } from 'common/react/components/SvgIcon';

export const Header = ({ match }) => {
    const homeTeamId = match.home.team.providerId;
    const awayTeamId = match.away.team.providerId;

    const { name: venueName, location: venueLocation, landOwner } = match.venue;

    const name = `${match.home.team.name} v ${match.away.team.name}`;

    const selectedRegion = useSingleSiteSetting('region');
    const startTime = moment(match.utcStartTime);

    const homeAbbr = PULSE.app.common.team.getTeamAbbr(homeTeamId);
    const awayAbbr = PULSE.app.common.team.getTeamAbbr(awayTeamId);

    const matchLink = PULSE.app.common.url.getMatchURL({
        compSeason: {
            providerId: match.compSeason.providerId
        },
        id: match.id
    });

    const onClick = () => {
        const url = new URL(matchLink, location.origin);

        /* eslint-disable camelcase */
        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.SELECT.LINK,
            {
                eventData: {
                    link_text: `${name}`,
                    link_url: matchLink,
                    outbound: url.origin !== location.origin,
                    type:
                        url.origin !== location.origin
                            ? 'external'
                            : 'internal',
                    component_name: 'team lineups',
                    component_content_type:
                        PULSE.app.common.CONSTANTS.TRACKING.CONTENT_TYPES
                            .LINEUPS
                }
            }
        );
        /* eslint-enable camelcase */
    };

    return (
        <a className="team-lineups-header" href={matchLink} onClick={onClick}>
            <TeamWatermarkBackground
                className="team-lineups-header__watermark team-lineups-header__watermark--home"
                teamAbbreviation={homeAbbr}
                side="left"
            />
            <TeamWatermarkBackground
                className="team-lineups-header__watermark team-lineups-header__watermark--away"
                teamAbbreviation={awayAbbr}
            />
            <div className="team-lineups-header__details u-hide-from-tablet">
                <span className="team-lineups-header__name">
                    {name.split(' ').slice(0, -1).join(' ')}{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>
                        {name.split(' ').pop()}
                        <SvgIcon icon="arrow-right" className="icon" />
                    </span>
                </span>
            </div>
            <div className="team-lineups-header__content">
                <div className="team-lineups-header__logo-wrapper">
                    <TeamIcon
                        providerId={homeTeamId}
                        className="team-lineups-header__logo"
                        theme="light"
                    />
                </div>
                <div className="team-lineups-header__details">
                    <span className="team-lineups-header__name u-hide-until-tablet">
                        {name.split(' ').slice(0, -1).join(' ')}{' '}
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {name.split(' ').pop()}
                            <SvgIcon icon="arrow-right" className="icon" />
                        </span>
                    </span>
                    <div className="team-lineups-header__info">
                        <span>
                            {startTime
                                .tz(selectedRegion.timezone)
                                .format(
                                    PULSE.app.common.CONSTANTS.MATCH_TIME_FORMAT
                                )}
                            {startTime.tz(selectedRegion.timezone).format('a')}
                        </span>
                        {'\uFF65'}
                        <span>
                            {venueName}, {venueLocation}
                        </span>
                        {'\uFF65'}
                        <span>{landOwner}</span>
                    </div>
                </div>
                <div className="team-lineups-header__logo-wrapper">
                    <TeamIcon
                        providerId={awayTeamId}
                        className="team-lineups-header__logo"
                        theme="light"
                    />
                </div>
            </div>
        </a>
    );
};

Header.propTypes = {
    match: PropTypes.object.isRequired
};
