import React from 'react';
import PropTypes from 'prop-types';
import { useGetRoundsQuery } from 'store/modules/api/afl-api';
import { TeamIcon } from 'common/react/components/TeamIcon';
import { getTranslation } from 'common/react/utils/translations';

const PAGE_SIZE = 1;

/*
 * Render byes group
 */
export const ByesGroup = ({ compseasonId, roundNumber }) => {
    const { byesData } = useGetRoundsQuery(
        {
            compseasonId: compseasonId,
            params: {
                roundNumber: roundNumber,
                pageSize: PAGE_SIZE
            }
        },
        {
            selectFromResult: ({ data }) => ({
                byesData: data?.[0]?.byes || []
            })
        }
    );

    const renderByes = () => {
        return byesData.map((item) => (
            <TeamIcon
                key={item.id}
                className="ladder-predictor-sidebar__bye-fixture"
                providerId={item.providerId}
            />
        ));
    };

    return (
        <>
            {byesData.length > 0 && (
                <>
                    <div className="ladder-predictor-sidebar__list-divider">
                        <h3 className="ladder-predictor-sidebar__list-title">
                            {getTranslation('label.fixtures.byeRound')}
                        </h3>
                    </div>

                    <div className="ladder-predictor-sidebar__bye-fixtures">
                        {renderByes()}
                    </div>
                </>
            )}
        </>
    );
};

ByesGroup.propTypes = {
    compseasonId: PropTypes.number.isRequired,
    roundNumber: PropTypes.number.isRequired
};
