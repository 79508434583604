/* eslint-disable react/prop-types */
import React from 'react';
import { getVisibilityCondition } from '../DefaultTable/TableRow';

const TableModalStatGroup = ({
    state,
    fieldKey,
    tableName,
    handleAddRemoveFieldClick,
    getTranslation,
    setAccordionItem,
    getAccordionItem,
    setDescriptionShowing,
    getDescriptionShowing,
    closeDescription,
    customTable
}) => {
    const isFieldShowing = (field) => {
        return (
            state.displayedFields.filter(
                (displayedField) => displayedField.fieldName === field.fieldName
            ).length > 0
        );
    };

    const legendIcon = PULSE.app.templating.render(
        {
            cssClass: '',
            name: 'info'
        },
        'common.svg-icon'
    );

    const closeIcon = PULSE.app.templating.render(
        {
            cssClass: 'custom-table__description-close-icon',
            name: 'close'
        },
        'common.svg-icon'
    );

    if (state.tableFieldGroups[fieldKey].isPersistent) {
        return '';
    }

    const getDescription = (field) => {
        const descriptionShowing = getDescriptionShowing(field);

        if (
            field.description &&
            getTranslation(field.description) !== field.description
        ) {
            return (
                <div
                    className={`custom-table-modal__stats-item-description-container js-stats-item-description ${
                        descriptionShowing ? 'is-active' : ''
                    }`}
                    onClick={(evt) => setDescriptionShowing(evt, field)}
                >
                    <span
                        className="custom-table-modal__stats-item-icon"
                        dangerouslySetInnerHTML={{ __html: legendIcon }}
                    ></span>
                    <div className="custom-table__description">
                        <button
                            className="custom-table__description-close u-hide-from-tablet js-close-btn"
                            onClick={() => closeDescription()}
                        >
                            <span
                                dangerouslySetInnerHTML={{ __html: closeIcon }}
                            ></span>
                        </button>
                        <span className="custom-table__description-responsive-background">
                            <h4 className="custom-table__description-title">
                                {getTranslation(field.fieldLabel)}
                            </h4>
                            <p className="custom-table__description-text">
                                {getTranslation(field.description)}
                            </p>
                        </span>
                    </div>
                </div>
            );
        }

        return '';
    };

    // Check for visibility condition functions and fire any found
    const filtereredFields = state.tableFieldGroups[fieldKey].fields.filter(
        (field) => {
            if (typeof field.visibiltyConditionFunc !== 'string') {
                return true;
            }

            return getVisibilityCondition(field.visibiltyConditionFunc)(
                customTable.dataJson
            );
        }
    );

    return (
        <div className="custom-table-modal__stats-group-container">
            <div
                className={`custom-table-modal__stats-group-title-button-container  ${
                    getAccordionItem(fieldKey) ? 'is-open' : ''
                }`}
            >
                <h4 className="custom-table-modal__stats-group-title">
                    {getTranslation(
                        state.tableFieldGroups[fieldKey].groupLabel
                    )}
                </h4>
                <button
                    className={`custom-table-modal__stats-group-toggle-button u-hide-from-tablet ${
                        getAccordionItem(fieldKey) ? 'is-open' : 'is-closed'
                    }`}
                    onClick={() => setAccordionItem(fieldKey)}
                >
                    <span className="custom-table-modal__stats-group-toggle-icon"></span>
                </button>
            </div>
            <div
                className={`custom-table-modal__stats-group-list ${
                    getAccordionItem(fieldKey)
                        ? 'is-opening is-open'
                        : 'is-closed'
                }`}
            >
                {filtereredFields.map((field) => (
                    <div
                        key={`${state.tableFieldGroups[fieldKey].groupName}_${field.fieldName}`}
                        className={`custom-table-modal__stats-item ${
                            field.description
                                ? ''
                                : 'custom-table-modal__stats-item--no-description'
                        }`}
                    >
                        <button
                            className="custom-table-modal__add-remove-button"
                            onClick={() =>
                                handleAddRemoveFieldClick(
                                    field,
                                    tableName,
                                    state.tableFieldGroups[fieldKey].groupName
                                )
                            }
                        >
                            <span
                                className={`custom-table-modal__add-remove-button-icon custom-table-modal__add-remove-button-icon--${
                                    isFieldShowing(field) ? 'remove' : 'add'
                                }`}
                            ></span>
                            <span className="custom-table-modal__add-remove-short-title">
                                ({getTranslation(field.fieldLabelShort)})
                            </span>
                            {getTranslation(field.fieldLabel)}
                        </button>

                        {getDescription(field)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TableModalStatGroup;
